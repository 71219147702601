import { Component, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project, Room, OrderSet, OrderSetItem, Sku } from '../../../_models';
import * as XLSX from 'xlsx';
import { OrderService } from '../../../services/orders.service';
import { AppComponent } from '../../app.component';
class XlsRow {
    sku: Sku;
    qty: number;
}
export interface FileReaderProgressEvent extends EventTarget {
    result: string
}
export interface B2BNewProjectDialogData {
    orderService: OrderService;
    importFile: boolean;
}

@Component({
    selector: 'new-project-dialog',
    templateUrl: './new-project-dialog.component.html',
    styleUrls: ['./new-project-dialog.component.scss']
})
export class B2BNewProjectDialog implements AfterViewInit {
    static parent: any;
    ngAfterViewInit(): void {
        if (this.importFile)
            this.clickFileUpload();
    }
    get roomName() {
        return this.project!=null ? this.project.rooms[0].title:null;
    }
    //projectName: string;
    project: Project;
    importFile: boolean;
    orderService: OrderService;
    static functionsAll: Array<Sku>;
    static xlsContent: Array<XlsRow>;
    public static nextStepOffset = 1;
    constructor(
        public dialogRef: MatDialogRef<B2BNewProjectDialog>,
        @Inject(MAT_DIALOG_DATA) public data: B2BNewProjectDialogData) {
        this.orderService = data.orderService;
        this.importFile = data.importFile;
        B2BNewProjectDialog.xlsContent = null;
        B2BNewProjectDialog.nextStepOffset = 1;
        this.orderService.httpclient.get<Sku[]>('/api/catalogdata/functionsAll').subscribe(result => {
             B2BNewProjectDialog.functionsAll = result;
        }, error => console.error(error));
        let room = new Room(this.roomName);
        this.project = {
            title: null, rooms: [room], type:0
        }
        this.project.rooms[0].orderSets = new Array<OrderSet>();
        let nos = { id: 0, title: null, orderSetItems: null };
        this.project.rooms[0].orderSets.push(nos);
        this.project.rooms[0].orderSets[0].orderSetItems = new Array<OrderSetItem>();
        
    }


    onNoClick(): void {
        AppComponent.SendEventGa('new-project', null, 'B2B_Anulowanie konfiguracji projektu', 0);
        this.dialogRef.close();

    }
    
    get namesInValid() {
        return this.project.title == null ||
            this.project.title.length == 0 ||
            this.roomName == null ||
            this.roomName.length == 0;
    }
    get projectNameInValid() {
        return this.project.title == null ||
            this.project.title.length == 0;
    }

    clickFileUpload() {
        AppComponent.SendEventGa('new-project', 'select', 'B2B_Import formatki z produktami', 0);
        var input = document.getElementById("inputfile");
        //input.addEventListener('change', this.handleFile, false);
        input.click();
    }
    get xlsContent() {
        return B2BNewProjectDialog.xlsContent;
    }
    file: File = null;
    importedFileName: string = null;
    handleFile(e) {
        AppComponent.SendEventGa('new-project', 'load', 'B2B_Import formatki z produktami wybór pliku', 0);
        var rABS = true; // true: readAsBinaryString ; false: readAsArrayBuffer
        var files = e.target.files, f = files[0];
        this.file = f; 
        var reader: any,
            target: EventTarget;
        reader = new FileReader();
        reader.onload = function (imgsrc: any) {

            var data = imgsrc.target.result;
            //var data = reader.result;
            if (!rABS) data = new Uint8Array(data);
            var workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
            
            console.log("uploaded xls: " + workbook.SheetNames[0]);
            var worksheet = workbook.Sheets[workbook.SheetNames[0]];
            B2BNewProjectDialog.xlsContent = new Array<XlsRow>();
            
            let codeCol = 'A';
            let qtyCol = 'B';
            var rowOffset = 1;
            var icodeCell = worksheet[codeCol + rowOffset];
            var readNext = icodeCell != null;
            while (readNext && icodeCell != null) {
                if (worksheet[qtyCol + rowOffset] == null)
                    break;
                let iqty = parseInt(worksheet[qtyCol + rowOffset].v);
                console.log("import row: " + codeCol + rowOffset +
                    "=" + icodeCell.v + ": " + qtyCol + rowOffset +
                    "=" + iqty);
                if (!isNaN(iqty)) {
                    let isku = B2BNewProjectDialog.functionsAll.find(s => s.code == icodeCell.v);
                    if(isku != null)
                        B2BNewProjectDialog.xlsContent.push({ sku: isku, qty: iqty });
                }
                rowOffset += 1;
                icodeCell = worksheet[codeCol + rowOffset];
                readNext = icodeCell != null;
            }
        };
        if (rABS) reader.readAsBinaryString(f); else reader.readAsArrayBuffer(f);
        this.importedFileName = this.file.name;
        B2BNewProjectDialog.nextStepOffset = 3;
    }
    static saveProjectAndGenerateCart(p: Project) {
        document.getElementById("waitingoverlay").hidden = false;
        B2BNewProjectDialog.parent.orderService.addAndOpenProjectWithDefaultRoom(p, B2BNewProjectDialog.goOn);

        //B2BNewProjectDialog.parent.orderService.generateCartOnCurrentAndCallback(B2BNewProjectDialog.goOn)
    }
    static goOn() {
        document.getElementById("waitingoverlay").hidden = true;
        B2BNewProjectDialog.parent.incStep(B2BNewProjectDialog.nextStepOffset);
        B2BNewProjectDialog.parent.close();
    }
    
    onSubmit(project, xlsContent) {
    /*
        if (xlsContent != null) {
            for (let row of xlsContent) {
                console.log("adding sku:" + row.sku.code + " number: " + row.qty);
                let newOsi = { sku: row.sku, qty: row.qty, id: -1 };
                project.rooms[0].orderSets[0].orderSetItems.push(newOsi);
            }
        }
        this.saveProjectAndGenerateCart(project);
        */
    }

    onSubmitClick() {
        this.dialogRef.close([this.project, this.xlsContent]);
    }
    public static newProjectConfig(parent: any, importFile: boolean = false) {
        AppComponent.SendEventGa('new-project', 'start', 'B2B_Otwarcie konfiguracji projektu z dialogu startowego', 0);
        let newProject: Project = { title: "Przykładowy projekt", rooms: new Array<Room>(), type: 0 };
        this.parent = parent;
        B2BNewProjectDialog.nextStepOffset = 1;
        const dialogRef = parent.dialog.open(B2BNewProjectDialog, {
            minwidth: '250px',
            data: { orderService: parent.orderService, importFile: importFile }
        });
        this.nextStepOffset
        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != null) {
                if (result[1] != null) {
                    for (let row of result[1]) {
                        console.log("adding sku:" + row.sku.code + " number: " + row.qty);
                        let newOsi = { sku: row.sku, qty: row.qty, id: -1 };
                        result[0].rooms[0].orderSets[0].orderSetItems.push(newOsi);
                    }
                }
                this.saveProjectAndGenerateCart(result[0]);
            }
        });
    }
}
