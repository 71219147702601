<div class="pseudoshadow" *ngIf="!fullscreen">
    <div class="frame-parameters-container">
        <div class="frame-parameter-container">
            <div class="frame-parameter">
                <h3>Kolor</h3>
                <div class="small-screen selected-color-label">{{selectedColorTitle}}</div>
                <div class="frame-parameter-values">
                    <div class="frame-parameter-value  {{colorSelectedStyle(color.colorId)}}"
                         *ngFor="let color of availableColors"
                         (click)="selectColor(color.colorId,color.colorName)">
                        <div class="frame-img-bordered">
                            <img src="/images/series/{{color.seriesName}}/colors/{{color.colorName}}.png" />
                        </div>
                        <div class="big-screen">{{color.colorName}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="function-configuration" *ngIf="!fullscreen">


    <div class="selection-column" *ngIf="suggestedCGF != null">
        
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (tabChange)="tabsetChange($event)" >
            <li [ngbNavItem]="1" id="1">
                <a ngbNavLink class="tab-headers">Polecane</a>
                <ng-template ngbNavContent>
                    <div class="screen600plus" *ngIf="!isLess(600)">
                        <div *ngFor="let cat of suggestedCGF.functionTypes; let i = index; " class="functioncol screen600plus">
                            <div *ngIf="cat != null" title="{{skuShortInfo(cat.repSku)}}" (click)="setFunction(cat)" (tap)="setFunction(cat)"
                                 (touchstart)="touchstartOnCat(cat)" (touchend)="touchendOnCat(cat)">
                                <div mwlDraggable class="app-component single-fame-container" id="gcf_-1_{{i}}"
                                     [validateDrag]="dragThresholdValidator"
                                     [dropData]="cat" dragActiveClass="drag-active" (dragStart)="startDragging('gcf_-1_' + i)"
                                     (dragEnd)="endDragging('gcf_-1_' + i)" style="cursor:move;background-color: rgba(0,0,0,0);">
                                    <div *ngIf="cat != null" class="seriesButton">

                                        <div *ngFor="let layer of layers(cat.repSku.code)" class="function-image" title="{{skuShortInfo(cat.repSku)}}">
                                            <img draggable="false" src="/images/series/{{cat.repSku.series.title}}/functions/nomargin2/{{layer}}.png"
                                                 onerror='this.src="/images/icons/noimage.png"' alt="{{cat.repSku.functionType.title}}" style="max-height: 10vh;max-width: 100%;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="app-component">
                                    <div class="sku-title">
                                        <span [innerHTML]="nonBreakingHyphen(cat.title)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="own-carousel-back" *ngIf="slideIndex !=0" (click)="slideIndex=slideIndex-1;"><img src="/images/icons/back.png" /></div>
                        <div class="own-carousel-next" *ngIf="slideIndex < slideNumber-1" (click)="slideIndex=slideIndex+1;"><img src="/images/icons/next.png" /></div>
                    </div>
                    <div class="own-carousel-slide" *ngIf="isLess(600)"
                         style="flex-direction: row;flex-wrap: wrap;justify-content: space-evenly;padding: 4px 20px;">
                        <div *ngFor="let cat of groupesCategries(suggestedCGF.functionTypes,2,3,orderService.actOrderItem)[slideIndex]; let j = index;"
                             class="functioncol">
                            <div *ngIf="cat != null" title="{{skuShortInfo(cat.repSku)}}" (click)="setFunction(cat)" (tap)="setFunction(cat)"
                                 (touchstart)="touchstartOnCat(cat)" (touchend)="touchendOnCat(cat)" style="display: flex;flex-direction: column;">
                                <div mwlDraggable class="app-component single-fame-container" id="gcf_-1_{{i*6 + j}}"
                                     [validateDrag]="dragThresholdValidator"
                                     [dropData]="cat" dragActiveClass="drag-active" (dragStart)="startDragging('gcf_-1_' + i*6 + j)"
                                     (dragEnd)="endDragging('gcf_-1_' + i*6 + j)" style="cursor:move;background-color: rgba(0,0,0,0);">
                                    <div *ngIf="cat != null" class="seriesButton">
                                        <div *ngFor="let layer of layers(cat.repSku.code)" class="function-image" title="{{skuShortInfo(cat.repSku)}}">
                                            <img draggable="false" src="/images/series/{{cat.repSku.series.title}}/functions/nomargin2/{{layer}}.png" (click)="console.log('clickeeeeeeeeeeeed')"
                                                 onerror='this.src="/images/icons/noimage.png"' alt="{{cat.repSku.functionType.title}}" style="max-height: 10vh;max-width: 100%;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="app-component">
                                    <div class="sku-title">
                                        <span [innerHTML]="nonBreakingHyphen(cat.title)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="own-carousel-back" *ngIf="slideIndex !=0" (click)="slideIndex=slideIndex-1;"><img src="/images/icons/back.png" /></div>
                        <div class="own-carousel-next" *ngIf="slideIndex < slideNumber-1" (click)="slideIndex=slideIndex+1;"><img src="/images/icons/next.png" /></div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" id="2">
                <a ngbNavLink class="tab-headers">Wszystkie</a>
                <ng-template ngbNavContent>
                    <ngb-accordion [closeOthers]="true" activeIds="static--1" class="screen600plus" *ngIf="!isLess(600)">
                        <ngb-panel id="static-{{suf}}" title="{{selectedCGF.cg.title}}" *ngFor="let selectedCGF of categoryGroupFormated;let suf = index;">
                            <ng-template ngbPanelContent>
                                <div *ngFor="let cat of selectedCGF.categoriesFormatedStatic[0]; let i = index; " class="functioncol">
                                    <div *ngIf="cat != null" title="{{skuShortInfo(cat.repSku)}}">
                                        <div mwlDraggable class="app-component single-fame-container" id="gcf_{{cat.id}}_{{i}}"
                                             (dragStart)="startDragging('gcf_'+cat.id+'_' + i)"
                                             (dragEnd)="endDragging('gcf_'+cat.id+'_' + i)"
                                             [dropData]="cat" dragActiveClass="drag-active" style="cursor:move;background-color: rgba(0,0,0,0);">
                                            <div *ngIf="cat != null" class="seriesButton" (click)="setFunction(cat)" (tap)="setFunction(cat)"
                                                 (touchstart)="touchstartOnCat(cat)" (touchend)="touchendOnCat(cat)">
                                                <div *ngFor="let layer of layers(cat.repSku.code)" class="function-image" title="{{skuShortInfo(cat.repSku)}}">
                                                    <img src="/images/series/{{cat.repSku.series.title}}/functions/nomargin2/{{layer}}.png"
                                                         onerror='this.src="/images/icons/noimage.png"'
                                                         alt="{{cat.repSku.functionType.title}}" style="max-height: 10vh;max-width:100%" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="app-component blacklowlabel">
                                            <div class="sku-title"
                                                 title="{{cat.title}}">
                                                <span [innerHTML]="nonBreakingHyphen(cat.title)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>

                    </ngb-accordion>
                    <!-- mobile screen categories of 'wszystkie'-->
                    <div class="screen600less categories-list" *ngIf="categoryShow && isLess(600)">
                        <div class="category-title" *ngFor="let cgf of categoryGroupFormated;let suf = index;" (click)="selectCategoryGroupFormated(cgf)">
                            {{cgf.cg.title}}
                        </div>
                    </div>
                    <!-- mobile screen selected category of 'wszystkie'-->
                    <div class="categories-list" *ngIf="!categoryShow && isLess(600)">
                        <div class="category-title" (click)="selectedCGF = null">
                            {{selectedCGF.cg.title}}
                            <img src="/images/icons/clear-red.png" />
                        </div>

                        <div class="category-items-container">

                            <div class="own-carousel-slide screen600less"
                                 style="flex-direction: row;flex-wrap: wrap;justify-content: space-evenly;padding: 4px 20px;">
                                <div *ngFor="let cat of groupesCategries(selectedCGF.categoriesFormatedStatic[0],2,3,orderService.actOrderItem)[slideIndex]; let i = index; "
                                     class="functioncol">
                                    <div *ngIf="cat != null" title="{{skuShortInfo(cat.repSku)}}">
                                        <div mwlDraggable class="app-component single-fame-container" id="gcf_{{cat.id}}_{{i}}"
                                             (dragStart)="startDragging('gcf_'+cat.id+'_' + i)"
                                             (dragEnd)="endDragging('gcf_'+cat.id+'_' + i)"
                                             [dropData]="cat" dragActiveClass="drag-active" style="cursor:move;background-color: rgba(0,0,0,0);">
                                            <div *ngIf="cat != null" class="seriesButton" (click)="setFunction(cat)" (tap)="setFunction(cat)"
                                                 (touchstart)="touchstartOnCat(cat)" (touchend)="touchendOnCat(cat)">
                                                <div *ngFor="let layer of layers(cat.repSku.code)" class="function-image" title="{{skuShortInfo(cat.repSku)}}">
                                                    <img src="/images/series/{{cat.repSku.series.title}}/functions/nomargin2/{{layer}}.png"
                                                         onerror='this.src="/images/icons/noimage.png"'
                                                         alt="{{cat.repSku.functionType.title}}" style="max-height: 10vh;max-width:100%" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="app-component blacklowlabel">
                                            <div class="sku-title"
                                                 title="{{cat.title}}">
                                                <span [innerHTML]="nonBreakingHyphen(cat.title)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="own-carousel-back" *ngIf="slideIndex !=0" (click)="slideIndex=slideIndex-1;"><img src="/images/icons/back.png" /></div>
                                <div class="own-carousel-next" *ngIf="slideIndex < slideNumber-1" (click)="slideIndex=slideIndex+1;"><img src="/images/icons/next.png" /></div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>

    <!-- PRESENTATION PART -->
    <div class="presentation-column" >
        <div class="presentation-top-info">Przeciągnij mechanizm do ramki</div>
        <div class="presentation-column-rows">
            <div class="bckWall" *ngIf="orderService.actSeries() != null && orderService.actFrame() != null">
                
                <div id="frameBackbround" class="wallBackground {{orderService.getbackgroundstyle()}}"
                     [ngStyle]="{'background-color': _orderService.wallColorSafe}">
                    <div class="frameBackground {{actFramePositionStyle()}}">
                        <img draggable="false" *ngIf="!orderService.actFrameEmpty()"  src="/images/series/{{orderService.actSeries().title}}/frames/{{frameSubDir(orderService.actOrderItem)}}{{orderService.actFrame().code}}.png" alt="{{orderService.actFrame().functionType.title}}"/>
                        <img draggable="false" *ngIf="orderService.actFrameEmpty()" src="/images/series/empty1slot.png" alt="Zestaw bez ramki"/>
                        <div *ngFor="let position of frameSlotIndexes; let i=index" mwlDroppable (drop)="setFunction($event.dropData,position)" dragOverClass="drop-over-active"
                             class="{{getDropableClass(position)}} position-absolute" style="flex-direction: column; display: flex;"
                             [style.z-index]="revertSlotId(orderService.actOrderItem,i)"
                             [style.top]="positionTopPrValue(orderService.actOrderItem,i)"
                             [style.left]="positionLeftPrValue(orderService.actOrderItem,i)"
                             [style.bottom]="positionBottomPrValue(orderService.actOrderItem,i)"
                             [style.height]="positionHeightPrValue(orderService.actOrderItem,i)"
                             [style.width]="positionWidthPrValue(orderService.actOrderItem)">
                            <div draggable="true"
                                 (dragStart)="startDraggingPresented($event,'pgcf_' + position)"
                                 (dragEnd)="endDraggingPresented('pgcf_' + position)"
                                 mwlDraggable [dropData]="functionTypePositionSelected(position)"
                                 *ngIf="hasPositionSelected(position)"
                                 id="pgcf_{{position}}"
                                 class="positionwrapper {{rotationStyle(orderService.actFrame(),orderService.actOrderItem,functionPositionSelected(position).sku)}}">
                                <div style="background-color: darkgrey; width: 55%; height: 55%; display: flex; left: 22.5%; position: relative; vertical-align: middle; border-radius: 50%;"></div>
                                <img *ngFor="let layer of layers(functionPositionSelectedImage(position))"
                                     src="/images/series/{{functionPositionSelected(position).sku.series.title}}/functions/{{layer}}.png"
                                     onerror='this.src="/images/icons/noimage.png"' />

                            </div>
                            <div class="dropable-input-wrapper">
                                <div *ngIf="false && !hasPositionSelected(position)" class="big-screen" style="width: 90%;font-size: 0.7vw;">
                                    Przeciągnij tutaj.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="left-align-button" (click)="prevSet()"
             *ngIf="_orderService!=null && _orderService.openedRoom()!=null && _orderService.openedRoom().orderSets != null && _orderService.openedRoom().orderSets.length > 1">
            <div class="vertical-middle"
                 *ngIf="_orderService!=null && _orderService.openedRoom()!=null && _orderService.openedRoom().orderSets != null && _orderService.openedRoom().orderSets.length > 1">
                <img src="/images/icons/back.png" />
            </div>
        </div>
            <div id="function-bottom-navigation">
                <div class="button-align-label" *ngIf="_orderService!=null && _orderService.openedRoom()!=null && _orderService.openedRoom().orderSets != null">

                    <div *ngFor="let set of _orderService.openedRoom().orderSets;let i = index; "
                         (click)="gotoSet(i)" style="cursor:pointer;">
                        <div *ngIf="i == this.orderService.openedRoom().itemIndex" class="circle-full"></div>
                        <div *ngIf="i != this.orderService.openedRoom().itemIndex" class="circle"></div>
                    </div>
                </div>
                <div id="right-bottom-buttons">
                    <div class="{{trashbackground}}" matTooltip="Przeciągnij mechanizm do kosza aby usunąć" id="trash-dropable" mwlDroppable (drop)="remFunction($event.dropData)">

                    </div>
                </div>
                <div class="big-screen"></div>
            </div>
            <div class="right-align-button" (click)="nextSet()"
                 *ngIf="_orderService!=null && _orderService.openedRoom()!=null && _orderService.openedRoom().orderSets != null && _orderService.openedRoom().orderSets.length > 1">
                <div class="vertical-middle">
                    <img src="/images/icons/next.png" />
                </div>
            </div>

        </div>
</div>
<app-side-summary *ngIf="!fullscreen" (refreshViewEvent)="refreshView()">Side menu</app-side-summary>

<!--it does not work after marten multilayer for sure div *ngIf="fullscreen"

     class="frameBackgroundOnWall frameSize{{actFrameSize()}} {{actFramePositionStyle()}}"
     id="draggableFrame" style="cursor:move">
    <img src="/images/series/{{orderService.actSeries().title}}/frames/{{orderService.actFrame().code}}.png" alt="{{orderService.actFrame().functionType.title}}" />
    <div *ngFor="let position of frameSlotIndexes" class="{{getDropableClass(position)}} position-absolute" style="z-index: -1;">
        <img *ngIf="hasPositionSelected(position)"
             id="pgcff_{{position}}"
             src="/images/series/{{functionPositionSelected(position).sku.series.title}}/functions/{{functionPositionSelectedImage(position)}}.png" />
    </div>

    <div *ngIf="fullscreen" id="wallFrameOverlay"
         (mousedown)="startDraggingOnWall('draggableFrame')"
         (mouseup)="endDraggingOnWall()"
         (mousemove)="dragTheFrame($event)"
         (mouseexit)="endDraggingOnWall()" (mouseenter)="endDraggingOnWall()">

    </div>

</div-->>
<div *ngIf="fullscreen" (click)="fullscreen = false" id="fullscreen-quit-button">
    <img src="/images/icons/menu_minimize-512.png" />
</div>
