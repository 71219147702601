<div class="vertical-center-flex">
    <div class="login-container center-block">
        <div class="boxshadow">

            <div class="panel-body-left panel-body-white">
                <div class="panel-title">Zmiana hasła</div>
                <div class="panel-description">
                    Wprowadź email, aby odzyskać hasło.
                </div>
                <form class="login" name="loginForm" #f="ngForm" novalidate
                      (ngSubmit)="f.form.valid ? initReset() :
                          (!email.valid && showErrorAlert('E-mail jest wymagany'));">
                    <div class="form-group-row">
                        <div class="form-group has-feedback input-group">
                            <img src="/images/icons/login.png" />
                            <div class="field-input" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
                                <input type="text" id="login-email" name="email" placeholder="e-mail" class="form-control" [(ngModel)]="model.username" #email="ngModel" required />
                            </div>
                        </div>

                    </div>
                    <span class="errorMessage" *ngIf="f.submitted && !email.valid">
                        E-mail jest wymagany
                    </span>

                    <div class="form-group-row">
                        <div class="form-group">
                            <button id="login-button" mat-flat-button
                                    style="text-align:center;"
                                    type="submit" class="btn btn-primary next-step-button" [disabled]="isLoading">
                                <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                                <img src="/images/icons/zaloguj.png"
                                     style="margin-top: 5px;position: inherit;margin-right: 11px;margin-left: 0px;" />
                                <span>
                                    {{isLoading ? 'Logowanie ...' : 'WYŚLIJ'}}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group-row">
                        <div class="action-register" (click)="signUp()">Zarejestruj się</div>
                    </div>

                </form>
            </div>
            <div class="panel-body-right panel-body-livegreen">

                <div class="panel-title">Witaj!</div>
                <div class="panel-description">
                    Uzupełnij podstawowe dane aby móc w pełni skorzystać z możliwości aplikacji.
                    Dostęp na dowolnym urządzeniu i zapisywanie historii projektów.
                </div>
                <div class="form-group-row">
                    <div class="form-group">
                        <button id="register-button" mat-flat-button
                                style="text-align:center;"
                                type="submit" class="btn btn-primary white-button" [disabled]="isLoading"
                                (click)="signUp()">
                            <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                            <img src="/images/icons/zaloz konto-livegreen.png" style="margin-top: 5px;position: inherit;margin-right: 11px;margin-left: 0px;" />
                            <span>
                                ZAŁÓŻ KONTO
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
