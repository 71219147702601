import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ContentChild, AfterViewInit, QueryList, ElementRef, Inject, AfterContentInit, ViewChildren } from "@angular/core";
import { Router, NavigationStart, ActivatedRoute} from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../services/animations';

import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from '../../services/alert.service';
import { NotificationService } from "../../services/notification.service";
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AppTitleService } from '../../services/app-title.service';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Permission } from '../../models/permission.model';
import { LoginComponent } from "../../components/login/login.component";
import { BootstrapTabDirective } from "../../directives/bootstrap-tab.directive";
import { HttpClient } from "@angular/common/http";
import { OrderService } from '../../services/orders.service';

import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatStepper } from '@angular/material/stepper';

import { FormsModule, ReactiveFormsModule, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { Series, Sku, CategoryGroup, Category, Project } from "../../_models/catalog";
import { Subscription } from "rxjs";
import { ProjectNameDialog } from "../controls/project-name.component";
import { B2BCollectFunctionsComponent } from "./collect-functions.component";
import { User } from "../../models/user.model";
import { ProjectSelectionComponent } from "../b2c/project-selection.component";
import { PerfectScrollbarComponent, PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { B2BStartDialogComponent } from "./start-dialog.component";
import { AppComponent } from "../app.component";
import { CanDeactivateGuard } from "../../services/can-deactivate-guard.service";
import { EcommerceRedirectComponent } from "../controls/ecommerce-redirect.component";



export class MyStepper {
    step: number = 0;
    next() {
        this.step += 1;
    }
    prev() {
        this.step -= 1;
    }
    reset() {

        this.step = 0;
    }
    incStep(i: number) {
        this.step += i;
    }
    get selectedIndex():number {
        return this.step;
    }
}


export class KeyValue {
    key: string;
    value: string;
}

@Component({
  selector: "app-b2b-config",
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss'],
    animations: [fadeInOut],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class B2BConfigComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit{
    @ViewChild(PerfectScrollbarComponent, { static: true }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
    @ViewChild("tab", { static: true })
    tab: BootstrapTabDirective;
    // Ensure Change Detection runs before accessing the instance
    //@ContentChild('functionselector', { static: false }) functionselector!: ElementRef<B2BCollectFunctionsComponent>;
    @ViewChild('functionSelector', { static: false })
    functionSelector: B2BCollectFunctionsComponent;

    groupCatId: number;
    groupCatTitle: string;
    ngAfterViewInit(): void {
        console.log("ngAfterViewInit parent with functionSelector = ", this.functionSelector);
        }
    public onScrollEvent(event: any): void {
        console.log(event);
    }
    onResize(event) {
        if (window.screen.width > 800) {
            this.wide = true;
        } else {
            this.wide = false;
        }
    }
    dynamicdata: string = 'This is dynamic data!';
    http: HttpClient;
    baseUrl: string;
    user:User = null;
    //stepper
    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    zeroFormGroup: FormGroup;
    //@ViewChild('stepper', {static: true}) stepper: MatStepper;
    @ViewChild('projectControler', { static: true }) projectCntr: ProjectSelectionComponent;
    stepper = new MyStepper();
    cStepper = new MyStepper();
    
    step: number = 0;
    selectProject() {
        this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('navigation', 'b2b', 'B2B_Przejście do projektów', 0);
        this.stepper.reset();
        this.step = 0;
        
    }
    gotoStep(e: Event) {
        this.functionSelector.searchedByCode = false
        console.log('it does not do enything');
        //this.functionSelector.selectedTab = this.stepper.selectedIndex + 1;
    }
    openCategories() {
        this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('navigation', 'b2b', 'B2B_MOBILE_Przejście do kategorii', 0);
        this.stepper.step = 2;
        this.cStepper.step = 0;
    }
    openFilters() {
        this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('navigation', 'b2b', 'B2B_MOBILE_Otwarcie filtrów', 0);
        this.stepper.step = 2;
        this.cStepper.step = 1;
    }


    ngOnDestroy(): void {
        this.fragmentSubscription.unsubscribe();
    }
    get userName(): string {
        return this.authService.currentUser ? this.authService.currentUser.userName : "";
    }
    get collectedSumNet() {
        return (this.functionSelector != null) ? this.functionSelector.collectedSumNet : 0;
    }
    get collectedElkPoints() {
        return (this.functionSelector != null) ? this.functionSelector.collectedElkPoints : 0;
    }
    get collectedSumDiscounted() {
        return (this.functionSelector != null) ?
            this.functionSelector.roundToTwo(this.functionSelector.collectedSumNet * ((this.functionSelector.discount > 0) ? (100 - this.functionSelector.discount) : 100) / 100)
            : 0;
    }
    get collectedProjectSkus() {
        return this.orderService != null ? this.orderService.collectedProjectSkus : 0;
    }
    catId: number;
    catTitle: string;
    wide: boolean = false;
    ngOnInit(): void {
        document.getElementsByClassName("navbar-collapse")[0].classList.remove('show')
        if (window.screen.width > 600) {
            this.wide = true;
        } else {
            this.wide = false;
        }
            this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
            this.isUserLoggedIn = this.authService.isLoggedIn;

            // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
            setTimeout(() => this.isAppLoaded = true, 100);
            setTimeout(() => this.removePrebootScreen = true, 100);
        
        this.http.get<CategoryGroup[]>(this.baseUrl + 'api/catalogdata/categoryGroups').subscribe(result => {
            for (let cg of result) {
                console.log("cg.categories.sort(this.compareCategories);");
                cg.categories.sort(this.compareCategories);
            }
            result.sort(this.compareCategoryGroups);
                //this.orderService.currentConfig.fH.categoryGroups = result;
                this.categoryGroups = result;
                if (this.categoryGroups != null && this.categoryGroups.length > 0
                    && this.categoryGroups[0].categories != null && this.categoryGroups[0].categories.length > 0) {
                    this.catId = this.categoryGroups[0].categories[0].id;
                    this.groupCatTitle = this.categoryGroups[0].title;
                    this.catTitle = this.categoryGroups[0].categories[0].title;
                    if (this.functionSelector!=null)
                        this.functionSelector.reloadFunctions(this.catId, this.catTitle, this.groupCatTitle);
                }
        }, error => console.error(error));

        this.zeroFormGroup = this._formBuilder.group({
            zeroCtrl: ['', Validators.required]
        });
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
        /*
        if (this.categoryGroups != null && this.categoryGroups[0] != null && this.categoryGroups[0].categories != null && this.categoryGroups[0].categories[0]!=null) {
            var c = this.categoryGroups[0].categories[0];
            this.setCategory(c.id,c.title);
        }*/

        


    }
    compareCategoryGroups(c1: CategoryGroup, c2: CategoryGroup) {
        if (c1.order < c2.order)
            return -1;
        else if (c1.order > c2.order)
            return 1;
        else
            return 0;
    }
    compareCategories(c1: Category, c2: Category) {
        if (c1.order < c2.order)
            return -1;
        else if (c1.order > c2.order)
            return 1;
        else
            return 0;
    }
    
    /*
    @ViewChildren("functionselector")
    functionselector: B2BCollectFunctionsComponent;
    */

    //isPreferencesActivated = false;
    //isUsersActivated = false;
    isAddFunctionsActivated = true;
    get isAddFunctionsDeactivated() : boolean { return this.isAddFunctionsActivated; };
    isSetupActivated = false;

    fragmentSubscription: any;

    //readonly preferencesTab = "preferences";
    //readonly usersTab = "users";
    readonly addFunctionsTab = "addFunctions";
    readonly setupTab = "setup";

    ngAfterContentInit(): void {
        
    }

    expandedCategoryGroup = 1;
    ifExpandedCategoryGroup(cgId) {
        if (this.expandedCategoryGroup == cgId)
            return 'in';
        else
            return '';
    }
    expandCategoryGroup(cgId, cgTitle) {
        this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('navigation', cgTitle, 'B2B_Wybór grupy kategorii', 0);
        this.expandedCategoryGroup = cgId;
        let firstSubcategory = this.categoryGroups.find(cg => cg.id == cgId).categories[0];
        this.groupCatTitle = cgTitle;
        this.setCategory(firstSubcategory.id, firstSubcategory.title, cgTitle);
        
        //console.log("expandedCategoryGroup:" + this.expandedCategoryGroup + ":Title:" + this.dynamicdata);
    }
    currentCallParams = new Array<KeyValue>();
    clearAnchor: string = null;
    showContent(anchor: string) {
        if (this.functionSelector != null)
            this.functionSelector.searchedByCode = false
        //if ((this.isFragmentEquals(anchor, this.usersTab) && !this.canViewUsers) || false)
        //    return;
        this.parseParams(anchor);
        
        if (this.tab !=null)
            this.tab.show(`/wybierz-design/${this.clearAnchor || this.addFunctionsTab} Tab`);
        if (this.currentCallParams.find(kv => kv.key == 'category') != null) {
            this.orderService.currentCategory = this.currentCallParams.find(kv => kv.key == 'category').value;
        }
        
    }
    selectedCategoryId: number = null;
    ifSelectedCategoryGroup(cgid: number) {
        return (this.selectedCategoryId == cgid) ? 'categoryactive' : '';
    }
    ifSelectedLiked() {
        return (this.likedOpened) ? 'categoryactive' : '';
    }
    searchedCode: string;
    setCategoryRun(catid: number, catTitle: string) {
        this.functionSelector.searchedByCode = false
        this.setCategory(catid, catTitle,this.groupCatTitle);
        this.cStepper.step = 2;
    }
    setCategory(catid: number, catTitle: string, groupCatTitle: string) {
        this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('navigation', catTitle, 'B2B_Wybór kategorii', 0);
        this.selectedCategoryId = catid;
        this.orderService.selectedCategoryId = catid;
        this.functionSelector.reloadFunctionsAndUpdateFunctions(catid, catTitle, groupCatTitle);
        B2BCollectFunctionsComponent.searchedCode = null;
        this.searchedCode = null;
        this.functionSelector.foundCodes = this.functionSelector.filteredSkus;
        this.likedOpened = false;
    }
    clearCart() {
        this.functionSelector.clearCart();
    }
    saveCart() {
        this.orderService.saveCurrentContextOld();
    }
    selectedTab = 1
    

    parseParams(anchor: string) {
        let anchors = null;
        if (anchor != null) {
            anchors = anchor.split("?");
            this.clearAnchor = anchors[0];
            if (anchors.length > 1) {
                anchors = anchors[1].split('&');
            }
            this.currentCallParams = new Array<KeyValue>();
            for (let a of anchors) {
                let kvs = a.split('=');
                if (kvs.length == 2) {
                    this.currentCallParams.push({ key: kvs[0], value: kvs[1] });
                }
            }
        }
    }


    isFragmentEquals(fragment1: string, fragment2: string) {

        if (fragment1 == null)
            fragment1 = "";

        if (fragment2 == null)
            fragment2 = "";

        return fragment1.toLowerCase() == fragment2.toLowerCase();
    }

    updateCart() {
        this.orderService.cartFromProjectAndCallback(function () {
            var d = document.getElementById('spinner');
            if (d != null) d.hidden = true;

        });
    }
    get shopOpacity() : string {
        if (this.orderService.order != null || !this.orderService.changedAfterOrder || !this.orderService.updatingCart)
            return "opacity:0.3";
        return "";
    }
    openSummary() {
        //this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('navigation', 'b2b', 'Otwarcie Twojej Listy', 0);
        this.updateCart();
        setTimeout(_ => { this.stepper.step = 3; this.orderService.setValuesforEcommerce(); });
    }
    onShowTab(event) {
        let activeTab = event.target.hash.split("/", 2).pop();
        let index = -1;
        if (activeTab.split('&').length > 1)
            index = activeTab.split('&').pop();

        this.isSetupActivated = activeTab == this.setupTab;
        //this.isPreferencesActivated = activeTab == this.preferencesTab;
        //this.isUsersActivated = activeTab == this.usersTab;
        this.isAddFunctionsActivated = activeTab == this.addFunctionsTab;
        //console.log("functionCategoryIndex:" + index);
    }


    get canViewUsers() {
        return this.accountService.userHasPermission(Permission.viewUsersPermission);
    }

    get canViewRoles() {
        return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;

    projectName = 'Nowy Projekt';
    

    
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, storageManager: LocalStoreManager, private toastyService: ToastyService, private toastyConfig: ToastyConfig,
    private accountService: AccountService, private alertService: AlertService, private notificationService: NotificationService, private appTitleService: AppTitleService,
        public authService: AuthService, private translationService: AppTranslationService, public configurations: ConfigurationService, public router: Router,
        public orderService: OrderService, public dialog: MatDialog,
        private _formBuilder: FormBuilder, private canDeactivateGuard: CanDeactivateGuard) {
        this.baseUrl = baseUrl;
        this.http = http;
        storageManager.initialiseStorageSyncListener();
        if (this.openedProject == null || this.openedProject.type != 0) {

            const dialogRef = this.dialog.open(B2BStartDialogComponent, {
                //width: '620px',
                data: { os: this.orderService, stepper: this.stepper }
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
                /*let returnCode = result[0];
                if (returnCode == 0) {
                    this.stepper.reset();
                }*/
            });

        }
  }
    
    

    categoryGroups: CategoryGroup[] = new Array<CategoryGroup>();
    /*
    openProjectNameDialog(): void {
        const dialogRef = this.dialog.open(ProjectNameDialog, {
            width: '250px',
            data: {projectName: this.projectName }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.projectName = result;
        });
    }*/


    get openedProject():Project {
        return this.orderService.cProject;
    }
    get openedRoom() {
        return this.orderService.openedRoom();
    }
    searchForCode(event) {
        
        this.likedOpened = false;
        if (event != null) {
            let element = event.currentTarget as HTMLInputElement;
            let searchedCode = element.value;
            B2BCollectFunctionsComponent.searchedCode = element.value;
        }
        AppComponent.SendEventGa('filters', this.searchedCode, 'B2B_wyszukiwanie po referencji', 0);
        console.log("search for: " + this.searchedCode);
        this.functionSelector.searchForCodeGlobal(this.searchedCode);
    }
    searchForCodeRun(event) {
        this.searchForCode(event);
        this.cStepper.step = 2;
    }

    likedOpened = false;
    openLiked() {
        this.functionSelector.searchedByCode = false
        AppComponent.SendEventGa('filtering', null, "B2B_Otwarcie 'Ulubionych'", 0);
        this.likedOpened = true;
        this.functionSelector.openLiked();
    }
    get isAnythingInSet() {
        if (this.functionSelector == null) return false;
        return this.functionSelector.isAnythingInSet;
    }
    get likedNumber() {
        if (this.orderService == null || this.orderService.likedSkus == null)
            return 0;
        else
            return this.orderService.likedSkus.length;
        
    }

    currency(a: number) {
        return a.toFixed(2).replace('.', ',');
    }
    openelk() {
        console.log('goto elektroklub');
        AppComponent.SendEventGa('navigation', null, 'B2B_Przejście do Elektroklubu', 0);
        window.open('https://www.elektroklub.pl', '_blank');
    }

    anyProductsInCategory(catId: number) {
        return this.functionSelector != null && this.functionSelector.anyProductsInCategory(catId);
    }


    logAndSubmit(shop: string, formId: string, orderId: string, orderService: OrderService) {
        event.preventDefault();
        orderService.updateOrder(orderId, "redirected");
        AppComponent.SendEventGa('cart', shop, "Otwarcie przekierowania do sklepu", 0);

        var form = <HTMLFormElement>document.getElementById(formId);
        form.submit();
    }
}




