import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

export interface DialogData {
    url: string;
    messageTitle: string;
    messageBody: string;
    confirmCallback: Function;
}

@Component({
    selector: 'app-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss']
})
/** InformationDialog component*/
export class InformationDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<InformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        protected router: Router) { }


    onNoClick(): void {
        this.dialogRef.close();
        if(this.data.url!=null)
            window.history.back();
    }

    onAck(): void {
        if (this.data.confirmCallback != null) {
        //    this.data.confirmCallback();
        }
        this.dialogRef.close(true);
        if (this.data.url != null)
            this.router.navigateByUrl(this.data.url);
    }
}
