import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import { Project, Room, OrderSetItem, Sku } from "../../_models/catalog";
import { OrderService } from "../../services/orders.service";
import { AppComponent } from "../app.component";

export interface ExportCartDialogData{
    os: OrderService;
    p: Project;
}

@Component({
    selector: 'app-export-config',
    templateUrl: './export-config.component.html',
    styleUrls: ['./export-config.component.scss']
})
export class ExportConfigComponent {
    selectedFormat: string;
    availableFormats: string[] = ['PDF', 'Excel', 'CSV'];
    bundled: boolean;
    set: Project;
    httpclient: HttpClient;
    baseUrl: string;
    constructor(public dialogRef: MatDialogRef<Project>,
        @Inject(MAT_DIALOG_DATA) public data: ExportCartDialogData, http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.httpclient = http;
        this.set = data.p;
        this.baseUrl = baseUrl;
        this.turnOnSpinner()
        this.data.os.cartFromProjectAndCallback(function () {
            var d = document.getElementById('spinner');
            if (d != null) d.hidden = true;
        });
        
    }
    turnOnSpinner() {
        var d = document.getElementById('spinner');
        if(d != null)d.hidden = false;
    }
    turnOffSpinner() {
        var d = document.getElementById('spinner');
        if (d != null)d.hidden = true;
    }
    downloadExcel() {
        AppComponent.SendEventGa('download', null, 'Export_pobranie excela', 0);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        let units = new Array<Array<any>>(); //[ string, string, number, number, string, string, number ]>
        units.push(["Opis", "numer referencyjny", "ilość", "ilość punktów w Elektroklub",
            "Seria", "Kolor", "cena katalogowa netto wg cennika"])
        let isAnyunitSplited = false;
        for (let r of this.set.rooms) {
            let array = new Array<Array<any>>();
            array.push(["Opis", "numer referencyjny", "ilość", "ilość punktów w Elektroklub",
            "Seria", "Kolor", "cena katalogowa netto wg cennika"])
            for (let os of r.orderSets) {
                for (var i = 0; i < os.orderSetItems.length;i++) {
                    let osi = os.orderSetItems[i];
                    if (this.set.type != 1 || this.notFrameOfMechWithFrame(os.orderSetItems, i))
                        if (osi !=null && osi.sku != null) {
                            array.push([osi.sku.info, osi.sku.code, osi.qty, osi.sku.elk,
                            osi.sku.series.title, osi.sku.color.title,
                                osi.sku.net])

                            if (osi.sku.code.includes("+")) {
                                isAnyunitSplited = true;
                            }

                        }
                }
            }
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(array);
            XLSX.utils.book_append_sheet(wb, ws, r.title);
        }
        if (isAnyunitSplited) {
            this.httpclient.get<OrderSetItem[]>('/api/UserCart/unitsTable/' + this.set.id).subscribe(result => {
                console.log("adding unitsTable: " + result)
                result.forEach(osi => {
                    units.push([osi.sku.info, osi.sku.code, osi.qty, osi.sku.elk,
                    osi.sku.series.title, osi.sku.color.title,
                    osi.sku.net]);
                });
                const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(units);
                XLSX.utils.book_append_sheet(wb, ws, 'Pojedyncze pozycje');
                XLSX.writeFile(wb, this.set.title + "_" + this.data.os.cartId + "_" + this.data.os.timestamp + '.xlsx');
            });
        } else {
            /* save to file */
            XLSX.writeFile(wb, this.set.title + "_" + this.data.os.cartId + "_" + this.data.os.timestamp + '.xlsx');
        }
    }
    ExportConfigComponent
    downloadPdf() {
        AppComponent.SendEventGa('download', null, 'Export_pobranie pdf', 0);
        if (this.set.type == 1) {

            document.getElementById('spinner').hidden = false;
            var opt1 = {
                margin: 1,
                filename: 'Zestawienie' + "_" + this.data.os.cartId + "_" + this.data.os.timestamp+".pdf",
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'pt', orientation: 'p' },
                pagebreak: {
                    mode: ['avoid-all', 'css'], after: ['html2pdf__page-break','summary-collection', 'summary-row-wrapper0', 'summary-row-wrapper0'] }
            };
            var div = document.getElementById('b2cSummaryCanvas');
            div.hidden = false;
            html2pdf().set(opt1).from(div).save().then(function (value) {
                console.log('Success');
                div.hidden = true;
                document.getElementById('spinner').hidden = true;
            });
        } else {
            var opt = {
                margin: 1,
                filename: 'zestawienie' + "_" + this.data.os.cartId + "_" + this.data.os.timestamp + ".pdf",
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'pt', orientation: 'p' }
                //pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: ['.function-row-wrapper0', '.function-row-wrapper1'] }
            };
            document.getElementById('spinner').hidden = false;
            var div = document.getElementById('b2bSummaryCanvas');
            div.hidden = false;
            html2pdf().set(opt).from(div).save().then(function (value) {
                console.log('Success');
                div.hidden = true;
                document.getElementById('spinner').hidden = true;
            });
        }
    }
    public static myCallback(pdf) {
        document.getElementById('spinner').hidden = true;
        var div = document.getElementById('b2bSummaryCanvas');
        //div.hidden = true;
    }
    downloadCsv() {
        AppComponent.SendEventGa('download', null, 'Export_pobranie csv', 0);
        let array = new Array<Array<any>>();
        var csv = 'opis;numer referencyjny;ilość;ilość punktów w Elektroklub;'+
            'seria;kolor;cena katalogowa netto wg cennika\n';
        for (let r of this.set.rooms) {

            for (let os of r.orderSets) {
                for (var i = 0; i < os.orderSetItems.length; i++) {
                    let osi = os.orderSetItems[i];
                    if (this.set.type != 1 || this.notFrameOfMechWithFrame(os.orderSetItems, i)) {
                        if (osi != null && osi.sku != null) {
                            csv += osi.sku.info + ';' +
                                osi.sku.code + ';' +
                                osi.qty + ';' +
                                osi.sku.elk + ';' +
                                osi.sku.series.title + ';' +
                                osi.sku.color.title + ';' +
                                osi.sku.net;
                            csv += "\n";
                        }
                    }
                }
            }
        }
        console.log(csv);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = this.set.title + "_" + this.data.os.cartId + "_" + this.data.os.timestamp + '.csv';
        hiddenElement.click();
    }



    openEmail() {
        AppComponent.SendEventGa('download', null, 'Export_wysłanie e-mailem', 0);
        let array = new Array<Array<any>>();
        var body = 'opis\tnumer referencyjny\tilość\tilość punktów w Elektroklub\t' +
            'seria\tkolor\tcena katalogowa netto wg cennika\n';
        for (let r of this.set.rooms) {

            for (let os of r.orderSets) {
                for (let osi of os.orderSetItems) {
                    if (osi.sku != null) {
                        body += osi.sku.info + '\t' +
                            osi.sku.code + '\t' +
                            osi.qty + '\t' +
                            osi.sku.elk + '\t' +
                            osi.sku.series.title + '\t' +
                            osi.sku.color.title + '\t' +
                            osi.sku.net;
                        body += "\n";
                    }
                }
            }
        }
        console.log(body);
        window.location.href = 'mailto:?' + encodeURI(body);
    }

    wait() {
        var overlay = document.getElementById("waitingoverlay");
        overlay.hidden = false;
    }

    stopWaiting() {
        var overlay = document.getElementById("waitingoverlay");
        overlay.hidden = true;
    }

    downloadCardsForProject() {
        AppComponent.SendEventGa('download', null, 'Export_pobranie kart katalogowych', 0);
        this.downloadZip('api/FileExport/bundledCards');
    }

    downloadPicturesForProject() {
        AppComponent.SendEventGa('download', null, 'Export_pobranie obrazów', 0);
        this.downloadZip('api/FileExport/bundledPictures');
    }

    downloadZip(path: string) {
        this.wait();
        let headers = { "Content-Type": "application/json", Accept: "application/zip" };

        this.httpclient.post(this.baseUrl + path, this.data.p, {
            headers: headers,
            responseType: 'blob',
            observe: 'response'
        })
            .subscribe(res => {
                console.log('start download:', res);
                console.log(res.toString());
                this.stopWaiting();
                var blob = new Blob([res.body], { type: 'application/zip' });
                var reader = new FileReader();
                reader.onload = function (e) {
                    window.location.href = reader.result.toString();
                }
                reader.readAsDataURL(blob);
                /*
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                console.log(res.headers.get('content-disposition'));
                a.download = res.headers.get('content-disposition')
                    .split(';').find(s => s.trim().startsWith('filename')).split('=')[1];
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                */
            }, error => {
                    console.log('download error:' + error);
            }, () => {
                console.log('Completed file download.')
            });
    }


    currency(a: number) {
        return a.toFixed(2).replace('.', ',');
    }

    numberOfFrames(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p!=null && p.sku != null && p.sku.slots > 0)
                            sum += p.qty * p.sku.slots;
                        else if (p != null && p.sku != null && p.sku.withFrame)
                            sum += 1;
                    }
            }
        return sum;
    }
    numberOfFunctions(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p != null && p.sku != null && p.sku.slots == 0)
                            sum += p.qty;
                    }
            }
        return sum;
    }
    sumNet(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p != null && p.sku != null) {
                            sum += p.qty * p.sku.net;
                        }
                    }
            }
        return (Math.round(sum * 100) / 100).toFixed(2).replace('.', ',');
    }


    notFrameOfMechWithFrame(orderSetItems: Array<OrderSetItem>, i: number): boolean {
        return i != 0 || orderSetItems.length < 2 ||
            orderSetItems[i + 1] == null ||
            orderSetItems[i + 1].sku == null ||
            orderSetItems[i + 1].qty == null || orderSetItems[i + 1].qty == 0 ||
            (orderSetItems[i] != null && !this.frameIncluded(orderSetItems[i].sku, orderSetItems[i + 1].sku));
            !this.frameIncluded(orderSetItems[i].sku, orderSetItems[i + 1].sku);
    }
    frameIncluded(frame: Sku, mech: Sku) {
        return mech.withFrame
            && frame.slots == 1
            && mech.color.title == frame.color.title;
    }
}
