<div style="display:flex;flex-direction:column">
    <div mat-dialog-content>
        <div class="sku-image">
        <img *ngFor="let layer of layers(data.sku.code)"
             src="/images/seriesorg/{{data.sku.series.title}}/{{layer}}.png" />
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button class="cancel-button" (click)="onNoClick()">Zamknij</button>
    </div>
</div>
