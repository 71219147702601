
<!--div class="mainpageoverlay">
</div-->
<div class="main-text-container">
    <div class="app-component main-main-header">
        <p>
            <b>
                {{title}}
            </b>
        </p>
    </div>
    <div class="app-component sub-header">
        <p>
            <b>
                {{subtitle}}
            </b>
        </p>
    </div>
</div>
<div class="tilegrid">
    <div class="tiles">
        <div class="lefttiles">
            <div class="{{objectClass(2,1)}} button_{{2}}_{{1}}  main-col"
                 (mouseenter)="switch(1)" (mouseleave)="reset()" (click)="open(1)">

                <div class="tile-button-container">
                    <div class="button-icon"></div>
                    <div class="button-label">
                        <span>{{buttonLabel(1,1,0)}}</span>
                        <span>{{buttonLabel(1,1,1)}}</span>
                    </div>
                </div>
            </div>
            <div class="{{objectClass(2,2)}} button_{{2}}_{{2}}  main-col"
                 (mouseenter)="switch(2)" (mouseleave)="reset()" (click)="open(2)">

                <div class="tile-button-container">
                    <div class="button-icon"></div>
                    <div class="button-label">
                        <span>{{buttonLabel(1,2,0)}}</span>
                        <span>{{buttonLabel(1,2,1)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tiles">
        <div (click)="showVideo()" class="video-button">
            <div class="video-button-text"><div>Zobacz Jak</div></div>
            <div class="video-button-img"><img src="/images/icons/play.png" /></div>
        </div>
    </div>

</div>
<!--div class="tilegrid">
    <div class="tiles" *ngFor="let r of tileRows;let i = index">
        <div *ngIf="i == 3" (click)="showVideo()" class="video-button">
            <div class="video-button-text"><div>Zobacz Jak</div></div>
            <div class="video-button-img"><img src="/images/icons/play.png" /></div>
        </div>
        <div class="{{objectClass(i,j)}} button_{{i}}_{{j}}  main-col" *ngFor="let c of r;let j = index"
             (mouseenter)="switch(j)" (mouseleave)="reset()" (click)="open(j)">
            <picture *ngIf="c != null">
                <source srcset="/images/main/WebP/{{c}}.webp" type="image/webp">
                <img class="{{tailclass(i,j)}}" src="/images/main/{{c}}.png"
                     alt="tile_{{i}}_{{j}}" />
            </picture>

            <div *ngIf="i==2 && j>=position1 && j<=position2"
                 class="tile-button-container">
                <div class="button-icon"></div>
                <div class="button-label">
                    <span>{{buttonLabel(i,j,0)}}</span>
                    <span>{{buttonLabel(i,j,1)}}</span>
                </div>
            </div>
        </div>
    </div>
</div-->

