import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Room } from '../../../_models/catalog';
import { DialogData } from '../../controls/project-name.component';
import { OrderService } from '../../../services/orders.service';

@Component({
    selector: 'prompt-dialog',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss']
})
export class PromptDialogComponent {
    
    orderService: OrderService;
	
    constructor(
        public dialogRef: MatDialogRef<PromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public os: OrderService) {
        this.orderService = os;
        }
    

    onNoClick(): void {
        this.dialogRef.close(null);
    }

    onSubmitClick() {
        this.dialogRef.close(true);
    }
    
}
