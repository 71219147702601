<h3 mat-dialog-title>Wybierz kolor</h3>
<div mat-dialog-content>
    <!--
    <h5>{{data.sku.info}}</h5>
    <h5>{{data.sku.indexOfProtection.title}}</h5>
    <h6>({{data.sku.code}})</h6>
        -->
    <!--div *ngFor="let s of data.alternateSkus">
        {{s.series.title}} - {{s.code}}
    </div-->
    <div class="button-row" *ngFor="let s of data.alternateSkus">
        <button mat-raised-button
                mat-button [mat-dialog-close]="s" cdkFocusInitial>
            {{s.color.title}}
        </button>
    </div>

</div>
<!--
<div mat-dialog-actions>
    <button mat-stroked-button color="warn" (click)="onNoClick()">Zamknij</button>
</div>
    -->
