


<div #summary class="summary-container" [class.example-is-mobile]="mobileQuery.matches"
     [@openClosed]="showSummary ? 'opened' : 'closed'">
    <button mat-flat-button id="summaryOpenButton" (click)="showSummaryToggle()">
        Twój zestaw
    </button>
    <div class="summary-list-container">
        <div class="bolded">{{functionselector.openedRoom.title}}</div>
        <div class="summary">
            <div class="summary-table">
                <div class="sum-wrapper">
                    <div class="sum">Ilość mechanizmów:</div>
                    <div class="sum-value">{{numberOfMechanisms}}</div>
                </div>
                <div class="sum-wrapper">
                    <div class="sum">Ilość miejsc w ramkach:&nbsp;</div>
                    <div class="sum-value">{{numberOfFrames}}</div>

                </div>
                <div class="sum-wrapper" *ngIf="numberOfFrames<numberOfMechanisms">
                    <div class="red">
                        Brakuje {{numberOfMechanisms-numberOfFrames}} <span *ngIf="(numberOfMechanisms-numberOfFrames) == 1">&nbsp;miejsca&nbsp;</span>
                        <span *ngIf="(numberOfMechanisms-numberOfFrames) > 1">&nbsp;miejsc&nbsp;</span> w ramkach
                    </div>
                </div>
                <div class="sum-wrapper" *ngIf="numberOfFrames>numberOfMechanisms">
                    <div class="red">
                        Brakuje {{numberOfFrames-numberOfMechanisms}} <span *ngIf="(numberOfMechanisms-numberOfFrames) == -1">&nbsp;miejsca&nbsp;</span>
                        <span *ngIf="(numberOfMechanisms-numberOfFrames) != -1">&nbsp;miejsc&nbsp;</span> w ramkach
                    </div>
                </div>

            </div>
            <div>
                <button [disabled]="!functionselector.isAnythingInSet" mat-stroked-button color="warn" class="white-small-button red-label clear-button"
                        style="text-align:center;padding-left:10px" (click)="clearSet()">
                    <img src="/images/icons/usun-red.png" style="height: 21px;position: inherit;margin: 5px;" />
                    Wyczyść
                </button>
            </div>
        </div>
        <div class="summary-list-wrapper" *ngIf="functionselector.isAnythingInSet">
            <mat-list role="list" >

                <mat-list-item class="summary-position" *ngFor="let orderItemSummary of functionselector.openedRoomOrderSet.orderSetItems;let i = index;" role="listitem"
                               style="height:unset;">

                    <div mat-list-text class="position-wrapper">
                        <div class="position-label">{{i+1}}) {{orderItemSummary.sku.functionType.title}}</div>
                        <div class="position-label">
                            {{orderItemSummary.sku.series.title}} {{orderItemSummary.sku.code}}
                            <div class="summary-action" (click)="functionselector.deletePosition(i)">Usuń</div>
                        </div>

                    </div>


                    <div class="frame-image">
                        <img *ngFor="let layer of layers(orderItemSummary.sku.code)"
                             src="/images/seriesorg/{{orderItemSummary.sku.series.title}}/{{layer}}.png"
                             onerror='this.src="/images/icons/noimage.png"'
                             alt="{{orderItemSummary.sku.functionType.title}}" style="max-height:50px;max-width: 80%;" />
                    </div>
                    <div class="frame-number-config">
                        <div class="frame-number-manipulation-cell" (click)="functionselector.decSku(orderItemSummary.sku)">
                            <img src="/images/icons/minus.png" />
                        </div>
                        <div class="frame-number-manipulation-cell">{{orderItemSummary.qty}}</div>
                        <div class="frame-number-manipulation-cell" (click)="functionselector.incSku(orderItemSummary.sku)">
                            <img src="/images/icons/plus.png" />
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>

        
        <div class="summary-list-wrapper" *ngIf="!functionselector.isAnythingInSet">
            <mat-list role="list" >
                <mat-list-item class="summary-position" role="listitem"
                               style="height:unset;">
                    <div mat-list-text class="position-wrapper">
                        <div class="position-label">Brak pozycji na liście</div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
        
    </div>
</div>
