import { Component, AfterContentInit } from '@angular/core';
import { OrderService } from '../../services/orders.service';
import { ProjectNameDialog } from '../controls/project-name.component';
import { MatDialog } from '@angular/material/dialog';
import { Project, Room } from '../../_models';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-project-selection',
    templateUrl: './project-selection.component.html',
    styleUrls: ['./project-selection.component.scss']
})
/** ProjectSelection component*/
export class ProjectSelectionComponent implements AfterContentInit {
    projectSelected: number = null;
    /** ProjectSelection ctor */
    constructor(public orderService: OrderService, public dialog: MatDialog, public router: Router) {

    }
    ngAfterContentInit(): void {
        $('#projectsButton').addClass('active');
    }
    openProject(i: number) {
        AppComponent.SendEventGa('operation', null, 'B2C_otwarcie projektu');
        //let j = this.orderService.projects.length - i - 1;
        let j = i;
        this.orderService.openProject(j);
        this.router.navigateByUrl('/wybierz-design#pokoje');
        //document.getElementsByClassName('tab2-content')[0].scrollIntoView();
    }
    changeProject(i: number): void {
        AppComponent.SendEventGa('operation', null, 'B2C_rozpoczęcie edycji projektu',i);
        //let j = this.orderService.projects.length - i - 1;
        let j = i;
        const dialogRef = this.dialog.open(ProjectNameDialog, {
            width: '250px',
            data: { projectName: this.orderService.projects[j].title }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result != null) {
                this.orderService.projects[j].title = result;
                this.orderService.saveChangesProjectById(j);
                //if (i == this.projectSelected)
                //    this.orderService.setProjectName(result);

            }
        });
    }
    duplicateProjekt(i: number) {
        AppComponent.SendEventGa('operation', null, 'B2C_skopiowanie pomieszczenia', i);
        //let j = this.orderService.projects.length - i - 1;
        let j = i;
        let newProject: Project = {
            title: this.orderService.projects[j].title,
            rooms: this.orderService.projects[j].rooms,
            type: 1 
        }
        this.orderService.projects.push(
            newProject
        );
        this.orderService.saveCurrentContextOld();
        //TODO: copying room after room
    }
    deleteProject(i: number) {
        AppComponent.SendEventGa('operation', null, 'B2C_usunięcie projektu', i);
        let j = i;
        this.orderService.delProject(j)
    }
    newProjectConfig() {
        AppComponent.SendEventGa('operation', null, 'B2C_Otwarcie nowego projektu z menu projektów');
        let newProject: Project = {
            title: null,
            rooms: new Array<Room>(),
            type: 1
        };
        const dialogRef = this.dialog.open(ProjectNameDialog, {
            width: '250px',
            data: { projectName: newProject.title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                newProject.title = result;
                this.addProject(newProject);
                this.router.navigateByUrl('/wybierz-design#pokoje');
            }
        });
    }
    addProject(p: Project) {

        if (this.orderService.projects == null)
            this.orderService.projects = new Array<Project>();
        this.orderService.addProject(p);
    }
}
