import { Component, Inject } from '@angular/core';
import { InspWallColor } from '../main.component';
import { AppComponent } from '../../app.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'color-info-dialog',
    templateUrl: './color-info-dialog.component.html',
    styleUrls: ['./color-info-dialog.component.css']
})
export class ColorInfoDialog {

    constructor(
        public dialogRef: MatDialogRef<ColorInfoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: InspWallColor) {
        console.log("dialog color: " + data.Color);
    }

    onNoClick(): void {
        AppComponent.SendEventGa('sku-image', null, 'Insp_zamknięcie koloru', 0);
        this.dialogRef.close();

    }

}
