<div mat-dialog-content>

    <h4>
        Nowa seria: {{getSeriesTitle}}
    </h4>

    <div *ngIf="changes.changedSkus.length > 0">
        <h4>Produkty do zmiany:</h4>
        <div class="function-row-wrapper-header big-table">
            <div class="function-row-wrapper">
                <div class="function_header cols1" style="width: 44px;"></div>
                <div class="function_header cols2 left-white-margin" style="width: 290px;">Produkt</div>
                <div class="function_header cols6 left-white-margin" style="width: 95px;">
                    <div>Kolor</div>
                </div>
                <div class="function_header code left-white-margin" title="Numer referencyjny w koszyku" style="width: 139px;">Poprzednia ref.</div>
                <div class="function_header code left-white-margin" title="Proponowany numer referencyjny" style="width: 139px;">Zmieniona ref.</div>
            </div>
            <div class="function-row-wrapper{{i%2}}"
                 *ngFor="let set of changes.changedSkus ;let i = index;">
                <div class="cols1 function-label function-label-icon function-label-icon-small"
                      style="width: 34px;"
                     *ngIf="set.bestMatchingSku != null">
                    <img src="images/icons/checked.png" />
                </div>
                <div class="cols1 function-label function-label-icon function-label-icon-small"
                     title="Wybierz kolor"  style="width: 34px;"
                     *ngIf="set.bestMatchingSku == null">
                    <img src="images/icons/nok.png" />
                </div>

                <div class="function-label cols2 left-white-margin" style="width: 290px;">
                    {{set.mainSku.functionType.title}}
                </div>

                <div class="colorInSummary function-label cols6 left-white-margin" title="Zmień kolor" style="width: 84px;">
                    <mat-form-field title="Zmień kolor" style="width: 85px;">
                        <mat-label>{{saveBestMatchingColor(i)}}</mat-label>
                        <mat-select value="{{saveBestMatchingValue(i)}}" [(ngModel)]="set.newBestMatchingSku"
                                    (selectionChange)="changeColorOfUnit(i)" width="calc(100% - 32px)">
                            <mat-option *ngFor="let altCol of set.alternateSkus;let j = index;"
                                        value="{{j}}" width="100%">
                                <img style="height:24px;" src="/images/series/colors/{{altCol.color.title}}.png" />
                                {{altCol.color.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="function-label code left-white-margin" title="Poprzedni numer referencyjny" style="width: 139px;">
                    {{set.mainSku.code}}
                </div>
                <div class="function-label code left-white-margin" *ngIf="set.bestMatchingSku != null" title="Nowy numer referencyjny" style="width: 139px;">
                    {{set.bestMatchingSku.code}}
                </div>
                <div class="function-label code left-white-margin" *ngIf="set.bestMatchingSku == null" title="Nowy numer referencyjny" style="width: 139px;">
                    -
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="changes.unmappedSkus.length > 0">
        <h4>
            Produkty, które nie mają odpowiednika w {{changes.newSeries.title}}
        </h4>
        <div class="function-row-wrapper-header big-table">
            <div class="function-row-wrapper">
                <div class="function_header cols2 left-white-margin" style="width:360px">Produkt</div>
                <div class="function_header code left-white-margin" title="Numer referencyjny w koszyku" style="width:360px">Nr ref.</div>
            </div>
            <div class="function-row-wrapper{{i%2}}"
                 *ngFor="let sku of changes.unmappedSkus ;let i = index;">

                <div class="function-label cols2 left-white-margin" style="width:350px">
                    {{sku.functionType.title}}
                </div>
                <div class="function-label code left-white-margin" title="Numer referencyjny" style="width:350px">
                    {{sku.code}}
                </div>

            </div>
        </div>
    </div>
 </div>
<div mat-dialog-actions>
    <button mat-button class="cancel-button" (click)="onNoClick()">Anuluj</button>
    <button  *ngIf="changes.changedSkus.length > 0" class="accept-button"  [disabled]="!allChangesFinal"
            mat-button [mat-dialog-close]="changes.changedSkus" cdkFocusInitial>Zamień</button>
</div>
