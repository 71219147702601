<div class="pseudoshadow">
    <div class="tab-content-description">
        <h2>
            Twoje projekty
        </h2>
    </div>
</div>
<div id="tab-content-projects">
    <div *ngFor="let project of orderService.projects; let i = index;">
        <div *ngIf="project.type == 1"  class="project-button-label"  >
            <div class="project-button" title="{{project.title}}" (click)="openProject(i)">
                {{project.title}}
            </div>

            <div class="project-buttons-line">
                <div id="edit-button" class="project-bottom-buttom" matTooltip="Nazwa projektu">
                    <img class="project-button-icon" (click)="changeProject(i); $event.stopPropagation();" src="/images/icons/edytuj-white.png" />
                </div>
                <div id="duplicate-button" class="project-bottom-buttom" matTooltip="Duplikuj projekt">
                    <img class="project-button-icon" (click)="duplicateProjekt(i); $event.stopPropagation();" src="/images/icons/kopiuj-white.png" />
                </div>
                <div id="del-button" class="project-bottom-buttom" matTooltip="Usuń projekt">
                    <img class="project-button-icon" (click)="deleteProject(i);$event.stopPropagation();" src="/images/icons/usun-white.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="project-button-new">
        <div class="app-component projectContainer">
            <div (click)="newProjectConfig()" class="project-button" title="Utwórz nowy projekt">
                Utwórz nowy projekt
            </div>
            <div (click)="newProjectConfig()" class="new-project-icon">
                <img src="/images/icons/dodaj projekt livegreen.png" alt="Ikona dodania nowego projektu"/>
            </div>
        </div>
    </div>
</div>

