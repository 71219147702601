import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../_services/index';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppComponent } from '../app.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    moduleId: module.id.toString(),
    template: '',
    providers: [UserService]
})

export class BaseRegisterComponent {
    model: any = {};
    isLoading = false;
    constructor(
        protected router: Router,
        protected userService: UserService,
        protected alertService: AlertService) {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_otwarcie formularza', 0);
    }

    register(successSummary: string, successDetails: string) {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_Zlecenie rejestracji', 0);
        if (!this.consent2 || !this.consent3) {
            return;
        }
            
        this.isLoading = true;

        if (this.model.firstName == null) {
            this.model.firstName = this.model.email;
        }
        if (this.model.lastName == null) {
            this.model.lastName = this.model.email;
        }
        this.model.username = this.model.email;
        if (this.consent1 != null)
            this.model.consent1 = this.consent1;
        
        this.model.consent2 = this.consent2;
        this.model.consent3 = this.consent3;
        this.userService.register(this.model)
            .subscribe(
                data => {
                    this.alertService.showMessage(successSummary, successDetails, MessageSeverity.success);
                    this.router.navigate(['/rejestracja-wyslana']);
                },
                error => {
                    let e: HttpErrorResponse = error;
                    let ee = e.error;
                    console.log('Rejestracja nie udana: ' + e.message + Object.keys(e.error)[0] + e.statusText + e.status);
                    if (error.error[""] != null
                        && error.error[""][0] != null) {
                        console.log(this.translate(error.error[""][0]));
                        this.alertService.showStickyMessage('Rejestracja nie udana', this.translate(error.error[""][0]), MessageSeverity.error);
                    }else if (e.error != null && Object.keys(e.error)[0] != null) {
                        this.alertService.showStickyMessage('Rejestracja nie udana', this.translate(Object.keys(e.error)[0]), MessageSeverity.error);
                    } else {
                        this.alertService.showStickyMessage('Rejestracja nie udana', 'Sprawdź poprawność maila', MessageSeverity.error);
                    }

                    this.isLoading = false;
                });
    }

    translate(text: string) {
        return text.replace('Passwords must have at least one non alphanumeric character.',
            'Hasło musi mieć co najmniej jednen znak specjalny').
            replace("Passwords must have at least one uppercase ('A'-'Z').",
                "Hasło musi mieć co najmniej jedną wielką literę.").replace("Passwords must have at least one digit ('0'-'9').",
                    "Hasło musi mieć co najmniej jedną cyfrę.").replace("Passwords must have at least one lowercase ('a'-'z').",
                        "Hasło musi mieć co najmniej jedną małą literę.").replace("User name", "Konto").replace("is already taken.", "już istnieje.").
            replace("No code found for: ","Kod nie został znaleziony lub jest już nieaktywny: ");

    }
    setRole(role: string) {
        this.model.roles = [role];
    }

    isActive(role: string) {
        if (this.model.roles != null && this.model.roles[0] == role)
            return 'role-picture-active';
        else
            return 'role-picture-inactive';
    }
    signIn() {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_Przejście do logowania', 0);
        this.router.navigateByUrl('/logowanie');
    }
    consent: boolean = true;
    consent1: boolean = null;
    consent2: boolean = null;
    consent3: boolean = null;

    passwordConfirm: string = null;


    //strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    strongRegex = new RegExp("^(?=.{6,})");
    get passwordStrong(): boolean {
        return this.model.newPassword != null && this.strongRegex.test(this.model.newPassword);
    }

    get passwordsMatches(): boolean {
        return this.model.newPassword != null &&
            this.passwordConfirm != null &&
            this.model.newPassword == this.passwordConfirm;
    }
    emailRregex = /.*@.*\...*/g;
    get emailValid(): boolean {
        return this.model.email != null &&
            this.model.email.match(this.emailRregex) == this.model.email;

    }
}
