import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { OrderService } from '../../services/orders.service';
import { PresentationToolsComponent } from '../../services/presentation-tools.component';
import { Color, Series, Sku } from '../../_models/catalog';
import { AppComponent } from '../app.component';
import { SwiperConfigInterface, SwiperPaginationInterface, SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
    selector: 'frames-selection',
    templateUrl: './frames-selection.component.html',
    styleUrls: ['./frames-selection.component.scss']
})
export class FramesSelectionComponent implements OnInit, AfterViewInit, AfterContentInit {
    index: number = 0;
    images: Array<NodeRequire>;
    columns: any[] = [];
    loadingIndicator: boolean;
    selectedSeriesSingleFrameSkus: Sku[];
    presentedSkus: Sku[][];
    selectedSeriesTitle = 'Wybierz serię';
    selectedFrameColorSku: Sku;
    presentedFrameShapes: Sku[];

    @Input() _orderService: OrderService;
    ngAfterContentInit(): void {
        $('#framesButton').addClass('active');
    }

    public config: SwiperConfigInterface = {
        a11y: true,
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: false
    };

    @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;

    public onIndexChange(index: number): void {
        console.log('Swiper index: ', index);
    }

    public onSwiperEvent(event: string): void {
        console.log('Swiper event: ', event);
    }
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private route: ActivatedRoute, private accountService: AccountService,
        public orderService: OrderService, private router: Router, private alertService?: AlertService) {
        //this._orderService = orderService;
        
        console.log("FramesSelectionComponent.orderService:" + orderService.instance);
        if (this.orderService.cProject == null) {
            setTimeout(() => this.router.navigateByUrl('/wybierz-design#!projekty'));
        }
        else if (this.orderService.actRoomIndex == null) {
            setTimeout(() => this.router.navigateByUrl('/wybierz-design#!pokoje'));
        }
        else if (this.orderService.selectedSeriesItem != null &&
            this.orderService.selectedSubSeriesItem != null) {
            document.getElementById('waitingoverlay').hidden = false;
            console.log(this.orderService.selectedSubSeriesItem);
            
            this.selectedSeriesSingleFrameSkus = this.orderService.getSingleFrames(this.orderService.selectedSubSeriesItem);
            this.presentedSkus = PresentationToolsComponent.formatPresentationLists(this.selectedSeriesSingleFrameSkus, 8);
            this.orderService.seriesSku$.subscribe(skus => {
                this.selectedSeriesSingleFrameSkus = skus;
                this.seriesColorSkus = PresentationToolsComponent.UniqueColors(skus);
                this.presentedSkus = PresentationToolsComponent.formatPresentationLists(this.selectedSeriesSingleFrameSkus, 8);

                console.log("SeriesSelection: subscribed event: " + this.selectedSeriesSingleFrameSkus);
                //this.UpdateIfHasSeriesWaterProof();
                this.retrieveAndPresentFrames();

            });
            this.selectedSeriesTitle = this.orderService.currentConfig.selectedSeries.title;
            this.orderService.selectSeries(this.orderService.currentConfig.selectedSeries);


        } else {
            setTimeout(() => this.router.navigateByUrl('/wybierz-design#!serie'));
        }

        }
    
 
    ngAfterViewInit(): void {
        if (this.orderService == null ) {
            setTimeout(() => this.router.navigateByUrl('/wybierz-design#!serie'));
        }

        //document.getElementById('project-header').scrollIntoView();
    }
    ngOnInit() {
        if (this._orderService == null || this._orderService.cProject == null ||
            this._orderService.openedRoom() == null) {
            this.router.navigateByUrl('/wybierz-design#!pokoje');
        }
    }
    ngOnDestroy() {
    }
    get availableSeries() {
        /*if (this._orderService._availableSeries != null)
            console.log("this._orderService._availableSeries: " + this._orderService._availableSeries[0].title);
            */
        
        if (this._orderService._availableSeries != null) {
            var series = this._orderService._availableSeries;
            this.images = new Array<NodeRequire>();
            var s: any;
            
            return this._orderService._availableSeries;
        } else {
            return null;
        }
    }
    seriesColorSkus = new Array<Sku>();
    
    retrieveAndPresentFrames() {
        //select first of available colors as reference and query shapes based on it
        if (this.presentedSkus != null && this.presentedSkus.length > 0) {
            if (this.selectedFrameColorSku == null) {
                this.selectedFrameColorSku = this.presentedSkus[0][0];
                this.selectedColor = this.selectedFrameColorSku.color.title;
            }

            this.orderService.setFrameByColor(this.selectedFrameColorSku);

            console.log("this.selectedFrameSku: " + this.selectedFrameColorSku);
            this._orderService.getFrameShapes(this.selectedFrameColorSku);
            this._orderService.shapesSku$.subscribe(skus => {
                this.rawPresentedFrameShapes = skus.filter(s => s.slots > 0);
                this.presentedFrameShapes = this.sortBySlots(this.filterSkusByProtectionIndexRaw(this.rawPresentedFrameShapes));
                document.getElementById('waitingoverlay').hidden = true;
                //console.log("SeriesSelection.chooseFrameColor: subscribed event: " + this.rawPresentedFrameShapes);
                //this.UpdateIfHasSeriesWaterProof();
                //this.presentedSkus = this.selectedSeriesSingleFrameSkus = null;
            });
        }

    }

    sortFyFunctionname(skus: Sku[]): Sku[] {
        return skus.sort(function (s1, s2) {
            return (s1.functionType < s2.functionType) ? -1 :
                (s1.functionType == s2.functionType) ? 0 : 1
        });
    }
    sortBySlots(skus: Sku[]): Sku[] {
        return skus.sort(function (s1, s2) {
            return (s1.slots < s2.slots) ? -1 :
                (s1.slots == s2.slots) ? 0 : 1
        });
    }


    
    isSelectedSeries(series: Series) {
        if (this.orderService.selectedSeriesItem != null && this.orderService.selectedSeriesItem.id == series.id)
            return true;
        return false;
    }
    
    seriesChipSelectable = true;
    seriesChipRemovable = true;
    
    cancelSeries(): void {
        this.orderService.selectedSeriesItem = null;
        this.presentedSkus = null;
        this.selectedSeriesSingleFrameSkus = null;
        this.presentedFrameShapes = null;
        this.rawPresentedFrameShapes = null;
        //this.seriesColorSkus = null;
        this.selectedFrameColorSku = null;
        setTimeout(() => this.router.navigateByUrl('/wybierz-design#!serie'));
    }
    anyIP44() {
        return this.indexOfProtection != this.indexOfProtectionON && this.presentedFrameShapes && 
            this.presentedFrameShapes.findIndex(s => s.indexOfProtection.title == this.indexOfProtectionON) > 0;
    }
    
    updateFrames() {
        this.selectedFrameColorSku = this.seriesColorSkus.filter(sku => sku.color.title == this.selectedColor)[0];
        console.log(this.selectedFrameColorSku);
        this.retrieveAndPresentFrames();
    }
   
    filterSkusByProtectionIndexRaw(skus: Sku[]):Sku[] {
        if (skus != null) {
            return skus.filter(s => s.slots > 0).
                filter(
                    s => s.indexOfProtection.title == this.indexOfProtection
                ).filter(s => (!this.orientationSelectedHorizontal && s.isVertical) ||
                    (this.orientationSelectedHorizontal && !s.isVertical) ||
                    s.universal);
        }
        return null;
    }
    
    

    decFrame(sku: Sku) {
        AppComponent.SendEventGa('operation', sku.code, 'B2C_zmniejszenie ilości produktu (-)', 0);
        console.log('delete Frame');
        this._orderService.decFrame(sku, !this.orientationSelectedHorizontal);
        this.alertService.showRemMessage();
    }
    addFrame(s: Sku) {
        AppComponent.SendEventGa('operation', s.code, 'B2C_zwiększenie ilości produktu (+)', 0);
        console.log('add Frame');
        this._orderService.addFrame(s, !this.orientationSelectedHorizontal);
        this.alertService.showAddMessage();
    }
    public rotationStyle(actFrame) {
        return PresentationToolsComponent.rotationFrameStyle(actFrame, !this.orientationSelectedHorizontal);
    }

    rawPresentedFrameShapes: Sku[];
    chosenFrameShape: Sku;


    selectedColor = null;
    selectColor(color: Color) {
        AppComponent.SendEventGa('filtering', color.title, "B2C_wybór koloru na filtrach", 0);
        this.selectedColor = color.title;
        this.updateFrames();
    }
    orientationSelectedHorizontal: boolean = true;
    orientationSelectedTitle: string = 'Poziome';
    setOrientation(orientation: string) {
        AppComponent.SendEventGa('filtering', orientation, "B2C_Wybór orientacji ramek", 0);
        if (orientation == 'H') {
            this.orientationSelectedHorizontal = true;
            this.orientationSelectedTitle = 'Poziome';
            this.updatePresentationAfterFilterChange();
        } else if (orientation == 'V') {
            this.orientationSelectedHorizontal = false;
            this.orientationSelectedTitle = 'Pionowe';
            this.updatePresentationAfterFilterChange();
        }

    }

    indexOfProtectionOFF = 'IP20';
    indexOfProtectionON = 'IP44';
    protectionSwitchColor = "primary";
    defaultIndexOfProtection = "IP20";
    indexOfProtection = this.defaultIndexOfProtection;

    get waterProof() {
        return this.orderService.waterProof;
    }
    toggleWaterProof() {
        AppComponent.SendEventGa('filtering', '' + !this.orderService.waterProof , "B2C_Zmiana wodoodporności", 0);
        this.orderService.waterProof = !this.orderService.waterProof;
        if (this.orderService.waterProof) {
            this.indexOfProtection = this.indexOfProtectionON;
        } else {
            this.indexOfProtection = this.indexOfProtectionOFF;
        }
        this.updatePresentationAfterFilterChange();
    }

    updatePresentationAfterFilterChange() {
        this.presentedFrameShapes = this.sortBySlots(this.filterSkusByProtectionIndexRaw(this.rawPresentedFrameShapes));
    }


    //Styles
    colorSelectedStyle(colorSku: Sku): string{
        if (colorSku == null || colorSku.color == null)
            return "";
        return (colorSku.color.title == this.selectedColor) ? 'selected-option' : '';
    }

    orderByOrientation(skus: Sku[]): Sku[][] {
        return PresentationToolsComponent.formatFramePresentationLists(skus);
    }

    newValueDetected(event: Event, sku: Sku) {
        
        let element = event.currentTarget as HTMLInputElement;
        let value = element.value;
        let eId = "row_" + sku.code;
        
        let newValue = parseFloat(value) < 0 ? 0 : parseFloat(value);
        AppComponent.SendEventGa('operation', sku.code, 'B2C_Dodanie produktu do Twojej Listy (ramka lub mechanizm)', newValue);
        console.log("Show alert" + sku.code);

        let oldValue = this.numberOfFrameItems(sku)
        if (oldValue == newValue)
            return;
        if (oldValue < newValue) {
            this.alertService.showAddMessage();
            for (let i = 0; i < newValue - oldValue; i++) {
                this._orderService.addFrameNoSave(sku, !this.orientationSelectedHorizontal);
            }
        } else if (oldValue > newValue) {
            this.alertService.showRemMessage();
            for (let i = 0; i < oldValue - newValue ; i++) {
                this._orderService.decFrameNoSave(sku, !this.orientationSelectedHorizontal);
            }
        }

        this._orderService.saveChangesProjectById(this._orderService.openedProjectIndex);
        this._orderService.saveCurrentContextLocaly();
    }
    numberOfFrameItems(sku: Sku): number {
        if (this.orderService.openedRoom().orderSets == null || this.orderService.openedRoom().orderSets.length == 0)
            return 0;
        let count = 0;
        for (let os of this.orderService.openedRoom().orderSets) {
            if (os != null && os.orderSetItems != null && os.orderSetItems.length > 0
                && os.orderSetItems[0] != null && os.orderSetItems[0].sku.code == sku.code
                && (os.orderSetItems[0].isVertical == null || os.orderSetItems[0].isVertical == !this.orientationSelectedHorizontal)
            ) {
                count += os.orderSetItems[0].qty;
            }
                
        }
        return count;
    }
    gotoFunctions() {
        setTimeout(() => this.router.navigateByUrl('/wybierz-design#funkcje'));
    }
}

