<div id="app_container" class="{{getBackground()}}">
    <ng2-toasty class="big-screen" [position]="'bottom-right'"></ng2-toasty>
    <ng2-toasty class="small-screen" [position]="'top-right'"></ng2-toasty>
    <div class='headerbck'>

        <div class='main-header-container'>
            <nav id="header" class="app-component navbar navbar-inner">
                <nav class="seheader" *ngIf="!isMainPage">
                    <div class="navbar-header">
                        <button type="button"
                                class="app-component navbar-toggle collapsed" data-toggle="collapse" data-target=".menuItemsContainer.app-component" aria-expanded="false"
                                style="float: left;">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <a class="app-component navbar-brand" routerLink="/">
                            <img src="/images/logo-white2.png" alt="logo">

                        </a>
                    </div>
                    <div class="app-component collapse navbar-collapse menuItemsContainer" *ngIf="!isMainPage">
                        <ul *ngIf="!isMainPage" class="app-component nav navbar-nav nav-pills navBarPadding">

                            <li routerLinkActive="active"><a routerLink="/wybierz-design">Wybierz design</a></li>

                            <li routerLinkActive="active">
                                <a routerLink="/szybka-konfiguracja">
                                    Szybka konfiguracja
                                </a>
                            </li>
                            <!--
    <li routerLinkActive="active">
        <a href="/odace">
            ODACE
        </a>
    </li>
        -->

                        </ul>
                        <ul *ngIf="!isUserLoggedIn" class="app-component nav navbar-nav nav-pills navBarPadding navbar-right login-link">
                            <li><a routerLink="/logowanie"><span class="glyphicon glyphicon-log-in"></span> Zaloguj</a></li>
                        </ul>
                        <ul *ngIf="isUserLoggedIn" class="app-component nav navbar-nav nav-pills navBarPadding navbar-right">

                            <li *ngIf="false" routerLinkActive="active">
                                <a href="/moje-konto">Konto</a>
                            </li>
                            <li (click)="logout()"><a href="javascript:;"><span class="glyphicon glyphicon-log-out"></span> Wyloguj</a></li>
                        </ul>


                    </div>
                </nav>
                <nav class="seheader" *ngIf="isMainPage">
                    <div class="navbar-header">

                        <a class="app-component navbar-brand" routerLink="/">
                            <img src="/images/logo-white2.png" alt="logo">

                        </a>
                        <button type="button"
                                class="app-component navbar-toggle collapsed" data-toggle="collapse" data-target=".menuItemsContainer.app-component" aria-expanded="false"
                                style="float: left;">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                    </div>


                    <div class="app-component menuItemsContainer " style="top: -14px;right: 40px;">
                        <div style="display: flex;">
                            <div class="sedna_design_bar sednadesign">

                                <!--<img src="/images/main/sednadesign.png" />-->
                                <a href="/sednadesign">
                                    <span class="glyphicon glyphicon-none">
                                    </span><img src="/images/main/sednadesign.png" alt="Sedna Design & Elegance" />
                                </a>
                            </div>
                            <!--<ul class="app-component nav navbar-nav nav-pills navBarPadding navbar-right button-border sednadesign">
                                    <li><a href="/sednadesign/"><span class="glyphicon glyphicon-none">
                                         </span><img src="/images/main/sednadesign.png" alt="Sedna Design & Elegance" /> </a></li>
                                </ul>
                                -->
                            </div>
                        </div>

                        <div class="app-component menuItemsContainer" style="float: right;position: absolute;top: -14px;right: 40px;">
                            <ul *ngIf="!isUserLoggedIn" class="app-component nav navbar-nav nav-pills navBarPadding navbar-right">
                                <li><a routerLink="/logowanie"><span class="glyphicon glyphicon-log-in"></span> Zaloguj</a></li>
                            </ul>
                            <ul *ngIf="isUserLoggedIn" class="app-component nav navbar-nav nav-pills navBarPadding navbar-right">
                                <li *ngIf="false" routerLinkActive="active">
                                    <a href="/moje-konto">Konto</a>
                                </li>
                                <li (click)="logout()"><a href="javascript:;"><span class="glyphicon glyphicon-log-out"></span> Wyloguj</a></li>
                            </ul>

                        </div>
                    </nav>
                </nav>
            </div>
        </div>
        <div class="maincontentcontainer">
            <div id="pre-bootstrap" *ngIf="!removePrebootScreen" [class.prebootShow.app-component]="!isAppLoaded" class="app-component prebootStep">
                <div class="messaging">
                    <h1>
                        Loaded!
                    </h1>
                    <p>
                        SCHNEIDER_ELECTRIC - <span class="appName" style="font-style:italic">Twój</span><span class="appName">konfigurator</span>
                    </p>

                </div>
            </div>

            <router-outlet></router-outlet>



        </div>



        <div *ngIf="shouldShowLoginModal" class="modal fade" bsModal #loginModal="bs-modal" (onShown)="onLoginModalShown()" (onHidden)="onLoginModalHidden()" (onHide)="onLoginModalHide()"
             [config]="{backdrop: 'static'}" tabindex="-1">
            <div class="modal-dialog modal-lg">
                <app-login #loginControl isModal="true"></app-login>
            </div>
        </div>
    </div>

    <!--footer-->
                            <div class="row" *ngIf="isMainPage || isSePage || isRegisterPage">
                                <div id="footer">
                                    <div class="app-component navbar-bottom navbar-inner footer">
                                        <footer>
                                            <div class="app-component vright_text center-block">
                                                <div *ngIf="authService.isLoggedIn" class="footer-function" (click)="removeAccountDialog()">Usuń konto</div>
                                                <p class="text-center text-muted footer-text">
                                                    <a *ngIf="isSePage" target="_blank" href="/documents/Common/Zasady korzystania ze zniżek pracowniczych.pdf">Zasady korzystania ze zniżek pracowniczych</a>
                                                    <span class="footer-text" *ngIf="isSePage">|</span>
                                                    <a target="_blank" href="https://www.se.com/pl/pl/about-us/legal/data-privacy.jsp">Polityka prywatności</a>
                                                    <span class="footer-text">|</span>
                                                    <a target="_blank" href="https://www.schneider-electric.pl" target="_blank">© Schneider Electric Polska.</a>
                                                </p>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                            <div id="waitingoverlay" hidden>
                                <mat-spinner></mat-spinner>
                            </div>

                            <cookie-law>
                                W celu zapewniania najlepszych usług aplikacja zapisuje część informacji w tzw. ciastkach.
                                Korzystając z aplikacji zgadzasz się na ich użycie. Poznaj naszą <a target="_blank" href="https://www.se.com/pl/pl/about-us/legal/data-privacy.jsp">Politykę prywatności</a>.
                            </cookie-law>
