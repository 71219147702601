import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, Component, HostListener, Inject, OnChanges, OnDestroy, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { OrderService } from '../../services/orders.service';
import { PresentationToolsComponent } from '../../services/presentation-tools.component';
import { StylingHelper } from '../../_helpers/StylingHelper';
import { CategoryGroup, CategoryGroupFormated, ColorSeries, FunctionsTypeImpl, Sku, OrderSetItem } from '../../_models/catalog';
import { DialogData, InformationDialogComponent } from '../controls/information-dialog.component';
import { AppComponent } from '../app.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


import { SwiperConfigInterface, SwiperPaginationInterface, SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { PromptDialogComponent } from './controls/prompt.component';
import { MessageDialogComponent } from './controls/message.component';


@Component({
    selector: 'function-selection',
    templateUrl: './function-selection.component.html',
    styleUrls: ['./function-selection.component.scss'],
    
})
export class FunctionSelectionComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit     {
    active = 1;
    suggestedFunctions: Boolean = false;
    activeTabIdString: "suggestedTab";
    selectedColorTitle: string;
    functionsScrollConfig: PerfectScrollbarConfigInterface = {
        wheelSpeed: 2,
        wheelPropagation: true,
        suppressScrollY: true
    };
    setSuggestedFunctions(value:boolean) {
        this.suggestedFunctions = !this.suggestedFunctions;

        this.updateFunctionsForSelectedOrderItem();
    }
    categoryGroups: CategoryGroup[];

    
    filteredFunctions: any;
    ngAfterContentInit(): void {
        console.log("ngAfterContentInit");
        $('#functionsButton').addClass('active');

        if (this.orderService.selectedSeriesItem == null ||
            this.orderService.actFrame() == null || this._orderService == null) {
            return
        }
        this.updateFunctionsForSelectedOrderItem();
        //document.getElementById('project-header').scrollIntoView();
    }
    ngAfterViewInit(): void {
        console.log("ngAfterViewInit");
        if (this.orderService.actFrame() != null) {
            console.log("/images/series/" +
                this.orderService.selectedSeriesItem.title +
                "/frames/" +
                this.orderService.actFrame().code +
                ".png");
            
        }else {
            
            setTimeout(() => this.router.navigateByUrl('/wybierz-design#serie'));
        }

    }

    availableFunctions: Sku[];
    get suggestedFunctionSkus(): Sku[]{
        return this._orderService.recomendedSkus;
    }
    public _orderService: OrderService;
    availableColors: ColorSeries[] = null;
    selectedColorId: number;
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private route: ActivatedRoute, private accountService: AccountService,
        public orderService: OrderService, private router: Router,
        public dialog: MatDialog, private sanitizer: DomSanitizer,
        private alertService?: AlertService
    ) {
        console.log("Init function selection");
        console.log("FunctionSelectorB2C.constructor");
        this._orderService = orderService;

        if (this.orderService.actFrame() == null) {
            console.log("FunctionSelectorB2C.actFrame is null");
            return;
        }
        console.log("FunctionSelectorB2C.getColorsOfSeries is null");
        this.orderService.contentLoading = true;
        this._orderService.getColorsOfSubSeriesMechanisms(true);
        
        this._orderService.colorOfSeries$.subscribe(colors => {
            this.availableColors = colors.filter(cs => cs.colorId != -1);
            var sColor = this.availableColors.find(ac => ac.colorId == this.orderService.selectedFrameColorSku.color.id);
            if (sColor != null) {
                this.selectedColorId = this.orderService.selectedFrameColorSku.color.id;
                this.selectedColorTitle = this.orderService.selectedFrameColorSku.color.title;
            } else {
                this.selectedColorId = this.availableColors[0].colorId;
                this.selectedColorTitle = this.availableColors[0].colorName;
            }
            
            console.log("AvailableColors " + this.availableColors);
            
        });
        this.updateColorsAndFunctions();
        this.orderService.contentLoading = false;
    }

    		

    updateFunctions() {
        //this.orderService.getCategoryGroups();
        this.orderService.getFunctionsBySeriesAndColorActOrderSetSeriesB2C(this.selectedColorId);
        this.orderService.getCategoryGroups();
        var groupsGot = false;
        var functionsGot = false;
        let seriesId = this._orderService.actSeries().id;
        this._orderService.getRecommendedFunctions(seriesId, this.selectedColorId);
        
        this.orderService.filteredFunctions$.subscribe(

            functions => {
                functionsGot = true;
                this.availableFunctions = functions;
                if (groupsGot)
                    this.updateFunctionPresentation(functions, this.categoryGroups); 
            });
        this.orderService.categoryGroups$.subscribe(
            groups => {
                groupsGot = true;
                this.categoryGroups = groups;
                if (functionsGot)
                    this.updateFunctionPresentation(this.availableFunctions, this.categoryGroups);
            });

    }
    nRows = 100;
    categoryGroupFormated: CategoryGroupFormated[];
    selectedCGF: CategoryGroupFormated = null;

    isSelectedCGF(cgf: CategoryGroupFormated) {
        return this.selectedCGF != null && this.selectedCGF.cg != null && cgf != null && cgf.cg != null &&
            cgf.cg.id == this.selectedCGF.cg.id;
    }
    selectedCategoryGroup: CategoryGroup = null;
    functionsOfSelectedCategory: Sku[];
    setSelectedCGF(cgf: CategoryGroupFormated) {
        
        if (cgf == null && this.categoryGroupFormated != null && this.selectedCGF != null) {
            this.selectedCGF = this.categoryGroupFormated.find(cg => cg.cg.id == this.selectedCGF.cg.id);
        }
        if (cgf == null && this.categoryGroupFormated != null && this.selectedCGF == null) {
            this.selectedCGF = this.categoryGroupFormated[0];
        } else if(cgf != null){
            this.selectedCGF = cgf;
        }
        if (this.categoryGroupFormated != null && this.selectedCGF == null) {
            this.selectedCGF = this.categoryGroupFormated[0];
        }
    }
    suggestedCGF: CategoryGroupFormated = null
    updateFunctionPresentation(functions: Sku[], categoryGroups: CategoryGroup[]): any {
        var suggestedSku;
        //this._orderService.getRecommendedFunctions(this._orderService.actSeries().id, this.selectedColorId);
        //console.log('getRecommendedFunctions result: ' + this._orderService.recomendedSkus.length)
        if (this.suggestedFunctionSkus != null && this.suggestedFunctionSkus.length > 0) {
            suggestedSku = functions.filter(f => this.suggestedFunctionSkus.findIndex(f2 => f2.code == f.code) >= 0);
        }
                
        else {
            let room = 'any';
            let suggestedInfos = this.getSuggestedFunctionInfos(room, functions[0].series.title);
            console.log(suggestedInfos);
            console.log('functions:' + functions);
            suggestedSku = functions.filter(f => suggestedInfos.includes(f.info));
            console.log('suggestedSku:' + suggestedSku);
        }
        let uniqueSuggestedSkus = new Array<Sku>();
        suggestedSku.forEach(function(s){
            if(uniqueSuggestedSkus.findIndex(us => (us.functionType.title == s.functionType.title)) < 0) {
            uniqueSuggestedSkus.push(s)
        }
    });
        console.log('uniqueSuggestedSkus:' + uniqueSuggestedSkus);
        this.suggestedCGF = PresentationToolsComponent.categorisedCollections(uniqueSuggestedSkus, null, this.nRows)[0];
        console.log('suggestedCGF:' + this.suggestedCGF);
            this.availableFunctions = functions;

            this.filteredFunctions = PresentationToolsComponent.filterFunctions(this.availableFunctions,
                null, //this.orderService.waterProof,
                this.orderService.actFrame().series,
                this.selectedColorId,
                this.orderService.actOrderItem.sku.subSeries.title
            );
            this.categoryGroupFormated =
                PresentationToolsComponent.categorisedCollections(this.filteredFunctions, categoryGroups, this.nRows);
            this.setSelectedCGF(null);
        this.isLoading = false;
    }
    noRoomsSelected() {
        this.routingDialog("Brak wybranego pokoju",
            "Dodaj najpierw pokój, aby dopasować serię i mechniazmy",
            "/wybierz-design#pokoje");
    }

    positions = [,];
    noSeriesSelected() {
        this.routingDialog("Brak wybranej serii",
            "Dodaj najpierw ramki z wybranej serii, aby dopasować mechanizm",
            "/wybierz-design#serie");
    }
    noFramesSelected() {
        this.routingDialog("Brak wybranej ramki",
            "Dodaj najpierw ramki z wybranej serii, aby dopasować mechanizm",
            "/wybierz-design#ramki");
    }
    routingDialog(inMmessageTitle: string, inMessageBody: string, inUrl: string, inConfirmCallback: Function = null) {
        let newDialog: DialogData = {
            messageTitle: inMmessageTitle,
            messageBody: inMessageBody,
            url: inUrl,
            confirmCallback: inConfirmCallback
        };
        setTimeout(() => {
            const dialogRef = this.dialog.open(InformationDialogComponent, {
                width: '350px',
                data: newDialog
                });
            },1);
        
    }


    ngOnInit() {
        console.log("Init function selection");
        if (this._orderService == null || this._orderService.cProject == null) {
            this.router.navigateByUrl('/wybierz-design#projekty');
        }
        if (this._orderService.openedRoom() == null) {
            this.router.navigateByUrl('/wybierz-design#pokoje');
        }
        if (this.orderService.selectedSeriesItem == null) {
            this.router.navigateByUrl('/wybierz-design#series');
        }
        else if (this.orderService.actFrame() == null) {
            this.noFramesSelected();
            return;
        }
        else if (this.orderService.openedRoom() == null) {
            this.noRoomsSelected();
            return;
        }
        else if (this.orderService.openedRoom().orderSets == null || this.orderService.openedRoom().itemIndex >= this.orderService.openedRoom().orderSets.length)
            this.orderService.openedRoom().itemIndex = 0;
        // Generate initial model
        /*for (var i = 1; i <= 3; ++i) {
            //this.lists.A.push({ label: "Item A" + i });
            //this.lists.B.push({ label: "Item B" + i });
        }*/

    }

    

    ngOnDestroy() {
    }



    dropableMargins(position: number, isV: boolean) {
        let frame = this.orderService.actFrame();
        console.log('dropableMargins: ' + position);
        return StylingHelper.positionLeftPr(frame, position, isV) + ';' + StylingHelper.positionBottomPr(frame, position, isV);
    }
    public revertSlotId(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        let isV = osi.isVertical;
        
        if(isV)
            return this.sanitizer.bypassSecurityTrustStyle((10 - position).toString());
        else
            return this.sanitizer.bypassSecurityTrustStyle('unset');
    }

    public positionLeftPrValue(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        let isV = osi.isVertical;
        if (sku.code == 'S52P712')
            return this.sanitizer.bypassSecurityTrustStyle('0%');
        else if (sku.code == 'S52P742')
            return this.sanitizer.bypassSecurityTrustStyle('0%');
        else if (sku.series.id == 8) return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionLeftPx(sku, position + 1, isV) / (StylingHelper.frameLenPx(sku, isV) * 1.00) * 100).toFixed(1).toString() + '%');
        else return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionLeftPx(sku, position + 1, isV) / (StylingHelper.frameLenPx(sku, isV)*1.00) * 100 - 0.5).toFixed(1).toString() + '%');
    }
    public positionBottomPrValue(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        let isV = osi.isVertical;
        let orderItem = this.functionPositionSelected(position);
        if (sku.code == 'S52P722')
            return this.sanitizer.bypassSecurityTrustStyle('25%');
        else if (isV && orderItem && orderItem.sku != null && ["SDD111122", "SDD112122", "SDD113122", "SDD114122"].includes(orderItem.sku.code))
            return this.sanitizer.bypassSecurityTrustStyle('unset');
        else if (sku.code == 'S52P742')
            return this.sanitizer.bypassSecurityTrustStyle('22%');
        else if (sku.series.id == 8) return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionBottomPx(sku, position + 1, isV) / (StylingHelper.frameLenPx(sku, isV) * 1.00) * 100).toFixed(1).toString() + '%');
        else return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionBottomPx(sku, position + 1, isV) / StylingHelper.frameLenPx(sku, isV) * 100 - 0.5).toFixed(1).toString() + '%'
        );
    }

    public positionTopPrValue(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        let isV = osi.isVertical;
        let orderItem = this.functionPositionSelected(position);
        
        if (!isV && orderItem && orderItem.sku != null && ["SDD111122", "SDD112122", "SDD113122", "SDD114122"].includes(orderItem.sku.code))
            return this.sanitizer.bypassSecurityTrustStyle('0');
        else if (isV && orderItem && orderItem.sku != null && ["SDD111122", "SDD112122", "SDD113122", "SDD114122"].includes(orderItem.sku.code))
            return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionTopPx(sku, position + 1, isV) / (StylingHelper.frameLenPx(sku, isV) * 1.00) * 100).toFixed(1).toString() + '%');
        else 
            return this.sanitizer.bypassSecurityTrustStyle('unset');
        
    }

    public positionWidthPrValue(osi: OrderSetItem) {
        let w = null;
        let sku = osi.sku;
        let isV = osi.isVertical;
        if (sku.code == 'S52P742')
            w = '100%';
        else if (sku.code == 'S52P712')
            w = '100%';
        else if (isV && osi.sku.series.id == 8)
            w = '100%';
        else if (isV || sku.isVertical)
            if (sku.series.id == 8)
                w = '103%';
            else
                w = '101%';
        else if (osi.sku.series.id == 8)
            w = (StylingHelper.positionWidthPx(sku, isV) / StylingHelper.frameLenPx(sku, isV) * 100).toFixed(1).toString() + '%';
        else
            w = (StylingHelper.positionWidthPx(sku, isV) / StylingHelper.frameLenPx(sku, isV) * 100 + 1).toFixed(1).toString() + '%';

        return this.sanitizer.bypassSecurityTrustStyle(w);
    }
    

    public positionHeightPrValue(osi: OrderSetItem, position: number) {
        let h = null;
        let sku = osi.sku;
        let isV = osi.isVertical;
        let orderItem = this.functionPositionSelected(position);
        if (sku.code == 'SDD111122')
            h = '109%';
        else if (sku.code == 'S52P722')
            h = '74%';
        else if (sku.code == 'S52P742')
            h = '78%';
        else if (sku.code == 'S52P732')
            h = '83%';
        else if (sku.code == 'S52P712')
            h = '83%';
        else if (sku.code == 'S52P712')
            h = '83%';
        else if (!isV && orderItem && orderItem.sku != null &&
            ["EPH2800671", "EPH2800662"].includes(orderItem.sku.code))
            h = '107%';
        else if (isV && orderItem && orderItem.sku != null &&
            ["EPH2800671", "EPH2800662"].includes(orderItem.sku.code))
            h = (StylingHelper.positionWidthPx(sku, isV) / StylingHelper.frameHighPx(sku, isV) * 107 + 1.5).toFixed(1).toString() + '%';
        else if (!isV && orderItem && orderItem.sku != null && 
            ["SDD111122", "SDD112122", "SDD113122", "SDD114122"].includes(orderItem.sku.code))
            h = '109%';
        else if (orderItem && orderItem.sku != null && ["SDD111122", "SDD112122", "SDD113122", "SDD114122"].includes(orderItem.sku.code))
            h = (StylingHelper.positionWidthPx(sku, isV) / StylingHelper.frameHighPx(sku, isV) * 109).toFixed(1).toString() + '%';
        else if (!isV && !sku.isVertical)
            if (sku.series.id == 8)
                h = '100%';
            else
                h = '101%';
        else if (osi.sku.series.id == 8)
            h = (StylingHelper.positionWidthPx(sku, isV) / StylingHelper.frameHighPx(sku, isV) * 100).toFixed(1).toString() + '%';
        else
            h = (StylingHelper.positionWidthPx(sku, isV) / StylingHelper.frameHighPx(sku, isV) * 100 + 1.5).toFixed(1).toString() + '%';
        return this.sanitizer.bypassSecurityTrustStyle(h);
    }

    frameSlotIndexes = [0, 1, 2, 3, 4, 5];
    getDropableClass(position: number) {
        var currentframelength = this.orderService.actFrame().slots;
        if ((position+1) > currentframelength)
            return 'hidden';

        var prefix = 'dropable' + (position + 1) + 'of';
        var main = new String(currentframelength);
        var suffix = '';
        if (! this.hasPositionSelected(position)) {
            suffix = ' opacity03';
        }
        if (this.orderService.actOrderSet().orderSetItems[position + 1] != null &&
            this.orderService.actOrderSet().orderSetItems[position + 1].sku != null)
            suffix = suffix + 'Img';
        //console.log('position: ' + position + " suffix: " + suffix);
        if (this.orderService.actOrderItem.isVertical || this.orderService.actFrame().isVertical)
            return prefix + main + 'Vert' + suffix;
        else if (this.orderService.actFrame().series.title == 'Odace')
            return prefix + main + 'Uni' + suffix;
        else
            return prefix + main + 'Hor' + suffix;
    }

    currentframelength(chosenFrameFunctionTitle: string) {
        if (chosenFrameFunctionTitle == null)
            var chosenFrameFunctionTitle = this.orderService.actFrame().functionType.title;
        if (chosenFrameFunctionTitle.indexOf('1-krotna') > 0)
            return 1;
        else if (chosenFrameFunctionTitle.indexOf('2-krotna') > 0)
            return 2;
        else if (chosenFrameFunctionTitle.indexOf('3-krotna') > 0)
            return 3;
        else if (chosenFrameFunctionTitle.indexOf('4-krotna') > 0)
            return 4;
        else if (chosenFrameFunctionTitle.indexOf('5-krotna') > 0)
            return 5;
    }

    hasPositionSelected(position: number) {
        console.log("hasPositionSelected: " + position + " result: " + this.orderService.actOrderSet().orderSetItems[position + 1] != null);
        return this.orderService.actOrderSet().orderSetItems[position + 1] != null;
        /*if (this.orderService.currentConfig != null && this.orderService.openedRoom() != null
            && this.orderService.openedRoom().orderSets != null && this.orderService.openedRoom().orderSets[this.orderService.openedRoom().itemIndex] != null)
        return this.orderService.openedRoom().orderSets[this.orderService.openedRoom().itemIndex].orderSetItems[position + 1] != null;
        */
    }
    functionTypePositionSelected(position: number): FunctionsTypeImpl {
        if (this.orderService.openedRoom() != null
            && this.orderService.openedRoom().orderSets != null && this.orderService.actOrderSet() != null) {
            console.log("this.orderService.actOrderItem().orderSetItems[position + 1]: " + position + this.orderService.actOrderSet().orderSetItems[position + 1])
            let sku = this.orderService.actOrderSet().orderSetItems[position + 1].sku;
            return {
                id:sku.id,
                repSku: sku,
                title: sku.functionType.title,
                avaiableSkus: [sku],
                position: position
            };
        }
        return null;
    }

    private functionPositionSelected(position: number): OrderSetItem {
        if (this.orderService.openedRoom() != null
            && this.orderService.openedRoom().orderSets != null && this.orderService.actOrderSet() != null) {

            console.log("init functionPositionSelected:" + position + ":" + this.orderService.actOrderSet().orderSetItems[position + 1]);
            return this.orderService.actOrderSet().orderSetItems[position + 1];
        }
        /*
        return this.orderService.currentConfig.selectedFunctions.filter(sp => (sp != null && sp.position == position))[0].sku;
        */
    }
    functionPositionSelectedImage(position: number): string {
        let orderItem = this.functionPositionSelected(position);
        if (orderItem.multiBlockPosition != null && orderItem.multiBlockPosition > 0)
            return orderItem.sku.code + "_1";
        return orderItem.sku.code;
    }
    get categoryShow(): boolean {
        //return this.selectedCategoryGroup === null;
        console.log('this.selectedCGF: ' + this.selectedCGF);
        return this.selectedCGF == null;
    }
    selectCategoryGroupFormated(cgf: CategoryGroupFormated) {
        this.selectedCGF = cgf;
    }

    droppedData: string;

    dragEnd(event) {
        console.log('Element was dragged', event);
    }
    trashbackground: string = "trashbackground-none";
    startDragging(drillTag) {
        console.log("startDragging: " + drillTag)
        
        let drill = document.getElementById(drillTag);
        let domRect = drill.getBoundingClientRect();
        drill.style.position = 'fixed';
        drill.style.top = domRect.top + 'px';
        drill.style.left = domRect.left + 'px';
        drill.style.zIndex = '99';
        this.tmpHeight = drill.style.height;
        this.tmpWidth = drill.style.width;
        drill.style.height = '60px';
        drill.style.width = '80px';
    }
    startDraggingPresented(e,drillTag) {
        console.log('start dragging: ' + drillTag);
        let drill = document.getElementById(drillTag);
        let domRect = drill.getBoundingClientRect();
        drill.style.position = 'fixed';
        //drill.style.top = domRect.top + 'px';
        //drill.style.left = domRect.left + 'px';
        let top = e.clientY - 50;
        
        //var e = window.event;
        console.log('startDraggingPresented:' + e.transformY + "-" + e.clientY + "-" + drillTag);
        var posY = Math.round(domRect.top + domRect.height / 2);
        var posX = Math.round(domRect.left + domRect.width / 2);
        drill.style.top = posY + 'px';
        drill.style.left = posX + 'px';
        drill.style.zIndex = '98';
        this.tmpHeight = drill.style.height;
        this.tmpWidth = drill.style.width;
        drill.style.height = '20vh';
        drill.style.width = '20vh';
        this.trashbackground = "trashbackground-active";
        
    }
    tmpHeight;
    tmpWidth;
    dragThresholdValidator({ x, y }) {
        const MIN_DRAG_THRESHOLD = 3;
        return Math.abs(x) > MIN_DRAG_THRESHOLD || Math.abs(y) > MIN_DRAG_THRESHOLD;
    }

    endDragging(drillTag) {
        
        let drill = document.getElementById(drillTag);
        drill.style.position = 'relative';
        drill.style.top = 'unset';
        drill.style.left = 'unset';
        drill.style.height = this.tmpHeight;
        drill.style.width = this.tmpWidth;
        
    }
    endDraggingPresented(drillTag) {
        console.log('end dragging: ' + drillTag);
        this.endDragging(drillTag);
        this.trashbackground = "trashbackground-none";
    }

    droppedData2: string = '';

    touchedFTI: FunctionsTypeImpl = null;
    touchstartOnCat(ft: FunctionsTypeImpl) {
        this.touchedFTI = ft;
    }

    touchendOnCat(ft: FunctionsTypeImpl) {
        if (this.touchedFTI != null &&
            this.touchedFTI.repSku.code == ft.repSku.code) {
            this.setFunction(ft);
        }
        this.touchedFTI = null;
    }

    warnOnMonoblockAsFunction(ft: FunctionsTypeImpl, position: number) {
        const dialogRef = this.dialog.open(PromptDialogComponent, {
            maxWidth: '100%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null)
                this.continueSetFunction(ft, position);
        });
    }
    informationOnMonoblockRemovalError(ft: FunctionsTypeImpl, position: number): void {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
            maxWidth: '100%'
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    continueSetFunction(ft: FunctionsTypeImpl, position: number): void {
        this.droppedData = ft.repSku.code;
        
        if (ft.position != null && this.orderService.actOrderSet().orderSetItems[position + 1] != null) {
            let tmpSku = this.orderService.actOrderSet().orderSetItems[position + 1].sku;
            this.orderService.setFunction(ft.repSku, position);
            this.orderService.setFunction(tmpSku, ft.position);
        } else if (ft.position != null) {
            this.orderService.setFunction(ft.repSku, position);
            this.orderService.remFunction(ft.position);
        }else {
            this.orderService.setFunction(ft.repSku, position);
        }
        this.alertService.showAddMessage();
    }

    setFunction(ft: FunctionsTypeImpl, position = -1): void {
        AppComponent.SendEventGa('operation', ft.repSku.code, 'B2C_Dodanie produktu do Twojej Listy (ramka lub mechanizm)');
        console.log('OrderService.setFunction(' + ft.repSku.code + ',' + position + ',' + ft.repSku.series.title + ');');
        /*if (ft.repSku.functionType.id == 1411 && this.orderService.actOrderSet().orderSetItems[0].sku.slots > 1) {
            this.routingDialog("Brak zgodności","Ten mechanizm nie pasuje do wielokrotnych ramek, wybierz pojedynczą ramkę.", null);
            return;
        }*/
        if ((ft.repSku.code == 'S520583' || ft.repSku.code == 'S530583' || ft.repSku.code =='MTN4375-0303') && this.orderService.actOrderSet().orderSetItems[0].sku.slots < 2) {
            this.routingDialog("Wymagana podwójna ramka",
                "wybrany mechanizm składa się z 2 modułów i wymaga podwójnej lub większej ramki",
                null);
            return;
        }
        let oldFunction = null;
        if (position < 0)
            position = this.nextPosition();
        if (this.orderService.actOrderSet().orderSetItems[position + 1] != null &&
            this.orderService.actOrderSet().orderSetItems[position + 1].sku != null &&
            this.orderService.actOrderSet().orderSetItems[position + 1].sku.functionType != null &&
            this.orderService.actOrderSet().orderSetItems[position + 1].sku.functionType.id == 1411 &&
            ft.repSku.functionType.id != 1411)
            this.informationOnMonoblockRemovalError(ft, position);
        else if (ft.repSku.functionType.id == 1411) {
            this.warnOnMonoblockAsFunction(ft, position);
        } else {
            this.continueSetFunction(ft, position);
        }
    }
    refreshView() {
        this.updateFunctionsForSelectedOrderItem();
        this.alertService.showRemMessage();
    }
    remFunction(fti: FunctionsTypeImpl): void {
        AppComponent.SendEventGa('operation', fti.repSku.code, 'B2C_usuniecie produktu (wyzerowanie ilości)');
        console.log('OrderService.remFunction('  + fti );
        if (fti.position <0)
            return;
        this.orderService.remFunction(fti.position);
        this.updateFunctionsForSelectedOrderItem();
        
        this.alertService.showRemMessage();
    }


    lastPosition: number = -1;
    nextPosition(): number {
        //AppComponent.SendEventGa('navigation', null, 'next-frame');
        this.lastPosition = (this.lastPosition + 1) % this.currentframelength(null);
        return this.lastPosition;
    }

    deleteFunction(position: number) {
        AppComponent.SendEventGa('operation', position, 'B2C_usuniecie produktu (wyzerowanie ilości)');
        this.orderService.currentConfig.selectedFunctions =
            this.orderService.currentConfig.selectedFunctions.filter(sp => (sp != null && sp.position != position));
    }

    changeFrame() {
        this.router.navigateByUrl('/wybierz-design#serie');
    }

    deleteFrame() {
        AppComponent.SendEventGa('operation', null, 'B2C_Usunięcie ramki');

        console.log('deleteFrame');
        this.orderService.deleteFrame();
        this.router.navigateByUrl('/wybierz-design#serie');
    }

    updateFunctionsForSelectedOrderItem() {
        this.slideIndex = 0;
        let selectedFrameSku: Sku;
        if (this._orderService.currentConfig != null &&
            this._orderService.openedRoom() != null &&
            this._orderService.openedRoom().orderSets != null &&
            this._orderService.openedRoom().itemIndex != null &&
            this._orderService.actOrderSet() != null &&
            this._orderService.actOrderSet().orderSetItems[0] != null) {
            selectedFrameSku = this._orderService.actOrderSet().orderSetItems[0].sku;
        } else return;

        if (selectedFrameSku == null && this._orderService.openedRoom() != null && this._orderService.openedRoom().orderSets != null)
            if (this._orderService.openedRoom().orderSets[0] != null &&
                this._orderService.openedRoom().orderSets[0].orderSetItems != null &&
                this._orderService.openedRoom().orderSets[0].orderSetItems[0] != null)
                selectedFrameSku = this._orderService.openedRoom().orderSets[0].orderSetItems[0].sku;

        if (selectedFrameSku.series != null) {
            this._orderService.selectedSeriesItem = selectedFrameSku.series;
            this._orderService.selectedSubSeriesItem = selectedFrameSku.subSeries;
            console.log("this._orderService.selectedSeriesItem: " + this._orderService.selectedSeriesItem.title);
        }
        if (selectedFrameSku != null) {
            this._orderService.selectedFrameColorSku = selectedFrameSku;
            this._orderService.selectedFrameShapeSku = selectedFrameSku;
        }
        this.updateColorsAndFunctions();
        console.log("selectedFrameShapeSku:" + this._orderService.selectedFrameShapeSku + "selectedFrameColorSku" + this._orderService.selectedFrameColorSku);


    }

    updateColorsAndFunctions() {
        console.log("FunctionSelectorB2C.getColorsOfSeries is null");
        this._orderService.getColorsOfSubSeriesMechanisms(true);
        this._orderService.colorOfSeries$.subscribe(colors => {
            this.availableColors = colors.filter(cs => cs.colorId != -1);
            var sColor = this.availableColors.find(ac => ac.colorId == this.orderService.selectedFrameColorSku.color.id);
            if (sColor != null) {
                this.selectedColorId = this.orderService.selectedFrameColorSku.color.id;
                this.selectedColorTitle = this.orderService.selectedFrameColorSku.color.title;
            } else {
                this.selectedColorId = this.availableColors[0].colorId;
                this.selectedColorTitle = this.availableColors[0].colorName;
            }

            console.log("AvailableColors " + this.availableColors);
            if (this._orderService.selectedFrameShapeSku != null && this._orderService.selectedFrameColorSku != null) {
                //console.log("selectedFrameShapeSku:" + this._orderService.selectedFrameShapeSku + "selectedFrameColorSku" + this._orderService.selectedFrameColorSku);
                this.updateFunctions();
            }
        });
    }
    slideNumber = 0;
    tabsetChange(event: Event) {
        console.log("tabsetchanged: " + event.type);
        this.slideIndex = 0;
        this.selectedCategoryGroup = null;
        this.selectedCGF = null;
        
    }
    groupesCategries(functionTypes: FunctionsTypeImpl[], rows: number, cols: number, osi: OrderSetItem) {
        console.log("Groups categories: " + osi.sku.subSeries.title)
        this.orderService.contentLoading = true;
        this._orderService.contentLoading = true;
        let newSet = new Array<Array<FunctionsTypeImpl>>();
        var counter = 0;
        var groupIndex = -1;
        this.slideNumber = 0;
        for (let fti of functionTypes) {
            /*if ((osi.sku.subSeries.title == 'Sedna Design' && fti.title.indexOf('Elements') >= 0) ||
                (osi.sku.subSeries.title == 'Sedna Elements' && fti.title.indexOf('Design') >= 0))
                continue;*/
            console.log("add: " + fti.title);
            if (counter == 0) {
                let subset = new Array<FunctionsTypeImpl>();
                newSet.push(subset);
                groupIndex += 1;
                this.slideNumber++;
            }
            newSet[groupIndex].push(fti);
            counter = (counter + 1) % (rows * cols);
        }
        console.log(newSet + "number of groups: " + this.slideNumber);

        return newSet;
    }
    isLoading = false;
    prevSet() {
        AppComponent.SendEventGa('navigation', null, 'B2C_Poprzednia ramka/strzałka');
        this.isLoading = true;
        this._orderService.decOrderSetIndex()
        this.updateFunctionsForSelectedOrderItem();
    }
    nextSet() {
        AppComponent.SendEventGa('navigation', null, 'następna ramka/strzałka)');
        this.isLoading = true;
        this.lastPosition = -1;
        this._orderService.incOrderSetIndex();
        this.updateFunctionsForSelectedOrderItem();
    }
    gotoSet(i: number) {
        AppComponent.SendEventGa('navigation', null, 'B2C_Wybór konkretnego zestawu/kropki na dole', i);
        this.isLoading = true;
        this._orderService.openedRoom().itemIndex = i;
        this.updateFunctionsForSelectedOrderItem();
    }
    

    gotoAllFunctions() {
        AppComponent.SendEventGa('filtering', null, 'B2C_Wybór wszystkich mechanizmów zamiast polecanych');
        this.suggestedFunctions = false;
        
        this.updateFunctionsForSelectedOrderItem();
    }
    gotoSuggectedFunctions() {
        AppComponent.SendEventGa('filtering', null, 'B2C_Wybór polecanych mechanizmów zamiast wszystkich');
        this.suggestedFunctions = true;
        this.updateFunctionsForSelectedOrderItem();
    }



    //'Monoblok gniazdo podwójne 2P+PE'
    getSuggestedFunctionInfos(roomDiscriminator: string, seriesTitle: string): string[]{
        console.log("this._orderService.openedRoom().image: " + this._orderService.openedRoom().image);
        console.log('roomDiscriminator:' + roomDiscriminator);
        var suggested = ['aaaaaaaa'];
        if (seriesTitle == 'Odace')
            suggested = ['Monoblok gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe']

        if (this._orderService.openedRoom() == null)
            return null;
        //salon
        
        else if (this._orderService.openedRoom().image == 'real0')
            return suggested.concat(['Gniazdo pojedyncze 2P+PE (z uziemieniem) z przesłonami, 16A / 250V zaciski śrubowe', 'Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Gniazdo R / TV / SAT końcowe(tłumienność 1dB)', 'Gniazdo głośnikowe', 'Gniazdo komputerowe pojedyncze', 'Gniazdo ładowania USB 2.0  5V DC', 'Wypust kablowy', 'Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe  ', 'Wypust kablowy 25A',
                'Ściemniacz obrotowy z funkcją łącznika schodowego RL 40-600VA', 'Gniazdo TV końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania USB 2.0  5V DC', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik żaluzjowy  6A/ 250V, 3 przyciskowy zaciski bezgwintowe', 'Przycisk żaluzjowy 6A/ 250V, zaciski bezgwintowe', 'Gniazdo R/TV/SAT końcowe (tłumienność 1dB)', 'Gniazdo SAT końcowe (tłumienność 1dB)', 'Przycisk jednobiegunowy z piktogramem "dzwonek" 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A']);
            //sypialnia
        else if (this._orderService.openedRoom().image == 'real1')
            return suggested.concat(['Gniazdo pojedyncze 2P+PE (z uziemieniem) z przesłonami, 16A / 250V zaciski śrubowe','Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A',
                'Ściemniacz obrotowy z funkcją łącznika schodowego RL 40-600VA', 'Gniazdo TV końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania USB 2.0  5V DC', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik żaluzjowy  6A/ 250V, 3 przyciskowy zaciski bezgwintowe', 'Przycisk żaluzjowy 6A/ 250V, zaciski bezgwintowe', 'Gniazdo R/TV/SAT końcowe (tłumienność 1dB)', 'Gniazdo SAT końcowe (tłumienność 1dB)', 'Przycisk jednobiegunowy z piktogramem "dzwonek" 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A']);
            //kuchnia
        else if (this._orderService.openedRoom().image == 'real2')
            return suggested.concat(['Gniazdo pojedyncze 2P+PE (z uziemieniem) z przesłonami, 16A / 250V zaciski śrubowe','Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe',' Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Łącznik 1-biegunowy IP44 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy IP44 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 2-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 16AX/ 250V, zaciski bezgwintowe', 'Gniazdo 2P+PE z przesłonami, 16A/ 250V zaciski śrubowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe',
                'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo pojedyncze 2P+PE bez przesłon, 16A / 250V zaciski śrubowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik wentylatora, 10AX/250V, zaciski bezgwintowe', 'Łącznik wentylatora trzyprzyciskowy, 6AX/250V, zaciski bezgwintowe', 'Łącznik dwubiegunowy 16AX/ 250V, zaciski bezgwintowe']);
            //łazienka
        else if (this._orderService.openedRoom().image == 'real3')
            return suggested.concat(['Gniazdo pojedyncze 2P+PE (z uziemieniem) z przesłonami, 16A / 250V zaciski śrubowe','Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Gniazdo 2P+PE z przesłonami, zaciski bezgwintowe', 'Łącznik 1-biegunowy IP44 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy IP44 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 2-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 16AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe',
                'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Regulator temperatury 10A, programowalny, dotykowy w kolorze czarnym', 'Regulator temperatury 10A, do ogrzewania podłogowego', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Radio FM z głośnikiem', 'Łącznik wentylatora, 10AX/250V, zaciski bezgwintowe', 'Łącznik wentylatora trzyprzyciskowy, 6AX/250V, zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik dwubiegunowy 16AX/ 250V, zaciski bezgwintowe']);
            //jadalnia
        else if (this._orderService.openedRoom().image == 'real4')
            return suggested.concat(['Gniazdo pojedyncze 2P+PE (z uziemieniem) z przesłonami, 16A / 250V zaciski śrubowe','Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A',
                'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo pojedyncze 2P+PE bez przesłon, 16A / 250V zaciski śrubowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik wentylatora, 10AX/250V, zaciski bezgwintowe', 'Łącznik wentylatora trzyprzyciskowy, 6AX/250V, zaciski bezgwintowe', 'Łącznik dwubiegunowy 16AX/ 250V, zaciski bezgwintowe']);
            //gabinet
        else if (this._orderService.openedRoom().image == 'real5')
            return suggested.concat(['Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A',
                'Ściemniacz obrotowy z funkcją łącznika schodowego RL 40-600VA', 'Gniazdo TV końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania USB 2.0  5V DC', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik żaluzjowy  6A/ 250V, 3 przyciskowy zaciski bezgwintowe', 'Przycisk żaluzjowy 6A/ 250V, zaciski bezgwintowe', 'Gniazdo R/TV/SAT końcowe (tłumienność 1dB)', 'Gniazdo SAT końcowe (tłumienność 1dB)', 'Przycisk jednobiegunowy z piktogramem "dzwonek" 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A']);
            //pokój dziecięcy
        else if (this._orderService.openedRoom().image == 'real6')
            return suggested.concat(['Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A',
                'Ściemniacz obrotowy z funkcją łącznika schodowego RL 40-600VA', 'Gniazdo TV końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania USB 2.0  5V DC', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik żaluzjowy  6A/ 250V, 3 przyciskowy zaciski bezgwintowe', 'Przycisk żaluzjowy 6A/ 250V, zaciski bezgwintowe', 'Gniazdo R/TV/SAT końcowe (tłumienność 1dB)', 'Gniazdo SAT końcowe (tłumienność 1dB) ', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A']);
            //przedpokój
        else if (this._orderService.openedRoom().image == 'real7')
            return suggested.concat(['Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A',
                'Czujnik ruchu 10A, 3 przewodowy z regulacją natężenia światła oraz czasu załaczenia ', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania USB 2.0  5V DC', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik podwójny schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik krzyżowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A']);
            //inne
        else return suggested.concat(['Gniazdo podwójne 2P+PE (z uziemieniem) bez przesłon, Ʃ16A/250V zaciski śrubowe','Ściemniacz obrotowy RC 25-325VA', 'Gniazdo TV/R końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania podwójne USB, 2.1A', 'Gniazdo 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE bez przesłon, Ʃ16A / 250V ', 'Łącznik żaluzjowy  10AX/ 250V, zaciski bezgwintowe', 'Przycisk żaluzjowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy z podświetleniem, 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik 1-biegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A',
            'Ściemniacz obrotowy z funkcją łącznika schodowego RL 40-600VA', 'Gniazdo TV końcowe (tłumienność 1dB)', 'Gniazdo głośnikowe podwójne', 'Gniazdo komputerowe RJ45 kategoria 5e, nieekranowane', 'Gniazdo ładowania USB 2.0  5V DC', 'Regulator temperatury 8A, z wbudowanym czujnikiem temperatuy otoczenia', 'Gniazdo pojedyncze 2P+PE z przesłonami, 16A / 250V zaciski bezgwintowe', 'Gniazdo podwójne 2P+PE z przesłonami,  Ʃ16A/250V zaciski śrubowe', 'Łącznik żaluzjowy  6A/ 250V, 3 przyciskowy zaciski bezgwintowe', 'Przycisk żaluzjowy 6A/ 250V, zaciski bezgwintowe', 'Gniazdo R/TV/SAT końcowe (tłumienność 1dB)', 'Gniazdo SAT końcowe (tłumienność 1dB)', 'Przycisk jednobiegunowy z piktogramem "dzwonek" 10AX/ 250V, zaciski bezgwintowe', 'Łącznik schodowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik świecznikowy 10AX/ 250V, zaciski bezgwintowe', 'Łącznik jednobiegunowy 10AX/ 250V, zaciski bezgwintowe', 'Wypust kablowy 25A']);

    }
    getAlternativeFunctionTypes(sku: Sku): Sku[]{
        if (this.categoryGroupFormated == null)
            return null;
        let catgf = this.categoryGroupFormated.filter(cgf => cgf.cg.id == sku.categoryGroupID);
        if (catgf == null || catgf.length != 1) {
            //console.error("getAlternativeFunctionTypes has >1 categoryGroupFormated:" + sku.functionType.title);
            return null;
        }
        let fts = catgf[0].functionTypes.filter(ft => ft.title == sku.functionType.title);
        if (fts == null || fts.length != 1) {
            //console.error("getAlternativeFunctionTypes has >1 functiontype in categoryGroupFormated:" + sku.functionType.title);
            return null;
        }
        return fts[0].avaiableSkus;
    }
    /*
    updatePosition(value: string, position: number) {
        AppComponent.SendEventGa('operaton', value , 'change_sku',position);
        let sku = this.availableFunctions.find(af => af.code == value);
        this._orderService.setFunction(sku, position);

    }*/

    actFramePositionStyle() {

        return (this.orderService.actFrame().universal && this.orderService.actOrderItem.isVertical) ?
            'verticalframe' : (this.orderService.actFrame().universal) ?
            'universalframe': ((this.orderService.actFrame().slots == 1) ?
            'singleframe':((this.orderService.actFrame().isVertical) ?
            'verticalframe':'horizontalframe'));
    }
    actFrameSize() {
        let suffix = (this.orderService.actFrame() != null &&
            this.orderService.actFrame().params != null &&
            this.orderService.actFrame().params.find(par => par.title == 'Rodzaj') != null &&
            (this.orderService.actFrame().params.find(par => par.title == 'Rodzaj').value == 'pionowa' ||
                this.orderService.actFrame().isVertical || this.orderService.actOrderItem.isVertical)) ?
            'Ver' : 'Hor';
        let prefix = '';
        if (this.orderService.actFrame() != null) {
            return this.currentframelength(this.orderService.actFrame().functionType.title) + suffix;
        }
        return '';
    }

    skuShortInfo(sku: Sku) {
        return sku.shortInfo;
    }
    skuShortInfoRem(sku: Sku) {
        let pv = sku.params.find(p => p.title == 'Podświetlenie' && p.value == 'tak');
        if (pv != null)
            return sku.functionType.title + 'z podświetleniem';
        else
            return sku.functionType.title + sku.params.find(p => p.title == 'Podświetlenie')
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        console.log(event);

        if (event.keyCode === 27) {
            this.fullscreen = false;
        }
    }
    slideIndex: number = 0;
    fullscreen: boolean = false;
    dragging: boolean = false;
    draggableFrame = null;
    startDraggingOnWall(id) {
        console.log("start dragging the: " + this.draggableFrame);
        this.draggableFrame = document.getElementById(id);
        this.draggableFrame.style.zIndex = '89';
        this.dragging = true;

    }
    endDraggingOnWall() {
        this.dragging = false;
    }
    dragTheFrame(event) {
        console.log("dragTheFrame x=" + event.movementX + ":y=" + event.movementY);
        event.preventDefault();
        if (this.dragging) {
            var deltaX = event.movementX;
            var deltaY = event.movementY;
            var rect = this.draggableFrame.getBoundingClientRect();
            this.draggableFrame.style.left = rect.x + deltaX + 'px';
            this.draggableFrame.style.top = rect.y + deltaY + 'px';
        }
    }


    //Styles
    selectedColor: string;
    colorSelectedStyle(colorId: number): string {
        //console.log("is selected color: " + colorId + ":" + this.selectedColorId);
        return (colorId == this.selectedColorId) ? 'selected-option' : '';
    }
    selectColor(colorId: number,colorName: string) {
        AppComponent.SendEventGa('operaton', colorName, 'B2C_wybór koloru mechanizmów',);
        console.log("selected color: " + colorId + ":" + this.selectedColorId);
        this.selectedColorId = colorId;
        this.selectedColorTitle = colorName;
        this.updateFunctions();
    }

    nonBreakingHyphen(s: string) {
        //return s;
        return this.sanitizer.bypassSecurityTrustHtml(s.replace('-', '&#8288;-&#8288;'));
    }
    gotoSummary() {
        AppComponent.SendEventGa('navigation', null, 'B2B_Przejście do Zestawienia');
        setTimeout(() => this.router.navigateByUrl('/wybierz-design#zestawienie'));
    }



    public config: SwiperConfigInterface = {
        a11y: true,
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: false
    };

    @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;

    public onIndexChange(index: number): void {
        console.log('Swiper index: ', index);
    }

    public onSwiperEvent(event: string): void {
        console.log('Swiper event: ', event);
    }


    public rotationStyle(actFrameOrderSet, osi: OrderSetItem, actFunction) {
        return PresentationToolsComponent.rotationStyle(actFrameOrderSet, osi,  actFunction);
    }
    public rotationFrameStyle(actFrameOrderSetItem: OrderSetItem) {
        console.log('actFrameOrderSetItem: ' + actFrameOrderSetItem)
        //return 'rotate90';
        if (!actFrameOrderSetItem)
            return '';
        return PresentationToolsComponent.rotationFrameStyle(actFrameOrderSetItem.sku, actFrameOrderSetItem.isVertical);
    }

    public static rotationFrameStyle(frame: Sku, vertical: boolean): string {
        console.log('rotationFrameStyle:' + frame.code + ' vertical: ' + vertical)
        if (frame != null && frame.slots > 1 && !frame.isVertical && vertical)
            return 'rotate90';
        return 'rotate0';
    }

   
    public frameSubDir(osi: OrderSetItem): string {
        if (osi != null && osi.sku != null && osi.sku.slots > 1 && osi.sku.universal && osi.isVertical)
            return "vertical/";
        else return '';
    }

    private layers(allCodes: string) {
        let layers = allCodes.split("+");

        for (var i = 0; i < layers.length; i++) {
            layers[i] = layers[i].trim();
        }
        return layers;
    }

    isLess(resolution: number): boolean {
        return window.screen.width < resolution;
    }

}

