import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, Component, Directive, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user.model';
import { AccountService } from "../../services/account.service";
import { AlertService } from '../../services/alert.service';
import { DBkeys } from '../../services/db-Keys';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { OrderService } from '../../services/orders.service';
import { Series, Sku, SubSeries } from '../../_models/catalog';






export interface Fruit {
    name: string;
}

@Directive({ selector: 'seriesButton' })
export class SeriesButton {
    id: string;
}

@Component({
    selector: 'series-selection',
    templateUrl: './series-selection.component.html',
    styleUrls: ['./series-selection.component.css']
})
export class SeriesSelectionComponent implements OnInit, AfterViewInit, AfterContentInit {
    images: Array<NodeRequire>;
    selectedSeries: Series;
    selectedSeriesSingleFrameSkus: Sku[];
    presentedSkus: Sku[][];
    selectedSeriesTitle = 'Wybierz serię';
    presentedFrameShapes: Sku[];

    @Input() _orderService: OrderService;
    
    ngAfterContentInit(): void {
        $('#seriesButton').addClass('active');
    }
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private route: ActivatedRoute, private accountService: AccountService,
        public orderService: OrderService, private router: Router, private localStorage: LocalStoreManager, private alertService?: AlertService) {
        //this._orderService = orderService;
        
        console.log("SeriesSelectionComponent.orderService:" + orderService.instance);
        
        }
    
 
    ngAfterViewInit(): void {
        if (this.orderService != null ) {

        } else {

            setTimeout(() => this.router.navigateByUrl('/wybierz-design#pokoje'));
        }

        //document.getElementById('project-header').scrollIntoView();
    }
    ngOnInit() {
        console.log("SeriesSelectionComponent.ngOnInit:" + this._orderService.instance);
        if (this._orderService == null || this._orderService.cProject == null) {
            this.router.navigateByUrl('/wybierz-design#projekty');
        }
        if (this._orderService.openedRoom() == null) {
            this.router.navigateByUrl('/wybierz-design#pokoje');
        }
    }
    ngOnDestroy() {
    }
    get availableSeries() {
        if (this._orderService._availableSeries != null) {
            var series = this._orderService._availableSeries;
            this.images = new Array<NodeRequire>();
            var s: any;
            return this._orderService._availableSeries;
        } else {
            return new Array<Series>();
        }
    }
    get seriesSubseries() {
        let res = new Array<[Series, SubSeries]>();

        var user:User = this.localStorage.getDataObject(DBkeys.CURRENT_USER);
        //var isEmployee = false;
        //if (user.roles.findIndex(r => r == "Pracownik") > -1)
        //    isEmployee = true;
        for (let se of this.availableSeries) {
            console.log(se)
        //    if (!se.employeeOnly || isEmployee)
                this.subSeriesOfSeries(se).forEach(t => {
                    if (!t[1].inactive) res.push(t);
                });
        }
        return res;
    }
    subSeriesOfSeries(s: Series): [Series, SubSeries][] {
        let result = new Array<[ Series, SubSeries ]>();
        if (s.subSeries != null) {
            for (let ss of s.subSeries) {
                console.log('push:'+s+':'+ss);
                result.push([s,ss]);
            }
        }
        return result;
    }
    seriesColorSkus = new Array<Sku>();
    selectSubSeries(subseries, series) {
        this._orderService.selectSubSeries(subseries, series);
        setTimeout(() => this.router.navigateByUrl('/wybierz-design#ramki'));
    }
    selectSeries(series: Series) {
        this._orderService.selectSeries(series);
        setTimeout(() => this.router.navigateByUrl('/wybierz-design#ramki'));
    }


    sortFyFunctionname(skus: Sku[]): Sku[] {
        return skus.sort(function (s1, s2) {
            return (s1.functionType < s2.functionType) ? -1 :
                (s1.functionType == s2.functionType) ? 0 : 1
        });
    }


    
    isSelectedSeries(series: Series) {
        if (this.selectedSeries != null && this.selectedSeries.id == series.id)
            return true;
        return false;
    }
    
    seriesChipSelectable = true;
    seriesChipRemovable = true;
  

}

