<h1 mat-dialog-title >{{data.sku.functionType.title}}</h1>
<div mat-dialog-content>

    <h4>{{data.sku.info}}</h4>
    <h5>{{data.sku.code}}</h5>
    <!--
    <div class="sku-image"><img src="/images/series/{{data.sku.series.title}}/{{data.sku.code}}.png" /></div>
        -->
</div>
<div mat-dialog-actions >
    <button mat-stroked-button class="cancel-button" (click)="onNoClick()">Zamknij</button>
</div>
