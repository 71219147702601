<div mat-dialog-content>

    <div *ngIf="changes.changedSkus.length > 0">
        <h4>Potwierdź zmianę serii dla:</h4>
        <div *ngFor="let pair of changes.changedSkus">
            {{pair[0].category.description}} {{pair[0].functionType.title}}: {{pair[0].code}} -> {{pair[1].code}}
        </div>
    </div>
    <div *ngIf="changes.unmappedSkus.length > 0">
        <h4>
            Produkty, które nie mają odpowiednika w kolorze: {{changes.newColor.title}}
        </h4>
        <div *ngFor="let pair of changes.unmappedSkus">
            {{pair.category.description}} {{pair.functionType.title}}
        </div>
    </div>
    <div *ngIf="changes.unchangedSkus.length > 0">
        <h4>
            Produkty, które są obecnie w kolorze: {{changes.newColor.title}}
        </h4>
        <div *ngFor="let pair of changes.unchangedSkus">
            {{pair.category.description}} {{pair.functionType.title}}
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button class="cancel-button"  (click)="onNoClick()">Anuluj</button>
    <button  *ngIf="changes.changedSkus.length > 0" class="accept-button" 
            mat-button [mat-dialog-close]="changes.changedSkus" cdkFocusInitial>Ok</button>
</div>
