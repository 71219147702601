
    <div *ngIf="isStepActive(2)">
        <!--filters-->
        <div *ngIf="cStepper.step == 1 || wide">
            <mat-form-field title={{subSeriesAvailable.tooltip}} style="width:130px;margin: 0px 6px;" *ngIf="subseriesFilter">
                <mat-label>{{subSeriesAvailable.title}}</mat-label>
                <mat-select [compareWith]="compareIds" [disabled]="filtersDisabled()"
                            [(ngModel)]="selectedSubSeriesId" (selectionChange)="subSeriesChange()">

                    <mat-option value="15">
                        Sedna Design
                    </mat-option>
                    <mat-option value="16">
                        Sedna Elements
                    </mat-option>

                    <mat-option value="1">Odace Style</mat-option>
                    <mat-option value="2">Odace Touch</mat-option>

                    <mat-option value="4">
                        Asfora
                    </mat-option>
                    <mat-option value="7">
                        M-Smart
                    </mat-option>
                    <mat-option value="8">
                        M-Pure
                    </mat-option>
                    <mat-option value="9">
                        M-Pure Decor
                    </mat-option>
                    <mat-option value="10">
                        M-Plan
                    </mat-option>
                    <mat-option value="12">
                        M-Elegance szkło
                    </mat-option>
                    <mat-option value="13">
                        M-Elegance metal
                    </mat-option>
                    <mat-option value="14">
                        M-Elegance drewno
                    </mat-option>

                </mat-select>
            </mat-form-field>
            <mat-form-field title={{seriesAvailable.tooltip}} style="width:130px;margin: 0px 6px;" *ngIf="!subseriesFilter">
                <mat-label>{{seriesAvailable.title}}</mat-label>
                <mat-select [compareWith]="compareIds" [disabled]="filtersDisabled()"
                            [(ngModel)]="selectedSeriesId" (selectionChange)="seriesChange()">
                    <mat-option value="8">Sedna Design & Elements</mat-option>
                    <mat-option value="2">Asfora</mat-option> 
                    <mat-option value="3">Odace</mat-option>
                    <mat-option value="7">Merten</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field title={{colorsAvailable.tooltip}} style="width:130px;margin: 0px 6px;">
                <mat-label>{{colorsAvailable.title}}</mat-label>
                <mat-select *ngIf="!isColorFilterMulti" [compareWith]="compareColor" [disabled]="filtersDisabled()"
                            [(ngModel)]="selectedColorIds" (selectionChange)="filterAllFunctionsByFilters('Color')">
                    <mat-option *ngFor="let s of colorsAvailable.values" value="{{s.id}}" [hidden]="!s.available">
                        <img style="height:34px;width:34px;padding:2px;" src="/images/series/colors/{{s.title}}.png" />
                        {{s.title}}
                    </mat-option>
                </mat-select>
                <mat-select *ngIf="isColorFilterMulti" multiple [compareWith]="compareColor" [disabled]="filtersDisabled()"
                            [(ngModel)]="selectedColorIds" (selectionChange)="filterAllFunctionsByFilters('Color')">
                    <mat-option *ngFor="let s of colorsAvailable.values" value="{{s.id}}" [hidden]="!s.available">
                        <img style="height:34px;width:34px;padding:2px;" src="/images/series/colors/{{s.title}}.png" />
                        {{s.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field title={{ipsAvailable.tooltip}} style="width:130px;margin: 0px 6px;">
                <mat-label>{{ipsAvailable.title}}</mat-label>
                <mat-select [(ngModel)]="selectedWaterProof" [disabled]="filtersDisabled()"
                            (selectionChange)="filterAllFunctionsByFilters()">
                    <mat-option value="*">Wszystkie</mat-option>
                    <mat-option value="IP20">Standard (IP20)</mat-option>
                    <mat-option value="IP44">Bryzgoszczelne (IP44)</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngFor="let param of parameters" style="width:130px;margin: 0px 6px;">
                <mat-label>{{param.title}}</mat-label>
                <mat-select [compareWith]="compareParam" [disabled]="filtersDisabled()"
                            [(ngModel)]="param.selectedValue" (selectionChange)="filterAllFunctionsByFilters()">
                    <mat-option value="-1">Wszystkie</mat-option>
                    <mat-option *ngFor="let value of param.values" value="{{value.id}}">{{value.title}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-flat-button class="red-label" (click)="clearFilters()" style="background:white">
                <img src="/images/icons/clear-red.png" style="margin-top:6px;margin-right:4px;height: 21px;position: inherit;" />
                Wyczyść filtry
            </button>

        </div>
       
        <mat-spinner class="summary-set-spinner" *ngIf="functionsLoading"></mat-spinner>
        <div *ngIf="!functionsLoading">
            <h4 *ngIf="searchedCode!=null && searchedByCode">Szukany kod: {{searchedCode}}</h4>
            <div class="functions-column" style="display:inline-block;color: rgba(10,10,10,0.85);">
                <!--braki i inne podsumowania nad tabelką-->
                <div class="function-tooltip">
                    <div style="flex-wrap: wrap;display: flex;max-width: inherit;white-space: pre-wrap;top: 5px;
    position: relative;">
                        Mechanizmów: {{collectedMechanismsNumber}}
                        Miejsc w ramkach: {{collectedFrameSlotsNumber}}
                        <span style="color:red;margin-left: 4px;" *ngIf="collectedMechanismsNumber - collectedFrameSlotsNumber == 1">
                            Brakuje 1 miejsca w ramkach.
                        </span>
                        <span style="color:red;margin-left: 4px;" *ngIf=" (collectedMechanismsNumber - collectedFrameSlotsNumber) > 1">
                            Brakuje {{collectedMechanismsNumber-collectedFrameSlotsNumber}} miejsc w ramkach.
                        </span>
                        <span style="color:red;margin-left: 4px;" *ngIf="collectedMechanismsNumber < collectedFrameSlotsNumber &&
                  collectedMechanismsNumber - collectedFrameSlotsNumber == -1">
                            Brakuje 1 mechanizmu.
                        </span>
                        <span style="color:red;margin-left: 4px;" *ngIf=" (collectedMechanismsNumber - collectedFrameSlotsNumber) < -1">
                            Brakuje {{collectedFrameSlotsNumber - collectedMechanismsNumber}} mechanizmów.
                        </span>
                    </div>
                    <button mat-flat-button class="red-label big-screen" (click)="clearScreen()" style="background:white">
                        <img src="/images/icons/usun-red.png" style="margin-top:6px;margin-right:4px;height: 21px;position: inherit;" />
                        Wyczyść ekran
                    </button>
                </div>

                <!--<div *ngFor="let subSeries of subSeriesAvailableOnly; let l = index" class="b2b-skus-available">-->
                <!--<h4 *ngIf="searchedCode==null" style="font-size:18px" class="big-table">{{selectedCategory}} serii</h4>-->
                <!--big table zamawianie-->
                <div class="function-row-wrapper-header big-table"
                     *ngIf="filteredSkus != null && filteredSkus.length>0">

                    <div class="function-row-wrapper">


                        <div class="function_header colf1">
                        </div>
                        <div class="function_header colf2 left-white-margin">
                            <span class="function_header_span" style="text-align:left;">Nazwa</span>
                        </div>
                        <div class="function_header code left-white-margin">
                            <span class="function_header_span" title="Numer referencyjny produktu">Nr ref.</span>
                        </div>
                        <div class="function_header colf4 left-white-margin">
                            <span class="function_header_span">Podgląd</span>
                        </div>

                        <div class="function_header colf5 left-white-margin">
                            <span class="function_header_span">Karta&nbsp;kat.</span>
                        </div>

                        <div *ngFor="let p of paramsWithoutType;let pi = index;"
                             class="function_header colf6 left-white-margin">
                            <span class="function_header_span" *ngIf="p.title!='Podświetlenie'" title="{{p.title}}">{{p.title}}</span>
                            <span class="function_header_span" *ngIf="p.title=='Podświetlenie'" title="{{p.title}}"><img src="/images/icons/light-bulb-white.png" /></span>
                        </div>

                        <div title="Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego." class="function_header colf7 left-white-margin">
                            <span class="function_header_span">Cena netto*</span>
                        </div>
                        <div class="function_header colf8 left-white-margin">
                            <span class="function_header_span"></span>
                        </div>
                        <div class="function_header colf9">
                            <span class="function_header_span">Ilość</span>
                        </div>
                        <div class="function_header colf10">
                            <span class="function_header_span"></span>
                        </div>

                    </div>

                    <div class="function-row-wrapper{{j%2}} colf" id="row_{{sku.code}}"
                         *ngFor="let sku of foundCodesOfSubSeries();let j = index;">

                        <div *ngIf="!isLikedSku(sku)"
                             class="function-label colf1 function-label-icon function-label-clickable function-label-right" (click)="likeSku(sku)"
                             title="Dodaj do ulubionych">
                            <img src="/images/icons/star-hollow-darkgray.png" />
                        </div>
                        <div *ngIf="isLikedSku(sku)"
                             class="function-label colf1 function-label-icon function-label-clickable function-label-right left-white-margin" (click)="dislikeSku(sku)"
                             title="Usuń z ulubionych">
                            <img src="/images/icons/star-darkgray.png" />
                        </div>
                        <div class="function-label label-clickable colf2 left-white-margin"
                             (mouseover)="presentTooltip(sku.code,$event)" (mouseout)="hideTooltip(j)"
                             (click)="presentDialogWithSku(sku.code)">
                            {{sku.functionType.title}}
                            <span style="color:red;" *ngIf="sku.withFrame">z&nbsp;ramką</span>
                        </div>
                        <div class="function-label function-label-right code colf3 left-white-margin"
                             title="Numer referencyjny produktu">
                            <div style="align-self:center"></div>{{sku.code}}
                        </div>
                        <div class="function-label colf4 function-label-icon function-label-clickable function-label-right left-white-margin" (click)="presentImageWithSku(j)"
                             title="Podgląd produktu">
                            <img src="/images/series/colors/{{sku.color.title}}.png" />
                        </div>
                        <div *ngIf="sku" class="function-label colf5 function-label-icon function-label-right  left-white-margin"
                             title="Pobierz kartę katalogową">
                            <a class="{{isDisabledCardLink(sku)}}" href="/api/FileExport/card/{{sku.series.title}}/{{remSpaces(sku.code)}}" download style="width:100%;">
                                <img src="/images/icons/download-darkgray.png" />
                            </a>
                        </div>
                        <div *ngFor="let p of paramsWithoutType;let pi = index;"
                             class="function-label colf6 left-white-margin" title="{{p.title}}">
                            <span class="function_header_span" *ngIf="p.title!='Podświetlenie' && p.title!='Ekranowane' && p.title!='Przesłony'">{{skuParamValue(p,j)}}</span>
                            <span class="function_header_span" *ngIf="p.title=='Podświetlenie'">
                                <span *ngIf="skuParamValue(p,j) == 'tak'" title="Z podświetleniem"><img src="/images/icons/icons8-checkmark-48-livegreen.png" style="height:26px" /></span>
                                <span *ngIf="skuParamValue(p,j) != 'tak'" title="Bez podświetlenia"></span>
                            </span>
                            <span class="function_header_span" *ngIf="p.title=='Ekranowane'">
                                <span *ngIf="skuParamValue(p,j) == 'tak'"><img src="/images/icons/icons8-checkmark-48-livegreen.png" style="height:26px" /></span>
                                <span *ngIf="skuParamValue(p,j) != 'tak'"></span>
                            </span>
                            <span class="function_header_span" *ngIf="p.title=='Przesłony'">
                                <span *ngIf="skuParamValue(p,j) == 'tak'"><img src="/images/icons/icons8-checkmark-48-livegreen.png" style="height:26px" /></span>
                                <span *ngIf="skuParamValue(p,j) != 'tak'"></span>
                            </span>
                        </div>

                        <div class="colf7 function-label left-white-margin" title="Cena katalogowa netto bez rabatu">
                            {{currency(sku.net)}}&nbsp;zł
                        </div>
                        <div class="plus-minus-wrapper colf8 left-white-margin">
                            <div class="icon-wrapper">
                                <img src="/images/icons/minus-white-greenbck.png" *ngIf="numberOfSkuItems(sku)<=0" style="cursor:not-allowed" />
                                <img src="/images/icons/minus-greenbck.png" (click)="decSku(sku)" *ngIf="numberOfSkuItems(sku)>0" />
                            </div>
                        </div>
                        <div class="function-input-col colf9">
                            <div class="function-input-wrapper">
                                <input class="function-number" type="number" min="0"
                                       onclick="this.select()" (change)="newValueDetected($event,sku)" value="{{numberOfSkuItems(sku)}}">
                            </div>
                        </div>
                        <div class="plus-minus-wrapper colf10">
                            <div class="icon-wrapper">
                                <img src="/images/icons/plus-greenbck.png" (click)="incSku(sku)" />
                            </div>
                        </div>

                    </div>
                </div>
                <!--small table zamawianie-->
                <div class="function-row-wrapper-header small-table"
                     *ngIf="filteredSkus != null && filteredSkus.length>0" style="margin-top:8px;">

                    <div class="function-row-wrapper{{j%2}} colf" id="row_{{sku.code}}"
                         *ngFor="let sku of foundCodesOfSubSeries(subSeries);let j = index;">
                        <div class="main-group">

                            <div class="summary-small-image" (click)="presentImageOfSku(osi.sku)"
                                 style="width:85px;font-size:16px;">
                                <img *ngFor="let layer of layers(sku.code)"
                                     src="/images/seriesorg/{{sku.series.title}}/{{layer}}.png" />
                            </div>
                            <div class="main-group-1">
                                <div class="function-label label-clickable" style="font-size:16px;width: inherit;justify-content: left;"
                                     (mouseover)="presentTooltip(sku.code,$event)" (mouseout)="hideTooltip(j)"
                                     (click)="presentDialogWithSku(sku.code)">
                                    {{sku.functionType.title}}
                                    <span style="color:red;" *ngIf="sku.withFrame">z&nbsp;ramką</span>
                                </div>
                            </div>
                        </div>
                        <div class="summary-small-secondrow">
                            <div style="width: 85px; flex-direction: row; display: flex;justify-content:space-between;">
                                <div *ngIf="!isLikedSku(sku)"
                                     class="function-label cols1 function-label-icon function-label-clickable function-label-right" (click)="likeSku(sku)"
                                     title="Dodaj do ulubionych">
                                    <img src="/images/icons/star-hollow-darkgray.png" style="width: 34px;" />
                                </div>
                                <div *ngIf="isLikedSku(sku)"
                                     class="function-label cols1 function-label-icon function-label-clickable function-label-right" (click)="dislikeSku(sku)"
                                     title="Usuń z ulubionych">
                                    <img src="/images/icons/star-darkgray.png" style="width: 34px;" />
                                </div>
                                <div class="function-label function-label-icon function-label-right"
                                     title="Pobierz kartę katalogową" style="margin-left: 18px;">
                                    <a *ngIf="sku" href="/api/FileExport/card/{{sku.series.title}}/{{remSpaces(sku.code)}}" download style="width:100%;" class="{{isDisabledCardLink(sku)}}">
                                        <img src="/images/icons/download-darkgray.png" style="width: 30px;height:34px" />
                                    </a>
                                </div>
                            </div>
                            <div class="function-label label-clickable"
                                 title="Numer referencyjny produktu" style="margin-left: 17px;font-size:16px;">
                                {{sku.code}}
                            </div>
                        </div>
                        <div class="icon-group">
                            <div class="function-label" title="Cena katalogowa netto bez rabatu"
                                 style="font-size:16px;font-weight:700;width:85px;justify-content: center;flex-direction: column;">
                                {{currency(sku.net)}}&nbsp;zł
                            </div>
                            <div class="function-label-params" style="margin-bottom: 4px;width:unset">
                                <mat-expansion-panel expanded='false' (click)="toggleparam()"
                                                     style="width:100%;box-shadow:none;">
                                    <mat-expansion-panel-header [ngClass]="tickets-container-header"
                                                                [collapsedHeight]="'34px'" [expandedHeight]="'34px'">
                                        <mat-panel-title>

                                            <div>
                                                Szczegóły
                                            </div>

                                        </mat-panel-title>

                                    </mat-expansion-panel-header>

                                    <div *ngFor="let p of paramsWithoutType;let pi = index;"
                                         class="function-label cols6" title="{{p.title}}" style="width: 100%;">
                                        <span class="function_header_span" *ngIf="p.title!='Podświetlenie' && p.title!='Ekranowane' && p.title!='Przesłony'">{{p.title}}: {{skuParamValue(p,j)}}</span>
                                        <span class="function_header_span" *ngIf="p.title=='Podświetlenie'">
                                            <span *ngIf="skuParamValue(p,j) == 'tak'" title="Z podświetleniem">{{p.title}}: <img src="/images/icons/checked-livegreen.png" /></span>
                                            <span *ngIf="skuParamValue(p,j) != 'tak'" title="Bez podświetlenia"></span>
                                        </span>
                                        <span class="function_header_span" *ngIf="p.title=='Ekranowane'">
                                            <span *ngIf="skuParamValue(p,j) == 'tak'">Ekranowane</span>
                                            <span *ngIf="skuParamValue(p,j) != 'tak'"></span>
                                        </span>
                                        <span class="function_header_span" *ngIf="p.title=='Przesłony'">
                                            <span *ngIf="skuParamValue(p,j) == 'tak'">Z przesłoną</span>
                                            <span *ngIf="skuParamValue(p,j) != 'tak'">Bez przesłon</span>
                                        </span>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: row;justify-content:center;flex-wrap:wrap;width: 100%;padding:4px;">

                            <div class="plus-minus-wrapper" style="margin-right: 19px;">
                                <img src="/images/icons/minus-white-greenbck.png" *ngIf="numberOfSkuItems(sku)<=0"
                                     style="cursor:not-allowed;width:30px;height:30px;margin:3px;" />
                                <img src="/images/icons/minus-greenbck.png" (click)="decSku(sku)" *ngIf="numberOfSkuItems(sku)>0"
                                     style="width:30px;height:30px;margin:3px;" />
                            </div>
                            <div class="function-input-col" style="margin-top: -6px;">
                                <div class="function-input-wrapper">
                                    <input class="function-number" type="number" min="0" style="width:29px;line-height:30px"
                                           onclick="this.select()" (change)="newValueDetected($event,sku)" value="{{numberOfSkuItems(sku)}}">
                                </div>
                            </div>

                            <div class="plus-minus-wrapper cols10" style="margin-left: 19px;">
                                <img src="/images/icons/plus-greenbck.png" (click)="incSku(sku)"
                                     style="width:30px;height:30px;margin:3px;" />
                            </div>
                        </div>
                    </div>
                </div>
                <!--</div>-->
                <div class="function-row-wrapper-header"
                     *ngIf="(searchedCode==null && foundCodes == null || foundCodes.length == 0)||
             (searchedCode!=null && foundCodes.length == 0) ">
                    <div>Brak produktów o wybranych parametrach  w kategorii {{selectedCategory}}</div>
                </div>
                <div class="function-row-wrapper-header"
                     *ngIf="searchedCode!=null && tooManyResults">
                    <div>Wyszukano zbyt dużo pozycji, wprowadź dłuższy fragment kodu.</div>
                </div>

                <div *ngIf="(foundCodes != null && foundCodes.length >= 0)" class="red-note">
                    Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego. Konfigurator nie jest sklepem internetowym, Użytkownik może dokonać zakupów w sklepach internetowych i stacjonarnych partnerów Schneider Electric.
                </div>

            </div>
        </div>
    </div>
<!--zestawienie-->
    <div *ngIf="isStepActive(3)">
        <div id="spinner" hidden>
            <mat-spinner class="summary-set-spinner"></mat-spinner>
        </div>

        <mat-spinner class="summary-set-spinner" *ngIf="functionHelper.functionsAll==null"></mat-spinner>


        <div class="row" *ngIf="functionHelper.functionsAll!=null">

            <div style="width: inherit;">
                <mat-list role="list" class="function-row-wrapper-header"
                          *ngIf="!orderService.isAnythingInAnyRoom">
                    <mat-list-item role="listitem"><div style="margin-left: 20px;">Brak produktów</div></mat-list-item>
                </mat-list>
                <div *ngIf="orderService.isAnythingInAnyRoom" style="padding: 4px;">
                    <div class="b2b-summary-of-summary big-screen">
                        <div style="font-weight: 700;line-height: 2.2;margin-right: 0;position: relative;display:flex;flex-direction:column">

                            <div style="align-items: center;display: flex;flex-direction: row;flex-wrap: wrap;">
                                <div style="font-size: 24px;margin:5px 3px 3px 5px;color: grey;top:9px">Twoja lista</div>

                            </div>

                        </div>
                    </div>
                    <div class="b2b-summary-of-summary" style="justify-content: center;">
                        <div class="b2b-summary-ammounts">
                            <div class="b2b-summary-sum" style="width: 100%;font-size: medium;margin-left: 5px;position: relative;margin-right: auto;display:flex;justify-content:space-between;">
                                <div>Suma projektu: </div>
                                <div><b>{{sumNetProject(null)}}&nbsp;zł</b></div>
                            </div>
                            <div style="margin-left:5px;display:flex;flex-direction:column;position: relative;">
                                <div style="font-size: medium;display:flex;justify-content:space-between;">
                                    <div>
                                        Twój rabat<span *ngIf="isDiscountValid()" style="color:red;"> Rabat nie może być mniejszy niż 0 i większy niż 100%</span>
                                    </div>
                                    <div>
                                        <input style="text-align: right;width: 40px;border: none;border-bottom: solid;border-width: 1px;"
                                               [(ngModel)]="discount" type="number"
                                               [ngModelOptions]="{standalone: true}" min="0" max="100">
                                        <span matSufix>%.</span>
                                    </div>
                                </div>

                                <div style="font-size: medium;position:relative;display:flex;justify-content:space-between;">
                                    <div>
                                        <span style="position: relative;color:green;font-weight:700;">
                                            Suma po rabacie:&nbsp;
                                        </span>
                                    </div>
                                    <div>
                                        <span style="position: relative;color:green;font-weight:700;">
                                            <b>{{sumAfterDiscount()}}&nbsp;zł</b>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="b2bSummary" style="margin-top: 14px;">

                        <mat-expansion-panel *ngFor="let room of openedProject.rooms;let j = index;"
                                             [expanded]="orderService.expanded || orderService.actRoomIndex == j"
                                             style="border: solid 1px grey;width: 100%;">
                            <mat-expansion-panel-header>
                                <mat-panel-title style="display:flex;flex-direction:row;flex-wrap:wrap">
                                    <div style="width:200px">Zestaw:&nbsp; <b>{{room.title}}&nbsp;  </b></div>

                                    <div style="display:flex">
                                        <div *ngIf="wide">
                                            Ilość ramek:&nbsp;<b>{{numberOfFrames(room)}}</b>, ilość mechanizmów:&nbsp;<b>{{numberOfFunctions(room)}}</b>.
                                        </div>
                                        <div>
                                            Suma netto*:&nbsp;<b>{{sumNet(room)}}&nbsp;zł</b>
                                        </div>

                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="function-row-wrapper-header big-table">
                                <div class="function-row-wrapper">
                                    <div class="function_header cols1 "></div>
                                    <div class="function_header cols2 left-white-margin">Produkt</div>
                                    <div class="function_header code left-white-margin" title="Numer referencyjny produktu">Nr ref.</div>
                                    <div class="function_header cols4 left-white-margin" title="Punkty w Elektroklubie">Punkty</div>
                                    <div class="function_header cols5 left-white-margin">
                                        <div>Seria</div>
                                        <div style="cursor:pointer" (click)="changeSeriesGlobal(j)"><u>Zmień</u></div>
                                    </div>
                                    <div class="function_header cols6 left-white-margin">
                                        <div>Kolor</div>
                                        <div style="cursor:pointer" (click)="changeColorGlobal(j)"><u>Zmień</u></div>
                                    </div>
                                    <div class="function_header cols7 left-white-margin">Podgląd</div>
                                    <div class="function_header cols8 left-white-margin">Karta kat.</div>
                                    <div title="Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego." class="function_header cols9">Cena netto*</div>
                                    <div class="function_header cols10 left-white-margin"></div>
                                    <div class="function_header cols11">Ilość</div>
                                    <div class="function_header cols12"></div>
                                    <div class="function_header cols13 left-white-margin">Wartość netto</div>
                                </div>

                                <div class="function-row-wrapper{{i%2}}"
                                     *ngFor="let osi of room.orderSets[0].orderSetItems ;let i = index;">
                                    <div class="cols1 function-label function-label-icon function-label-icon-small"
                                         (click)="deletePosition(i)" title="Usuń pozycję" style="width: 26px;">
                                        <img src="images/icons/usun.png" />
                                    </div>

                                    <div class="function-label cols2 left-white-margin">
                                        {{osi.sku.info}}
                                        <span style="color:red;" *ngIf="osi.sku.withFrame">z&nbsp;ramką</span>
                                    </div>
                                    <div class="function-label code left-white-margin" title="Numer referencyjny produktu">
                                        {{osi.sku.code}}
                                    </div>
                                    <div class="function-label cols4 left-white-margin">
                                        {{osi.sku.elk * osi.qty}}
                                    </div>

                                    <div class="seriesInSummary function-label cols5 left-white-margin" title="Zmień serię">

                                        <mat-form-field *ngIf="osi.sku.slots>0 && osi.sku.subSeries!=null" title="Zmień serię" style="width: 85px;">
                                            <mat-label>{{osi.sku.subSeries.title}}</mat-label>
                                            <mat-select value="{{osi.sku.subSeries.title}}" [(ngModel)]="osi.newSkuId"
                                                        (selectionChange)="changeSeriesOfUnit(j,i)" width="calc(100% - 32px)">
                                                <mat-option *ngFor="let altSer of alternateSubSeriesWithColor(skuFull(osi.sku))"
                                                            value="{{altSer.id}}" width="calc(100% - 32px)">
                                                    <div>{{altSer.subSeries.title}}</div>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field *ngIf="osi.sku.slots<=0" title="Zmień serię" style="width: 85px;">
                                            <mat-label>{{osi.sku.series.title}}</mat-label>
                                            <mat-select value="{{osi.sku.series.title}}" [(ngModel)]="osi.newSkuId"
                                                        (selectionChange)="changeSeriesOfUnit(j,i)">
                                                <mat-option *ngFor="let altSer of alternateSeriesWithColor(skuFull(osi.sku))"
                                                            value="{{altSer.id}}" width="100%">
                                                    <div>{{altSer.series.title}}</div>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="colorInSummary function-label cols6 left-white-margin" title="Zmień kolor">
                                        <mat-form-field title="Zmień kolor" style="width: 85px;">
                                            <mat-label>{{osi.sku.color.title}}</mat-label>
                                            <mat-select value="{{osi.sku.color.title}}" [(ngModel)]="osi.newSkuId"
                                                        (selectionChange)="changeColorOfUnit(j,i)" width="calc(100% - 32px)">
                                                <mat-option *ngFor="let altCol of alternateColors(skuFull(osi.sku))"
                                                            value="{{altCol.id}}" width="100%">
                                                    <img style="height:24px;" src="/images/series/colors/{{altCol.color.title}}.png" />
                                                    {{altCol.color.title}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>


                                    <div class="function-label cols7 function-label-icon-big function-label-clickable function-label-right  left-white-margin" (click)="presentImageOfSku(osi.sku)"
                                         title="Podgląd produktu">
                                        <img src="/images/series/colors/{{osi.sku.color.title}}.png" />
                                    </div>
                                    <div class="function-label cols8 function-label-icon function-label-icon-small left-white-margin"
                                         title="Pobierz kartę katalogową">
                                        <a *ngIf="osi && osi.sku" href="/api/FileExport/card/{{osi.sku.series.title}}/{{remSpaces(osi.sku.code)}}"
                                           download style="width:100%;" class="{{isDisabledCardLink(osi.sku)}}">
                                            <img src="/images/icons/download-darkgray.png" />
                                        </a>
                                    </div>


                                    <div title="cena katalogowa netto wg cennika" class="cols9 function-label  left-white-margin">
                                        {{currency(osi.sku.net)}}&nbsp;zł
                                    </div>
                                    <div class="plus-minus-wrapper cols10  left-white-margin">
                                        <div class="icon-wrapper">
                                            <img src="/images/icons/minus-white-greenbck.png" *ngIf="osi.qty<=0" style="cursor:not-allowed" />
                                            <img src="/images/icons/minus-greenbck.png" (click)="decSkuInRoom(osi.sku,j)" *ngIf="osi.qty>0" />
                                        </div>
                                    </div>
                                    <div class="function-input-col colf9">
                                        <div class="function-input-wrapper">
                                            <input class="function-number" type="number" min="0"
                                                   onclick="this.select()" (change)="newValueDetectedInRoom($event,osi.sku,j)" value="{{osi.qty}}">
                                        </div>
                                    </div>
                                    <div class="plus-minus-wrapper cols12">
                                        <div class="icon-wrapper">
                                            <img src="/images/icons/plus-greenbck.png" (click)="incSkuInRoom(osi.sku,j)" />
                                        </div>
                                    </div>
                                    <div title="suma wg ceny katalogowej netto" class="function-label cols13  left-white-margin">
                                        {{currency(osi.sku.net*osi.qty)}}&nbsp;zł
                                    </div>
                                </div>
                            </div>

                            <div class="function-row-wrapper-header small-table">

                                <div class="function-row-wrapper{{i%2}}"
                                     *ngFor="let osi of room.orderSets[0].orderSetItems ;let i = index;">

                                    <div class="main-group">
                                        <div *ngFor="let layer of layers(osi.sku.code)"
                                             class="summary-small-image" (click)="presentImageOfSku(osi.sku)">
                                            <img src="/images/seriesorg/{{osi.sku.series.title}}/{{layer}}.png" />
                                        </div>
                                        <div class="main-group-1">
                                            <div class="function-label ">
                                                {{osi.sku.info}}
                                                <span style="color:red;" *ngIf="osi.sku.withFrame">z&nbsp;ramką</span>
                                            </div>

                                        </div>
                                        <div class=" function-label function-label-icon function-label-icon-small"
                                             (click)="deletePosition(i)" title="Usuń pozycję">
                                            <img src="images/icons/clear-red.png" style="height:unset" />
                                        </div>
                                    </div>
                                    <div class="summary-small-secondrow">
                                        <div class="function-label  function-label-icon function-label-icon-small"
                                             title="Pobierz kartę katalogową" style="display:flex;flex-direction:row">
                                            <a href="/api/FileExport/card/{{osi.sku.series.title}}/{{remSpaces(osi.sku.code)}}" download style="width:100%;" class="{{isDisabledCardLink(osi.sku)}}">
                                                <img src="/images/icons/download-darkgray.png" />
                                            </a>
                                        </div>
                                        <div class="function-label code" title="Numer referencyjny produktu">
                                            {{osi.sku.code}}
                                        </div>
                                    </div>

                                    <div class="icon-group">
                                        <div class="colorInSummary function-label " title="Zmień kolor"
                                             style="display:flex;flex-direction:row">
                                            <div style="margin: 16px 6px 0px 0px;font-size: 14px;">Kolor</div>
                                            <mat-form-field title="Zmień kolor" style="width:75px">
                                                <mat-label>{{osi.sku.color.title}}</mat-label>
                                                <mat-select value="{{osi.sku.color.title}}" [(ngModel)]="osi.newSkuId"
                                                            (selectionChange)="changeColorOfUnit(j,i)">
                                                    <mat-option *ngFor="let altCol of alternateColors(skuFull(osi.sku))"
                                                                value="{{altCol.id}}">
                                                        <img style="height:24px;" src="/images/series/colors/{{altCol.color.title}}.png" />
                                                        {{altCol.color.title}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="seriesInSummary function-label " title="Zmień serię"
                                             style="display:flex;flex-direction:row">
                                            <div style="margin: 16px 6px 0px 0px;font-size: 14px;">Seria</div>
                                            <mat-form-field *ngIf="osi.sku.slots>0 && osi.sku.subSeries!=null" title="Zmień serię" style="width:75px">
                                                <mat-label>{{osi.sku.subSeries.title}}</mat-label>
                                                <mat-select value="{{osi.sku.subSeries.title}}" [(ngModel)]="osi.newSkuId"
                                                            (selectionChange)="changeSeriesOfUnit(j,i)">
                                                    <mat-option *ngFor="let altSer of alternateSubSeriesWithColor(skuFull(osi.sku))"
                                                                value="{{altSer.id}}">
                                                        {{altSer.subSeries.title}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="osi.sku.slots<=0" title="Zmień serię" style="width: 75px;">
                                                <mat-label>{{osi.sku.series.title}}</mat-label>
                                                <mat-select value="{{osi.sku.series.title}}" [(ngModel)]="osi.newSkuId"
                                                            (selectionChange)="changeSeriesOfUnit(j,i)">
                                                    <mat-option *ngFor="let altSer of alternateSeriesWithColor(skuFull(osi.sku))"
                                                                value="{{altSer.id}}">
                                                        <div>{{altSer.series.title}}</div>
                                                        <div *ngIf="osi.sku.slots<=0">{{altSer.series.title}}</div>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="summary-group">
                                        <div title="cena katalogowa netto wg cennika" class=" function-label">
                                            Cena*:&nbsp;{{currency(osi.sku.net)}}&nbsp;zł
                                        </div>
                                        <div class="function-label">
                                            Elektroklub:&nbsp;{{osi.sku.elk * osi.qty}}&nbsp;pkt
                                        </div>

                                    </div>
                                    <div class="summary-group">
                                        <div title="suma wg ceny katalogowej netto" class="function-label"
                                             style="font-weight:700;color:#3dcd58;padding-top: 14px;">
                                            Suma: {{currency(osi.sku.net*osi.qty)}}&nbsp;zł
                                        </div>
                                        <div class="summary-group-nowrap">
                                            <div class="plus-minus-wrapper" style="padding-top: 12px;">
                                                <img src="/images/icons/minus-white-greenbck.png" *ngIf="osi.qty<=0" style="cursor:not-allowed" />
                                                <img src="/images/icons/minus-greenbck.png" (click)="decSku(osi.sku)" *ngIf="osi.qty>0" />
                                            </div>
                                            <div class="function-input-col " style="height: 24px;margin: 11px;">
                                                <div class="function-input-wrapper">
                                                    <input class="function-number" type="number" min="0" style="width:30px;"
                                                           onclick="this.select()" (change)="newValueDetectedInRoom($event,osi.sku,j)" value="{{osi.qty}}">
                                                </div>
                                            </div>
                                            <div class="plus-minus-wrapper" style="padding-top: 12px;">
                                                <img src="/images/icons/plus-greenbck.png" (click)="incSku(osi.sku)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>

            </div>
        </div>

        <div class="red-note">Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego. Konfigurator nie jest sklepem internetowym, Użytkownik może dokonać zakupów w sklepach internetowych i stacjonarnych partnerów Schneider Electric.</div>
    </div>

<div id="skuInfoPopup">
    <!--<div class="image" *ngIf="skuForHover != null"><img src="/images/series/{{skuForHover.series.title}}/{{skuForHover.code}}.png" /></div>-->
    <div class="label" *ngIf="skuForHover != null">{{skuForHover.info}}</div>
</div>


<app-b2b-summary [functionselector]="this"> </app-b2b-summary>
