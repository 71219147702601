<h1 mat-dialog-title style="text-align: center;">Podaj nazwę twojego projektu</h1>
<form class="new-set-form">
    <div mat-dialog-content>
        <div class="row" style="display:block;margin-bottom:20px">
            <input autocomplete="off" autocorrect="off" cdkFocusInitial name="project-name" placeholder="Nazwa projektu" [(ngModel)]="data.projectName"
                   style="margin:12px 9px;position:relative;font-size:large;border:none;border-bottom:solid;border-width:1px;border-color:black;" />
        </div>
    </div>
    <div mat-dialog-actions style="justify-content: space-around;">
        <button mat-button type="button" class="cancel-button" (click)="onNoClick()">Anuluj</button>
        <button mat-button type="submit" (click)="onSubmitClick()" class="accept-button" [disabled]="data.projectName == null">Zapisz</button>
    </div>

</form>
