<div class="vertical-center-flex">
    <div class="login-container center-block">
        <div class="boxshadow">
            <div class="panel-body-left panel-body-white">
                <div class="panel-title">Dziękujemy!</div>
                <div class="panel-description">
                    {{message}}
                </div>
                <div class="form-group-row"></div>
            </div>
            <div class="panel-body-right panel-body-livegreen">

                <div class="panel-title">Rejestracja</div>
                <div class="panel-description">
                    Wróć do rejestracji aby w pełni korzystać z konfiguratora.
                </div>
                <div class="form-group-row">
                    <div class="form-group">
                        <button id="login-button" mat-flat-button
                                type="submit" class="btn btn-primary white-button" 
                                (click)="signUp()">
                            <img src="/images/icons/plus-livegreen.png"/>
                            <span>
                                REJESTRACJA
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
