import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../app.component";
import { SwiperConfigInterface, SwiperPaginationInterface, SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { ColorInfoDialog } from "./controls/color-info-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";

export class InspProduct {
    Image: string;
    Title: string;
    Subtitle: string;
    Desc: string;
    Url: string;
    Skus: string[];
}
export interface InspOptionVariant {
    Image: string;
    Products: InspProduct[];
    ProductSetHeader: string;
    Walls?: InspWallColor[];
}
export interface InspSideMenuOptions {
    Image: string;
    Label: string;
    Variants: InspOptionVariant[];
}
export interface InspWallColor {
    Color: string;
    Label: string;
    Url: string;
    Position: string;
}

@Component({
    selector: "app-inspirations-main",
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class InspirationsMainComponent {

    sideMenuOptions: InspSideMenuOptions[] = [
        {
            Image: 'kitchen',
            Label: 'Kuchnia',
            Variants: [
                {
                    Image: '1',
                    ProductSetHeader: 'Styl nowoczesny',
                    Walls: [{
                        Color: 'ti_sorbet',
                        Label: 'F306 (Sorbet)',
                        Url: 'https://www.tikkurila.pl/farby_dekoracyjne/kolory/wzorniki_kolorow_do_wnetrz/tikkurila_symphony_2436/f306.4374.xhtml',
                        Position: 'left:10%;top:5%'
                    },
                        {
                            Color: 'ti_tiffany', Label: 'X370 (Tiffany)', Url: 'https://www.tikkurila.pl/farby_dekoracyjne/kolory/wzorniki_kolorow_do_wnetrz/tikkurila_symphony_2436/x370.4374.xhtml',
                            Position: 'left:60%;top:67%' }],
                    Products: [{
                        Image: '1',
                        Title: 'Tytuł produktu',
                        Subtitle: 'Podtytuł produktu',
                        Desc: 'Dłuższy opis produktu np. >1 linijkowy',
                        Url: 'adres-do-produktu-lub-serii',
                        Skus: ['refcode1', 'refcode2']
                    },
                    {
                        Image: '2',
                        Title: 'Tytuł produktu',
                        Subtitle: 'Podtytuł produktu',
                        Desc: 'Dłuższy opis produktu np. >1 linijkowy',
                        Url: 'adres-do-produktu-lub-serii',
                        Skus: ['refcode1', 'refcode2']
                    }]
                }]
        },
        {
            Image: 'living-room',
            Label: 'Salon',
            Variants: [
                {
                    Image: '1',
                    ProductSetHeader: 'Styl klasyczny',
                    Walls: [{
                        Color: 'ti_sorbet', Label: 'F306 (Sorbet)', Url: 'https://www.tikkurila.pl/farby_dekoracyjne/kolory/wzorniki_kolorow_do_wnetrz/tikkurila_symphony_2436/f306.4374.xhtml',
                        Position: 'left:10%;top:5%' },
                        {
                            Color: 'ti_tiffany', Label: 'X370 (Tiffany)', Url: 'https://www.tikkurila.pl/farby_dekoracyjne/kolory/wzorniki_kolorow_do_wnetrz/tikkurila_symphony_2436/x370.4374.xhtml',
                            Position: 'left:10%;top:5%' }],
                    Products: [{
                        Image: '1',
                        Title: 'Tytuł produktu',
                        Subtitle: 'Podtytuł produktu',
                        Desc: 'Dłuższy opis produktu np. >1 linijkowy',
                        Url: 'adres-do-produktu-lub-serii',
                        Skus: ['refcode1', 'refcode2']
                    },
                    {
                        Image: '2',
                        Title: 'Tytuł produktu',
                        Subtitle: 'Podtytuł produktu',
                        Desc: 'Dłuższy opis produktu np. >1 linijkowy',
                        Url: 'adres-do-produktu-lub-serii',
                        Skus: ['refcode1', 'refcode2']
                    }]
                }]
        }
    ];
    sOpt = 0;
    sVariant = 0;
    sProduct = 0;
index: number = 0;
    constructor(private sanitizer: DomSanitizer, public dialog: MatDialog) {
        
    }
    secureStyle(s: string) {
        return this.sanitizer.bypassSecurityTrustStyle(s);
    }
    variantStyle(i: number) {
        return (i == this.sVariant) ? 'selected-border' : '';
    }
    selectVariant(i: number) {
        this.sVariant = i;
        this.selectProduct(0);
    }
    productStyle(i: number) {
        return (i == this.sProduct) ? 'selected-border' : '';
    }
    selectProduct(i: number) {
        this.sProduct = i;
    }
    optionStyle(i: number) {
        return (i == this.sOpt) ? 'selected-background' : '';
    }
    optionIconColor(i: number) {
        return (i == this.sOpt) ? 'white' : 'grey';
    }
    selectOption(i: number) {
        this.sOpt = i;
        this.selectVariant(0);
    }


    openColorDialog(w: InspWallColor) {
        AppComponent.SendEventGa('sku-image', null, 'Insp_otwarcie koloru ściany', 0);
        console.log(w.Color);
        const dialogRef = this.dialog.open(ColorInfoDialog, {
            data: w
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }
    colorDialogHidden: boolean = true;


    public config: SwiperConfigInterface = {
        a11y: true,
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: false
    };

    @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;

    public onIndexChange(index: number): void {
        console.log('Swiper index: ', index);
    }

    public onSwiperEvent(event: string): void {
        console.log('Swiper event: ', event);
    }
}
