export class User {
  id: number;
  username: string;
    password: string;
    nip: string;
  firstName: string;
    lastName: string;
    email: string;
    roles: string[];
}
