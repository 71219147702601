import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sku } from '../../../_models';
import { AppComponent } from '../../app.component';

export interface SkuDialogData {
    sku: Sku;
}

@Component({
    selector: 'sku-details-dialog',
    templateUrl: './sku-details-dialog.component.html',
    styleUrls: ['./sku-details-dialog.component.css']
})
export class SkuDetailsDialog {

    constructor(
        public dialogRef: MatDialogRef<SkuDetailsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SkuDialogData) {
        //data.sku.series.title
        data.sku.code

    }

    onNoClick(): void {
        AppComponent.SendEventGa('sku-details', null, 'B2B_szczegóły produktu zamknięcie', 0);
        this.dialogRef.close();
    }


}
