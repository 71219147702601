<div id="mainContent" class="app-component">
    <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
        <a href="/">Start</a>
        <a href="/">Katalog</a>
        <a href="/wybierz-design#projekty">Wybierz design</a>
        <a href="/">Inspiracje</a>
        <a href="/szybka-konfiguracja">Szybka konfiguracja</a>
    </div>
    <!--
    <span onclick="openNav()">open</span>
        -->







    <div [@fadeInOut] class="row container semaincontainer absolute-full">

        <!-- side menu-->
        <div class="side-menu">
            <div id="mainMenuTab" class="side-menu-tab">

            </div>
            <ul bootstrapTab #tab="bootstrap-tab" class="nav nav-tabs tabs-left tabs-left-icono" (showBSTab)="onShowTab($event)">

                <li class="active" id="projectsButton" (click)="openTab('projekty')" class="side-menu-tab">
                    <a id="projectsTabLink" [routerLink]="[]" class="side-menu-tab" fragment="projekty" href="#projekty" data-toggle="tab">
                        <img src="/images/icons/start project.png" />
                        <div class="side-menu-label" style="text-align: center;">PROJEKTY</div>
                    </a>
                </li>
                <li (click)="openTab('pokoje')" id="roomsButton">
                    <a id="roomsTabLink" [routerLink]="[]" class="side-menu-tab" fragment="pokoje" href="#pokoje" data-toggle="tab">
                        <img src="/images/icons/pomieszczenia.png" />
                        <div class="side-menu-label" style="text-align: center;">POKOJE</div>
                    </a>
                </li>
                <li (click)="openTab('serie')" id="seriesButton">
                    <a id="seriesTabLink" [routerLink]="[]" class="side-menu-tab" fragment="serie" href="#serie" data-toggle="tab">
                        <img src="/images/icons/seria.png" />
                        <div class="side-menu-label" style="text-align: center;">SERIE</div>
                    </a>
                </li>
                <li (click)="openTab('ramki')" id="framesButton">
                    <a id="framesTabLink" [routerLink]="[]" class="side-menu-tab" fragment="ramki" href="#ramki" data-toggle="tab">
                        <img src="/images/icons/multi-tab.png" />
                        <div class="side-menu-label" style="text-align: center;">RAMKI</div>
                    </a>
                </li>
                <li (click)="openTab('funkcje')" id="functionsButton">
                    <a id="functionsTabLink" [routerLink]="[]" class="side-menu-tab" fragment="funkcje" href="#funkcje" data-toggle="tab">
                        <img src="/images/icons/funkcje.png" />
                        <div class="side-menu-label" style="text-align: center;">FUNKCJE</div>
                    </a>
                </li>

            </ul>

        </div>

        <div class="maincontent">

            <!--header-->
            <div id="project-header" class="row header2bckc" *ngIf="!isProjectActivated && (functions == null || !isFunctionsActivated || !functions.fullscreen)">
                <header class="pageHeaderb2c ">
                    <div class="h3">

                        <div *ngIf="anyProjectOpened()" class="titleButtonb2c big-screen">
                            <div class="project-flineb2b" style="max-width: 800px;">
                                <div class="header-action" *ngIf="isRoomsActivated" (click)="router.navigateByUrl('/wybierz-design#projekty')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>
                                <div class="header-action" *ngIf="isSeriesActivated" (click)="router.navigateByUrl('/wybierz-design#pokoje')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>

                                <div class="header-action" *ngIf="isFramesActivated" (click)="router.navigateByUrl('/wybierz-design#serie')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>

                                <div class="header-action" *ngIf="isFunctionsActivated" (click)="router.navigateByUrl('/wybierz-design#ramki')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>
                                <div class="header-action" *ngIf="(isSummaryActivated && prevTab != null)" (click)="openPrevUrl()">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>
                                <div class="header-action" *ngIf="(isSummaryActivated && prevTab == null)" (click)="router.navigateByUrl('/wybierz-design#projekty')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>
                                <div class="project-prelabelb2b" *ngIf="orderService.cProject != null">Projekt: {{orderService.cProject.title}}</div>
                                <div class="project-prelabelb2b" *ngIf="orderService.openedRoom() != null">Pomieszczenie: {{orderService.openedRoom().title}}</div>
                            </div>
                        </div>
                        <div *ngIf="anyProjectOpened() && (isRoomsActivated || isSummaryActivated)" class="titleButtonb2c small-screen">
                            <div class="project-flineb2b" style="max-width: 600px;">
                                <div class="header-action" *ngIf="!isSummaryActivated" (click)="router.navigateByUrl('/wybierz-design#projekty')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>
                                <div class="header-action" *ngIf="isSummaryActivated" (click)="openPrevUrl('!serie')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px;" />
                                </div>
                                <div *ngIf="(isSummaryActivated || isRoomsActivated) &&
                                            orderService.projects[orderService.openedProjectIndex].title != null"
                                     class="project-prelabelb2b">{{orderService.projects[orderService.openedProjectIndex].title}}</div>

                                <!--<img class="header-icon" src="/images/icons/edytuj-white.png" />-->
                            </div>
                        </div>



                        <div *ngIf="(isSeriesActivated || isFramesActivated || isFunctionsActivated) &&
                             orderService.openedRoom() != null &&
                                !isSummaryActivated" class="titleButtonb2c left-bar small-screen"
                             (click)="changeRoom()">
                            <div class="project-flineb2b " style="max-width: 400px;">
                                <div class="header-action" (click)="router.navigateByUrl('/wybierz-design#pokoje')">
                                    <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px" />
                                </div>
                                <div class="project-prelabelb2b">{{orderService.openedRoom().title}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="button-container">

                        

                        <button *ngIf="isSummaryActivated" mat-button
                                class="big-button center-align-button b2c-export-button"
                                (click)="exportCart()">
                            <span class="button-label">Pobierz</span>

                        </button>

                        <button *ngIf="isSummaryActivated && orderService.ecommerceEndpooint == null" mat-button
                                class="big-button center-align-button b2c-export-button"
                                (click)="forwardToEcommerce()">
                            Kup w sklepie
                        </button>

                        <form *ngIf="isSummaryActivated && orderService.ecommerceEndpooint != null" ngNoForm
                              action="{{orderService.ecommerceEndpooint}}" target="_blank"
                              id="{{'form_' + orderService.eCommerceName}}"
                              (submit)="logAndSubmit(
                orderService.eCommerceName,
                'form_' + orderService.eCommerceName,
                orderService.orderIdWithPrefix(orderService.eCommercePrefix),
                orderService
              )"
                              class="big-button center-align-button b2c-export-button"
                              method="{{orderService.ecommerceMethod}}">
                            <input type="hidden" name="request" value="{{orderService.orderWithPrefix(orderService.eCommercePrefix)}}" />
                            <input type="submit" value="Kup w sklepie">
                        </form>
                        <button *ngIf="anyProjectOpened() && !isSummaryActivated" mat-stroked-button
                                class="big-button center-align-button b2c-summary-button"
                                (click)="openSummaryUrl()">

                            <span class="button-label">Twoja lista</span>
                            <img src="/images/icons/checklist-white.png" style="vertical-align: middle;height: 21px;position: inherit;margin: 6px 4px 0 0;" />
                            <span matBadge="{{collectedProjectSkus}}" matBadgeSize="medium"
                                  matBadgePosition="above after"
                                  matBadgeOverlap="false" matBadgeColor="basic"></span>
                        </button>
                        <!--small cart button was here-->
                    </div>
                </header>
            </div>
            <!--main content-->
            <div class="tab2-content">
                <div class="tab-pane active" id="projectsTab" *ngIf="isProjectActivated">

                    <div [@fadeInOut] *ngIf="isProjectActivated" class="content-container">
                        <app-project-selection (projectelectionEvent)="receiveProjectSelectionMessage($event)" #projects></app-project-selection>
                    </div>
                </div>
                <div class="tab-pane" id="roomsTab" *ngIf="isRoomsActivated">

                    <div [@fadeInOut] *ngIf="isRoomsActivated" class="content-container">
                        <rooms-selection (roomSelectionEvent)="receiveRoomSelectionMessage($event)" #rooms></rooms-selection>
                    </div>
                </div>
                <div class="tab-pane" id="seriesTab" *ngIf="isSeriesActivated">

                    <div [@fadeInOut] *ngIf="isSeriesActivated" class="content-container">

                        <series-selection [_orderService]="orderService"></series-selection>
                    </div>
                </div>
                <div class="tab-pane" id="framesTab" *ngIf="isFramesActivated">

                    <div [@fadeInOut] *ngIf="isFramesActivated" class="content-container">

                        <frames-selection [_orderService]="orderService"></frames-selection>
                    </div>
                </div>
                <div class="tab-pane" id="functionsTab" *ngIf="isFunctionsActivated">

                    <div [@fadeInOut] *ngIf="isFunctionsActivated" class="content-container">

                        <function-selection #functions></function-selection>
                    </div>
                </div>

                <div class="tab-pane" id="summaryTab" *ngIf="isSummaryActivated">
                    <div [@fadeInOut] *ngIf="isSummaryActivated" class="content-container">
                        <b2c-summary #summary [_orderService]="orderService"></b2c-summary>
                    </div>
                </div>
            </div>

            <!--colors palette-->
            <!-- Vertical Dots by Arthur Shlain from the Noun Project -->
            <div id="colorSelector" class="row nomargin-nopadding"
                 *ngIf="isSeriesActivated || isFramesActivated || isFunctionsActivated"
                 style="position:relative">
                <div class="flex color-palete">
                    <div class="color-selector-column">
                        <div class="color-selector-label">Wybierz kolor ściany</div>
                        
                        <div class="color-selector-color">
                            <div class="clickable color-logo" *ngIf="false"
                                 title="TIKKURILA">
                                <img src="/images/icons/tikkurila.png" style="width:inherit;height:inherit" />

                            </div>
                            <div class="color-collection">
                                <div *ngFor="let color of backgroundStyles; let i=index" class="tooltip2 clickable"
                                     [ngStyle]="{'background-color': color}"
                                     title="Ustaw kolor tła" (click)="selectColor(i)">
                                </div>
                            </div>
                            <div class="color-logo small-screen more-colors-icon-right" (click)="otherColor()"><img src="/images/icons/vertical_dots_livegreen.png" /></div>
                        </div>
                        
                    </div>
                    
                    <div class="color-selector-label big-screen clickable margin8" (click)="otherColor()">Więcej</div>
                    
                </div>
                <div class="flex">
                    <button *ngIf="isFramesActivated" mat-button
                            class="center-align-button b2c-summary-button  b2c-next-step-button"
                            style="min-width: 148px"
                            (click)="openFunctions()">
                        <div class="next-stap-label">Dalej</div>
                        <img src="/images/icons/next_white.png" style="margin: 8px 0px 8px 6px;"/>
                    </button>
                    <input type="file" name="file" id="upload-photo" (change)="onFileChanged($event)" hidden />
                    <label *ngIf="false && isFunctionsActivated" class="big-screen file-upload-label" for="upload-photo">
                        <img src="/images/icons/upload-black.png" style="height:20px;width:20px;margin-right:3px;margin-top: 0;" />
                        Dodaj swoje tło
                    </label>
                    <button *ngIf="isFunctionsActivated" mat-button
                            class="center-align-button  b2c-summary-button b2c-next-step-button"
                            style="min-width: 148px"
                            (click)="openSummary()">
                        <div class="next-stap-label">Dalej</div>
                        <img src="/images/icons/next_white.png" style="margin: 8px 0px 8px 6px;"/>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>



<button *ngIf="anyProjectOpened() && !isSummaryActivated" mat-stroked-button
        class="small-button b2c-summary-button"
        (click)="openSummaryUrl()" style="position:absolute; top:10px; right:6px;z-index:999">
    <img src="/images/icons/checklist-white.png" style="vertical-align: middle;height: 26px;position: inherit;" />
    <span matBadge="{{collectedProjectSkus}}" matBadgeSize="medium"
          matBadgePosition="above after"
          matBadgeOverlap="false" matBadgeColor="basic"></span>
</button>

<div class="header-top-buttons" *ngIf="isSummaryActivated" style="position:absolute; top:10px; right:6px;z-index:999;display: inline-flex;">
    <button *ngIf="isSummaryActivated && orderService.ecommerceEndpooint == null" mat-button
            class="small-button " style="line-height: 34px;"
            (click)="forwardToEcommerce()">
        <img src="/images/icons/koszyk-white.png" style="vertical-align: middle;height: 21px;position: inherit;" />
    </button>
    <form *ngIf="isSummaryActivated && orderService.ecommerceEndpooint != null" ngNoForm
          action="{{orderService.ecommerceEndpooint}}" target="_blank"
          id="{{'form_' + orderService.eCommerceName}}"
          (submit)="logAndSubmit(
                orderService.eCommerceName,
                'form_' + orderService.eCommerceName,
                orderService.orderIdWithPrefix(orderService.eCommercePrefix),
                orderService
              )"
          class="small-button" style="line-height: 34px;"
          method="{{orderService.ecommerceMethod}}">
        <input type="hidden" name="request" value="{{orderService.orderWithPrefix(orderService.eCommercePrefix)}}" />
        <button class="header-white-button" type="submit" value="Kup w sklepie"><img src="/images/icons/koszyk-white.png" style="vertical-align: middle;height: 21px;position: inherit;" /></button>
    </form>
    <button *ngIf="isSummaryActivated" mat-button
            class="small-button header-white-button"
            (click)="exportCart()">
        <img src="/images/icons/download-white.png" style="vertical-align: middle;height: 21px;position: inherit;" />
    </button>
</div>
