import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InformationDialogComponent, DialogData } from "./information-dialog.component";
import { Router } from "@angular/router";
import { Inject, Component } from "@angular/core";
import { AlertService, UserService } from "../../_services";
import { AuthService } from "../../services/auth.service";

@Component({
    selector: 'app-account-remove-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],
    providers: [UserService, AlertService, AuthService]
})
export class AccountRemovecomponent extends InformationDialogComponent {
    isLoading: boolean = false;
    dialog: any;

    constructor(
        public dialogRef: MatDialogRef<InformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        protected router: Router,
        protected userService: UserService,
        protected authService: AuthService,
        protected alertService: AlertService) {
        super(dialogRef, data, router);
        this.data = {
            messageTitle: "Ostrzeżenie",
            messageBody: "Czy na pewno chcesz usunąć swoje konto z systemu?",
            url: null,
            confirmCallback: this.removeAccount
        };
    }


    onNoClick(): void {
        this.dialogRef.close();
        if (this.data.url != null)
            window.history.back();
    }

    onAck(): void {
        this.removeAccount();
        this.dialogRef.close(true);
        if (this.data.url != null)
            this.router.navigateByUrl(this.data.url);
    }


    removeAccount() {
        this.isLoading = true;
        this.userService.delete(this.authService.currentUser.id).subscribe(
            data => {
                this.authService.logout();
                this.router.navigate(['/']);
            },
            error => {
                this.alertService.error("Usunięcie nie powiodło się. Skontaktuj się z admin@dobierz-gniazdko.pl");
                this.isLoading = false;
            }
        );
    }


}
