<div class="vertical-center-flex">
    <div class="login-container center-block">
        <div class="boxshadow">
            <div class="panel-body-left panel-body-white-full">
                <div class="panel-title">Aktywacja</div>
                <div class="panel-description">
                    {{message}}
                </div>
                <form class="login" name="loginForm" #f="ngForm" novalidate *ngIf="activated"
                      (ngSubmit)="f.form.valid ? login() :
                          (!email.valid && showErrorAlert('E-mail jest wymagany', 'Wprowadź poprawny e-mail'));
                          (!password.valid && showErrorAlert('Hasło jest wymagane', 'Wprowadź poprawne hasło'))">
                    <div class="form-group-row">
                        <span class="errorMessage" *ngIf="f.submitted && !email.valid">
                            E-mail lub NIP jest wymagany
                        </span>
                    </div>
                    <div class="form-group-row">
                        <div class="form-group has-feedback input-group">
                            <img src="/images/icons/login.png" />
                            <div class="field-input" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
                                <input type="text" id="login-email" name="email" placeholder="e-mail / NIP" class="form-control" [(ngModel)]="userLogin.email" #email="ngModel" required />
                                <img *ngIf="isLoading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group-row">
                        <span class="errorMessage" *ngIf="f.submitted && !password.valid">
                            Hasło jest wymagane
                        </span>
                    </div>
                    <div class="form-group-row">
                        <div class="form-group has-feedback input-group">
                            <img src="/images/icons/haslo.png" />
                            <div class="field-input" [ngClass]="{'has-success': f.submitted && password.valid, 'has-error' : f.submitted && !password.valid}">
                                <input type="password" id="login-password" name="password" placeholder="hasło" class="form-control" [(ngModel)]="userLogin.password" #password="ngModel" required />
                                <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': password.valid, 'glyphicon-remove' : !password.valid}" aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                    <!--
        <div class="form-group-row">
            <div class="form-group">
                <div class="col-md-offset-2 col-md-10" style="margin-left:62%">
                    <div class="checkbox">
                        <label><input type="checkbox" name="rememberMe" [(ngModel)]="userLogin.rememberMe"> Zapamiętaj mnie</label>
                    </div>
                </div>
            </div>
        </div>
            -->
                    <div class="form-group-row">
                        <div class="action-redirect" (click)="resetPass()">Zapomniałeś hasła?</div>
                    </div>

                    <div class="form-group-row">
                        <div class="form-group">
                            <button id="login-button" mat-flat-button
                                    style="text-align:center;"
                                    type="submit" class="btn btn-primary next-step-button" [disabled]="isLoading">
                                <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                                <img src="/images/icons/zaloguj.png"
                                     style="margin-top: 5px;position: inherit;margin-right: 11px;margin-left: 0px;" />
                                <span>
                                    {{isLoading ? 'Logowanie ...' : 'ZALOGUJ'}}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group-row">
                        <div class="action-register" (click)="signUp()">Zarejestruj się</div>
                    </div>

                </form>
            </div>
            <!--
            <div class="panel-body-right panel-body-livegreen" *ngIf="activated">

                <div class="panel-title">Witaj!</div>
                <div class="panel-description">
                    Zaloguj się aby móc w pełni skorzystać z możliwości aplikacji.
                    Dostęp na dowolnym urządzeniu i zapisywanie historii projektów.
                </div>
                
                <div class="form-group-row">
                    <div class="form-group">
                        <button id="login-button" mat-flat-button
                                style="text-align:center;"
                                type="submit" class="btn btn-primary next-step-button" [disabled]="isLoading"
                                (click)="signIn()">
                            <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                            <img src="/images/icons/zaloz konto.png" style="margin-top: 5px;position: inherit;margin-right: 11px;margin-left: 0px;" />
                            <span>
                                ZALOGUJ
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            -->
        </div>
    </div>

</div>
