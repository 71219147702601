import { Component, Inject } from '@angular/core';
import { Sku, CategoryGroupFormated, Series, Color, Category, CategoryGroup, FunctionType, OrderSet, OrderSetItem, Room } from '../../_models/catalog';
import { HttpClient } from '@angular/common/http';
import { PresentationToolsComponent } from '../../services/presentation-tools.component';


@Component({
    selector: 'app-function-helper',
    template:''
})
/** FunctionHelper component*/
export class FunctionHelperComponent {
    
    
    copyOrderSetItem(osi: OrderSetItem): OrderSetItem {
        let newOsi: OrderSetItem = { id: 0, sku: osi.sku, qty: osi.qty };
        return newOsi;
    }
    copyOrderSet(os: OrderSet): OrderSet {
        let newOs: OrderSet = { id: 0, title: os.title, orderSetItems: new Array<OrderSetItem>() };
        for (let osi of os.orderSetItems) {
            newOs.orderSetItems.push(this.copyOrderSetItem(osi));
        }
        return newOs;
    }
    copyRoom(r: Room): Room {
        let newR: Room = new Room(
            r.title, r.wallColor, r.image, 0, new Array<OrderSet>());
        for (let os of r.orderSets) {
            newR.orderSets.push(this.copyOrderSet(os));
        }
        return newR;
    }
    categoryGroups: CategoryGroup[];
    functionsAll: Sku[];
    functionsSelectable: Sku[];
    categories: Category[];
    categoryGroupFormated: CategoryGroupFormated[];
    filteredFunctions: Array<Sku>;
    uniqueFiltersFunctions: Array<Sku>;
    functionsCollected: FunctionSet[] = new Array<FunctionSet>();
    /** FunctionHelper ctor */
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {//, fh: FunctionHelperComponent = null) {
        /*if(fh != null){
            this.categoryGroups = fh.categoryGroups;
            this.functionsAll = fh.functionsAll;
            this.categories = fh.categories;
            this.categoryGroupFormated = fh.categoryGroupFormated;
            this.filteredFunctions = fh.filteredFunctions;
            this.uniqueFiltersFunctions = fh.uniqueFiltersFunctions;
            this.functionsCollected = fh.functionsCollected;
        }*/
    }

    


    uniqueGroupedFunctions(series: Series = null, colorSku: Sku = null, waterproof: Boolean = null) {
        //console.log('uniqueGroupedFunctions calling');
        if (this.functionsSelectable != null) {
            this.filteredFunctions = PresentationToolsComponent.filterFunctions(this.functionsSelectable, waterproof, series, colorSku.color.id,null);
            //console.log('filteredFunctions length: ' + this.filteredFunctions.length);
            this.uniqueFiltersFunctions = PresentationToolsComponent.uniqueFunctions(this.filteredFunctions);
            console.log('uniqueFilteredFunctions length: ' + this.uniqueFiltersFunctions.length);
        this.categoryGroupFormated =
            
            PresentationToolsComponent.categorisedCollections(this.uniqueFiltersFunctions, this.categoryGroups, 1);
        return this.categoryGroupFormated;
        }
        //console.log('uniqueGroupedFunctions called');
        return null;
    }


    uniqueColors(): Sku[] {
        let colorSkus = new Array<Sku>();
        
        for (let s of this.functionsSelectable) {
            //console.log(s.color);
            if (s.color != null && colorSkus.findIndex(cs => cs.color.id == s.color.id) < 0) {
                colorSkus.push(s);
            }
        }
        return colorSkus;
    }


    filterSkuList(skus: Sku[], series: Series, colorSku: Sku, waterproof: Boolean): Sku[] {
        //console.log("filterSkuList:" + skus.length + colorSku + series + waterproof);
        let filteredSkus = Array<Sku>();
        for (let s of skus) {
            if ((colorSku == null || s.color.id == colorSku.color.id) &&
                (series == null || series.id == s.series.id) &&
                (waterproof == null ||
                    (waterproof == true && s.indexOfProtection.title == 'IP44') ||
                    (waterproof == false && s.indexOfProtection.title == 'IP22')
                )
            ) {
                if (filteredSkus.findIndex(sku => sku.functionType.id == s.functionType.id) < 0)
                    filteredSkus.push(s);
            }

        }
        return filteredSkus;
    }
    isNumber(n): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
    }

    remSkuInOrderSet(set: OrderSet, isku: Sku) {
        if (set.orderSetItems != null) {
            var index = set.orderSetItems.findIndex(osi => osi.sku.id == isku.id);
            for (let i = index; i < set.orderSetItems.length-1; i++) {
                set.orderSetItems[i] = set.orderSetItems[i + 1];
            }
            set.orderSetItems.pop();
        }
    }
    static remSkuInOrderSet(set: OrderSet, isku: Sku) {
        if (set.orderSetItems != null) {
            var index = set.orderSetItems.findIndex(osi => osi.sku.id == isku.id);
            for (let i = index; i < set.orderSetItems.length - 1; i++) {
                set.orderSetItems[i] = set.orderSetItems[i + 1];
            }
            set.orderSetItems.pop();
        }
    }
    setSkuNumberInOrderSet(set: OrderSet, iqty: number, isku: Sku = null) {
        if (!this.isNumber(iqty))
            iqty = 0;
        if (iqty == 0) {
            this.remSkuInOrderSet(set, isku);
            return set;
        }
        var osItem;
        if (set.orderSetItems != null) {
            osItem = set.orderSetItems.find(osi => osi.sku.id == isku.id);
        }
        if (osItem == null) {
            let osItem = { sku: isku, qty: iqty, id: -1 }
            set.orderSetItems.push(osItem);
        } else {
            osItem.qty = iqty;
        }
    }
    setFunctionNumber(iqty: number, fsi: number, iseries: Series = null, icolor: Color = null, isku: Sku = null, iwaterproof: boolean = null) {
        if (!this.isNumber(iqty))
            iqty = 0;
        let index: number = null;
        let fFC = new Array<FunctionSet>();
        if (fsi != null) {
            index = fsi;
            fFC.push(this.functionsCollected[fsi]);
        }
        else {
            fFC = this.functionsCollected.filter(fc => fc.sampleSku.id == isku.id);
            if (fFC.length > 0) {
                if (fFC.length >1)
                    console.error("something went wrong we have several same position of sample sku in cart");
                if (iqty == 0)
                    this.remElement(this.functionsCollected,this.functionsCollected.findIndex(fc => fc.sampleSku.id == isku.id));
                else
                    fFC[0].qty = iqty;

            } else {
                if (iqty == 0)
                    return;
                let newPosition = {
                    functionType: isku.functionType,
                    category: isku.category,
                    sampleSku: isku,
                    finalSku: null,
                    series: iseries,
                    color: icolor,
                    qty: iqty,
                    newSkuId: isku.id
                }
                this.functionsCollected.push(newPosition);
            }
        }
    }
    remElement(collection: any[], index: number) {
        if (index < 0 || collection == null || collection.length <= index)
            return;
        let i = index;
        while (i < collection.length) {
            if (collection.length == i + 1)
                collection.pop();
            else {
                collection[i] = collection[i + 1];
            }
            i++;
        }
    }
}

export class FunctionSet {
    functionType: FunctionType;
    category: Category;
    sampleSku: Sku;
    finalSku: Sku;
    series: Series;
    color: Color;
    qty: number;
    newSkuId: number;
}
