import { Component, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { OrderService } from '../../services/orders.service';
import { StylingHelper } from '../../_helpers/StylingHelper';
import { OrderSet, Room, Sku, OrderSetItem } from '../../_models/catalog';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { EcommerceRedirectComponent } from '../controls/ecommerce-redirect.component';
import { window } from 'ngx-bootstrap/utils/public_api';
import { AppComponent } from '../app.component';
import { PresentationToolsComponent } from '../../services/presentation-tools.component';

export class BagElement{
    sku: Sku;
    qty: number;
}
@Component({
    selector: 'b2c-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})

export class B2CSummaryComponent implements AfterViewInit {
    ngAfterViewInit(): void {
        this.turnOnSpinner();
        this.updateCart();

        setTimeout(_ => this.orderService.setValuesforEcommerce());
    }

    isDisabledCardLink(sku: Sku) {
        if (sku.code.startsWith("SDD"))
            return 'isDisabled';
        return '';
    }
    updateCart() {
        document.getElementById('spinner').hidden = false;
        this.orderService.cartFromProjectAndCallback(function () {
            document.getElementById('spinner').hidden = true;
        });
    }
    turnOnSpinner() {
        var d = document.getElementById('spinner');
        if (d != null) d.hidden = false;
    }
    selectedRoom: number;
    selectedOrderSet: number;
    selectedOrderSetItem: number;
    @Input() _orderService: OrderService;
    layout = 0;
    activeTab(i: number) {
        return (this.layout == i) ? 'active' : '';
    }
    get minWidth():string {
        let c = 0;
        for (let r of this._orderService.cProject.rooms) {
            if (r.orderSets != null) for(let os of r.orderSets) {
                if (os.orderSetItems != null) for (let osi of os.orderSetItems) {
                    if (osi != null && osi.sku != null && osi.sku.slots != null)
                        if (osi.sku.slots > c)
                            c = osi.sku.slots;

                }
            }
        }
        if (c <= 3)
            return 'w30';
        else if (c == 4) {
            return 'w33'
        } else if (c == 5) {
            return 'w36'
        } else if (c == 6) {
            return 'w40'
        }
    }


    functionPositionSelectedImage(osi: OrderSetItem): string {
        if (osi == null || osi.sku == null || osi.qty <= -1)
            return null;
        if (osi.multiBlockPosition != null && osi.multiBlockPosition > 0)
            return osi.sku.code + "_1";
        return osi.sku.code;
    }

    editOrderSet(rid: number, osid: number) {
        this.orderService.openRoom(rid);
        this.orderService.openedRoom().itemIndex = osid;
        this.orderService.currentConfig.currentRoom = this.orderService.openedRoom();
        this.router.navigateByUrl("wybierz-design#funkcje");
    }
    duplicateOrderSet(rid: number, osid: number) {
        console.log('duplicateOrderSet (' + rid + ',' + osid + ')')
        let orgOS = this.orderService.cProject.rooms[rid].orderSets[osid];
        let newOS = this.orderService.duplicateOrderSet(orgOS);
        this.orderService.cProject.rooms[rid].orderSets.push(newOS);
        this.updateCart();
    }
    deleteOrderSet(rid: number, osid: number) {
        console.log('deleteOrderSet ('+rid + ',' + osid + ')')
        let sku = this.orderService.cProject.rooms[rid].orderSets[osid].orderSetItems[0].sku;
        for (let i = osid; i < this.orderService.cProject.rooms[rid].orderSets.length; i++) {
            this.orderService.cProject.rooms[rid].orderSets[i] =
                this.orderService.cProject.rooms[rid].orderSets[i + 1];
        }
        this.orderService.cProject.rooms[rid].orderSets.pop();
        this.updateCart();
    }
    delOrderSetItem(rid: number, osid: number, osiid: number) {
        console.log('delOrderSetItem:'+rid+":"+osid+":"+osiid);
        this.orderService.cProject.rooms[rid].orderSets[osid].orderSetItems[osiid] = null;
    }
    constructor(private accountService: AccountService, private authService: AuthService, public dialog: MatDialog,
        public orderService: OrderService, private router: Router, private sanitizer: DomSanitizer, private alertService?: AlertService
        ) {

        console.log("B2CSummaryComponent.create");
        if (this.orderService.cProject != null) {
            console.log("orderService.cproject:" + this.orderService.cProject.title);
            console.log("orderService.rooms:" + this.orderService.cProject.rooms.length);
        } else {

            setTimeout(() => this.router.navigateByUrl('/wybierz-design#serie'));
        }
    }
    classOfOrderSet(os: OrderSet): string {
        if (os == null || os.orderSetItems[0] == null || os.orderSetItems[0].sku == null)
            return '';
        return "frame_" +
            ((os.orderSetItems[0].sku.slots == 1 || os.orderSetItems[0].sku.isVertical || os.orderSetItems[0].isVertical) ? "V" : "H") +
            " frame_slots_" + (os.orderSetItems[0].sku.slots);
    }
    sumOfOrderSet(os: OrderSet) {
        let sum = 0;
        for (var i = 0; i < os.orderSetItems.length; i++) {
            let p = os.orderSetItems[i];
            if (p != null && p.sku != null && p.qty != null)
                if (this.orderService.notFrameOfMechWithFrame(os.orderSetItems, i))
                sum += p.qty * p.sku.net;
        }
        return this.roundToTwo(sum);
    }
    sumNet(room: Room = null) {
        let sum = 0;
        if (room == null) {
            let p = this.orderService.cProject;

            if (p.rooms != null) for (let r of p.rooms) {
                if (r.orderSets != null)
                    for (let os of r.orderSets) {
                        if (os.orderSetItems != null)
                            for (var i = 0; i < os.orderSetItems.length; i++) {
                                if (os.orderSetItems[i] != null
                                    && os.orderSetItems[i].sku != null
                                    && os.orderSetItems[i].qty != null)
                                    if (this.orderService.notFrameOfMechWithFrame(os.orderSetItems, i))
                                        sum += os.orderSetItems[i].qty * os.orderSetItems[i].sku.net;
                            }
                    }
            }
        } else {
            if (room.orderSets != null)
                for (let os of room.orderSets) {
                    if (os.orderSetItems != null)
                        for (var i = 0; i < os.orderSetItems.length; i++) {
                            if (os.orderSetItems[i] != null
                                && os.orderSetItems[i].sku != null
                                && os.orderSetItems[i].qty != null) {
                                //    console.log('notFrameOfMechWithFrame: ' + os.orderSetItems[i].sku.code + this.orderService.notFrameOfMechWithFrame(os.orderSetItems, i))
                                //console.log('notFrameOfMechWithFrame: '+'qty: ' + os.orderSetItems[i].qty + ':' + os.orderSetItems[i].sku.net);
                                if (this.orderService.notFrameOfMechWithFrame(os.orderSetItems, i))
                                    sum += os.orderSetItems[i].qty * os.orderSetItems[i].sku.net;
                            }
                        }
                    
                }
        }
        return this.roundToTwo(sum);
    }
    onScrollEvent(e) {

    }
    get groupedSkus() {
        console.log("summary summarising executing");
        let bag = new Array<BagElement>();
        for (let r of this.orderService.cProject.rooms) {
            if (r.orderSets != null)
                for (let os of r.orderSets)
                    if (os.orderSetItems != null)
                        for (var i = 0; i < os.orderSetItems.length; i++) {
                            let osi = os.orderSetItems[i];
                            if (osi != null
                                && osi.sku != null
                                && osi.qty != null && osi.qty > 0)
                                if (this.orderService.notFrameOfMechWithFrame(os.orderSetItems, i)) {

                                    let index = bag.findIndex(el => el.sku.code == osi.sku.code);
                                    if (index != null && index >= 0)
                                        bag[index].qty += osi.qty;
                                    else
                                        bag.push({ sku: osi.sku, qty: osi.qty });
                                }
                                    
                        }
            
        }
        console.log("summary summarising executed");
        return bag;
        
    }
    currency(a: number) {
        return this.roundToTwo(a);
    }

    roundToTwo(num: number) {
        return (Math.round(num * 100) / 100).toFixed(2).replace('.', ',');
    }
    
    downloadPdf() {
        document.getElementById('spinner').hidden = false;
        var opt = {
            margin: 1,
            filename: 'Zestawienie',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', orientation: 'p' },
            pagebreak: { mode: ['css'], }
        };
        var div = document.getElementById('b2cSummaryCanvas');
        div.hidden = false;
        html2pdf().set(opt).from(div).save().then(function (value) {
            console.log('Success');
            div.hidden = true;
            document.getElementById('spinner').hidden = true;
        });
    }


    numberOfFrames(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p!=null && p.sku != null && p.sku.slots > 0)
                            sum += p.qty * p.sku.slots;
                        //else if (p.sku.withFrame)
                        //    sum += 1;
                    }
            }
        return sum;
    }
    numberOfFunctions(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p!=null && p.sku != null && p.sku.slots == 0)
                            sum += p.qty;
                    }
            }
        return sum;
    }

    mouseEnterItem(rid, osid, osiid) {
        this.selectedRoom = rid;
        this.selectedOrderSet = osid;
        this.selectedOrderSetItem = osiid;
    }
    mouseLeaveItem() {
        this.selectedOrderSet = null;
        this.selectedOrderSetItem = null;
        this.selectedRoom = null;
    }
    hoverStyle(rid, osid, osiid) {
        if (this.selectedRoom == rid &&
            this.selectedOrderSet == osid &&
            this.selectedOrderSetItem == osiid) {
            return 'focused';
        } else
            return '';
    }
    public functionHeight(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        return osi && osi.sku && osi.sku.code &&
            ["SDD111122", "SDD112122", "SDD113122", "SDD114122"].includes(sku.code) ?
            "109px" : "100px";
        
    }

    public positionLeftPrValue(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        if (sku.code == 'S52P712')
            return this.sanitizer.bypassSecurityTrustStyle('0%');
        else if (sku.code == 'S52P742')
            return this.sanitizer.bypassSecurityTrustStyle('0%');
        else return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionLeftPx(sku, position + 1, osi.isVertical) / StylingHelper.frameLenPx(sku, osi.isVertical) * 100 + 0.01).toFixed(1).toString() + '%'
        );
    }
    public positionBottomPrValue(osi: OrderSetItem, position: number) {
        let sku = osi.sku;
        if (sku.code == 'S52P742')
            return this.sanitizer.bypassSecurityTrustStyle('25%');
        if (sku.code == 'S52P722')
            return this.sanitizer.bypassSecurityTrustStyle('25%');
        return this.sanitizer.bypassSecurityTrustStyle(
            (StylingHelper.positionBottomPx(sku, position + 1, osi.isVertical) / StylingHelper.frameLenPx(sku, osi.isVertical) * 100).toFixed(1).toString() + '%'
        );
    }

    dropableMarginsLeft(position: number) {
        let frame = this.orderService.actFrame();
        let osi = this.orderService.actOrderItem;
        console.log('dropableMargins: ' + position);
        return this.sanitizer.bypassSecurityTrustStyle(StylingHelper.positionLeftPr(frame, position, osi.isVertical));
    }

    dropableMarginsBottom(position: number) {
        let frame = this.orderService.actFrame();
        let osi = this.orderService.actOrderItem;
        console.log('dropableMargins: ' + position);
        return this.sanitizer.bypassSecurityTrustStyle(StylingHelper.positionBottomPr(frame, position, osi.isVertical));
    }
    

    forwardToEcommerce() {
        this.orderService.openEcommerce();
    }
    notFrameOfMechWithFrame(orderSetItems, i) {
        return this.orderService.notFrameOfMechWithFrame(orderSetItems, i);
    }

    logAndSubmit(shop: string, formId: string, orderId: string, orderService: OrderService) {
        event.preventDefault();
        orderService.updateOrder(orderId, "redirected");
        AppComponent.SendEventGa('cart', shop, "Otwarcie przekierowania do sklepu", 0);

        var form = <HTMLFormElement>document.getElementById(formId);
        form.submit();
    }
    public rotationStyle(actFrame, osi, actFunction) {
        let res = PresentationToolsComponent.rotationStyle(actFrame, osi, actFunction);
        console.log("cframe vertical - function code: " + actFrame.isVertical + "-" + actFunction.code);
        return res;
    }
    public frameSubDir(osi: OrderSetItem): string {
        if (osi != null && osi.sku != null && osi.sku.slots > 1 && osi.sku.universal && osi.isVertical)
            return "vertical/";
        else return '';
    }

    private layers(allCodes: string) {
        if (allCodes == null)
            return [];
        
        let layers = allCodes.split("+");

        for (var i = 0; i < layers.length; i++) {
            layers[i] = layers[i].trim();
        }
        return layers;
    }
    high_frames_codes = [1246]
    private frameheight(osi: OrderSetItem) {
        return osi && this.high_frames_codes.findIndex(aa => aa == osi.sku.functionType.id) >= 0 ?
            'hei150' : '';
    }
}

