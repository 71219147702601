import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Room } from '../../../_models/catalog';
import { DialogData } from '../../controls/project-name.component';
import { OrderService } from '../../../services/orders.service';

@Component({
    selector: 'colorselection-dialog',
    templateUrl: './colorselection.component.html',
    styleUrls: ['./colorselection.component.scss']
})
export class ColorSelectionDialogComponent {
    colors = [
        {
            'name': 'Kids Style', 'colors': [
                { 'name': 'F497 Paper', 'code': '#F2F2ED' },
                { 'name': 'J354 Daydream', 'code': '#8FB2D5' },
                { 'name': 'M350 Ink', 'code': '#414561' },
                { 'name': 'L385 Lime', 'code': '#868A50' },
                { 'name': 'K498 Haven', 'code': '#B9BFC4' },
                { 'name': 'F306 Sorbet', 'code': '#F6ECD1' },
                { 'name': 'M326 Marmalade', 'code': '#9E4247' },
                { 'name': 'Y370 Capri', 'code': '#C3E0D3' },
                { 'name': 'M316 Tomato', 'code': '#C85A47' },
                { 'name': 'M384 Basilica', 'code': '#6A7A47' },
                { 'name': 'N500 Agate', 'code': '#89929A' },
                { 'name': 'G401 Persimmon', 'code': '#F6D4B4' },
                { 'name': 'N429 Denim', 'code': '#485261' },
                { 'name': 'V389 Yellow Transparent', 'code': '#E1D998' },
                { 'name': 'J442 Menthol', 'code': '#96B2A4' },
                { 'name': 'X342 Hyacinth', 'code': '#C4B3D1' },
                { 'name': 'Y498 Indian Ink', 'code': '#3F4041' },
                { 'name': 'F336 Marshmallow', 'code': '#F5E3E2' },
                { 'name': 'Y354 Cumulus', 'code': '#C6D8E5' },
                { 'name': 'J383 Tinkerbell', 'code': '#BACD93' },
                { 'name': 'K385 Meadow', 'code': '#B3BB79' },
                { 'name': 'X370 Tiffany', 'code': '#A6D0C7' },
                { 'name': 'H336 Candyfloss', 'code': '#E7B6C9' },
                { 'name': 'Y441 Aquarelle', 'code': '#C4D5D5' }
            ]
        },
        {
            'name': 'Color Now 2019', 'colors': [
                { 'name': 'J356 Sky', 'code': '#88AFDA' },
                { 'name': 'J426 Lilac', 'code': '#B9ADB9' },
                { 'name': 'L433 Atlantis', 'code': '#5D7696' },
                { 'name': 'M354 Macaw', 'code': '#3D629F' },
                { 'name': 'G371 Crystal', 'code': '#D9EDE3' },
                { 'name': 'L373 Emerald', 'code': '#459370' },
                { 'name': 'M386 Amazon', 'code': '#73733F' },
                { 'name': 'N439 Abyss', 'code': '#57605E' },
                { 'name': 'F392 Cocoa butter', 'code': '#F2E6C1' },
                { 'name': 'S302 Grapefriut', 'code': '#EAC14E' },
                { 'name': 'L392 Ducat', 'code': '#BB9446' },
                { 'name': 'L396 Beeswax', 'code': '#BE8E59' },
                { 'name': 'H320 Magnolia', 'code': '#EEBEB3' },
                { 'name': 'L418 Nectar', 'code': '#A2595B' },
                { 'name': 'K319 Flamingo', 'code': '#E68079' },
                { 'name': 'M319 Reef', 'code': '#B74B42' }
            ]
        },
        {
            'name': 'Deco Grey', 'colors': [
                { 'name': '1904', 'code': '#9B998B' },
                { 'name': '1908', 'code': '#D9D6CE' },
                { 'name': '1912', 'code': '#867F75' },
                { 'name': '1916', 'code': '#C8C2BC' },
                { 'name': '1919', 'code': '#7F7B77' },
                { 'name': '1923', 'code': '#C9C8C2' },
                { 'name': '1929', 'code': '#EBEBE7' },
                { 'name': '1934', 'code': '#5D6060' },
                { 'name': '1938', 'code': '#A8ABA8' },
                { 'name': '1945', 'code': '#A8A7A5' },
                { 'name': '1948', 'code': '#5F5F5F' },
                { 'name': '1950', 'code': '#E8E9E7' },
                { 'name': '1952', 'code': '#B4B3B1' },
                { 'name': '1957', 'code': '#E2E0E1' },
                { 'name': '1959', 'code': '#B4B0B2' },
                { 'name': '1961', 'code': '#6E6B6D' },
                { 'name': '1964', 'code': '#DDE1E1' },
                { 'name': '1967', 'code': '#8D9194' }

            ]
        }
    ];
    orderService: OrderService;
	
    setColor(colorCode: string) {
        //console.log('orderService: ' + this.orderService.instance);
        //this.orderService.setWallColor(colorCode);
        this.dialogRef.close(colorCode);
    }
    constructor(
        public dialogRef: MatDialogRef<ColorSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public os: OrderService) {
        this.orderService = os;
        }
    

    onNoClick(): void {
        this.dialogRef.close(null);
    }

    onSubmitClick() {
        this.dialogRef.close();
    }
    
}
