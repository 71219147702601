import { Component, Input } from '@angular/core';
import { OrderService } from '../../services/orders.service';
import { ProjectNameDialog } from '../controls/project-name.component';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Project, Room, OrderSet } from '../../_models';
import { Router } from '@angular/router';
import { B2BNewProjectDialog } from './controls/new-project-dialog.component';
import { MyStepper } from './config.component';
import { AppComponent } from '../app.component';
import { GoogleAnalyticsService } from 'angular-ga';

@Component({
    selector: 'b2b-project-selection',
    templateUrl: './project-selection.component.html',
    styleUrls: ['./project-selection.component.scss']
})
/** ProjectSelection component*/
export class B2BProjectSelectionComponent {
    projectSelected: number = null;
    /** ProjectSelection ctor */
    constructor( public orderService: OrderService, public dialog: MatDialog, public router: Router) {
        (<any>window).ga('set', 'page', '/szybka-konfiguracja#projekty');
        (<any>window).ga('send', 'pageview');
    }
    selectRooms = false;
    openProject(i: number) {
        
        AppComponent.SendEventGa('navigation', 'button', 'B2B_Otwarcie projektu',i);
        //let j = this.orderService.projects.length - i - 1;
        let j = i;
        if (this.orderService.projects[j].rooms != null && this.orderService.projects[j].rooms.length > 1) {
            this.orderService.openProject(j);
            this.selectRooms = true;
        } else {
            this.orderService.openProject(j);
            
        }
        this.nextStep();
    }
    nextStep() {
        this.stepper.next();
        AppComponent.SendEventGa('navigation', 'sets-configuration', 'B2C_Następny krok', this.stepper.step);
    }
    incStep(i: number) {
        AppComponent.SendEventGa('navigation', 'sets-configuration', 'B2C_Następny krok', this.stepper.step+i);
        this.stepper.incStep(i);
    }
    close() {

    }
    changeProject(i: number): void {
        AppComponent.SendEventGa('navigation', 'button', 'B2B_Wybór projektu', i);
        //let j = this.orderService.projects.length - i - 1;
        let j = i;
        const dialogRef = this.dialog.open(ProjectNameDialog, {
            width: '250px',
            data: { projectName: this.orderService.projects[j].title }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result != null) {
                this.orderService.projects[j].title = result;
                this.orderService.saveChangesProjectById(j);
                //if (i == this.projectSelected)
                //    this.orderService.setProjectName(result);

            }
        });

        //this.orderService.saveCurrentContext();
        this.orderService.saveCurrentProject();
    }
    duplicateProjekt(i: number) {
        AppComponent.SendEventGa('operation', 'button', 'B2B_skopiowanie projektu', i);
        //let j = this.orderService.projects.length - i - 1;
        let j = i;

        let newRooms = new Array<Room>();
        for (let r of this.orderService.projects[j].rooms) {
            newRooms.push(this.orderService.duplicateRoomObject(r));
        }
        let newProject: Project = {
            title: this.orderService.projects[j].title,
            rooms: this.orderService.projects[j].rooms,
            type:0
        }
        this.orderService.addProject(newProject);
    }
    deleteProject(i: number) {
        AppComponent.SendEventGa('operation', 'button', 'B2B_usunięcie projektu', i);
        //let j = this.orderService.projects.length - i - 1;
        let j = i;
        this.orderService.delProject(j);
    }
    newProjectConfig() {
        //this.gaService.event.emit({ category: 'operation', action: 'click', label: 'new-project-numer'});
        AppComponent.SendEventGa('new-project', null, 'B2B_Otwarcie konfiguracji projektu z menu projektów',0);
        B2BNewProjectDialog.newProjectConfig(this);
    }


    @Input() stepper: MyStepper;
    selectedTab = 1;

    roundToTwo(num: number) {
        return Math.round(num * 100) / 100;
    }
    countAllProject(project: Project) {
        console.log("count project: " + project.title);
        if (project == null)
            project = this.orderService.cProject;
        let count = 0;
        let sum = 0;
        if (project.rooms != null) for (let r of project.rooms) {
            if (r.orderSets != null)
                for (let os of r.orderSets) {
                    if (os.orderSetItems != null)
                        for (let p of os.orderSetItems) {
                            sum += p.qty * p.sku.net;
                            count += p.qty
                        }
                }
        }
        return [(Math.round(sum * 100) / 100).toFixed(2).replace('.',','), count];
    }
    fromEnd(ps:Project[]) {
        ps.sort();
    }
}
