<div style="display:flex;flex-direction:column">
    <div mat-dialog-content>
        <a href="{{data.Url}}">
            <div class="sku-image">
                <img src="/images/icons/{{data.Color}}.png" />
            </div>
            <div>{{data.Label}}</div>
        </a>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button class="cancel-button" >Zamknij</button>
    </div>
</div>
