
    <div class="b2c-content-wrapper">
        <div class="summary-row">

            <!--div class="summary-summary big-screen">
                <div class="summary-set-img"><img src="/images/icons/kaska.png" /></div>
                <div class="summary-set-title">{{sumNet(null)}}&nbsp;zł</div>
            </div-->
            <ul class="nav nav-tabs" id="summary-tabs">
                <li class="{{activeTab(0)}}" (click)="layout=0">Komplety</li>
                <li class="{{activeTab(1)}}" (click)="layout=1">Lista produktów</li>

            </ul>
        </div>
        <!-- big screen table summary -->
        <div class="tab-content-summary-global big-table" style="padding: 24px;" *ngIf="layout == 1">
            <perfect-scrollbar class="scroll-container" fxFlex="auto" [scrollIndicators]="true"
                               (psXReachEnd)="onScrollEvent($event)" (psYReachEnd)="onScrollEvent($event)" (psXReachStart)="onScrollEvent($event)" (psYReachStart)="onScrollEvent($event)">

                <div class="summary-table" *ngIf="groupedSkus == null || groupedSkus.length == 0">
                    Brak produktów w zestawieniu.
                </div>
                <div class="summary-table" *ngIf="groupedSkus != null && groupedSkus.length>0">
                    <div class="summary-row-wrapper">
                        <div class="summary_header" style="height:inherit;">Produkt</div>
                        <div class="summary_header" style="height:inherit;text-align: center;" title="Numer referencyjny produktu">Nr ref.</div>
                        <div class="summary_header" style="height:inherit;text-align: center;">Seria</div>
                        <div class="summary_header" style="height:inherit;text-align: center;">Kolor</div>
                        <div class="summary_header" style="height:inherit;text-align: center;">Podgląd</div>
                        <div class="summary_header" style="height:inherit;text-align: center;">Karta&nbsp;kat.</div>
                        <div title="cena katalogowa netto wg cennika" class="summary_header" style="height:inherit;text-align: center;">Cena netto*</div>
                        <div class="summary_header" style="height:inherit;text-align: center;">Ilość</div>
                        <div class="summary_header" style="height:inherit;text-align: center;">Wartość netto</div>
                    </div>
                    <div class="summary-row-wrapper{{i%2}}"
                         *ngFor="let el of groupedSkus;let i = index;">
                        <div class="summary-label" style="text-align: left;">
                            {{el.sku.info}}
                            <span style="color:red;" *ngIf="el.sku.withFrame">z&nbsp;ramką</span>
                        </div>
                        <div class="summary-label">
                            {{el.sku.code}}
                        </div>
                        <div class="seriesInSummary summary-label" title="Zmień serię">
                            {{el.sku.series.title}}
                        </div>
                        <div class="colorInSummary summary-label" title="Zmień kolor">{{el.sku.color.title}}</div>


                        <div class="summary-label  summary-label-icon-big summary-label-clickable summary-label-right"
                             style="padding:0px;" title="Podgląd produktu">
                            <img *ngFor="let layer of layers(el.sku.code)" src="/images/seriesorg/{{el.sku.series.title}}/{{layer}}.png" />
                        </div>
                        <div class="summary-label  summary-label-icon summary-label-icon-small"
                             title="Pobierz kartę katalogową">
                            <a href="/api/FileExport/card/{{el.sku.series.title}}/{{el.sku.code}}"  download style="width:inherit;" class="{{isDisabledCardLink(el.sku)}}">
                                <img src="/images/icons/download.png" />
                            </a>
                        </div>


                        <div title="cena katalogowa netto wg cennika" style="width: 60px;" class="summary-label">
                            {{currency(el.sku.net)}}&nbsp;zł
                        </div>

                        <div class="function-input-wrapper">
                            {{el.qty}}
                        </div>
                        <div title="suma wg ceny katalogowej netto" style="width: 60px;" class="summary-label">
                            {{currency(el.sku.net*el.qty)}}&nbsp;zł
                        </div>

                    </div>

                </div>
                <div class="red-note">Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego. Konfigurator nie jest sklepem internetowym, Użytkownik może dokonać zakupów w sklepach internetowych i stacjonarnych partnerów Schneider Electric.</div>
            </perfect-scrollbar>
        </div>
        <!-- small screen table summary -->
        <div class="tab-content-summary-global small-table" style="padding: 24px;" *ngIf="layout == 1">
            <perfect-scrollbar class="scroll-container" fxFlex="auto" [scrollIndicators]="true"
                               (psXReachEnd)="onScrollEvent($event)" (psYReachEnd)="onScrollEvent($event)" (psXReachStart)="onScrollEvent($event)" (psYReachStart)="onScrollEvent($event)">

                <div class="summary-table" *ngIf="groupedSkus == null || groupedSkus.length == 0">
                    Brak produktów w zestawieniu.
                </div>
                <div class="summary-table" *ngIf="groupedSkus != null && groupedSkus.length>0">
                    <div class="summary-row-wrapper"
                         *ngFor="let el of groupedSkus;let i = index;">
                        <div class="main-group">
                            <div class="summary-small-image">
                                <img *ngFor="let layer of layers(el.sku.code)" src="/images/seriesorg/{{el.sku.series.title}}/{{layer}}.png" />
                            </div>
                            <div class="main-group-1">
                                <div class="function-label ">
                                    {{el.sku.info}}
                                    <span style="color:red;" *ngIf="el.sku.withFrame">z&nbsp;ramką</span>
                                </div>

                            </div>
                        </div>
                        <div class="summary-group">
                            <div class="info-label">Numer referencyjny</div>
                            <div class="function-label code" title="Numer referencyjny produktu">
                                {{el.sku.code}}
                            </div>
                        </div>
                        <div class="summary-group">
                            <div class="info-label">Seria</div>
                            <div class="seriesInSummary summary-label" title="Seria">
                                {{el.sku.series.title}}
                            </div>
                        </div>
                        <div class="summary-group">
                            <div class="info-label">Kolor</div>
                            <div class="colorInSummary summary-label" title="Kolor">
                                {{el.sku.color.title}}
                            </div>
                        </div>
                        <div class="summary-group">
                            <div class="info-label">Karta katalogowa</div>
                            <div class="function-label  function-label-icon function-label-icon-small"
                                 title="Pobierz kartę katalogową" style="display:flex;flex-direction:row">
                                <a href="/api/FileExport/card/{{el.sku.series.title}}/{{el.sku.code}}" download style="width:100%;" class="{{isDisabledCardLink(el.sku)}}">
                                    <img src="/images/icons/download-darkgray.png" />
                                </a>
                            </div>
                        </div>
                        <div class="summary-group">
                            <div class="info-label">Ilość</div>
                            <div class="summary-group-nowrap" style="padding-left: 4px;">
                                {{el.qty}}
                            </div>
                        </div>
                        <div class="summary-group">
                            <div class="info-label" style="font-weight:700;color:#3dcd58;">Suma</div>
                            <div title="suma wg ceny katalogowej netto" class="function-label font"
                                 style="font-weight:700;color:#3dcd58;">
                                {{currency(el.sku.net*el.qty)}}&nbsp;zł
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="red-note">Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego. Konfigurator nie jest sklepem internetowym, Użytkownik może dokonać zakupów w sklepach internetowych i stacjonarnych partnerów Schneider Electric.</div>
            </perfect-scrollbar>
        </div>
        <!-- any screen sets in summary -->
        <div class="tab-content-summary" *ngIf="layout == 0">
            <perfect-scrollbar class="scroll-container" fxFlex="auto" [scrollIndicators]="true"
                               (psXReachEnd)="onScrollEvent($event)" (psYReachEnd)="onScrollEvent($event)" (psXReachStart)="onScrollEvent($event)" (psYReachStart)="onScrollEvent($event)">
                <div class="summary-table" *ngIf="groupedSkus == null || groupedSkus.length == 0">
                    Brak produktów w zestawieniu.
                </div>
                <div class="b2b-summary-rooms" *ngIf="orderService.cProject != null">
                    <mat-expansion-panel *ngFor="let room of orderService.cProject.rooms;let k=index;" class="b2c-summary-room"
                                         [expanded]="orderService.actRoomIndex == k">
                        <mat-expansion-panel-header class="summary-room-title">
                            <mat-panel-title>
                                <span style="font-weight:400">Pomieszczenie:&nbsp;</span>
                                <span class="bolded-green">{{room.title}}</span>
                            </mat-panel-title>
                            <mat-panel-description *ngIf="room.orderSets != null">
                                Ilość miejsc w ramkach:&nbsp;<b>{{numberOfFrames(room)}}</b>, ilość mechanizmów:&nbsp;<b>{{numberOfFunctions(room)}}</b>.
                                Suma netto*:&nbsp;<b>{{sumNet(room)}}&nbsp;zł</b>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="summary-collection" *ngIf="room.orderSets != null">
                            <div *ngFor="let orderSet of room.orderSets;let j=index;"
                                 class="b2c-summary-set {{classOfOrderSet(orderSet)}}">
                                <div class="summary-set-desc summary-bottom-separator"
                                     *ngIf="orderSet.orderSetItems[0] == null">
                                    Brak zestawów w pomieszczeniu.
                                </div>
                                <div class="summary-set-desc summary-bottom-separator"
                                     *ngIf="orderSet.orderSetItems[0] != null">

                                    <div class="summary-setimage {{minWidth}}" *ngIf="orderSet.orderSetItems[0] != null">

                                        <div class="summary-setimage-content">
                                            <div class="summary-setimage-content-col {{frameheight(orderSet.orderSetItems[1])}}">
                                                <div class="b2c-summary-frame">
                                                    <img *ngIf="orderSet.orderSetItems[0].qty>0" src="/images/series/{{orderSet.orderSetItems[0].sku.series.title}}/frames/{{frameSubDir(orderSet.orderSetItems[0])}}{{orderSet.orderSetItems[0].sku.code}}.png" />
                                                    <img *ngIf="orderSet.orderSetItems[0].qty<=0" style="opacity:0" src="/images/series/{{orderSet.orderSetItems[0].sku.series.title}}/frames/{{orderSet.orderSetItems[0].sku.code}}.png" />

                                                </div>
                                                <div class="b2c-summary-function function_{{i}} {{hoverStyle(k,j,i+1)}} {{frameheight(orderSet.orderSetItems[1])}}"
                                                     *ngFor="let osi of orderSet.orderSetItems.slice(1);let i=index"
                                                     (mouseenter)="mouseEnterItem(k,j,i+1)" (mouseleave)="mouseLeaveItem()"
                                                     [style.height]="functionHeight(orderSet.orderSetItems[0],i)"
                                                     [style.left]="positionLeftPrValue(orderSet.orderSetItems[0],i)"
                                                     [style.bottom]="positionBottomPrValue(orderSet.orderSetItems[0],i)">
                                                    <!--[style.left]="positionLeftPrValue(i)"-->
                                                    <!--*ngIf="osi!=null && osi.sku!=null && osi.qty>-1" -->
                                                    <img *ngFor="let layer of layers(functionPositionSelectedImage(osi))"
                                                         src="/images/series/{{osi.sku.series.title}}/functions/{{layer}}.png"
                                                         class="{{rotationStyle(orderSet.orderSetItems[0].sku,orderSet.orderSetItems[0],osi.sku)}}" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="summary-description-wrapper">
                                        <div class="summary-number-row">
                                            <div class="summary-desc-col-names">
                                                <div class="summary-set-title">
                                                    <div class="summary-set-title-left">
                                                        Seria
                                                        <span style="font-weight:700">{{orderSet.orderSetItems[0].sku.series.title}}</span>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="summary-desc-col-numbers">
                                                <div class="summary-set-title">
                                                    <div class="summary-set-title-left">
                                                        <div class="summary-set-action" (click)="editOrderSet(k,j)"><img src="/images/icons/edytuj-light-gray.png" /></div>
                                                        <div class="summary-set-action" (click)="deleteOrderSet(k,j)"><img src="/images/icons/usun-light-gray.png" /></div>
                                                        <div class="summary-set-action" (click)="duplicateOrderSet(k,j)"><img src="/images/icons/kopiuj-light-gray.png" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="summary-number-column">
                                            <div class="summary-set-description {{hoverStyle(k,j,i)}}"
                                                 *ngFor="let osi of orderSet.orderSetItems;let i=index"
                                                 (mouseenter)="mouseEnterItem(k,j,i)" (mouseleave)="mouseLeaveItem()">
                                                <div class="flex-full-stretched" *ngIf="notFrameOfMechWithFrame(orderSet.orderSetItems,i) ">
                                                    <div class="summary-set-desc-list" *ngIf="osi != null && osi.qty > 0 && osi.sku != null" style="display:flex;flex-direction:column;">
                                                        <div class="summary-desc-row" style="display:flex;">
                                                            <div class="summary-set-desc summary-set-bold">{{osi.sku.functionType.title}}<div *ngIf="osi.sku.withFrame" style="color:red">&nbsp;z ramką</div></div>
                                                        </div>
                                                        <div class="summary-desc-row">
                                                            <div class="summary-set-desc">kolor {{osi.sku.color.title}}</div>
                                                            <!--<div class="summary-set-action" *ngIf="i!=0" (click)="delOrderSetItem(k,j,i)">
                                                <img src="/images/icons/usun-light-gray.png" />
                                            </div>
                                                -->
                                                        </div>
                                                    </div>
                                                    <div class="summary-set-desc-list" *ngIf="osi != null && osi.qty > 0 && osi.sku != null"
                                                         style="display:flex;flex-direction:column;width: 103px;">
                                                        <div class="summary-desc-row" style="display:flex;justify-content: flex-end;">
                                                            <div class="summary-set-desc" title="Cena katalogowa netto:">
                                                                <span class="bold-font">{{currency(osi.sku.net)}}</span><span>&nbsp;zł</span>
                                                            </div>
                                                        </div>
                                                        <div class="summary-desc-row">
                                                            <div class="summary-set-desc">&nbsp;</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="summary-set-title-left font14" style="text-align: end;">
                                                <span class="font14">Suma:&nbsp;</span>
                                                <span class="bolded-green">{{sumOfOrderSet(orderSet)}}</span>
                                                <span class="green">&nbsp;zł</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="summary-collection" *ngIf="room.orderSets == null">
                            Brak zestawów w pomieszczeniu.
                        </div>
                    </mat-expansion-panel>

                    <div class="red-note">Prezentowane ceny stanowią ceny katalogowe Schneider Electric i mają na celu oszacowanie wartości koszyka, nie stanowią jednak oferty w rozumieniu kodeksu cywilnego. Konfigurator nie jest sklepem internetowym, Użytkownik może dokonać zakupów w sklepach internetowych i stacjonarnych partnerów Schneider Electric.</div>
                </div>


            </perfect-scrollbar>
        </div>

        <!-- bottom table summary -->
        <div class="summary-row-right small-button">

            <div class="summary-summary fifty-bottom-button">
                <div class="summary-set-img"><img src="/images/icons/kaska.png" /></div>
                <div class="summary-set-title">Suma:&nbsp;{{sumNet(null)}}&nbsp;zł</div>
            </div>

            <div class="fifty-bottom-button small-button">
                <button *ngIf="orderService.ecommerceEndpooint == null" mat-button
                        class="accept-button-green-standard"
                        (click)="forwardToEcommerce()">
                    Kup
                </button>

                <form *ngIf="orderService.ecommerceEndpooint != null" ngNoForm
                      action="{{orderService.ecommerceEndpooint}}" target="_blank"
                      id="{{'form_' + orderService.eCommerceName}}"
                      (submit)="logAndSubmit(
                orderService.eCommerceName,
                'form_' + orderService.eCommerceName,
                orderService.orderIdWithPrefix(orderService.eCommercePrefix),
                orderService
              )"
                      class="b2c-fullwidth-green"
                      method="{{orderService.ecommerceMethod}}">
                    <input type="hidden" name="request" value="{{orderService.orderString}}" />
                    <input class="big-button" type="submit" value="Kup w sklepie">
                    <input class="small-button" type="submit" value="Kup w sklepie">
                </form>
            </div>
        </div>
    </div>
<!-- PDF export -->
<div id="b2cSummaryCanvas" hidden>
    <div class="e-pdf-header">

        <div class="e-pdf-logo-wrapper">
            <div class="e-pdf-logo">
                <img src="/images/logo-white2.png" />
            </div>
        </div>
        <div class="e-pdf-text">
            <span>
                ul. Konstruktorska 12
            </span>
            <span>
                02-673 Warszawa
            </span>
            <span>
                www.se.com/pl
            </span>
            <span>
                www.dobierz-gniazdko.pl
            </span>
        </div>
        <div class="e-pdf-text">
            <span>
                Cetrum Obsługi Klienta
            </span>
            <div style="display:flex;flex-direction:row">
                <div>
                    Infolinia:
                </div>
                <div style="display:flex;flex-direction:column">
                    <div>
                        &nbsp; +48 22 511 84 64
                    </div>
                    <div>
                        &nbsp; +48 801 171 500
                    </div>
                </div>
            </div>
            <span>
                e-mail: poland.helpdesk@se.com
            </span>
        </div>
    </div>

    <div class="b2b-summary-rooms" style="break-inside:auto" *ngIf="orderService.cProject != null">
        <div class="version-note">Numer zamówienia: {{orderService.cartId}} {{orderService.timestamp}}</div>
        <div *ngFor="let room of orderService.cProject.rooms;let k=index;" class="b2c-summary-room">
            <div class="summary-room-title">
                <span style="font-weight:400">Pomieszczenie:&nbsp;</span>
                <span class="bolded-green">{{room.title}}</span>
                <span *ngIf="room.orderSets != null" style="font-weight:400">
                    ilość miejsc w ramkach:&nbsp;<b>{{numberOfFrames(room)}}</b>, ilość mechanizmów:&nbsp;<b>{{numberOfFunctions(room)}}</b>.
                    Suma netto*:&nbsp;<b>{{sumNet(room)}}&nbsp;zł</b>
                </span>
            </div>
            <div class="summary-collection" *ngIf="room.orderSets != null">
                <div *ngFor="let orderSet of room.orderSets;let j=index;" style="break-inside:avoid;width:700px"
                     class="b2c-summary-set {{classOfOrderSet(orderSet)}}">

                    <div class="summary-set-desc summary-bottom-separator"
                         *ngIf="orderSet.orderSetItems[0] != null">

                        <div class="summary-setimage {{minWidth}}" *ngIf="orderSet.orderSetItems[0] != null">

                            <div class="summary-setimage-content">
                                <div class="summary-setimage-content-col {{frameheight(orderSet.orderSetItems[1])}}">
                                    <div class="b2c-summary-frame">
                                        <img *ngIf="orderSet.orderSetItems[0].qty>0" src="/images/series/{{orderSet.orderSetItems[0].sku.series.title}}/frames/{{frameSubDir(orderSet.orderSetItems[0])}}{{orderSet.orderSetItems[0].sku.code}}.png" />
                                        <img *ngIf="orderSet.orderSetItems[0].qty<=0" style="opacity:0" src="/images/series/{{orderSet.orderSetItems[0].sku.series.title}}/frames/{{frameSubDir(orderService.actOrderItem)}}{{orderSet.orderSetItems[0].sku.code}}.png" />
                                    </div>
                                    <div class="b2c-summary-function function_{{i}} {{frameheight(osi)}}"
                                         *ngFor="let osi of orderSet.orderSetItems.slice(1);let i=index"
                                         [style.left]="positionLeftPrValue(orderSet.orderSetItems[0],i)"
                                         [style.height]="functionHeight(orderSet.orderSetItems[0],i)"
                                         [style.bottom]="positionBottomPrValue(orderSet.orderSetItems[0],i)">
                                        <div *ngIf="osi && osi.sku && osi.qty>-1" class="inherit-size">
                                            <img *ngFor="let layer of layers(osi.sku.code)" src="/images/series/{{osi.sku.series.title}}/functions/{{layer}}.png"
                                                 class="{{rotationStyle(orderSet.orderSetItems[0].sku,orderSet.orderSetItems[0],osi.sku)}}" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="summary-description-wrapper">
                            <div class="summary-desc-col-names">
                                <div class="summary-set-title">
                                    <div class="summary-set-title-left">
                                        Seria
                                        <span style="font-weight:700">{{orderSet.orderSetItems[0].sku.series.title}}</span>
                                    </div>
                                </div>

                                <div class="summary-set-description"
                                     *ngFor="let osi of orderSet.orderSetItems;let i=index">
                                    <div class="summary-set-desc-list"
                                         *ngIf="osi != null && osi.qty>0 && osi.sku != null && notFrameOfMechWithFrame(orderSet.orderSetItems,i)"
                                         style="display:flex;flex-direction:column;">
                                        <div class="summary-desc-row" style="display:flex;">
                                            <div class="summary-set-desc summary-set-bold">
                                                {{osi.sku.functionType.title}}
                                                <div *ngIf="osi.sku.withFrame" style="color:red">&nbsp;z ramką</div>
                                            </div>
                                        </div>
                                        <div class="summary-desc-row">
                                            <div class="summary-set-desc">kolor {{osi.sku.color.title}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="summary-desc-col-numbers">
                                <div class="summary-set-title" style="display: flex;justify-content: flex-end;">
                                    <div class="bold-font">
                                        Cena
                                    </div>
                                </div>
                                <div class="summary-set-description" *ngFor="let osi of orderSet.orderSetItems;let i=index">
                                    <div class="summary-set-desc-list"
                                         *ngIf="osi != null && osi.qty>0 && osi.sku != null && notFrameOfMechWithFrame(orderSet.orderSetItems,i)"
                                         style="display:flex;flex-direction:column;">
                                        <div class="summary-desc-row" style="display:flex;justify-content: flex-end;">
                                            <div class="summary-set-desc" title="Cena katalogowa netto:">
                                                <span class="bold-font">{{currency(osi.sku.net)}}</span><span>&nbsp;zł</span>
                                            </div>
                                        </div>
                                        <div class="summary-desc-row">
                                            <div class="summary-set-desc">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="summary-set-title-left" style="text-align: end;">
                                    <span class="font14">Suma:&nbsp;</span>
                                    <span class="bolded-green">{{sumOfOrderSet(orderSet)}}</span>
                                    <span class="green">&nbsp;zł</span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div class="summary-collection" *ngIf="room.orderSets == null">
                Brak zestawów w pomieszczeniu.
            </div>
        </div>


        <div class="html2pdf__page-break"></div>
        <div class="summary-table">
            <div class="summary-row-wrapper">
                <div class="summary_header" style="width:1%;height:inherit;"></div>
                <div class="summary_header" style="width:20%;height:inherit;">Produkt</div>
                <div class="summary_header" style="width:14%;height:inherit;text-align: center;" title="Numer referencyjny produktu">Nr ref.</div>
                <div class="summary_header" style="width:15%;height:inherit;text-align: center;">Seria</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Kolor</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Podgląd</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Cena netto*</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Ilość</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Wartość netto</div>
            </div>
            <div class="summary-row-wrapper{{i%2}}"
                 *ngFor="let el of groupedSkus;let i = index;">
                <div class="summary-label" style="width:1%;text-align: left;">

                </div>
                <div class="summary-label" style="width:20%;text-align: left;">
                    {{el.sku.info}}
                    <span style="color:red;" *ngIf="el.sku.withFrame">z&nbsp;ramką</span>
                </div>
                <div class="summary-label" style="width:14%;">
                    {{el.sku.code}}
                </div>
                <div class="seriesInSummary summary-label" title="Zmień serię" style="width:15%">
                    {{el.sku.series.title}}
                </div>
                <div class="colorInSummary summary-label" style="width:10%;">{{el.sku.color.title}}</div>


                <div class="summary-label  summary-label-icon-big summary-label-clickable summary-label-right"
                     style="width:10%;padding:0px;" title="Podgląd produktu">
                    <img *ngFor="let layer of layers(el.sku.code)" src="/images/seriesorg/{{el.sku.series.title}}/{{layer}}.png" />
                </div>
                <div title="cena katalogowa netto wg cennika" style="width:10%;" class="summary-label">
                    {{currency(el.sku.net)}}&nbsp;zł
                </div>

                <div class="summary-label" style="width:10%;">
                    {{el.qty}}
                </div>
                <div title="suma wg ceny katalogowej netto" style="width:10%;" class="summary-label">
                    {{currency(el.sku.net*el.qty)}}&nbsp;zł
                </div>

            </div>



        </div>

        <div class="summary-table">
            <div class="summary-row-wrapper">
                <div class="summary-label" style="width:90%;text-align: left;">
                    SUMA
                </div>
                <div title="cena katalogowa netto wg cennika" class="summary-label">
                    {{sumNet(null)}}&nbsp;zł
                </div>


            </div>
        </div>
        <div class="e-pdf-tablehint">* Ceny katalogowe netto wg cennika</div>


        <div class="html2pdf__page-break" *ngIf="orderService.containsComplexSkus"></div>
        <div class="summary-room-title"  *ngIf="orderService.containsComplexSkus && orderService.unitProjectSkus != null;">
            <span class="bolded-green">Pojedyncze produkty - zestawienie</span>
        </div>
        <div class="summary-table" *ngIf="orderService.containsComplexSkus && orderService.unitProjectSkus != null">
            <div class="summary-row-wrapper">
                <div class="summary_header" style="width:1%;height:inherit;"></div>
                <div class="summary_header" style="width:20%;height:inherit;">Produkt</div>
                <div class="summary_header" style="width:14%;height:inherit;text-align: center;" title="Numer referencyjny produktu">Nr ref.</div>
                <div class="summary_header" style="width:15%;height:inherit;text-align: center;">Seria</div>
                <!--div class="summary_header" style="width:10%;height:inherit;text-align: center;">Kolor</div-->
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Podgląd</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Cena netto*</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Ilość</div>
                <div class="summary_header" style="width:10%;height:inherit;text-align: center;">Wartość netto</div>
            </div>
            <div class="summary-row-wrapper{{i%2}}"
                 *ngFor="let el of orderService.unitProjectSkus;let i = index;">
                <div class="summary-label" style="width:1%;text-align: left;">

                </div>
                <div class="summary-label" style="width:20%;text-align: left;">
                    {{el.sku.info}}
                    <span style="color:red;" *ngIf="el.sku.withFrame">z&nbsp;ramką</span>
                </div>
                <div class="summary-label" style="width:14%;">
                    {{el.sku.code}}
                </div>
                <div class="seriesInSummary summary-label" title="Zmień serię" style="width:15%">
                    {{el.sku.series.title}}
                </div>
                <!--
                <div class="colorInSummary summary-label" style="width:10%;">{{el.sku.color.title}}</div>
-->

                <div class="summary-label  summary-label-icon-big summary-label-clickable summary-label-right"
                     style="width:10%;padding:0px;" title="Podgląd produktu">
                    <img *ngFor="let layer of layers(el.sku.code)" src="/images/seriesorg/{{el.sku.series.title}}/{{layer}}.png" />
                </div>
                <div title="cena katalogowa netto wg cennika" style="width:10%;" class="summary-label">
                    {{currency(el.sku.net)}}&nbsp;zł
                </div>

                <div class="summary-label" style="width:10%;">
                    {{el.qty}}
                </div>
                <div title="suma wg ceny katalogowej netto" style="width:10%;" class="summary-label">
                    {{currency(el.sku.net*el.qty)}}&nbsp;zł
                </div>

            </div>



        </div>
    </div>

    <div id="spinner" hidden>
        <mat-spinner class="summary-set-spinner"></mat-spinner>
    </div>
</div>

