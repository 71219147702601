import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService, AlertService } from '../../_services/index';
import { AppComponent } from "../app.component";


@Component({
    selector: "app-register-sent",
    templateUrl: './register-sent.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService, AlertService]
})

export class AccountRegisterSentComponent implements OnInit {
    
    ngOnInit(): void {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_wysłanie zlecenia', 0);
        const parameters = new URLSearchParams(window.location.search);
    }
    model: any = {};

    isLoading = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) {
    }
    message = "Konto zostało pomyślnie założone. Wejdź na mail podany podczas rejestracji i kliknij w link, aby aktywować konto.";
    
    signIn() {
        this.router.navigateByUrl('/logowanie');
    }

    signUp() {

        this.router.navigateByUrl('/rejestracja');
    }
}
