<div class="admin-panel">
    <div class="row control-box">
        <div class="col-sm-8">
            <div class="form-group search-box">
                <search-box (searchChange)="onSearchChanged($event)" placeholder="Wyszukaj">></search-box>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="navbar action-box">
                <ul class="nav navbar-nav navbar-right">
                    <li *ngIf="canManageUsers && canAssignRoles" class="toolbaritem">
                        <a href="javascript:;" (click)="newUser(row)">
                            <i class="fa fa-plus-circle"></i> Nowy
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
    <ngx-datatable class="material colored-header sm table table-striped table-hover"

                   [rows]="rows"
                   [rowHeight]="35"
                   [headerHeight]="35"
                   [footerHeight]="35"
                   [columns]="columns"
                   [scrollbarV]="true"
                   [columnMode]="'force'">
    </ngx-datatable>
        <!--
    <table mat-table [dataSource]="rows">
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let rows; columns: columns"></tr>
    </table>
        -->
    <ng-template #indexTemplate let-value="value">
        <strong>{{value}}</strong>
    </ng-template>

    <ng-template #userNameTemplate let-row="row" let-value="value">
        <span [class.locked-out]="row.isLockedOut" [class.user-disabled]="!row.isEnabled">
            <i *ngIf="row.isLockedOut" class="fa fa-exclamation-triangle"> </i>
            <i *ngIf="!row.isEnabled" class="fa fa-exclamation"> </i>
            {{value}}
        </span>
    </ng-template>

    <ng-template #rolesTemplate let-row="row" let-value="value" let-i="index">
        <span class="user-role badge" *ngFor="let role of value">{{role}}</span>
    </ng-template>

    <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
        <div *ngIf="canManageUsers">
            <a class="btn btn-link btn-xs" href="javascript:;" (click)="editUser(row)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edytuj</a>
            |
            <a class="btn btn-link btn-xs" href="javascript:;" (click)="deleteUser(row)"><i class="fa fa-trash-o" aria-hidden="true"></i> Usuń</a>
        </div>
    </ng-template>


    <div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left"><i class="fa fa-user-circle-o"></i> {{editingUserName ? "Edytuj" : "Nowy"}}</h4>

                    <!--<h4 class="modal-title pull-left"><i class="fa fa-user-circle-o"></i> {{editingUserName ? ('users.management.EditUser':editingUserName) : ('users.management.NewUser')}}</h4>
                    -->
                    <button type="button" class="close pull-right" title="Close" (click)="editorModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <user-info #userEditor [isGeneralEditor]="true"></user-info>
                </div>
            </div>
        </div>
    </div>

</div>
