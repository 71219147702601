import { Component, Inject, AfterViewInit, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Room } from '../../../_models/catalog';
import { AppComponent } from '../../app.component';


export interface DialogData {
    roomId: number;
    room: Room;
}
@Component({
    selector: 'app-room-config-dialog',
    templateUrl: './room-config-dialog.component.html',
    styleUrls: ['./room-config-dialog.component.scss']
})
/** RoomConfigDialog component*/
export class RoomConfigDialogComponent implements OnInit {
    ngOnInit(): void {
        if (this.data != null && this.data.room != null) {
            this.room = new Room(this.data.room.title, this.data.room.wallColor, this.data.room.image, this.data.room.id, this.data.room.orderSets);
            console.log("roomid being updated: " + this.room.id);
            this.updateBckSelection(this.room.image, this.data.room.id.toString());
            if (this.room == null) {
                this.room = new Room(null, "white", null);
            }
        }
    }
    room: Room;
    roomTitle: string;
    roomImage: string;
    selectedBackground: string;
    /** RoomConfigDialog ctor */
    constructor(
        public dialogRef: MatDialogRef<RoomConfigDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        
    }
    updateBckSelection(roomImage: string, id: string) {
        if (roomImage != null) {
            this.selectedBackground = roomImage.substr(roomImage.length - 1, 1);
            this.setFocusAndStoreOldValue(id);
        }
    }

    onNoClick(): void {
        AppComponent.SendEventGa('operation', null, 'B2C_anulowanie edycji pomieszczenia');
        this.dialogRef.close(null);
    }

    onSubmitClick(room: Room) {
        AppComponent.SendEventGa('operation', null, 'B2C_zakończenie edycji pomieszczenia');
        if (this.selectedInput.value != null && this.selectedInput.value.length > 0)
            room.title = this.selectedInput.value;
        else {
            room.title = this.roomLabels[this.selectedBackground]
            console.log("room title from labels: " + this.roomLabels[this.selectedBackground]);
        }
        console.log("saving room: " + room.title);
        this.dialogRef.close(room);
    }

    roomLabels = ['Salon', 'Sypialnia', 'Kuchnia', 'Łazienka', 'Jadalnia', 'Gabinet',
        'Pokój dziecięcy', //'Przedpokój',
        'Dodaj nowy']
    roomPresent = [true, true, true, true, true, true, true, false, true]
    labels(i: number) {
        return this.roomLabels[i - 1]; 
    }
    selectedInput: HTMLInputElement;
    inputLastName: string = '';
    changeRoom(id: string) {
        AppComponent.SendEventGa('operation', this.roomLabels[id], 'B2C_edycja pomieszczenia zmiana tła');
        console.log("this.room.title:" + this.room.title)
        if (this.room.title == null || this.room.title == this.roomLabels[this.selectedBackground] )
                this.room.title = this.roomLabels[id];
        this.selectedBackground = id;
        this.roomImage = 'real' + id;
        this.room.image = 'real' + id;

        this.restoreName();
        this.setFocusAndStoreOldValue(id);
    }
    private restoreName() {
        if (this.selectedInput != null) {
            this.selectedInput.value = this.inputLastName;
        }
    }
    private setFocusAndStoreOldValue(id: string) {
        this.selectedInput = <HTMLInputElement>document.getElementById('room-name-' + id);
        this.inputLastName = this.selectedInput.value;
        //this.selectedInput.value = '';
        this.selectedInput.focus();
        //document.getElementById('room-name-' + id).focus();
        this.selectedInput.select();
    }
    roomSelected(id: string): boolean{
        return this.selectedBackground == id;
    }

    roomBackground(id: string):string {
        if (this.selectedBackground == id) {
            return ' selectedRoom';
        }
        return 'unselectedRoom';
    }
    selected(id: string): boolean {
        return this.selectedBackground != null && this.selectedBackground == id;
    }
}
