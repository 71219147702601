
import { Injectable, Inject } from '@angular/core';

import { AppTranslationService } from './app-translation.service';
import { LocalStoreManager } from './local-store-manager.service';
import {
    Busket, Category, Sku, Series, OrderSet, OrderSetItem, Color,
    CategoryGroup, Project, Room, ColorSeries, SubSeries, ProjectOrder
} from '../_models/catalog';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AlertService, DialogType, MessageSeverity } from '../services/alert.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationSet } from '../_models/project-config';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { FunctionHelperComponent } from '../components/b2b/function-helper.component';
import { EcommerceRedirectComponent } from '../components/controls/ecommerce-redirect.component';
import { MatDialog } from '@angular/material/dialog';
import { isUndefined } from 'util';
import { delay } from 'rxjs/operators';


export class SkuPosition {
    sku: Sku;
    position: number;
}

@Injectable()
export class OrderService {
    contentLoading = true;
    recomendedSkus: Sku[] = null;
    getRecommendedFunctions(seriesId, colorId){
        let roomId = this.openedRoom().image.substring(4);
        let r = null;
        this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/roomRecomendationSkus/' + roomId + '/' + seriesId + '/' + colorId)
            .subscribe(

                results => {
                    this.recomendedSkus = results;
                    console.log('getRecommendedFunctions result: ' + this.recomendedSkus);

                }
        );
    }


    updateOrder(orderId: string, status: string): any {
        console.log("update Order: " + orderId + " set status: " + status);
        this.httpclient.get(this.baseUrl + 'api/UserCart/updateOrder/' + orderId + '?status=' + status).subscribe();
    }
    orderString: string;
    ecommerceMethod: any;
    eCommercePrefix: string = "__";
    eCommerceName: string = "empty";
    order: ProjectOrder;

    duplicateRoomObject(orgRoom: Room):Room {
        let newOrderSets = this.duplicateOrderSets(orgRoom.orderSets);
        let newRoom = new Room(
            orgRoom.title,
            orgRoom.wallColor,
            orgRoom.image,
            null,
            newOrderSets
        );
        this.changedAfterOrder = true;
        this.changed = true;
        return newRoom;
    }
    duplicateRoom(i: number) {
        console.log('duplicateRoom: ' + i);
        let newRoom: Room = new Room(this.cProject.rooms[i].title);
        newRoom.image = this.cProject.rooms[i].image;
        newRoom.wallColor = this.cProject.rooms[i].wallColor;
        newRoom.orderSets = this.duplicateOrderSets(this.cProject.rooms[i].orderSets);
        this.addRoom(newRoom);
        this.changedAfterOrder = true;
        this.changed = true;
    }
    duplicateOrderSets(oss: OrderSet[]): OrderSet[] {
        let newOss = new Array<OrderSet>();
        if(oss != null ) for (let os of oss) {
            let newOS = { title: os.title, id: null, orderSetItems: new Array<OrderSetItem>() };
            if(os != null ) for(let osi of os.orderSetItems) {
                let newOsi = { sku: osi.sku, id: null, qty: osi.qty }
                newOS.orderSetItems.push(newOsi);
                }
        }
        this.changedAfterOrder = true;
        this.changed = true;
        return newOss;
    }
    duplicateOrderSet(os: OrderSet): OrderSet {
        let newOS = { title: os.title, id: null,orderSetItems:new Array<OrderSetItem>()};
        for (let osi of os.orderSetItems) {
            let newOsi = { sku: osi.sku, id: null, qty: osi.qty }
            newOS.orderSetItems.push(newOsi);
        }
        this.changedAfterOrder = true;
        this.changed = true;
        return newOS;
    }
    expanded: boolean = false;
    updateFrameSummary() {

    }
    get collectedProjectSkus() {
        let sum = 0;
        if (this.cProject == null || this.cProject.rooms == null)
            return 0;
        for (let r of this.cProject.rooms) {
            if (r.orderSets!=null)for (let os of r.orderSets) {
                if (os.orderSetItems!=null)for (let osi of os.orderSetItems) {
                    if(osi != null && osi.sku != null) sum += osi.qty;
                }
            }
        }
        return sum;

    }
    openRoom(i: number): any {
        this.actRoomIndex = i;
        this.wallColor = this.openedRoom().wallColor;
        this.fontColor = this.openedRoom().fontColor;
    }
    initRoomAndOrderSets() {
        if (this.cProject.rooms == null)
            this.cProject.rooms = new Array<Room>();
        if (this.cProject.rooms.length == 0)
            this.cProject.rooms.push(new Room('Zestaw1'));
        if (this.cProject.rooms.length <= this.actRoomIndex)
            this.actRoomIndex = 0;
        if (this.cProject.rooms[this.actRoomIndex].orderSets == null)
            this.cProject.rooms[this.actRoomIndex].orderSets = new Array<OrderSet>();
        if (this.cProject.rooms[this.actRoomIndex].orderSets.length == 0) {
            let nos = { id: 0, title: null, orderSetItems: null };
            this.cProject.rooms[this.actRoomIndex].orderSets.push(nos);
        }
        if (this.cProject.rooms[this.actRoomIndex].orderSets[0].orderSetItems == null)
            this.cProject.rooms[this.actRoomIndex].orderSets[0].orderSetItems = new Array<OrderSetItem>();
        this.changedAfterOrder = true;
    }

    likedSkus: Sku[] = new Array<Sku>();
    actRoomIndex = 0;
    projects: Project[];

    openedProjectIndex: number;
    selectedCategoryId: number;
    suggestedFunctions: string;

    get cProject() {
        this.changed = true;
        return (this.projects != null && this.projects.length > 0) ?
            this.projects[this.openedProjectIndex] : null;
    }
    newProjectRem(i: number) {
        this.currentConfig = new ConfigurationSet('Nowy projekt');
        this.changed = true;
    }
    delProject(i: number): any {
        this.changed = true;
        console.log("removed from database project: " + this.projects[i].title);
        if (this.projects[i].id != null && this.authService.isLoggedIn) {
            this.httpclient.delete<Project>('/api/UserCart/projects/' + this.projects[i].id).subscribe(result => {
                for (let j = i; j < this.projects.length - 1; j++) {
                    this.projects[j] = this.projects[j + 1];
                }
                this.projects.pop();
                //this.saveCurrentContextOld();
            })
        } else {
            for (let j = i; j < this.projects.length - 1; j++) {
                this.projects[j] = this.projects[j + 1];
            }
            this.projects.pop();
            this.saveCurrentContextLocaly();
        }

    }
    addAndOpenProjectWithDefaultRoom(p: Project,nextAction) {
        if (p.rooms == null)
            p.rooms = new Array<Room>();
        if (p.rooms.length == 0)
            p.rooms.push(new Room('Zestaw1', null, null, 0, new Array<OrderSet>()));
        this.addProject(p, this.generateCartOnCurrentAndCallback, nextAction);
        this.changed = true;
    }



    saveProject(p: Project) {
        this.httpclient.post<Project>('/api/UserCart/Projects/', p).subscribe(result => {
            p.id = result.id;
            this.saveCurrentContextLocaly();
        });
    }
    addProject(p: Project, nextAction = null, nextActionArg = null): any {
        console.log("addProject: " + p.id +" Title: " + p.title);
        this.changed = true;
        if (p.user == null && this.authService.isLoggedIn) {
            p.user = new User();
            p.user.id = this.authService.currentUser.id;
        }
        if (true || this.authService.isLoggedIn) {
            console.log("sending new project to BE");
            this.changedAfterOrder = true;
            this.httpclient.post<Project>('/api/UserCart/Projects/', p).subscribe(result => {
                p.id = result.id;
                if (this.projects == null)
                    this.projects = new Array<Project>();
                this.projects.push(p);
                this.openedProjectIndex = this.projects.length - 1;
                this.currentConfig = new ConfigurationSet(p.title);
                this.saveCurrentContextLocaly();
                console.log("saved project on BE");
                if (nextAction != null)
                    nextAction(this, nextActionArg);
            });

        } else {
            if (this.projects == null)
                this.projects = new Array<Project>();
            this.projects.push(p);
            this.openedProjectIndex = this.projects.length - 1;
            this.saveCurrentContextOld();
        }
    }
    clearProject(): any {
        //this.currentConfig = new ConfigurationSet('Nowy projekt');
        this.cProject.rooms = new Array<Room>();
        this.saveCurrentContextOld();
        this.changedAfterOrder = true;
    }
    openProject(i: number) {
        if (i == null || isUndefined(i))
            i = this.projects.length - 1;
        this.openedProjectIndex = i;
        let p = this.projects[i];
        this.currentConfig = new ConfigurationSet(p.title);
        this.currentConfig.rooms = p.rooms;
        this.currentConfig.project = p;
        //this.saveCurrentContext();
    }
    currentCategory: string;
    addRoom(room: Room): any {
        this.cProject.rooms.push(room);
        this.actRoomIndex = this.cProject.rooms.length - 1;
        this.wallColor = this.openedRoom().wallColor;
        this.saveChangesProjectEntity(this.cProject);
        this.changedAfterOrder = true;
        return true;

    }
    delRoom(i: number) {
        for (let j = i; j < this.cProject.rooms.length -1;j++) {
            this.cProject.rooms[j] = this.cProject.rooms[j + 1];

        }
        this.cProject.rooms.pop();
        if (i == this.actRoomIndex)
            this.actRoomIndex = 0;
        this.saveChangesProjectEntity(this.cProject);
        this.changedAfterOrder = true;
    }
    /*
    openRoomByObject(room: Room) {
        var urIndex = this.cProject.rooms.findIndex(r => r.id == room.id);
        if (urIndex > 0)
            this.actRoomIndex = urIndex;
        this.changedAfterOrder = true;
    }*/
    updateRoom(room: Room, index: number): any {
        this.cProject.rooms[index] = room;
        this.wallColor = this.openedRoom().wallColor;
        this.saveChangesProjectEntity(this.cProject);
        this.changedAfterOrder = true;
    }
    saveChangesProjectById(i: number, reloadData: boolean = true) {

        if (this.authService.isLoggedIn) {
            var p = this.projects[i];
            this.httpclient.put<Project>('/api/UserCart/projects/' + p.id, p).subscribe(result => {
                if (reloadData)
                    this.projects[i] = result;
            })
        } else
            this.saveCurrentContextOld();
        this.changedAfterOrder = true;
    }

    saveChangesLocalProject(p: Project) {
        return this.httpclient.put<Project>('/api/UserCart/projects/' + p.id, p).subscribe(result => {
            //this.getMyProjects(null, null);
        })
        this.changedAfterOrder = true;
    }
    saveChangesProjectEntity(p: Project) {
        if (this.authService.isLoggedIn) {
            if (p.id != null && p.id > 0) {
                this.saveChangesLocalProject(p);
            } else {
                this.postProject(p);
            }
        }
        this.saveCurrentContextLocaly();
        this.changedAfterOrder = true;
    }
    deleteFrame(): any {
        this.currentConfig.selectedSeries = null;
        this.currentConfig.selectedColorSku = null;
        this.currentConfig.selectedShapeSku = null;
        //this.saveCurrentContext();
        this.saveCurrentProject();
        this.changedAfterOrder = true;
    }
    removeOrderSetAndShiftLeft(index: number) {
        for (let i = index; i < this.openedRoom().orderSets.length - 1; i++) {
            let sets = this.openedRoom().orderSets;
            let j = i + 1;
            let nextSku = sets[j];
            console.log(this.openedRoom().orderSets[i].orderSetItems[0].sku.code + ">>" + nextSku.orderSetItems[0].sku.code);
            this.openedRoom().orderSets[i].id = nextSku.id;
            this.openedRoom().orderSets[i].title = nextSku.title;
            this.openedRoom().orderSets[i].orderSetItems = nextSku.orderSetItems;
            console.log(this.openedRoom().orderSets[i].orderSetItems[0].sku.code);
        }
        this.openedRoom().orderSets.pop();
    }
    remFunction(position: number) {
        console.log('OrderService.setFu nction(' + this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems[position+1].sku.code + ',' + position + ');');
        var curOrderSet = this.openedRoom().orderSets[this.openedRoom().itemIndex];
        if (this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems[position + 1].sku.functionType.id == 1411) {
            this.removeOrderSetAndShiftLeft(this.openedRoom().itemIndex);
        }
        else {
            if (curOrderSet.orderSetItems[position + 1].multiBlockPosition != null)
                if (curOrderSet.orderSetItems[position + 1].multiBlockPosition == 0)
                    curOrderSet.orderSetItems[position + 2] = null;
                else if (curOrderSet.orderSetItems[position + 1].multiBlockPosition == 1)
                    curOrderSet.orderSetItems[position] = null;
            curOrderSet.orderSetItems[position + 1] = null;
        }
        //this.saveCurrentContext();
        this.saveCurrentProject();
        this.changedAfterOrder = true;
    }

    setFunction(sku: Sku, position: number) {
        console.log('OrderService.setFunction(' + sku.code + ',' + position + ');');
        if (this.currentConfig.selectedFunctions == null) {
            this.currentConfig.selectedFunctions = [];
        }
        let newFunction = {
            id: 0,
            sku: sku,
            qty: 1
        }


        if (sku.functionType.id == 1411) {
            console.log("Monoblock add");
            let actSetIndex = this.openedRoom().itemIndex;
            var tempOS;
            let newOS = {
                id: 0,
                title: null,
                orderSetItems: new Array<OrderSetItem>()
            }
            let fakeFrame = { id: 5128, code: "S52P702", title: 'fantom', functionType: { id: 75, title: "Ramka 1-krotna" },
            category: { id: 71, title: "Ramki uniwersalne", description: "Ramka", order: 3 },
            categoryGroupID: 1006,
            color: { id: 1, title: "biały", order: 1 },
            elk: 1,
            indexOfProtection: { id: 1, title: "IP20" },
            info: "Ramka 1-krotna",
            isVertical: false,
                net: 14.33,
            gross: 0,
            params: [],
                selectable: true,
                series: sku.series,
            shortInfo: "Ramka 1-krotna",
                slots: 1,
                subSeries: sku.subSeries,
            universal: true,
            withFrame: false
            }

            let newNibyFrame = {
                id: 0,
                sku: fakeFrame,
                qty: 0
            }
            newOS.orderSetItems.push(newNibyFrame);
            newOS.orderSetItems.push(newFunction);
            this.openedRoom().orderSets.push(newOS);

            for (i = this.openedRoom().orderSets.length-1; i > actSetIndex; i--) {
                tempOS = this.openedRoom().orderSets[i];
                this.openedRoom().orderSets[i] = this.openedRoom().orderSets[i - 1];
                this.openedRoom().orderSets[i - 1] = tempOS;
            }
            return;
        }
        let oldSku:OrderSetItem = null;
        var curOrderSet = this.actOrderSet();
        var i = curOrderSet.orderSetItems.length;
        oldSku = curOrderSet.orderSetItems[position + 1];
        if (oldSku != null)
            console.log("oldSku" + oldSku.sku.code);
        this.clearNeihgtbour(position, curOrderSet);

        if (i >= position + 2) {
            if (sku == null) {
                curOrderSet.orderSetItems[position + 1] = null;
            } else {
                curOrderSet.orderSetItems[position + 1] = newFunction;
            }
        } else {
            while (i < position + 2) {
                curOrderSet.orderSetItems.push(null);
                i = i + 1;
            }
            curOrderSet.orderSetItems[position + 1] = newFunction;

        }
        //to be removed,
        if (curOrderSet.orderSetItems[0].sku.slots == 1) {
            if (sku && sku.functionType.id == 1411) {
                curOrderSet.orderSetItems[0].qty = 0;
            } else if ((!sku || (sku && sku.functionType.id != 1411)) && curOrderSet.orderSetItems[0].qty == 0) {
                curOrderSet.orderSetItems[0].qty = 1;
            }
        }
        //multiblock position
        if (sku.code == 'S520583' || sku.code == 'S530583' || sku.code == 'MTN4375-0303') {
            if (curOrderSet.orderSetItems[position + 1] != null &&
                curOrderSet.orderSetItems[0].sku.slots > position + 1) {
                curOrderSet.orderSetItems[position + 1].multiBlockPosition = 0;
                this.clearNeihgtbour(position + 1, curOrderSet);
                curOrderSet.orderSetItems[position + 2] = {
                    id: 0,
                    sku: sku,
                    qty: 0,
                    multiBlockPosition: 1
                };

            } else if (curOrderSet.orderSetItems[position + 1] != null &&
                curOrderSet.orderSetItems[0].sku.slots > 1) {
                curOrderSet.orderSetItems[position + 1].multiBlockPosition = 1;
                this.clearNeihgtbour(position - 1, curOrderSet);
                curOrderSet.orderSetItems[position] = {
                    id: 0,
                    sku: sku,
                    qty: 0,
                    multiBlockPosition: 0
                }
            }
        }
        this.saveCurrentProject();
        this.changedAfterOrder = true;
    }
    clearNeihgtbour(position, curOrderSet) {
        if (curOrderSet.orderSetItems[position + 1] != null &&
            curOrderSet.orderSetItems[position + 1].multiBlockPosition != null) {
            if (curOrderSet.orderSetItems[position + 1].multiBlockPosition == 0) {
                //this.clearNeihgtbour(position + 1, curOrderSet);
                curOrderSet.orderSetItems[position + 2] = null;
            }
            else if (curOrderSet.orderSetItems[position + 1].multiBlockPosition == 1) {
                this.clearNeihgtbour(position - 1, curOrderSet);
                curOrderSet.orderSetItems[position + 1] = null;
            }
        }
        curOrderSet.orderSetItems[position + 1] = null;
    }
    public getbackgroundstyle() {
        return OrderService.backgroundstyle;
    }


    public locbackgroundstyle: string = '_background2';
    public static backgroundstyle: string = '_background2';
    public waterProof: Boolean = false;
    public static readonly appVersion: string = "2.5.3";

    public baseUrl = environment.baseUrl || Utilities.baseUrl();
    public loginUrl = environment.loginUrl;
    public fallbackBaseUrl = "schneider-electric.pl"

    public currentBusket: Busket;
    public _currentSeries: Series;
    public _currentSeriesSource = new Subject<Series>();
    public _availableSeries: Series[];

    currentSeries$ = this._currentSeriesSource.asObservable();
    httpclient: HttpClient;
    baseurl: string;
    instance = Math.random();

    constructor(private localStorage: LocalStoreManager, private translationService: AppTranslationService,
        private alertService: AlertService, http: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private route: ActivatedRoute, private authService: AuthService, public dialog: MatDialog) {
        this.httpclient = http;
        this.loadLocalChanges();

        this.baseUrl = baseUrl;
        this.httpclient.get<Series[]>(baseUrl + 'api/CatalogData/series').subscribe(result => {
            this._availableSeries = result;
        }, error => console.error(error));

    }


    clearContext() {
        this.actRoomIndex = 0;
        this.orderString = null;
        this.ecommerceMethod = null;
        this.ecommerceEndpooint = null;
        this.eCommercePrefix = "__";
        this.eCommerceName = "empty";
        this.expanded = false;

        this.locbackgroundstyle = '_background2';
        this.waterProof = false;

        this.currentBusket = null;
        this._currentSeries = null;
        this._currentSeriesSource = new Subject<Series>();

        this.likedSkus = new Array<Sku>();
        this.actRoomIndex = 0;
        this.projects = null;

        this.openedProjectIndex = null;
        this.selectedCategoryId = null;
        this.suggestedFunctions = null;
        this.changed = true;
        this.changedAfterOrder = false;
        this.cartId = null;
        this.currentConfig = null;
        this.clearLocalData();
    }

    readStorageDataObject(key: string) {
        return this.localStorage.getDataObject("eCommerceId");
    }



    private seriesSkuSource = new Subject<Sku[]>();
    seriesSku$ = this.seriesSkuSource.asObservable();

    private selectedSeriesSource = new Subject<Series>();
    selectedSeries$ = this.selectedSeriesSource.asObservable();
    selectedSeriesItem: Series;
    selectedSubSeriesItem: SubSeries;

    public selectSeries(series: Series) {
        console.log("OrderService:" + this.instance + ".selectSeries: " + series);
        if (series == null)
            return null;
        if (this.currentConfig.selectedSeries != series) {
            this.tryToMatchFunctions();
        }
        this.selectedSeriesItem = series;
        this.currentConfig.selectedSeries = series;

        //this.selectedSeriesSource.next(series);
        //this.saveCurrentContext();
    }
    public selectSubSeries(ss: SubSeries, s:Series) {
        console.log("OrderService:" + this.instance + ".selectSeries: " + s);
        if (s == null)
            return null;
        if (this.currentConfig.selectedSeries != s) {
            this.tryToMatchFunctions();
        }
        this.selectedSeriesItem = s;
        this.selectedSubSeriesItem = ss;
        this.currentConfig.selectedSeries = s;
        this.currentConfig.selectedSubSeries = ss;
        //this.selectedSeriesSource.next(s);
        //this.saveCurrentContext();
    }

    public getSingleFrames(subSeries: SubSeries) {
        var skus;
        console.log(subSeries)
        this.seriesSku$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/singleframesbysubseries/' + subSeries.id);

        return skus;
    }
    colorOfSeries$ = new Subject<ColorSeries[]>().asObservable();
    public getColorsOfSeries() {
        var colors;
        var seriesId = this.actFrame().series.id;
        this.colorOfSeries$ = this.httpclient.get<ColorSeries[]>(this.baseUrl + 'api/CatalogData/colorsofseries/' + seriesId);

    }
    public getColorsOfSubSeriesMechanisms(b2c:boolean=false) {
        var colors;
        var seriesId = this.actFrame().series.id;
        let suffix = '';
        if (b2c)
            suffix = 'b2c'
        this.colorOfSeries$ = this.httpclient.get<ColorSeries[]>(this.baseUrl + 'api/CatalogData/colorsofmechseries'+suffix+'/' + seriesId);

    }
    private shapesSkuSource = new Subject<Sku[]>();
    shapesSku$ = this.seriesSkuSource.asObservable();
    public getFrameShapes(refColorSku: Sku) {
        this.shapesSku$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/colouredframesbysubseries/'
            + refColorSku.subSeries.id + "/" + refColorSku.color.id);
        //this.saveCurrentContext();
    }

    opacity: string;
    opacity$: Observable<string>;

    selectedFrameColorSku: Sku;
    selectedFrameColorSource = new Subject<Sku>();
    selectedFrameColor = this.selectedFrameColorSource.asObservable();

    setFrameByColor(sku: Sku): any {
        if (sku == null)
            return null;
        this.selectedFrameColorSource.next(sku);
        this.selectedFrameColorSku = sku;
        console.log("OrderService.setFrameByColor.selectedFrameColorSku:" + this.selectedFrameColorSku.code);
        this.changedAfterOrder = true;
        this.saveCurrentContextOld();
    }

    public selectedFrameShapeSku: Sku;
    selectedFrameShapeSource = new Subject<Sku>();
    selectedFrameShape$ = this.selectedFrameShapeSource.asObservable();
    setFrameByShape(sku: Sku): any {
        if (sku == null)
            return null;
        this.selectedFrameShapeSku = sku;
        console.log("OrderService.setFrameByShape.selectedFrameShapeSku:" + this.selectedFrameShapeSku.code);
        this.selectedFrameShapeSource.next(sku);
        this.changedAfterOrder = true;
        this.saveCurrentContextOld();
    }

    private filteredFunctionsSource = new Subject<Sku[]>();
    filteredFunctions$ = new Subject<Sku[]>().asObservable();
    public getFunctionsBySeriesAndColor(colorId = null) {

        if (this.selectedSeriesItem != null && (colorId != null || this.selectedFrameColorSku != null)) {
            var inColorId = colorId != null ? colorId : this.selectedFrameColorSku.color.id;
            this.filteredFunctions$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/functionsBySeriesAndColor/'
                + this.selectedSeriesItem.id + "/" + inColorId);
        }
        else if (this.selectedSeriesItem != null)
            this.filteredFunctions$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/functionsBySeriesAndColor/'
                + this.selectedSeriesItem.id + "/-1");
    }
    public getFunctionsBySeriesAndColorActOrderSetSeriesB2C(colorId = null) {

        if (this.actOrderSet().orderSetItems[0] != null && this.actOrderSet().orderSetItems[0].sku != null &&
            this.actOrderSet().orderSetItems[0].sku.series != null && (colorId != null || this.selectedFrameColorSku != null)) {
            var inColorId = colorId != null ? colorId : this.selectedFrameColorSku.color.id;
            this.filteredFunctions$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/functionsBySeriesAndColor/b2c/'
                + this.actOrderSet().orderSetItems[0].sku.series.id + "/" + inColorId);
        }
        else if (this.actOrderSet().orderSetItems[0].sku.series != null)
            this.filteredFunctions$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/functionsBySeriesAndColor/b2c/'
                + this.actOrderSet().orderSetItems[0].sku.series.id + "/-1");
        else if (this.selectedSeriesItem != null)
            this.filteredFunctions$ = this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/functionsBySeriesAndColor/b2c/'
                + this.selectedSeriesItem.id + "/-1");
    }


    categoryGroups$ = new Subject<CategoryGroup[]>().asObservable();
    public getCategoryGroups() {
        this.categoryGroups$ = this.httpclient.get<CategoryGroup[]>(
            this.baseUrl + 'api/catalogdata/categoryGroups');
    }
    wallColor: string = 'white';
    fontColor: string = 'green';
    get wallColorSafe(): string {
        return this.roomWallColor ? this.roomWallColor : 'white';
    }

    get fontColorSafe(): string {
        console.log(('fontColor: ' + this.fontColor))
        return (this.openedRoom() != null && this.openedRoom().fontColor != null) ? this.openedRoom().fontColor : this.fontColor
    }
    setWallColor(backgroundColor: string, fontColor: string) {
        console.log('orderService instance: ' + this.instance.toString())
        console.log('set background: ' + backgroundColor + " and font color: " + fontColor)
        this.wallColor = backgroundColor;
        this.fontColor = fontColor;
        this.openedRoom().wallColor = this.wallColor;
        this.openedRoom().fontColor = this.fontColor;
        this.saveCurrentProject();
        //this.currentConfig.selectedWallColor = color;
        //this.saveCurrentContext();
    }
    get roomWallColor(): string {
        return (this.openedRoom() != null && this.openedRoom().wallColor != null) ? this.openedRoom().wallColor : this.wallColor
    }


    tryToMatchFunctions(){

    }


    addFrame(s: Sku, vertical=null) {
        var newOrderSetItem = { id: 0, sku: s, qty: 1, isVertical: vertical};
        var newSet = {
            id: 0, title: s.functionType.title, orderSetItems: [newOrderSetItem]
        };
        if (this.openedRoom().orderSets == null) {
            this.openedRoom().orderSets = new Array<OrderSet>()
        }
        if (this.openedRoom().orderSets.length == 0) {
            this.openedRoom().orderSets.push(newSet);
        }
        else if (this.openedRoom().orderSets[0].orderSetItems == null ||
            this.openedRoom().orderSets[0].orderSetItems[0] == null ||
            this.openedRoom().orderSets[0].orderSetItems[0].sku == null) {
            this.openedRoom().orderSets[0] = newSet;
        } else {
            this.openedRoom().orderSets.push(newSet);
        }
        console.log('added to frame: ' + newSet.orderSetItems[0].sku.code);
        //this.openedRoom = this.openedRoom()
        //this.updateFrameSummary();
        this.saveChangesProjectById(this.openedProjectIndex, false);
        this.saveCurrentContextLocaly();
        //this.saveCurrentContext();
        this.changedAfterOrder = true;

    }
    addFrameNoSave(s: Sku, isVertical=false) {
        var newOrderSetItem = { id: 0, sku: s, qty: 1, isVertical: isVertical };
        var newSet = {
            id: 0, title: s.functionType.title, orderSetItems: [newOrderSetItem]
        };
        if (this.openedRoom().orderSets == null) {
            this.openedRoom().orderSets = new Array<OrderSet>()
        }
        if (this.openedRoom().orderSets.length == 0) {
            this.openedRoom().orderSets.push(newSet);
        }
        else if (this.openedRoom().orderSets[0].orderSetItems == null ||
            this.openedRoom().orderSets[0].orderSetItems[0] == null ||
            this.openedRoom().orderSets[0].orderSetItems[0].sku == null) {
            this.openedRoom().orderSets[0] = newSet;
        } else {
            this.openedRoom().orderSets.push(newSet);
        }

    }
    deleteOrderSetPosition(orderSetItemIndex: number, orderSetIndex = 0) {
        console.log("deleteOrderSetPosition: " + orderSetItemIndex + ":" + orderSetIndex);
        if (orderSetItemIndex == 0 ||
            this.openedRoom().orderSets[orderSetIndex].orderSetItems[orderSetItemIndex].sku.functionType.id == 1411) {
            let len = this.openedRoom().orderSets.length
            console.log("POP" + this.openedRoom().orderSets.length);
            var los = this.openedRoom().orderSets.pop();
            if (orderSetIndex < len - 1) {
                console.log("set other instead");
                this.openedRoom().orderSets[orderSetIndex] = los;

            }
            //this.saveCurrentContext();
            this.saveCurrentProject();
        } else {
            this.deletePosition(orderSetItemIndex, orderSetIndex);

        }
        this.changedAfterOrder = true;
    }
    decPosition(orderSetItemIndex: number, orderSetIndex = 0) {
        if (orderSetItemIndex == 0) {
            var los = this.openedRoom().orderSets.pop();
            if (orderSetIndex < this.openedRoom().orderSets.length)
                this.openedRoom().orderSets[orderSetIndex] = los;
            //this.saveCurrentContext();
            this.saveCurrentProject();
        } else {
            this.deletePosition(orderSetItemIndex, orderSetIndex);
        }
        this.changedAfterOrder = true;
    }
    incPosition(orderSetItemIndex: number, orderSetIndex = 0) {
        if (orderSetItemIndex == 0) {
            this.openedRoom().orderSets.push(
                this.currentConfig.fH.copyOrderSet(this.openedRoom().orderSets[orderSetIndex])
            );
            //this.saveCurrentContext();
            this.saveCurrentProject();
        } else {
            this.openedRoom().orderSets[orderSetIndex].orderSetItems.push(
                this.currentConfig.fH.copyOrderSetItem(
                    this.openedRoom().orderSets[orderSetIndex].orderSetItems[orderSetItemIndex]
                )
            );

            //this.saveCurrentContext();
            this.saveCurrentProject();
        }
        this.changedAfterOrder = true;
    }
    decFrame(sku: Sku, isVertical = false) {
        console.log('decrease orderset: ' + sku.code);
        var index = this.openedRoom().orderSets.findIndex(
            os => (os.orderSetItems[0] != null &&
                os.orderSetItems[0].sku != null &&
                os.orderSetItems[0].sku.id == sku.id &&
                (os.orderSetItems[0].isVertical == null || os.orderSetItems[0].isVertical == isVertical)));
        console.log('decFrame.index: ' + index);
        if (index < 0)
            return;
        console.log('decFrame.orderSets.length: ' + this.openedRoom().orderSets.length);
        if (this.openedRoom().orderSets.length <= 1)
            this.openedRoom().orderSets = new Array<OrderSet>();
        var los = this.openedRoom().orderSets.pop();
        console.log('decFrame.orderSets.length: ' + this.openedRoom().orderSets.length);
        if (index < this.openedRoom().orderSets.length)
            this.openedRoom().orderSets[index] = los;
        //this.saveCurrentContext();
        this.saveCurrentProject();
        this.changedAfterOrder = true;
    }
    decFrameNoSave(sku: Sku, isVertical = false) {
        console.log('decrease orderset: ' + sku.code);
        var index = this.openedRoom().orderSets.findIndex(
            os => (os.orderSetItems[0] != null &&
                os.orderSetItems[0].sku != null &&
                os.orderSetItems[0].sku.id == sku.id &&
                (os.orderSetItems[0].isVertical == null || os.orderSetItems[0].isVertical == isVertical)));
        if (index < 0)
            return;
        var los = this.openedRoom().orderSets.pop();
        if (index < this.openedRoom().orderSets.length)
            this.openedRoom().orderSets[index] = los;
        this.changedAfterOrder = true;
    }
    frameSummary = null;

    numberOfSkuItems(sku: Sku, isVertical = false) {
        if (this.openedRoom().orderSets.length == 0)
            return 0;
        var oss = this.openedRoom().orderSets.filter(os => os.orderSetItems != null &&
            os.orderSetItems.length > 0);
        let count = 0;
        for (let os of this.openedRoom().orderSets) {
            if (os.orderSetItems != null && os.orderSetItems.length > 0)
                for (let osi of os.orderSetItems) {
                    if (osi != null && osi.sku != null && osi.sku.code == sku.code
                        && (osi.isVertical == null || osi.isVertical == isVertical)) {
                        count += osi.qty;
                    }
                }
        }
        this.changedAfterOrder = true;
        return count;
    }

    resetOrderIndex() {
        this.openedRoom().itemIndex = 0;
    }
    actOrderSet(): OrderSet{
        if (this.openedRoom() != null && this.openedRoom().itemIndex < this.openedRoom().orderSets.length)
            return this.openedRoom().orderSets[this.openedRoom().itemIndex];
        else
            return null;

    }
    incOrderSetIndex() {
        if (this.openedRoom() != null && this.openedRoom().itemIndex != null)
            this.openedRoom().itemIndex = (this.openedRoom().itemIndex + 1) % this.openedRoom().orderSets.length;
    }
    decOrderSetIndex() {
        if (this.openedRoom() != null && this.openedRoom().itemIndex != null)
            this.openedRoom().itemIndex = (this.openedRoom().itemIndex - 1 + this.openedRoom().orderSets.length) % this.openedRoom().orderSets.length;
    }
    actSeries(): Series {
        if (this.actFrame() != null)
            return this.actFrame().series;
        else return null;

    }
    clearFirstItemIfEmpty() {

    }
    get actOrderItem():OrderSetItem {
        //console.log("actFrame......index:" + this.openedRoom().itemIndex);
        //if (this.openedRoom().itemIndex >= this.openedRoom().orderSets.length)
        //            this.openedRoom().itemIndex = 0;

        if (this.openedRoom() == null || this.openedRoom().orderSets == null || this.openedRoom().orderSets[0] == null)
            return null;
        //console.log("actFrame......index:" + this.openedRoom().itemIndex);
        if (this.openedRoom().orderSets[0].orderSetItems == null ||
            this.openedRoom().orderSets[0].orderSetItems.length == 0) {
            console.log("Empty first row");
            let os = this.openedRoom().orderSets.pop();
            if (this.openedRoom().orderSets.length > 0)
                this.openedRoom().orderSets[0] = os;
            else
                return null;
        }
        if (this.openedRoom().orderSets[0].orderSetItems.length == 0 ||
            this.openedRoom().orderSets[0].orderSetItems[0] == null)
            return null;
        if (this.openedRoom().orderSets[0].orderSetItems[0].sku == null) {
            console.log("Empty first orderSet");
            let os = this.openedRoom().orderSets.pop();
            if (this.openedRoom().orderSets.length > 0)
                this.openedRoom().orderSets[0] = os;
        }
        if (this.openedRoom().orderSets[0].orderSetItems.length == 0) {
            console.log("Empty first orderSet");
            return null;
        }
        if (this.openedRoom().itemIndex == null ||
            this.openedRoom().itemIndex >= this.openedRoom().orderSets.length)
            this.openedRoom().itemIndex = 0;


        //console.log('act item index: ' + this.openedRoom().itemIndex);
        //console.log('act item item: ' + this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems[0].sku);


        return this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems[0];
    }

    actFrame(): Sku {
        let aoi = this.actOrderItem;
        return (aoi != null) ? aoi.sku : null;
    }
    actFrameEmpty(): boolean {
        return this.openedRoom() == null ||
            this.openedRoom().orderSets == null ||
            this.openedRoom().itemIndex == null ||
            this.openedRoom().orderSets[this.openedRoom().itemIndex] == null ||
            this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems == null ||
            this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems[0] == null ||
            this.openedRoom().orderSets[this.openedRoom().itemIndex].orderSetItems[0].qty == 0;
    }


    currentConfig: ConfigurationSet;
    // currentConfigObs = new Subject<ConfigurationSet>(this.currentConfig).asObservable();
    private storeLocaly = true;
    private timestampExpirationTime: number = 15 * 60 * 1000;
    timestampNotExpired(timestamp: Date) {
        let now = new Date();
        if (timestamp != null && (now.getTime() - new Date(timestamp).getTime()) < this.timestampExpirationTime)
            return true;
        return false;
    }
    clearLocalData() {
        this.localStorage.deleteData("localStoreTs");
        this.localStorage.deleteData("openedProjectIndex");
        this.localStorage.deleteData("myProjects");
        this.localStorage.deleteData("likedSkus");
        this.localStorage.deleteData("currentConfiguration");
        this.localStorage.deleteData("busket");
    }

    isLocalStorageNotExpired():boolean {
        if (this.storeLocaly && this.localStorage.exists("localStoreTs"))
            return this.timestampNotExpired(this.localStorage.getDataObject<Date>("localStoreTs"));
        return null;
    }
    private loadLocalChanges() {
        var localStoreTs;
        if (this.storeLocaly && this.localStorage.exists("localStoreTs"))
            localStoreTs = this.localStorage.getDataObject<Date>("localStoreTs");
        let tsNotExpired = this.timestampNotExpired(localStoreTs);

        if (this.storeLocaly && tsNotExpired && this.localStorage.exists("openedProjectIndex"))
            this.openedProjectIndex = this.localStorage.getDataObject<number>("openedProjectIndex");

        var localProjects;
        //TO REM
        if (this.authService.isLoggedIn)
            this.loadMyProjectsFromDB();
        else if (this.storeLocaly && tsNotExpired && this.localStorage.exists("myProjects")) {
            this.projects = this.localStorage.getDataObject<Array<Project>>("myProjects");
        }

        if (this.localStorage.exists("likedSkus"))
            this.likedSkus = this.localStorage.getDataObject<Array<Sku>>("likedSkus");



        if (this.storeLocaly && tsNotExpired && this.localStorage.exists("currentConfiguration")) {
            this.currentConfig = this.localStorage.getDataObject<ConfigurationSet>("currentConfiguration");

            this.selectedFrameColorSku = this.currentConfig.selectedColorSku;
            this.selectedSeriesItem = this.currentConfig.selectedSeries;
            this.selectedFrameShapeSku = this.currentConfig.selectedShapeSku;
            console.log('OrderService.loadLocalChanges: loaded');
        } else {
            console.log('OrderService.loadLocalChanges: nodata');

            this.currentConfig = new ConfigurationSet('Nowy projekt');
        }
        this.selectSeries(this.currentConfig.selectedSeries);
        this.setFrameByColor(this.currentConfig.selectedColorSku)
        this.setFrameByShape(this.currentConfig.selectedShapeSku);

        if (this.storeLocaly && tsNotExpired && this.localStorage.exists("busket")) {
            this.currentBusket = this.localStorage.getDataObject<Busket>("busket");
            //ewentualnie
            //let busket: Busket = Utilities.JSonTryParse(jsonValue); ale wcześniej go załadować

        }
        else {
            let busket =
                {
                    title: null,
                    orderSet: null

                };
        }

    }

    private postProject(p: Project) {
        this.httpclient.post<Project>('/api/UserCart/Projects/', p).subscribe(result => {
            p.id = result.id;
        });
    }


    public saveCurrentProject() {
        this.saveChangesProjectEntity(this.cProject);
    }
    public saveCurrentContextOld() {
        if (this.authService.isLoggedIn)
            this.mergeLocalWithDb(this.projects);
        else
            this.saveCurrentContextLocaly();
    }
    mergeLocalWithDb(localProjects: Array<Project>): any {
        console.log('mergeLocalWithDb');
        this.httpclient.get<Project[]>('/api/UserCart/user/' + this.authService.currentUser.id + '/projects').subscribe(result => {

            console.log('mergeLocalWithDb result not sorted:' + result);
            result.forEach(p => p.rooms.forEach(r => r.orderSets.forEach(
                os => {
                    os.orderSetItems = os.orderSetItems.sort(this.compareOrderSetItems);
                    //console.log(os.orderSetItems[0].id);
                }
            )));
            console.log('mergeLocalWithDb result sorted:' + result);
            this.saveLocalProjectsOld(localProjects, result);
        })
    }

    loadMyProjectsFromDB() {
        console.log('getMyProjects start');
        this.httpclient.get<Project[]>('/api/UserCart/user/' + this.authService.currentUser.id + '/projects').subscribe(result => {
            result.forEach(p => p.rooms.forEach(r => r.orderSets.forEach(
                os => {
                    os.orderSetItems = os.orderSetItems.sort(this.compareOrderSetItems);
                }

            )))
            this.projects = result;
            this.localStorage.savePermanentData(result, "myProjects");
        })
    }
    private compareOrderSetItems(osi1: OrderSetItem, osi2: OrderSetItem): number {
        //console.log("compare sets: " + osi1.id + " - " + osi2.id);
        if (osi2 == null && osi1 == null)
            return 0;
        else if (osi2 == null)
            return 1;
        else if (osi1 == null)
            return -1;
        else if (osi1.id > osi2.id)
            return 1;
        else if (osi1.id < osi2.id)
            return -1;
        else
            return 0;
    }

    localMyProjects(): Project[] {
        if (this.storeLocaly && this.isLocalStorageNotExpired() && this.localStorage.exists("myProjects")) {
            return this.localStorage.getDataObject<Array<Project>>("myProjects");
        }
        return null;
    }
    public saveLocalProjectsOnUserAccount() {
        let local = this.localMyProjects()
        var newSaved = false;
        console.log('set remote projects as current');
        if (local != null && this.authService.currentUser && this.authService.currentUser.id)
            for (let p of local) {
                if (p.id == null || p.id <= 0) {
                    newSaved = true;
                    p.user = new User(this.authService.currentUser.id);
                    this.postProject(p);
                }
            }
        return newSaved;
    }

    private saveLocalProjectsOld(projects: Project[], remote: Project[]) {
        var newSaved = false;
        var i = 0;
        console.log('set remote projects as current');
        this.projects = remote;
        if (projects != null) for (let p of projects) {
            if (p.id == null || p.id <= 0) {
                newSaved = true;
                p.user = new User(this.authService.currentUser.id);
                this.postProject(p);
            } else if (p.user && this.authService.currentUser && p.user.id == this.authService.currentUser.id) {
                console.log("update project: " + p.id + " old was on position: " + remote.findIndex(rp => rp.id == p.id) + " of: " + remote.length);
                if (remote.findIndex(rp => rp.id == p.id) >= 0) {
                    p.user = new User(this.authService.currentUser.id);
                    this.saveChangesLocalProject(p);
                } else {
                    console.log("Local project id:" + p.id + " not found in the database. Removed manually?");
                }
            }
            i += 1;
        }

        this.saveCurrentContextLocaly();
        return newSaved;
    }

    public saveCurrentContextLocaly() {
        console.log("saveCurrentContextLocaly")
        this.currentConfig.selectedColorSku = this.selectedFrameColorSku;
        this.currentConfig.selectedSeries = this.selectedSeriesItem;
        this.currentConfig.selectedShapeSku = this.selectedFrameShapeSku;
        if (this.storeLocaly) {
            try {
                this.localStorage.savePermanentData(this.currentConfig, "currentConfiguration");
            } catch { }
            this.localStorage.savePermanentData(this.currentBusket, "busket");
            this.localStorage.savePermanentData(this.openedProjectIndex, "openedProjectIndex");
            this.localStorage.savePermanentData(this.projects, "myProjects");
        }
        this.localStorage.savePermanentData(new Date(), "localStoreTs");
    }


    get actRoomIndexSave(): number {
        if (this.cProject.rooms == null) {
            this.actRoomIndex = -1;
            return this.actRoomIndex;
        }
        if (this.actRoomIndex >= this.cProject.rooms.length)
            this.actRoomIndex = 0;
        return this.actRoomIndex
    }
    openedRoom(): Room {
        if (this.cProject == null || this.cProject.rooms == null || this.cProject.rooms.length == 0)
            return null;
        this.initRoomAndOrderSets();
        return this.actRoomIndex < this.cProject.rooms.length ?
            this.cProject.rooms[this.actRoomIndex] : this.cProject.rooms[0]
    }
    openedRoomOrderSet(i=0): OrderSet {
        this.initRoomAndOrderSets();
        return this.cProject.rooms[this.actRoomIndex].orderSets[i];
    }
    get isAnythingInAnyRoom() {
        for (let r of this.cProject.rooms) {
            if(r != null && r.orderSets != null)for (let os of r.orderSets)
                if (os.orderSetItems != null && os.orderSetItems.length > 0 && os.orderSetItems[0].sku != null)
                    return true;
        }
        return false;
    }
    get isAnythingInAnySet() {
        for (let os of this.openedRoom().orderSets) {
            if (os.orderSetItems != null && os.orderSetItems.length > 0 && os.orderSetItems[0].sku!=null)
                return true;
        }
        return false;
    }
    deletePosition(i: number,orderSetIndex=0) {
        //this.alertService.showMessage("Produkt ", this.openedRoomOrderSet.orderSetItems[i].sku.code + " został usunięty z zestawu.", MessageSeverity.warn);
        FunctionHelperComponent.remSkuInOrderSet(this.openedRoomOrderSet(orderSetIndex),
            this.openedRoomOrderSet(orderSetIndex).orderSetItems[i].sku);
        if (this.openedRoomOrderSet(orderSetIndex) != null &&
            this.openedRoomOrderSet(orderSetIndex).orderSetItems != null &&
            this.openedRoomOrderSet(orderSetIndex).orderSetItems.length == 1 &&
            this.openedRoomOrderSet(orderSetIndex).orderSetItems[0].qty == 0)
            this.openedRoomOrderSet(orderSetIndex).orderSetItems[0].qty = 1;
        this.saveCurrentContextOld();
        this.saveCurrentProject();
        this.changedAfterOrder = true;
    }



    eCommerceUrls = '[{"code":"H4jDhq9f","url":"https://www.dobregniazdka.pl/schneider,add_to_cart.html","name":"dobregniazdka","method":"POST","prefix":"DG"},'
        + '{"code":"h4jdhq9f","url":"https://www.dobregniazdka.pl/schneider,add_to_cart.html","name":"dobregniazdka","method":"POST","domain":"ohi.dobierz-gniazdko.pl","prefix":"DG"},'
        + '{ "code":"S94jDn4H","url":"https://www.tim.pl/koszyk/api","name":"TIM","method":"POST","prefix":"TI"},'
        + '{ "code":"s94jdn4h","url":"https://www.tim.pl/koszyk/api","name":"TIM","method":"POST","domain":"tim.dobierz-gniazdko.pl","prefix":"TI"},'
        + '{ "code":"f826b340","url":"https://onelectro.pl/koszyk","name":"ONE","method":"POST","domain":"onelectro.dobierz-gniazdko.pl","prefix":"OE"},'//test
        // + '{ "code":"f826b340","url":"http://elektromad.atomstore.pl/koszyk","name":"ONE","method":"POST","domain":"elektromad.atomstore.pl","prefix":"OE"},'//prod
        //+ '{ "code":"d8aysda","url":"https://qa.b2b.asaj.unity.pl/api/schneider-electric","name":"Asaj","method":"POST","domain":"asaj.dobierz-gniazdko.pl","prefix":"AS"},'
        + '{ "code":"112233","url":"https://www.dobregniazdka.pl/schneider,add_to_cart.html","name":"TIM","method":"POST","domain":"localhost2","prefix":"LH"},'
        + '{ "code":"39rjm4r3","url":"http://185.247.118.115/shoperSe","name":"unilight","method":"POST","domain":"kg.dobierz-gniazdko.pl","prefix":"UNL"},'
        + '{ "code":"rrer9fk","url":"https://epstryk.pl/","name":"epstryk","method":"GET","domain":"epstryk.dobierz-gniazdko.pl","prefix":"EPS"},'
        + '{ "code":"fsdj304923weior","url":"https://citrono.pl/configurator_products/action/addtocart","name":"citrono","method":"POST","domain":"citrono.dobierz-gniazdko.pl","prefix":"CTR"},'
        + '{ "code":"da3e9jne2i","url":"https://sonepar.pl/konfiguratory.html","name":"sonepar","method":"POST","domain":"www.sonepar.dobierz-gniazdko.pl","prefix":"SONEPAR"},'
        + '{ "code":"f92305kf","url":"https://elektrotech:e1EktRo@elektrotech.s15.grupakmk.pl/configurator_products/action/addtocart","name":"elektrotech","method":"POST","domain":"elektrotech.dobierz-gniazdko.pl","prefix":"ET"}]';
    jsonbodySource = new Subject<ProjectOrder>();
    jsonbody$ = this.jsonbodySource.asObservable();
    ecommerceEndpooint: string = null;
    target: string = null;

    openEcommerce() {
        let code = this.readStorageDataObject("eCommerceId");
        if (code != null) {
            this.forwardToEcommerce();
        } else if (window.location.hostname == "asaj.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('Asaj')
        } else if (window.location.hostname == "ohi.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('dobregniazdka')
        } else if (window.location.hostname == "tim.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('TIM')
        } else if (window.location.hostname == "onelectro.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('ONE')
        } else if (window.location.hostname == "elektrotech.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('elektrotech')
        } else if (window.location.hostname == "citrono.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('citrono')
        }
        else if (window.location.hostname == "kg.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('unilight')
        }
        else if (window.location.hostname == "epstryk.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('epstryk')
        }
        else if (window.location.hostname == "alfaelektro.dobierz-gniazdko.pl" || window.location.hostname == "www.alfaelektro.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('alfa')
        }
        else if (window.location.hostname == "sonepar.dobierz-gniazdko.pl" || window.location.hostname == "www.sonepar.dobierz-gniazdko.pl") {
            this.forwardToEcommerce('sonepar')
        }
        else if (this.authService.isLoggedIn &&
            (this.authService.currentUser.roles[0] == 'Pracownik' || this.authService.currentUser.roles[0] == 'Employee' ||
            this.authService.currentUser.roles[0] == 'EMPL')
        ) {
            console.log("forwardToEcommerce:target dobre gniazdka for " + this.authService.currentUser.roles[0]);
            this.forwardToEcommerce('dobregniazdka')
        } else {
            const dialogRef = this.dialog.open(EcommerceRedirectComponent, {
                data: this
            });

            dialogRef.afterClosed().subscribe(result => {

            });
        }
    }
    orderIdWithPrefix(prefix: string): string {
        return prefix + this.order.requestId;
    }

    orderWithPrefix(prefix: string): string {

        let newOrder: ProjectOrder = {
            cart: this.order.cart,
            projectId: this.order.projectId,
            requestId: prefix + this.order.requestId,
            userCode: this.order.userCode
        }
        return JSON.stringify(newOrder);
    }

    forwardToEcommerce(target: string = null) {

        this.target = target;
        console.log("forwardToEcommerce:target" + target);
        if (target != null) {
            this.ecommerceEndpooint = JSON.parse(this.eCommerceUrls).find(p => p.name == target).url;
            this.ecommerceEndpooint = JSON.parse(this.eCommerceUrls).find(p => p.name == target).method;
            this.eCommercePrefix = JSON.parse(this.eCommerceUrls).find(p => p.name == target).prefix;
            this.eCommerceName = JSON.parse(this.eCommerceUrls).find(p => p.name == target).name;
        } else {
            let code = this.localStorage.getDataObject("eCommerceId");
            if (code != null) {
                this.ecommerceEndpooint = JSON.parse(this.eCommerceUrls).find(p => p.code == code).url;
                this.ecommerceMethod = JSON.parse(this.eCommerceUrls).find(p => p.code == code).method;
                this.eCommercePrefix = JSON.parse(this.eCommerceUrls).find(p => p.code == code).prefix;
                this.eCommerceName = JSON.parse(this.eCommerceUrls).find(p => p.code == code).name;
            }
        }
        console.log("forwardToEcommerce:ecommerceEndpooint" + this.ecommerceEndpooint);
        this.cartFromProject();
    }
    changed = true;
    cartFromProject(callback: any = this.generateCart) {
        console.log("cartFromProject: " + this.cProject);
        let p = this.cProject
        if (!this.changedAfterOrder) {
            return;        }
        if (p.id == null) {
            console.log("cartFromProject p.id = null");
            this.httpclient.post<Project>('/api/UserCart/Projects/', p).subscribe(result => {
                p.id = result.id;
                this.saveCurrentContextLocaly();
                console.log("cartFromProject project stored");
                //this.generateCart(p);
                callback(p);
            });
        } else {
            this.httpclient.put<Project>('/api/UserCart/projects/' + p.id, p).subscribe(result => {
                p.id = result.id;
                this.saveCurrentContextLocaly();
                console.log("cartFromProject p.id != null");
                //this.generateCart(p);
                callback(p);
            });

        }

    }
    generateCart(p: Project) {
        console.log("generateCart");
        this.httpclient.get<ProjectOrder>('/api/UserCart/createOrder/' + this.cProject.id)
            .subscribe(
                result => this.executeForwardToCommerce(this.orderWithPrefix(this.eCommercePrefix))
            );
    }
    setValuesforEcommerce(): any {
        let epConfig = JSON.parse(this.eCommerceUrls).find(p => p.domain == window.location.hostname);
        if (epConfig != null) {
            this.ecommerceEndpooint = epConfig.url;
            this.ecommerceMethod = epConfig.method;
            this.eCommercePrefix = epConfig.prefix;
            this.eCommerceName = epConfig.name;
        } else if (this.authService.isLoggedIn &&
            (this.authService.currentUser.roles[0] == 'Pracownik' || this.authService.currentUser.roles[0] == 'Employee' ||
                this.authService.currentUser.roles[0] == 'EMPL')
            ) {
            console.log("set endpoint:target dobre gniazdka for " + this.authService.currentUser.roles[0]);
            let epConfig = JSON.parse(this.eCommerceUrls).find(p => p.name == 'dobregniazdka');
            if (epConfig != null) {
                this.ecommerceEndpooint = epConfig.url;
                this.ecommerceMethod = epConfig.method;
                this.eCommercePrefix = epConfig.prefix;
                this.eCommerceName = epConfig.name;
            }
        }
    }
    executeForwardToCommerce(json: string) {
        var mapForm = document.createElement("form");
        mapForm.target = "_self";
        mapForm.action = this.ecommerceEndpooint;
        mapForm.method = JSON.parse(this.eCommerceUrls).find(p => p.url == mapForm.action).method;
        if (mapForm.action != null) {
            var mapInput = document.createElement("input");
            mapInput.type = "hidden";
            mapInput.name = 'request';
            mapInput.id = "orderform";
            //mapInput.formTarget = '_blank';
            mapInput.setAttribute("value", json);
            mapForm.appendChild(mapInput);
            document.body.appendChild(mapForm);
            mapForm.submit();
            //mapInput.click();
        }
        console.log("mapForm.action = null; no cart sent");

    }
    changedAfterOrder = false;
    cartId = null;
    get timestamp() {
        var d = new Date();
        return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    }
    updatingCart = false;
    //init cart during parent initialisation
    cartFromProjectAndCallback(cbk: Function) {
        this.updatingCart = true;
        console.log("cartFromProject: " + this.cProject);
        let p = this.cProject
        if (p.id == null) {
            console.log("cartFromProject p.id = null");
            this.httpclient.post<Project>('/api/UserCart/projects/', p).subscribe(result => {
                p.id = result.id;
                this.saveCurrentContextLocaly();
                console.log("cartFromProject project stored");
                this.generateCartAndCallback(p, cbk);
            });
        } else {
            p = this.paddingOfProject(p);
            this.httpclient.put<Project>('/api/UserCart/projects/' + p.id, p).subscribe(result => {
                p.id = result.id;
                this.saveCurrentContextLocaly();
                console.log("cartFromProject p.id != null");
                this.generateCartAndCallback(p, cbk);
            });

        }

    }
    paddingOfProject(p: Project) {
        p.rooms.forEach(r => this.paddingOfRoom(r));
        return p;
    }
    paddingOfRoom(r: Room) {
        r.orderSets.forEach(os => this.paddingOfOrderSet(os))
    }
    paddingOfOrderSet(os: OrderSet) {
        if (os.id == null)
            os.id = 0;
        os.orderSetItems.forEach(osi => this.paddingOfOrderSetItems(osi))
    }
    paddingOfOrderSetItems(osi: OrderSetItem) {
        if (osi !=null && osi.id == null)
            osi.id = 0;
    }

    generateCartOnCurrentAndCallback(t, cbk) {
        t.setValuesforEcommerce()
        t.generateCartAndCallback(t.cProject, cbk);
    }

    generateCartAndCallback(p: Project, callback: Function) {
        console.log("generateCart");

        if (this.cartId == null || this.changedAfterOrder)
            this.httpclient.get<ProjectOrder>('/api/UserCart/createOrder/' + this.cProject.id)
                .subscribe(
                    result => {
                        this.order = result;
                        this.orderString = JSON.stringify(result);
                        this.cartId = result.requestId;
                        this.changedAfterOrder = false;
                        this.updatingCart = false;
                        this.generateUnitsList(p, callback);
                    }
                );
        else
            callback(this.cartId)
    }
    containsComplexSkus = false;
    unitProjectSkus: OrderSetItem[] = null;
    generateUnitsList(p: Project, callback: Function) {
        this.containsComplexSkus = false;
        console.log("generateUnitsList:  ")
        p.rooms.forEach(r => {
            r.orderSets.forEach(os => {
                os.orderSetItems.forEach(osi => {
                    if (osi?.sku?.code?.includes("+"))
                        this.containsComplexSkus = true;
                })
            })
        });
        if (this.containsComplexSkus) {
            this.httpclient.get<OrderSetItem[]>('/api/UserCart/unitsTable/' + p.id).subscribe(result => {
                console.log("adding unitsTable: " + result)
                this.unitProjectSkus = result;
                callback();
            });
        } else {
            callback();
        }

    }

    notFrameOfMechWithFrame(orderSetItems: Array<OrderSetItem>, i: number): boolean {
        return i != 0 || orderSetItems.length < 2 ||
            orderSetItems[i + 1] == null ||
            orderSetItems[i + 1].sku == null ||
            orderSetItems[i + 1].qty == null || orderSetItems[i + 1].qty == 0 ||
            !this.frameIncluded(orderSetItems[i].sku, orderSetItems[i + 1].sku);
    }
    frameIncluded(frame: Sku, mech: Sku) {
        return mech.withFrame
            && frame.slots == 1
            && mech.color.title == frame.color.title;
    }
}
