
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AboutComponent } from "./components/about/about.component";
import { B2BConfigComponent } from './components/b2b/config.component';
import { B2CConfigComponent } from './components/b2c/config.component';
import { AccountComponent } from './components/login/account.component';
import { AccountActivationComponent } from './components/login/activate.component';
import { ChangePassComponent } from './components/login/change-pass.component';
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from './components/login/register.component';
import { ResetPassComponent } from './components/login/reset-pass.component';
import { SesaRegisterComponent } from './components/login/sesa-register.component';
import { HomeMainComponent } from './components/main/main.component';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { B2ConfigModule } from './components/b2c/B2C-config.module';
import { B2BConfigModule } from './components/b2b/B2B-config.module';
import { AccountRegisterSentComponent } from './components/login/register-sent.component';
import { CodeRequestSentComponent } from './components/login/code-request-sent.component';
import { InspirationsMainComponent } from './components/inspirations/main.component';
//import { B2XConfigModule } from './components/main/B2X-config.module';




@NgModule({
    imports: [
        RouterModule.forRoot([
            //{ path: "", component: HomeMainComponent, data: { title: "Strona główna" } },
            //{ path: "login", component: LoginComponent, data: { title: "Logowanie" } },
            //{ path: "customers", component: CustomersComponent, canActivate: [AuthGuard], data: { title: "Klienci" } },
            //{ path: "products", component: ProductsComponent, canActivate: [AuthGuard], data: { title: "Products" } },
            //{ path: "orders", component: OrdersComponent, canActivate: [AuthGuard], data: { title: "Orders" } },
            //{ path: "settings", component: SettingsComponent, canActivate: [AuthGuard], data: { title: "Settings" } },
            //{ path: "about", component: AboutComponent, data: { title: "About Us" } },
            //{ path: "home", redirectTo: "/", pathMatch: "full" },
            //{ path: "register", component: RegisterComponent, pathMatch: "full" },
            //{ path: "se/register", component: SesaRegisterComponent, pathMatch: "full" },
            //{ path: "reset", component: ResetPassComponent, pathMatch: "full" },
            //{ path: "change", component: ChangePassComponent },
            //{ path: "activate", component: AccountActivationComponent },
            //{ path: "collect-functions", component: CollectFunctionsComponent, data: { title: "Schneider-Electric Konfigurator" } },
            //{ path: "b2config", component: B2CConfigComponent, data: { title: "Schneider-Electric Konfigurator" } },
            //{ path: "b2bconfig", component: B2BConfigComponent, data: { title: "Schneider-Electric Szybka Konfiguracja" } },
            //{ path: "b2bfunctions", component: B2BConfigComponent, canActivate: [AuthGuard], data: { title: "Schneider-Electric Konfigurator" } },
            /*{ path: "**", component: NotFoundComponent, data: { title: "Page Not Found" } },*/

            { path: "", component: HomeMainComponent, data: { title: "Strona główna" } },
            { path: "logowanie", component: LoginComponent, data: { title: "Logowanie" } },
            { path: "polityka-prywatnosci", component: AboutComponent, data: { title: "About Us" } },
            { path: "glowna", redirectTo: "/", pathMatch: "full" },
            { path: "rejestracja", component: RegisterComponent, pathMatch: "full" },
            { path: "se/rejestracja", component: SesaRegisterComponent, pathMatch: "full" },
            { path: "se", component: SesaRegisterComponent, pathMatch: "full" },
            { path: "SE", component: SesaRegisterComponent, pathMatch: "full" },
            { path: "odzyskaj-konto", component: ResetPassComponent, pathMatch: "full" },
            { path: "moje-konto", component: ChangePassComponent, pathMatch: "full" },
            { path: "haslo", component: ChangePassComponent },
            { path: "aktywuj", component: AccountActivationComponent },
            { path: "rejestracja-wyslana", component: AccountRegisterSentComponent },
            { path: "kod-aktywacyjny", component: CodeRequestSentComponent },
            { path: "wybierz-design", loadChildren: () => import('./components/b2c/B2C-config.module').then(m => m.B2ConfigModule)},
            { path: "szybka-konfiguracja", loadChildren: () => import('./components/b2b/B2B-config.module').then(m => m.B2BConfigModule) },
            { path: "admin", loadChildren: () => import('./components/admin/admin-config.module').then(m => m.AdminConfigModule) },
            { path: "inspiracje", component: InspirationsMainComponent },
            
            
            /*/
            { path: "wybierz-design", loadChildren: "./components/main/B2X-config.module#B2XConfigModule" },
            { path: "szybka-konfiguracja", loadChildren: "./components/main/B2X-config.module#B2XConfigModule" },
            */
             /*
            { path: "wybierz-design/projekty", component: B2CConfigComponent, data: { title: "Schneider-Electric Wybierz Design" } },
            { path: "wybierz-design/pokoje", component: B2CConfigComponent, data: { title: "Schneider-Electric Wybierz Design" } },
            { path: "wybierz-design/serie", component: B2CConfigComponent, data: { title: "Schneider-Electric Wybierz Design" } },
            { path: "wybierz-design/ramki", component: B2CConfigComponent, data: { title: "Schneider-Electric Wybierz Design" } },
            { path: "wybierz-design/funkcje", component: B2CConfigComponent, data: { title: "Schneider-Electric Wybierz Design" } },
            { path: "wybierz-design/zestawienie", component: B2CConfigComponent, data: { title: "Schneider-Electric Wybierz Design" } },
            */
            
            
            { path: "**", redirectTo: "/" }
            
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthService, AuthGuard
    ]
})
export class AppRoutingModule { }
