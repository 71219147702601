import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { B2BRoutingModule } from './B2B-routing.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BootstrapTabDirective } from '../../directives/bootstrap-tab.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { B2BConfigComponent } from './config.component';
import { RoomButton } from '../b2c/rooms-selection.component';
import { SeriesButton } from '../b2c/series-selection.component';
import { B2BProjectSelectionComponent } from './project-selection.component';
import { ProjectNameDialog } from '../controls/project-name.component';
import { SkuImageDialog } from './controls/sku-image-dialog.component';
import { ChangeSeriesDialog } from './controls/change-series-dialog.component';
import { ChangeColorDialog } from './controls/change-color-dialog.component';
import { ChangeSeriesGlobalDialog } from './controls/change-series-global-dialog.component';
import { SkuDetailsDialog } from './controls/sku-details-dialog.component';
import { ChangeColorGlobalDialog } from './controls/change-color-global-dialog.component';
import { B2BNewProjectDialog } from './controls/new-project-dialog.component';
import { B2BRoomSelectionComponent } from './room-selection.component';
import { B2BStartDialogComponent } from './start-dialog.component';
import { EcommerceRedirectComponent } from '../controls/ecommerce-redirect.component';
import { ExportConfigComponent } from '../controls/export-config.component';
import { NavMenuComponent } from '../../nav-menu/nav-menu.component';
import { AlertComponent } from '../../_directives';
import { LoginComponent } from '../login/login.component';
import { AccountComponent } from '../login/account.component';
import { B2BCollectFunctionsComponent } from './collect-functions.component';
import { FunctionHelperComponent } from './function-helper.component';
import { StylingHelper } from '../../_helpers/StylingHelper';
import { B2bSummaryComponent } from './controls/side-summary.component';
import { FormsModule } from '@angular/forms';
import { B2ConfigModule } from '../b2c/B2C-config.module';
import { CanDeactivateGuard } from '../../services/can-deactivate-guard.service';
import { SharedModule } from '../main/shared.module';
@NgModule({
    imports: [
        SharedModule,
        B2BRoutingModule,
    ],
    declarations: [B2BConfigComponent, RoomButton, SeriesButton, B2BProjectSelectionComponent,
        SkuDetailsDialog, SkuImageDialog, ChangeSeriesDialog, ChangeColorDialog,
        ChangeSeriesGlobalDialog, ChangeColorGlobalDialog,
        B2BStartDialogComponent, B2BNewProjectDialog, B2BRoomSelectionComponent,
        B2bSummaryComponent,
        B2BCollectFunctionsComponent,
        StylingHelper,
        B2bSummaryComponent
    ],
    entryComponents: [SkuDetailsDialog, SkuImageDialog,
        ChangeSeriesDialog, ChangeColorDialog, ChangeSeriesGlobalDialog, ChangeColorGlobalDialog,
        B2BNewProjectDialog, B2BStartDialogComponent
    ],
    providers: [CanDeactivateGuard]
})
export class B2BConfigModule { }
