<div>
    <form *ngIf="formResetToggle" [attr.autocomplete]="isGeneralEditor ? NaN : null" class="form-horizontal" name="userInfoForm" #f="ngForm" novalidate
          (ngSubmit)="f.form.valid ? save() :

          (!userName.valid && showErrorAlert('User name is required', 'Please enter a user name (minimum of 2 and maximum of 200 characters)'));

          (userPassword && !userPassword.valid && showErrorAlert('Password is required', 'Please enter the current password'));

          (email.errors?.required && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
          (email.errors?.pattern && showErrorAlert('Invalid Email', 'Please enter a valid email address'));

          (isChangePassword && isEditingSelf && !currentPassword.valid && showErrorAlert('Current password is required', 'Please enter the current password'));

          (isChangePassword && !newPassword.valid && showErrorAlert('New password is required', 'Please enter the new password (minimum of 6 characters)'));

          (isChangePassword && confirmPassword.errors?.required && showErrorAlert('Confirmation password is required', 'Please enter the confirmation password'));
          (isChangePassword && confirmPassword.errors?.validateEqual && showErrorAlert('Passwword mismatch', 'New password and comfirmation password do not match'));

          (canAssignRoles && !rolesValid && showErrorAlert('Roles is required', 'Please select a minimum of 1 role'));">

        <div class="form-group">
            <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="jobTitle-{{uniqueId}}">Tytuł</label>
            <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
                <p class="form-control-static">{{user.jobTitle}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
                <input autofocus type="text" attr.id="jobTitle-{{uniqueId}}" name="jobTitle" placeholder="Enter Job Title" class="form-control" [(ngModel)]="userEdit.jobTitle" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div class="form-group has-feedback">
            <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="userName-{{uniqueId}}">Nazwa użytkownika</label>
            <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
                <p class="form-control-static">{{user.userName}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly" [ngClass]="{'has-success': f.submitted && userName.valid, 'has-error' : f.submitted && !userName.valid}">
                <input type="text" attr.id="userName-{{uniqueId}}" name="userName" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter user name"
                       class="form-control" [(ngModel)]="userEdit.userName" #userName="ngModel" required minlength="2" maxlength="200" />
                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': userName.valid, 'glyphicon-remove' : !userName.valid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && !userName.valid" class="errorMessage">
                    {{'users.editor.UserNameRequired'}}
                </span>
            </div>
        </div>

        <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName" class="row">
            <div class="col-md-12">
                <hr class="password-separator-hr" />
            </div>
        </div>
        <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName" class="form-group has-feedback">
            <label class="control-label col-md-2" for="userPassword-{{uniqueId}}">Hasło</label>
            <div class="col-md-10" [ngClass]="{'has-success': f.submitted && userPassword.valid, 'has-error' : f.submitted && !userPassword.valid}">
                <input type="password" attr.id="userPassword-{{uniqueId}}" name="userPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Wprowadź hasło"
                       class="form-control" [(ngModel)]="userEdit.currentPassword" #userPassword="ngModel" required />
                <small class="hint-sm">Hasło</small>
                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': userPassword.valid, 'glyphicon-remove' : !userPassword.valid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && !userPassword.valid" class="errorMessage">
                    Aktualne hasło
                </span>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div class="form-group has-feedback">
            <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="email-{{uniqueId}}">E-mail</label>
            <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
                <p class="form-control-static">{{user.email}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
                <input type="text" attr.id="email-{{uniqueId}}" name="email" placeholder="Enter email address" class="form-control" [(ngModel)]="userEdit.email" #email="ngModel"
                       required maxlength="200" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />
                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': email.valid, 'glyphicon-remove' : !email.valid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && email.errors?.required" class="errorMessage">
                    E-mail jest wymagany
                </span>
                <span *ngIf="showValidationErrors && f.submitted && email.errors?.pattern" class="errorMessage">
                    Niepoprawny e-mail
                </span>
            </div>
        </div>

        <div *ngIf="isEditMode" class="row">
            <div class="col-md-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div *ngIf="isEditMode" class="form-group">
            <label for="newPassword-{{uniqueId}}" class="control-label col-md-2">
                Hasło</label>
                <div *ngIf="!isChangePassword && !isNewUser" class="col-md-10">
                    <button type="button" (click)="changePassword()" class="btn btn-link">Zmień hasło</button>
                </div>

                <div *ngIf="isChangePassword || isNewUser" class="col-md-10">
                    <div class="password-well well well-sm">
                        <div *ngIf="isEditingSelf" class="form-group has-feedback">
                            <label class="control-label col-md-3" for="currentPassword-{{uniqueId}}">{{'users.editor.CurrentPassword'}}</label>
                            <div class="col-md-9" [ngClass]="{'has-success': f.submitted && currentPassword.valid, 'has-error' : f.submitted && !currentPassword.valid}">
                                <input type="password" attr.id="currentPassword-{{uniqueId}}" name="currentPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter current password" class="form-control" [(ngModel)]="userEdit.currentPassword" #currentPassword="ngModel"
                                       required />
                                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': currentPassword.valid, 'glyphicon-remove' : !currentPassword.valid}"></span>
                                <span *ngIf="showValidationErrors && f.submitted && !currentPassword.valid" class="errorMessage">
                                    Aktualne hasło jest wymagane
                                </span>
                            </div>
                        </div>

                        <div *ngIf="!isNewUser" class="row">
                            <div class="col-md-12">
                                <hr class="password-separator-hr" />
                            </div>
                        </div>

                        <div class="form-group has-feedback">
                            <label class="control-label col-md-3" for="newPassword-{{uniqueId}}">{{'users.editor.NewPassword'}}</label>
                            <div class="col-md-9" [ngClass]="{'has-success': f.submitted && newPassword.valid, 'has-error' : f.submitted && !newPassword.valid}">
                                <input type="password" attr.id="newPassword-{{uniqueId}}" name="newPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter new password" class="form-control" [(ngModel)]="userEdit.newPassword" #newPassword="ngModel"
                                       required minlength="6" validateEqual="confirmPassword" reverse="true" />
                                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': newPassword.valid, 'glyphicon-remove' : !newPassword.valid}"></span>
                                <span *ngIf="showValidationErrors && f.submitted && !newPassword.valid" class="errorMessage">
                                    Nowe hasło jest wymagane
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <hr class="password-separator-hr" />
                            </div>
                        </div>

                        <div class="form-group has-feedback">
                            <label class="control-label col-md-3" for="confirmPassword-{{uniqueId}}">{{'users.editor.ConfirmPassword'}}</label>
                            <div class="col-md-9" [ngClass]="{'has-success': f.submitted && confirmPassword.valid, 'has-error' : f.submitted && !confirmPassword.valid}">
                                <input type="password" attr.id="confirmPassword-{{uniqueId}}" name="confirmPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Confirm new password" class="form-control" [(ngModel)]="userEdit.confirmPassword" #confirmPassword="ngModel"
                                       required validateEqual="newPassword" />
                                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': confirmPassword.valid, 'glyphicon-remove' : !confirmPassword.valid}"></span>
                                <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.required" class="errorMessage">
                                    Potwierdzenie hasła jest wymagane
                                </span>
                                <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.validateEqual && !confirmPassword.errors?.required" class="errorMessage">
                                    Hasła nie pasują do siebie
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <!--******************************************************************************************************-->

        <div *ngIf="!isEditMode || canAssignRoles" class="row">
            <div class="col-md-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div *ngIf="!isEditMode || canAssignRoles" class="form-group has-feedback">
            <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="roles-user-info">Role:</label>

            <div *ngIf="isEditMode && canAssignRoles" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly" [ngClass]="{'has-success': f.submitted && rolesValid, 'has-error' : f.submitted && !rolesValid}">

                Aktualne role:
                <mat-form-field>
                    <mat-label>Role: </mat-label>
                    <mat-select [(value)]="userEdit.roles" multiple required class="selectpicker form-control" style="background-color:grey">
                        <mat-option *ngFor="let role of allRoles" [value]="role.name">{{role.name}}</mat-option>
                    </mat-select>
                </mat-form-field>


                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': rolesValid, 'glyphicon-remove' : !rolesValid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && !rolesValid" class="errorMessage">
                    Rola jest wymagana
                </span>
            </div>

        </div>

        <!--******************************************************************************************************-->

        <div class="row">
            <div class="col-md-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div class="form-group">
            <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="fullName-{{uniqueId}}">Pełna nazwa</label>
            <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
                <p class="form-control-static">{{user.fullName}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
                <input type="text" attr.id="fullName-{{uniqueId}}" name="fullName" placeholder="Enter full name" class="form-control" [(ngModel)]="userEdit.fullName" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>
        <!--
    <div class="form-group">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="phoneNumber-{{uniqueId}}">{{'users.editor.PhoneNumber'}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
            <p class="form-control-static">{{user.phoneNumber}}</p>
        </div>
        <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
            <input type="text" attr.id="phoneNumber-{{uniqueId}}" name="phoneNumber" placeholder="Enter phone number" class="form-control" [(ngModel)]="userEdit.phoneNumber" />
        </div>
    </div>
        -->
        <div *ngIf="!isViewOnly" class="row">
            <div class="col-md-12">
                <hr [class.last-separator-hr]="!isEditMode" [class.edit-last-separator-hr]="isEditMode" />
            </div>
        </div>

        <div *ngIf="!isViewOnly" class="form-group">
            <div class="col-sm-5">
                <div *ngIf="isGeneralEditor && isEditMode" class="pull-left">
                    <div class="checkbox user-enabled">
                        <label>
                            <input type="checkbox" name="isEnabled" [(ngModel)]="userEdit.isEnabled">
                            Aktywowane
                        </label>
                    </div>
                    <button *ngIf="userEdit.isLockedOut" type="button" (click)="unlockUser()" class="btn btn-warning unblock-user" [disabled]="isSaving"><i class='fa fa-unlock-alt'></i> {{'users.editor.Unblock'}}</button>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="pull-right">
                    <button *ngIf="!isEditMode && isGeneralEditor" type="button" (click)="close()" class="btn btn-default"><i class='fa fa-close'></i> Zamknij</button>
                    <button *ngIf="!isEditMode && !isGeneralEditor" type="button" (click)="edit()" class="btn btn-default"><i class='fa fa-edit'></i> Edytuj</button>
                    <button *ngIf="isEditMode" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i>Anuluj</button>
                    <button *ngIf="isEditMode" type="submit" class="btn btn-primary" [disabled]="isSaving">
                        <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? 'Zapisuję' : 'Zapisz'}}
                    </button>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </form>
</div>
