import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../_services/index';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppComponent } from '../app.component';
import { SesaRegisterComponent } from './sesa-register.component';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'reset-pass.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService]
})

export class ResetPassComponent {
    model: any = {};

    isLoading = false;
    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) {
        AppComponent.SendEventGa('selection', null, 'Zmiana_hasła otwarcie formularza', 0);
        
    }
    showErrorAlert(message: string) {
        this.alertService.showMessage('e-mail lub NIP jest wymagany', 'Sprawdź poprawność danych', MessageSeverity.error);
    }
    initReset() {
        AppComponent.SendEventGa('selection', null, 'Zmiana_hasła zlecenie restartu', 0);
        this.isLoading = true;
        this.userService.initReset(this.model)
            .subscribe(
                data => {
                    this.alertService.showMessage('Link do zmiany hasła wysłany.', 'Sprawdź email.', MessageSeverity.success);
                    this.router.navigate(['/logowanie']);
                },
            error => {
                console.log(error.error[""][0]);
                if (error.error[""][0] != null) {
                    this.alertService.showMessage('Zmiana nie udana', this.translate(error.error[""][0]), MessageSeverity.error);
                } else {
                    this.alertService.showMessage('Zmiana nie udana', 'Sprawdź login', MessageSeverity.error);
                }

                this.isLoading = false;
            });
    }
    
    signIn() {
        AppComponent.SendEventGa('selection', null, 'Zmiana_hasła przejście to logowania', 0);
        this.router.navigateByUrl('/logowanie');
    }

    signUp() {
        AppComponent.SendEventGa('selection', null, 'Zmiana_hasła przejście to rejestracji', 0);
        let cameFromSesaRegistration = new Boolean(localStorage.getItem(SesaRegisterComponent.SESA_RECENT_VIEWER));
        console.log('cameFromSesaRegistration: ' + cameFromSesaRegistration);
        if (cameFromSesaRegistration != null && cameFromSesaRegistration == true)
            this.router.navigateByUrl('/se');
        else
            this.router.navigateByUrl('/rejestracja');
    }


    translate(text: string) {
        return text.replace('Passwords must have at least one non alphanumeric character.',
            'Hasło musi mieć co najmniej jednen znak specjalny').
            replace("Passwords must have at least one uppercase ('A'-'Z').",
                "Hasło musi mieć co najmniej jedną wielką literę.").replace("Passwords must have at least one digit ('0'-'9').",
                    "Hasło musi mieć co najmniej jedną cyfrę.").replace("Passwords must have at least one lowercase ('a'-'z').",
                        "Hasło musi mieć co najmniej jedną małą literę.").replace("User name", "Konto").replace("is already taken.", "już istnieje.");

    }
}
