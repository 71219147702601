import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../_services/index';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { BaseRegisterComponent } from './base-register.component';
import { InformationDialogComponent, DialogData } from '../controls/information-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from '../app.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'sesa-register.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService]
})


export class SesaRegisterComponent extends BaseRegisterComponent {
    public static SESA_RECENT_VIEWER = 'SERecentViewer';
    step: number = 0;
    showTutorial: Boolean = true;
    SHOW_TUTORIAL = "showTutorial";
    public static ALREADY_REGISTERED = "alreadyRegistered";
    alreadyRegistered: Boolean = null;
    tutorialInfo = ["Załóż konto podając swój służbowy email albo kod aktywacyjny (dzięki niemu będziemy mogli zweryfikować, czy jesteś naszym pracownikiem).",
"Wejdź na swoją pocztę i kliknij w link, aby aktywować konto.",
"Zaloguj się w systemie i dobierz ulubione łączniki oraz gniazda spośród 3 serii: Asfora, Sedna i Odace (Merten już wkrótce!)",
"Gdy wybierzesz wszystkie potrzebne produkty, przejdź do Twojej Listy i kliknij ‘Kup w sklepie’.",
        "Zostaniesz przekierowany do naszego Dystrybutora: dobregniazdka.pl. Rabat jest automatycznie naliczany!"]
    tutorialInfo2 = ["", "", "", "", "P.S. Bezpośrednio w sklepie możesz dodać kolejne produkty 😊"];
    
    constructor(
        protected router: Router,
        protected userService: UserService,
        protected alertService: AlertService,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer) {
        super(router, userService, alertService);
        this.model.roles = ["Pracownik"];
        this.showTutorial = new Boolean(localStorage.getItem(this.SHOW_TUTORIAL));
        this.alreadyRegistered = new Boolean(localStorage.getItem(SesaRegisterComponent.ALREADY_REGISTERED));
        localStorage.setItem(SesaRegisterComponent.SESA_RECENT_VIEWER,'true');
    }
    nonBreakingHyphen(s: string) {
        return this.sanitizer.bypassSecurityTrustHtml(s.replace('-', '&#8288;-&#8288;'));
    }


    toggleShowTutorial() {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_pokaż samouczek ustaw: ' + !this.showTutorial, 0);
        console.log("Pokaż samouczek krok: " + this.step);
        this.step = 0;
        this.showTutorial = !this.showTutorial;
        localStorage.setItem(this.SHOW_TUTORIAL, this.showTutorial.toString());
    }
    register() {
        super.register(this.SUCCESS_SUMMARY, this.SUCCESS_DETAILS);
    }
    SUCCESS_SUMMARY = 'Zlecenie rejestracji przyjęte';
    SUCCESS_DETAILS = 'Zaczekaj na potwierdzenie e-mailem';
    
    emailNonSe(email) {
        var emailRregex = /.*@.*\...*/g;
        var seRegex = /.*@se\.com/g;
        var nonSeEmail = email != null &&
            email.match(emailRregex) == email &&
            !(email.match(seRegex) == email);
        return nonSeEmail;
    }
    emailSe(email) {
        var emailRregex = /.*@.*\...*/g;
        var seRegex = /.*@se\.com/g;
        var nonSeEmail = email != null &&
            email.match(emailRregex) == email &&
            (email.match(seRegex) == email);
        return nonSeEmail;
    }

    emailconfirmed: boolean = false;
    get inputValid(): boolean {
        return this.emailValid
            //&& this.consent2
            //&& this.model.sesa != null
            && ((this.emailSe(this.model.email) && this.selectedFlow == 0)
                || (this.selectedFlow == 1 && this.model.code != null))
            && this.consent2 && this.consent3
            && this.passwordConfirm != null
            && this.model.newPassword != null
            && this.passwordConfirm == this.model.newPassword;
    }

    stepCircleClass(i: number) {
        return (i <= this.step) ? 'white' : 'livegreen';
    }
    selectedFlow: number = null;
    scenarioChoiceClass(i: number) {
        return (this.selectedFlow == i) ? 'scenario-choice-selected' : 'scenario-choice';
    }

    requestCode() {
        
        var newDialog: DialogData;
        if (this.model.email != null) {
            AppComponent.SendEventGa('selection', null, 'Rejestracja_Wyślij kod aktywacyjny_email wprowadzaony', 0);
            newDialog = {
                messageTitle: 'Rejestracja',
                messageBody: 'Potwierdź wysłanie prośby o kod aktywacyjny dla: ' + this.model.email,
                url: null,
                confirmCallback: function () { console.log("code request confirmed") }
            };
        } else {
            AppComponent.SendEventGa('selection', null, 'Rejestracja_Wyślij kod aktywacyjny_brak email', 0);
            newDialog = {
                messageTitle: 'Rejestracja',
                messageBody: 'Wprowadź e-mail na który ma być wysłany kod aktywacyjny',
                url: null,
                confirmCallback: null
            };
        }
        setTimeout(() => {
            const dialogRef = this.dialog.open(InformationDialogComponent, {
                width: '350px',
                data: newDialog
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log('dialog confirmed:' + result);
                if (result) {
                    this.callCodeRequest();
                }
            });
        }, 1);
        
    }


    callCodeRequest() {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_Potwierdzenie wysłanie prośby o kod aktywacyjny', 0);

        this.userService.requestCode(this.model)
            .subscribe(
                data => {
                    this.alertService.showStickyMessage("Żądanie wysłane", "Kod aktywacyjny dostaniesz na maila", MessageSeverity.success);
                    this.router.navigate(['/kod-aktywacyjny']);
                },
                error => {
                    if (error.error[""] != null
                        && error.error[""][0] != null) {
                        this.alertService.showMessage('Żądanie nie udane', this.translate(error.error[""][0]), MessageSeverity.error);
                    } else {
                        this.alertService.showMessage('Żądanie nie udane', 'Sprawdź poprawność maila', MessageSeverity.error);
                    }

                    this.isLoading = false;
                });
    }
}
