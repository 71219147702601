import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Project } from "../../_models/catalog";
import { OrderService } from "../../services/orders.service";
import { AppComponent } from "../app.component";

@Component({
    selector: 'app-ecommerce-redirect',
    templateUrl: './ecommerce-redirect.component.html',
    styleUrls: ['./ecommerce-redirect.component.scss']
})
export class EcommerceRedirectComponent {
    orderService: OrderService;
    shift: number = 0;//was used for random rotation of shops
    shops = ['dobregniazdka', 'TIM', 'ONE', 'citrono', 'sonepar']//'alfa']//,'epstryk']//, 'unilight', 'epstryk']//, 'elektrotech', 'alfaelektro']
    shopConfigs: any;
    constructor(public dialogRef: MatDialogRef<Project>,
        @Inject(MAT_DIALOG_DATA) public data: OrderService) {
        this.orderService = data;
        //this.shift = Math.round(Math.random() * 10) % this.shops.length;//was used for random rotation of shops
        this.shopConfigs = JSON.parse(this.orderService.eCommerceUrls)
    }

    goto(target: string) {
        this.orderService.forwardToEcommerce(target);
    }
    shopParam(i: number, param: string): string {
        let name = this.shops[(i + this.shift) % this.shops.length];
        let shop = this.shopConfigs.find(p => p.name == name);
        console.log("i="+i+":"+param+":"+shop[param]);
        return shop[param];
    }

    logAndSubmit(shop: string, formId: string, orderId: string, orderService: OrderService) {
        event.preventDefault();
        orderService.updateOrder(orderId, "redirected");
        AppComponent.SendEventGa('cart', shop, "Otwarcie przekierowania do sklepu", 0);

        var form = <HTMLFormElement>document.getElementById(formId);
        form.submit();
    }
}
