
<div id="tab-content-projects">
    <div class="project-button-new" *ngIf="hasAnyProjects">
        <div class="app-component projectContainer">
            <div (click)="openMyProjects()" class="project-button" title="Utwórz nowy zestaw">
                Moje projekty
            </div>
            <div (click)="openMyProjects()" class="new-project-icon">
                <img src="/images/icons/start project.png" alt="Ikona dodania nowego zestawu" />
            </div>
        </div>
    </div>

    <div class="project-button-new">
        <div class="app-component projectContainer">
            <div (click)="newProjectConfig()" class="project-button" title="Utwórz nowy zestaw">
                Utwórz nowy projekt
            </div>
            <div (click)="newProjectConfig()" class="new-project-icon">
                <img src="/images/icons/dodaj projekt white.png" alt="Ikona dodania nowego zestawu" />
            </div>
        </div>
    </div>

    <div class="project-button-new">
        <div class="app-component projectContainer">
            <div (click)="importProjectConfig()" class="project-button" title="Utwórz nowy zestaw">
                Zaimportuj zestaw
            </div>
            <div><a href="/documents/import template.xlsx">Pobierz formatkę</a></div>
            <div (click)="importProjectConfig()" class="new-project-icon">
                <img src="/images/icons/upload.png" alt="Ikona dodania nowego zestawu" />
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button
            class="cancel-button" id="closeDialogButton" (click)="onNoClick()">
        Anuluj
    </button>
    <!--<button [disabled]="namesInValid" mat-button [mat-dialog-close]="[project,xlsContent]" cdkFocusInitial>Utwórz</button>
        -->
</div>
