import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService, AlertService } from '../../_services/index';
import { AppComponent } from "../app.component";


@Component({
    selector: "app-code-request-sent",
    templateUrl: './code-request-sent.component.html',
    styleUrls: ['./login.component.scss']
})

export class CodeRequestSentComponent {

    constructor(
        private router: Router) {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_wysłanie żądania kodu aktywacyjnego', 0);
    }
    message = "Twoja prośba o kod aktywacyjny została przekazana do operatora. Po weryfikacji Twojego adresu otrzymasz kod aktywacyjny.";
    
    signUp() {
        this.router.navigateByUrl('/se');
    }
}
