import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Output, OnDestroy } from '@angular/core';

import { OrderService, SkuPosition } from '../../../services/orders.service';
import { PresentationToolsComponent } from '../../../services/presentation-tools.component';
import { OrderSet } from '../../../_models/catalog';
import { animate, transition, trigger, state, style } from '@angular/animations';
import { FunctionSelectionComponent } from '../function-selection.component';

@Component({
    selector: 'app-side-summary',
    templateUrl: './side-summary.component.html',
    styleUrls: ['./side-summary.component.scss'],
    animations: [
        trigger('openClosed', [
            state('opened', style({ position: 'fixed', top: ' 25vh', right: '40px' })),
            state('closed', style({
                right: '-248px',
                position: 'fixed',
                top: '25vh'
            })),
            transition('* => *', animate(300))
        ])
    ]
})
/** SideSummary component*/
export class SideSummaryComponent implements OnDestroy {
    public _orderService: OrderService;
    mobileQuery: MediaQueryList;

    fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

    fillerContent = Array.from({ length: 50 }, () =>
        `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

    private _mobileQueryListener: () => void;
    currency(a: number) {
        return this.roundToTwo(a);
    }

    @Output() refreshViewEvent = new EventEmitter<number>();
    deleteOrderSetPosition(orderSetItemIndex: number, orderSetIndex = 0) {
        this.orderService.deleteOrderSetPosition(orderSetItemIndex, orderSetIndex);
        this.refreshViewEvent.emit(orderSetItemIndex);
    }

    roundToTwo(num: number) {
        return (Math.round(num * 100) / 100).toFixed(2).replace('.', ',');
    }
    _parent: FunctionSelectionComponent;
constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public orderService: OrderService) {
        
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this._orderService = orderService;
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    showSummary = false;
    showSummaryToggle() {
        this.showSummary = !this.showSummary;
    }
    shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));


    get numberOfMechanisms() {
        return (this.orderService != null) ? PresentationToolsComponent.numberOfMechanismsInRoom(this.orderService.openedRoom()) : 0;
    }

    get numberOfFrames() {
        return PresentationToolsComponent.numberOfFramesInRoom(this.orderService.openedRoom());
    }
    clearAllSets() {
        console.log("clearAllSets");
        this.orderService.openedRoom().orderSets = new Array<OrderSet>();
    }

    private layers(allCodes: string) {
        if (allCodes == null)
            return [];

        let layers = allCodes.split("+");

        for (var i = 0; i < layers.length; i++) {
            layers[i] = layers[i].trim();
        }
        return layers;
    }
}
