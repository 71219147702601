<div mat-dialog-content>

    <div style="display:flex;flex-direction:row;">
        <h1 style="width: calc(100% - 55px)">Wybierz kolor ściany</h1>
        <div class="tooltip2 clickable" *ngIf="false"
             title="TIKKURILA">
            <img style="width:55px" src="/images/icons/tikkurila.png" />

        </div>
    </div>
    <div class="button-wrapper">
        <div *ngFor="let set of colors" class="button-row-label color-dialog ">
            <div class="colorsettitle">{{set.name}}</div>
            <div clss="button-row">
                <div class="colortail" *ngFor="let color of set.colors">
                    <div [ngStyle]="{'background-color': color.code}" title="{{color.name}}" class="tooltip3 clickable"
                         (click)="setColor(color.code)"></div>
                </div>
            </div>
        </div>
    </div>

    <!--<input type="file" name="file" id="upload-photo" (change)="onFileChanged($event)" hidden />
    <label *ngIf="isFunctionsActivated" class="big-screen file-upload-label" for="upload-photo">
        <img src="/images/icons/upload-black.png" style="height:20px;width:20px;margin-right:3px;margin-top: 0;" />
        Dodaj swoje tło
    </label>
        -->
    <div class="bottom-margin small-screen"></div>
</div>
    <div mat-dialog-actions>
        <button mat-stroked-button type="button" class="cancel-only-button" (click)="onNoClick()">Anuluj</button>
    </div>

