<div class="vertical-center-flex">
    <div class="registration-container center-block">
        <div class="boxshadow">
            <div class="panel-body-left panel-body-livegreen">
                <div class="tutorial-column" *ngIf="showTutorial && step < 5">
                    <div class="panel-description">
                        Jak kupić nasze łączniki i&nbsp;gniazda?
                        To proste!
                    </div>
                    <div class="tutorial-row"><div (click)="step=0" class="step-circle {{stepCircleClass(0)}}">1</div></div>
                    <div class="tutorial-row"><div class="vert-line"></div></div>
                    <div class="tutorial-row"><div (click)="step=1" class="step-circle {{stepCircleClass(1)}}">2</div></div>
                    <div class="tutorial-row"><div class="vert-line"></div></div>
                    <div class="tutorial-row"><div (click)="step=2" class="step-circle {{stepCircleClass(2)}}">3</div></div>
                    <div class="tutorial-row"><div class="vert-line"></div></div>
                    <div class="tutorial-row"><div (click)="step=3" class="step-circle {{stepCircleClass(3)}}">4</div></div>
                    <div class="tutorial-row"><div class="vert-line"></div></div>
                    <div class="tutorial-row"><div (click)="step=4" class="step-circle {{stepCircleClass(4)}}">5</div></div>

                    <div class="tutorial-row">
                        <div class="tutorial-checkbox" (click)="toggleShowTutorial()">
                            <img src="/images/icons/{{!showTutorial?'checked-checkbox-white':'unchecked-checkbox-white'}}.png" />
                            <div>Nie wyświetlaj samouczka</div>
                        </div>
                    </div>
                        

                        <div class="form-group-row">
                            <div class="form-group">
                                <button id="login-button" mat-flat-button *ngIf="!alreadyRegistered"
                                        style="text-align:center;"
                                        type="submit" class="btn btn-primary white-button" [disabled]="isLoading"
                                        (click)="step = 5">
                                    <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                                    <img src="/images/icons/zaloguj-livegreen.png" />
                                    <span class="big-bold">
                                        ZAREJESTRUJ
                                    </span>
                                </button>

                                <button id="login-button" mat-flat-button *ngIf="alreadyRegistered"
                                        style="text-align:center;"
                                        type="submit" class="btn btn-primary white-button" [disabled]="isLoading"
                                        (click)="signIn()">
                                    <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                                    <img src="/images/icons/zaloguj-livegreen.png" />
                                    <span class="big-bold">
                                        ZALOGUJ
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                <div class="info-column" *ngIf="!showTutorial || step == 5">
                    <div class="panel-title">Witaj ponownie</div>
                    <div class="panel-description">
                        Logując się otrzymujesz dostęp na dowolnym urządzeniu, możliwość zapisywania historii projektów i korzystania z rabatu pracowniczego.
                    </div>

                    <div class="form-group-row" style="flex-direction:column;justify-content:center">

                        <div class="tutorial-checkbox" (click)="toggleShowTutorial()">
                            <div>Samouczek</div>
                        </div>

                        <div class="form-group">
                            <button id="login-button" mat-flat-button
                                    style="text-align:center;"
                                    type="submit" class="btn btn-primary white-button" [disabled]="isLoading"
                                    (click)="signIn()">
                                <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                                <img src="/images/icons/zaloguj-livegreen.png" />
                                <span class="big-bold">
                                    ZALOGUJ
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                </div>
            <div class="panel-body-right panel-body-white">
                <div class="tutorial-column" *ngIf="showTutorial && step < 5">
                    <div class="tutorial-row-margin">
                        <div class="panel-description" *ngIf="step == 0">
                            Załóż konto podając swój służbowy email albo kod aktywacyjny (dzięki niemu będziemy mogli zweryfikować, czy jesteś naszym pracownikiem).
                        </div>
                        <div class="panel-description" *ngIf="step == 1">
                            Wejdź na swoją pocztę i kliknij w link, aby aktywować konto.
                        </div>
                        <div class="panel-description" *ngIf="step == 2">
                            Zaloguj się w systemie i dobierz ulubione łączniki oraz gniazda spośród serii: Sedna Design & Elements, Merten, Odace, Asfora i Sedna!
                        </div>
                        <div class="panel-description" *ngIf="step == 3">
                            Gdy wybierzesz wszystkie potrzebne produkty, przejdź do Twojej Listy i kliknij ‘Kup w sklepie’.
                        </div>
                        <div class="panel-description" *ngIf="step == 4">
                            Zostaniesz przekierowany do naszego Dystrybutora: <a href="http://dobregniazdka.pl">dobregniazdka.pl</a>. Rabat jest automatycznie naliczany!
                            <span><br />P.S. Bezpośrednio w sklepie możesz dodać kolejne produkty 😊</span>
                        </div>
                    </div>
                    <div class="tutorial-row-margin">
                        <div class="panel-bigicon">
                            <img src="/images/icons/tutorial{{step+1}}.svg" />
                        </div>
                    </div>
                    <div class="tutorial-row-margin" >

                            <button id="register-button" *ngIf="step > 0" mat-flat-button class="btn btn-primary next-step-button"
                                     (click)="step = step - 1">
                                <img src="/images/icons/back-white-thin.png" />
                                <span class="big-bold">WRÓĆ</span>
                            </button>
                        <button id="register-button" mat-flat-button *ngIf="step < 4"
                                style="text-align:center;"
                                type="submit" class="btn btn-primary next-step-button"
                                (click)="step = step + 1">
                            <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>

                            <span class="big-bold">
                                DALEJ
                            </span>
                            <img src="/images/icons/arrow-right-white.png" style="margin-top: 5px;position: inherit;margin-left: 11px;margin-right: 0px;" />
                        </button>
                        <button id="register-button" mat-flat-button *ngIf="step == 4"
                                style="text-align:center;"
                                type="submit" class="btn btn-primary next-step-button"
                                (click)="step = 5">
                            <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                            <span class="big-bold">
                                PRZEJDŹ DO REJESTRACJI
                            </span>
                            <img src="/images/icons/arrow-right-white.png" style="margin-top: 5px;position: inherit;margin-left: 11px;margin-right: 0px;" />
                        </button>
                    </div>
                </div>
                <div *ngIf="!showTutorial || step == 5" class="info-column">
                    <div class="panel-title">Rejestracja</div>

                    <div class="form-group-row" *ngIf="selectedFlow ==null">
                        <div class="form-group-col">
                            <div class="panel-description">
                                Czy posiadasz e-maila służbowego  w domenie se.com?
                            </div>
                            <div class="form-group-adaptive">
                                <div class="{{scenarioChoiceClass(0)}}"
                                     (click)="selectedFlow = 0">
                                    <div class="text-centered">Mam</div>
                                </div>
                                <div class="scenario-slash" *ngIf="false">
                                    <img src="/images/icons/slash-livegreen.png" />
                                </div>
                                <div class="{{scenarioChoiceClass(1)}}"
                                     (click)="selectedFlow = 1">
                                    <div class="text-centered">Nie mam</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedFlow ==null"></div>
                    <form class="register" name="registerForm" *ngIf="selectedFlow !=null"
                          (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>

                        <div class="form-group-row" *ngIf="false">
                            <div class="form-group has-feedback input-group">
                                <img src="/images/icons/NIP.png" />
                                <div class="field-input" [ngClass]="{ 'has-error': f.submitted }">
                                    <input type="text" id="login-sesa" name="sesa" value="SESA"
                                           prefix="SESA" mask="9999999"
                                           placeholder="Id. pracownika" class="form-control" [(ngModel)]="model.sesa" #sesa="ngModel" />
                                    <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': model.sesa != null, 'glyphicon-remove' : model.sesa == null}" aria-hidden="true"></span>
                                </div>
                            </div>
                        </div>
                        <span class="errorMessage" *ngIf="false && f.submitted && sesa == null">
                            Numer pracownika jest wymagany
                        </span>


                        <div class="form-group-row">
                            <div class="form-group has-feedback input-group">
                                <img src="/images/icons/login.png" />
                                <div class="field-input" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
                                    <input type="email" id="login-email" name="email"
                                           value='se.com' placeholder="e-mail" class="form-control" [(ngModel)]="model.email" #email="ngModel" required />
                                    <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': email.valid, 'glyphicon-remove' : !password.valid}" aria-hidden="true"></span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group-row">
                            <span class="errorMessage" *ngIf="f.submitted && !email.valid">
                                E-mail jest wymagany
                            </span>
                            <div class="errorMessage" *ngIf="false && emailNonSe(this.model.email)">
                                <mat-checkbox class="checkbox-margin" name="emailconfirmed" [(ngModel)]="emailconfirmed">Potwierdź e-mail spoza domeny se.com</mat-checkbox>
                            </div>
                            <div class="errorMessage" *ngIf="selectedFlow == 0 && emailNonSe(this.model.email)">
                                Wprowadź e-mail z domeny se.com.
                            </div>
                        </div>
                        <div class="form-group-row" *ngIf="selectedFlow == 1">
                            <div class="form-group has-feedback input-group">
                                <img src="/images/icons/NIP.png" />
                                <div class="field-input" [ngClass]="{ 'has-error': f.submitted }">
                                    <input type="text" id="login-code" name="code"
                                           placeholder="Kod aktywacyjny" class="form-control" [(ngModel)]="model.code" #sesa="ngModel" />
                                    <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': model.code != null, 'glyphicon-remove' : model.code == null}" aria-hidden="true"></span>
                                </div>
                            </div>
                        </div>
                        <span class="errorMessage" *ngIf="selectedFlow == 1 && (model.code == null || model.code == '')">
                            Kod aktywacyjny jest wymagany. 
                            <span *ngIf="!emailValid">Wprowadź e-mail aby otrzymać kod.</span>
                            <span *ngIf="emailValid" (click)="requestCode()">Kliknij tutaj aby otrzymać kod.</span>
                        </span>
                        <span class="infoMessage">
                            Hasło musi mieć co najmniej 6 znaków.
                        </span>
                        <div class="form-group-row">
                            <div class="form-group has-feedback input-group">
                                <img src="/images/icons/haslo.png" />
                                <div class="field-input" [ngClass]="{'has-success': f.submitted && password.valid, 'has-error' : f.submitted && !password.valid}">
                                    <input type="password" id="login-password" name="password" placeholder="hasło" class="form-control" [(ngModel)]="model.newPassword" #password="ngModel" required />
                                    <span *ngIf="model.newPassword != null" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': passwordStrong, 'glyphicon-remove' : !passwordStrong }" aria-hidden="true"></span>

                                </div>
                            </div>
                        </div>


                        <div class="form-group-row">
                            <div class="form-group has-feedback input-group">
                                <img src="/images/icons/haslo.png" />
                                <div class="field-input" [ngClass]="{'has-success': f.submitted, 'has-error' : f.submitted && passwordConfirm!= null && !passwordConfirm == model.newPassword}">
                                    <input type="password" id="login-password-confirm" name="passwordConfirm" placeholder="potwierdź hasło" class="form-control" [(ngModel)]="passwordConfirm" required />
                                    <span *ngIf="passwordConfirm != null" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': passwordsMatches, 'glyphicon-remove' : !passwordsMatches }" aria-hidden="true"></span>

                                </div>
                            </div>
                        </div>
                        <span class="errorMessage" *ngIf="f.submitted && !password.valid">
                            Hasło jest wymagane
                        </span>

                        <!--<div class="form-group-row">
        <mat-checkbox class="checkbox-margin" name="consent" [(ngModel)]="consent">Akceptuję <a href="http://se.com" target="_blank">regulamin.</a></mat-checkbox>

    </div>-->


                        <div class="form-group-row left">
                            <mat-checkbox class="checkbox-margin" name="consent1" [(ngModel)]="consent1"></mat-checkbox>
                            <span class="consent-text">
                                Wyrażam zgodę na otrzymywanie na wskazany przeze mnie adres e-mail informacji handlowych od Schneider Electric Polska Sp. z o.o. z siedzibą
                                w Warszawie („Schneider Electric”).
                            </span>
                        </div>
                        <div class="form-group-row left">
                            <mat-checkbox class="checkbox-margin" name="consent2" [(ngModel)]="consent2">

                            </mat-checkbox>
                            <span class="consent-text">
                                * Potwierdzam, że zapoznałem się ze <a href="https://dobierz-gniazdko.pl/documents/Common/Konfigurator_Obowi%C4%85zek%20informacyjny17.07.2019.pdf" target="_blank">szczegółowymi informacjami</a>
                                dotyczącymi zasad przetwarzania  moich danych osobowych przez Schneider Electric.
                            </span>
                        </div>
                        <div class="form-group-row left">
                            <mat-checkbox class="checkbox-margin" name="consent3" [(ngModel)]="consent3">

                            </mat-checkbox>
                            <span class="consent-text">
                                * Potwierdzam, że zapoznałem się z <a href="https://dobierz-gniazdko.pl/documents/Common/Regulamin_Konfigurator_Osprz%C4%99tu_Schneider_Electric_17.07.2019.pdf" target="_blank">regulaminem</a> i akceptuję jego postanowienia.
                            </span>
                        </div>
                        <div class="form-group-row left">
                            <span class="consent-text" style="color:red; margin-left:10px">
                                * Pole obowiązkowe
                            </span>
                        </div>


                        <span class="errorMessage" *ngIf="f.submitted && (!consent2 || !consent3)">
                            Potwierdź zapoznanie się z
                            <span class="" *ngIf="!consent2">
                                zasadami przetwarzania danych
                            </span>
                            <span class="" *ngIf="!consent2 && !consent3">
                                i
                            </span>
                            <span class="" *ngIf="!consent3">
                                regulaminem
                            </span>
                            .
                        </span>

                        <div class="form-group-row">


                            <div class="form-group">
                                <button id="register-button" mat-flat-button class="btn btn-primary next-step-button"
                                        (click)="selectedFlow = null">
                                    <img src="/images/icons/back-white-thin.png" />
                                    <span class="big-bold">WRÓĆ</span>
                                </button>
                            </div>
                            <div class="form-group">
                                <button id="register-button" mat-flat-button
                                        type="submit" class="btn btn-primary next-step-button"
                                        [disabled]="!inputValid">
                                    <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                                    <img src="/images/icons/zaloz konto.png" />
                                    <span class="big-bold">
                                        ZAŁÓŻ KONTO
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </div>
    </div>

