<div class="container" style="background: rgba(255,255,255,0.7);">

    <div [@fadeInOut]>
        <div class="row">
            <div class="col-md-9">
                <h3>Polityka prywatności</h3>
                <p>W każdej chwili możesz wrócić do naszej Polityki Prywatności <a href="https://www.se.com/pl/pl/about-us/legal/data-privacy.jsp">TUTAJ</a> i sprawdzić, w jaki sposób przetwarzamy Twoje dane, jeśli będziesz miał co do tego wątpliwości.<br /></p>
</div>
        </div>

    </div>
</div>
