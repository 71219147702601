import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, AfterViewInit, QueryList, ElementRef, Inject, AfterContentInit } from "@angular/core";
import { Sku } from "../_models";

@Component({
    selector: 'app-styling-helper',
    template: ''
})
export class StylingHelper {

    static frameSizes(sku: Sku, isV: boolean) {
        var mlen = 104, slen = 304, ilen = 92;
        switch (sku.series.title) {
            case 'Sedna':
                if (sku.isVertical || isV) {
                    ilen = 107;
                    mlen = 104;
                    slen = 288;
                } else {
                }
                
                console.log('slen = ' + slen);
                break;
            case 'Asfora':
                ilen = 107;
                break;
            case 'Odace':
                slen = 303;
                ilen = 111;
                break;
            case 'Merten':
                slen = 303;
                ilen = 79;
                break;
            case 'Sedna Design & Elements':
                mlen = 103;
                slen = 307;
                ilen = 77;
        }
        return { mlen, slen, ilen }
    }
    //not height/width, but len which is orientation insensitive
    public static frameLenPx(sku: Sku, isV: boolean) :number{
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        return mlen * 2 + sku.slots * slen + (sku.slots-1) * ilen;
    }

    public static frameHighPx(sku: Sku, isV: boolean): number {
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        if (isV || sku.isVertical)
            return mlen * 2 + sku.slots * slen + (sku.slots - 1) * ilen;
        else
            return mlen * 2 + slen;
    }

    public static positionWidthPx(sku: Sku, isV: boolean): number {
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        return mlen * 2 + slen
    }
    public static positionHeightPx(sku: Sku, isV: boolean): number {
        //it is assumed that the mech is square (that's why we use mlen)
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        return mlen * 2 + slen
    }
    /*1-indexed*/
    public static positionLeftPx(sku: Sku, position: number, isV: boolean): number {
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        if (isV || sku.isVertical)
            return 0;
        return (position-1)* (slen + ilen);
    }

    public static positionBottomPx(sku: Sku, position: number, isV: boolean):number {
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        position = sku.slots - position + 1;
        if (!isV && !sku.isVertical)
            return 0;
        return (position - 1) * (slen + ilen);
    }
    public static positionTopPx(sku: Sku, position: number, isV: boolean): number {
        var { mlen, slen, ilen } = StylingHelper.frameSizes(sku, isV);
        //if (isV || sku.isVertical)
        //    return 0;
        return (position - 1) * (slen + ilen);
    }
    public static positionLeftPr(sku: Sku, position: number, isV: boolean):string {
        return 'left: ' + StylingHelper.positionLeftPrValue(sku, position, isV);
    }
    public static positionLeftPrValue(sku: Sku, position: number, isV: boolean): string {
        return (StylingHelper.positionLeftPx(sku, position, isV) / StylingHelper.frameLenPx(sku, isV) * 100).toFixed(1).toString() + '%';
    }

    public static positionBottomPr(sku: Sku, position: number, isV: boolean): string{
        return 'bottom: ' + StylingHelper.positionBottomPrValue(sku, position, isV);
    }
    public static positionBottomPrValue(sku: Sku, position: number, isV: boolean): string {
        if (sku.code == 'S52P742')
            return '0';
        return (StylingHelper.positionBottomPx(sku, position, isV) / StylingHelper.frameLenPx(sku, isV) * 100).toFixed(1).toString() + '%';
    }
}
