import { Component, Input, Inject } from '@angular/core';
import { OrderService } from '../../services/orders.service';
import { ProjectNameDialog } from '../controls/project-name.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Project, Room, OrderSet } from '../../_models';
import { Router } from '@angular/router';
import { MyStepper } from './config.component';
import { B2BNewProjectDialog } from './controls/new-project-dialog.component';
import { AppComponent } from '../app.component';

export interface B2BStartDialogComponentData{
    os: OrderService;
    stepper: MyStepper;
}
@Component({
    selector: 'b2b-start-dialog',
    templateUrl: './start-dialog.component.html',
    styleUrls: ['./start-dialog.component.scss']
})
export class B2BStartDialogComponent {
    orderService: OrderService;
    roomSelected: number = null;
    stepper: MyStepper;
    /** ProjectSelection ctor */
    constructor(
        public dialogRef: MatDialogRef<B2BStartDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: B2BStartDialogComponentData,
        public dialog: MatDialog) {
        this.stepper = data.stepper;
        this.orderService = data.os;
        
    }
    get hasAnyProjects(): boolean {
        return this.orderService.projects != null && this.orderService.projects.filter(p => p.type==0).length > 0;
    }
    newProjectConfig() {
        AppComponent.SendEventGa('operation', 'button', 'B2B_otwarcie nowego projektu z menu projektów', 0);
        B2BNewProjectDialog.newProjectConfig(this);
    }


    importProjectConfig() {
        AppComponent.SendEventGa('operation', 'button', 'B2B_Import formatki z produktami z dialogu startowego', 0);
        B2BNewProjectDialog.newProjectConfig(this,true);
    }

    openMyProjects() {
        AppComponent.SendEventGa('navigation', 'button', 'B2B_Otwarcie moich projektów z dialogu startowego', 0);
        this.close();
    }
    nextStep() {
        this.stepper.next();
        AppComponent.SendEventGa('navigation', 'button', 'B2B_Przejście do kolejnego kroku', this.stepper.step);
        
    }
    close() {
        AppComponent.SendEventGa('navigation', 'button', 'B2B_zamknięcie dialogu startowego', 0);
        this.dialog.closeAll();
    }
    onNoClick() {
        AppComponent.SendEventGa('navigation', 'button', 'B2B_zamknięcie dialogu startowego', 0);
        this.dialog.closeAll();
    }
}
