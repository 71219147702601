<div class="dialog-cohoerntainter">
    <form>
        <h4 style="margin:10px 0;text-align:center">Wybierz rodzaj pomieszczenia</h4>
        <div class="tab-content-rooms">

            <div class="room-container" *ngFor="let roomName of roomLabels;let i = index;">
                <div class="app-component roomContainer {{roomBackground(i)}}">
                    <button type="button" class="room-button" (click)="changeRoom(i)">
                        <div class="room-image">
                            <img src="/images/backgrounds/Mains/real{{i}}.jpg" alt="real1" style="width:100%" />
                        </div>

                    </button>
                    <div class="app-component greenlowlabel">
                        <input autocomplete="off" autocorrect="off" id="room-name-{{i}}" type="text" title="własny tytuł pomieszczenia" value="{{selectedBackground == i && selectedInput? selectedInput.value:roomName}}" class="room-name" />

                    </div>
                </div>
            </div>
        </div>
        <!--
    <div class="row" style="display:flex;margin-bottom:20px;justify-content: center;">
        <input name="room-title" placeholder="Nazwa pomieszczenia" [(ngModel)]="room.title"
               style="font-size:large;border:none;border-bottom:solid;border-width:1px;border-color:black;" />

    </div>
        -->
        <div mat-dialog-actions>
            <button mat-stroked-button type="button" class="cancel-button" (click)="onNoClick()">Anuluj</button>
            <button mat-stroked-button type="submit" (click)="onSubmitClick(room)" class="accept-button" [disabled]="room.title == null || selectedBackground == null">
                Zapisz
            </button>
        </div>
    </form>
</div>
