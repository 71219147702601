<div class="pseudoshadow">
    <div class="tab-content-description">
        <h2>Twoje pomieszczenia</h2>
        <hr class="separator-hr" />
        Dodawaj kolejne pomieszczenia aby kompletować zestawy pod kolory ścian i wystrój.
    </div>
</div>

<div id="tab-content-rooms">

        <div *ngFor="let room of orderService.cProject.rooms; let i = index;"
             class="room-container">
            <div class="app-component roomContainer" >
                <div class="room-button" >
                    <div class="app-component imagelabel" (click)="selectRoom(i)">
                        <img src="/images/backgrounds/Mains/{{room.image}}.jpg" alt="{{room.title}}" style="width:100%" />
                    </div>
                    <div class="app-component greenlowlabel" (click)="selectRoom(i)">
                        {{room.title}}
                    </div>
                    <div class="room-buttons-line">
                        <div id="edit-button" class="room-edit-button" matTooltip="Edytuj pomieszczenie">
                            <img (click)="changeRoom(i)" src="/images/icons/edytuj.png" />
                        </div>
                        <div id="duplicate-button" class="room-edit-button" matTooltip="Duplikuj pomieszczenie">
                            <img (click)="duplicateRoom(i)" src="/images/icons/kopiuj.png" />
                        </div>
                        <div id="del-button" class="room-edit-button" matTooltip="Usuń pomieszczenie">
                            <img (click)="deleteRoom(i)" src="/images/icons/usun.png" />
                        </div>
                    </div>

                </div>

            </div>
        </div>

    <div class="room-button-new room-container">
        <div class="app-component">
            <button class="transient room-aligned" (click)="newRoomConfig()" style="height: 80%;">
                <img src="/images/icons/dodaj pomieszczenie livegreen.png" alt="add" style="width:100%" />
            </button>
            <div class="app-component greenlowlabel" (click)="newRoomConfig()">
                Dodaj nowy
            </div>
        </div>
    </div>

</div>

