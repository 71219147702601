import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'message-dialog',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageDialogComponent {
    
    
    constructor(
        public dialogRef: MatDialogRef<MessageDialogComponent>) {
        
        }
    

    onNoClick(): void {
        this.dialogRef.close(null);
    }

    onSubmitClick() {
        this.dialogRef.close(true);
    }
    
}
