
    <div id="tab-content-projects">

        <div *ngFor="let room of orderService.cProject.rooms; let i = index;" class="project-button-label"
             >
            <div class="room-info-line" (click)="openRoom(i)">
                <div class="project-button" title="{{room.title}}">
                    {{room.title}}
                </div>

                <div class="tile-summary">Suma netto*: <b>{{sumNet(room)[0]}}zł</b></div>
                <div class="tile-summary">Elementów: <b>{{sumNet(room)[1]}}</b></div>
            </div>
            <div class="project-buttons-line">
                <div id="edit-button" class="project-bottom-buttom" matTooltip="Nazwa zetawu">
                    <img class="project-button-icon" (click)="changeRoom(i)" src="/images/icons/edytuj-white.png" />
                </div>
                <div id="duplicate-button" class="project-bottom-buttom" matTooltip="Duplikuj zestaw">
                    <img class="project-button-icon" (click)="duplicateRoom(i)" src="/images/icons/kopiuj-white.png" />
                </div>
                <div id="del-button" class="project-bottom-buttom" matTooltip="Usuń zestaw">
                    <img class="project-button-icon" (click)="deleteRoom(i)" src="/images/icons/usun-white.png" />
                </div>
            </div>
        </div>

        <div class="project-button-new">
            <div class="app-component projectContainer">
                <div (click)="newRoomConfig()" class="project-button" title="Utwórz nowy zestaw">
                    Utwórz nowy zestaw
                </div>
                <div (click)="newRoomConfig()" class="new-project-icon">
                    <img src="/images/icons/dodaj projekt livegreen.png" alt="Ikona dodania nowego zestawu" />
                </div>
            </div>
        </div>

    </div>

