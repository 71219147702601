<!-- Series selected-->
    <div id="series-selection-header" class="pseudoshadow">
        <div class="tab-content-description">
            <h2>Wybierz ramki</h2>
        </div>
    </div>
<!-- filters: series, color, -->
<div class="pseudoshadow">
    <div class="frame-parameters-container">
        <div class="frame-parameter-container not-last-parameter" *ngIf="orderService.selectedSubSeriesItem!=null">
            <div class="selected-series-description">
                <h3>Seria</h3>
                <div class="chosen-series-value-wrapper">
                    <div class="chosen-series-value">
                        {{orderService.selectedSubSeriesItem.title}}
                        </div>
                </div>
            </div>
        </div>
        <div class="frame-parameter-container not-last-parameter not-first-parameter">
            <div class="frame-parameter">
                <h3>Kolor</h3>
                <div *ngIf="selectedFrameColorSku" class="small-button selected-color-label">{{selectedFrameColorSku.color.title}}</div>
                <div class="frame-parameter-values" *ngIf="seriesColorSkus!=null">
                    <div class="frame-parameter-value {{colorSelectedStyle(color)}}" *ngFor="let color of seriesColorSkus" (click)="selectColor(color.color)">
                        <div class="frame-img-bordered"><img src="/images/series/{{color.series.title}}/colors/{{color.color.title}}.png" /></div>
                        <div class="color-title">{{color.color.title}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="frame-parameter-container not-last-parameter not-first-parameter"
             *ngIf="this.orderService.selectedSeriesItem!=null">
            <div class="frame-parameter">
                <h3>Orientacja</h3>
                <div class="frame-parameter-values">
                    <div class="frame-parameter-value" (click)="setOrientation('H')">
                        <div class="frame-img">
                            <img *ngIf="!orientationSelectedHorizontal" src="/images/icons/horizontal-grey.png" />
                            <img *ngIf="orientationSelectedHorizontal" src="/images/icons/horizontal.png" />
                        </div>
                        <div class="big-button" *ngIf="!orientationSelectedHorizontal">Poziome</div>
                        <div class="big-button" *ngIf="orientationSelectedHorizontal">Poziome</div>
                    </div>
                    <div class="frame-parameter-value" (click)="setOrientation('V')">
                        <div class="frame-img">
                            <img *ngIf="!orientationSelectedHorizontal" src="/images/icons/vertical.png" />
                            <img *ngIf="orientationSelectedHorizontal" src="/images/icons/vertical-grey.png" />
                        </div>
                        <div class="big-button" *ngIf="!orientationSelectedHorizontal">Pionowe</div>
                        <div class="big-button" *ngIf="orientationSelectedHorizontal">Pionowe</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="frame-parameter-container not-first-parameter" *ngIf="anyIP44()">
            <div class="frame-parameter">
                <h3>Stopień ochrony</h3>
                <div class="frame-parameter-values">
                    <div class="frame-parameter-value" (click)="toggleWaterProof()">
                        <div class="frame-img">
                            <img *ngIf="waterProof" src="/images/icons/checked-checkbox.png" />
                            <img *ngIf="!waterProof" src="/images/icons/unchecked-checkbox.png" />
                        </div>
                        <div>Bryzgoszczelne (IP44)</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="frameCollectionContainer" class="frame-collection" [ngStyle]="{'background-color': _orderService.wallColorSafe}">
    <!-- Shapes selection -->
    <div class="swiper-container small-screen" [swiper]="config" [(index)]="index">
        <swiper class="swiper-container" fxFlex="auto" [config]="config"
                (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')" (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
            <div class="swiper-slide" *ngFor="let sku of presentedFrameShapes">
                <div class="seriesButton">
                    <div class="frame-image">
                        <img src="/images/series/{{sku.series.title}}/frames/{{sku.code}}.png" alt="{{sku.functionType.title}}" style="width:100%" class="{{rotationStyle(sku)}}" />
                    </div>
                    <div class="series-button-texts">
                        <div class="app-component greenhighlabel">
                            {{sku.functionType.title}}<br />
                        </div>

                        <div class="frame-number-config">
                            <div class="frame-number-manipulation-cell" (click)="decFrame(sku)">
                                <img *ngIf="_orderService.wallColorSafe == 'white'" src="/images/icons/minus.png" />
                                <img *ngIf="_orderService.wallColorSafe != 'white'" src="/images/icons/minus-white.png" />
                            </div>
                            <input class="frame-number-manipulation-cell" type="number" min="0"
                                   onclick="this.select()" (change)="newValueDetected($event,sku)" value="{{_orderService.numberOfSkuItems(sku)}}" />

                            <div class="frame-number-manipulation-cell" (click)="addFrame(sku)">
                                <img *ngIf="_orderService.wallColorSafe == 'white'" src="/images/icons/plus.png" />
                                <img *ngIf="_orderService.wallColorSafe != 'white'" src="/images/icons/plus-white.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </swiper>


        <div class="own-swiper-button-prev"></div>
        <div class="own-swiper-button-next" [hidden]="swiper.isAtLast" (click)="swiper.index = swiper.index + 1"></div>
    </div>

    <div class="frame-container big-screen" *ngFor="let sku of presentedFrameShapes">
        <div *ngIf="sku != null" class="app-component frame-internal-container">
            <div class="seriesButton">
                <div class="frame-image">
                    <img src="/images/series/{{sku.series.title}}/frames/{{sku.code}}.png" alt="{{sku.functionType.title}}" style="width:100%" class="what {{rotationStyle(sku)}}"/>
                </div>
                <div class="series-button-texts">
                    <div class="app-component greenhighlabel">
                        {{sku.functionType.title}}<br />
                    </div>

                    <div class="frame-number-config">
                        <div class="frame-number-manipulation-cell" (click)="decFrame(sku)">
                            <img *ngIf="_orderService.wallColorSafe == 'white'" src="/images/icons/minus.png" />
                            <img *ngIf="_orderService.wallColorSafe != 'white'" src="/images/icons/minus-white.png" />
                        </div>
                        <input class="frame-number-manipulation-cell" type="number" min="0"
                               onclick="this.select()" (change)="newValueDetected($event,sku)" value="{{_orderService.numberOfSkuItems(sku,!this.orientationSelectedHorizontal)}}" />

                        <div class="frame-number-manipulation-cell" (click)="addFrame(sku)">
                            <img *ngIf="_orderService.wallColorSafe == 'white'" src="/images/icons/plus.png" />
                            <img *ngIf="_orderService.wallColorSafe != 'white'" src="/images/icons/plus-white.png" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="presentedFrameShapes == null || presentedFrameShapes.length == 0">
        Aktualnie brak ramek dla Twojej konfiguracji.
        Ramki bryzgoszczelne występują jedynie w wersji poziomej.
        Zmień filtry aby obejrzeć więcej produktów.
    </div>
</div>

<app-side-summary></app-side-summary>
