import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sku } from '../../../_models';
import { AppComponent } from '../../app.component';
import { PresentationToolsComponent } from '../../../services/presentation-tools.component';

export interface SkuDialogData {
    sku: Sku;
}

@Component({
    selector: 'sku-image-dialog',
    templateUrl: './sku-image-dialog.component.html',
    styleUrls: ['./sku-image-dialog.component.css']
})
export class SkuImageDialog {

    constructor(
        public dialogRef: MatDialogRef<SkuImageDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SkuDialogData) {

    }

    onNoClick(): void {
        AppComponent.SendEventGa('sku-image', null, 'B2B_powiększenie produktu zamknięcie', 0);
        this.dialogRef.close();
    }

    layers(allCodes) {
        return PresentationToolsComponent.layers(allCodes).reverse();
    }
}
