import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { B2BConfigComponent } from './config.component';
import { CanDeactivateGuard } from '../../services/can-deactivate-guard.service';


const routes: Routes = [
    {
        path: '', data: {
            title: "Schneider Electric Szybka Konfiguracja"
        },
        component: B2BConfigComponent, canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class B2BRoutingModule { }
