<div id="mainContent" class="app-componentb2b">
    <div class="maincontentcontainerb">
        <div class="header2bck">
            <header class="pageHeaderb2b underlinedHeaderb2b">
                <div class="h3" *ngIf="stepper.step == 0">
                    <div class="titleButtonb2b">
                        <div class="project-flineb2b">
                            <div class="project-labelb2b">Projekty</div>
                        </div>
                    </div>
                </div>
                <div class="h3" *ngIf="openedProject != null">

                    <div *ngIf="stepper.step >= 1" class="titleButtonb2b">
                        <div class="project-flineb2b">
                            <div class="project-action " *ngIf="stepper.step == 1" (click)="stepper.reset()">
                                <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px" />
                            </div>
                            <div class="project-action " *ngIf="stepper.step == 2" (click)="stepper.step = 1">
                                <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px" />
                            </div>
                            <div class="project-action " *ngIf="stepper.step == 3" (click)="stepper.step = 2">
                                <img src="/images/icons/back-white-thin.png" style="height:52px;margin:5px 3px" />
                            </div>
                            <div class="project-prelabelb2b">Projekt:&nbsp;</div><div class="project-labelb2b">{{openedProject.title}}</div>
                        </div>
                    </div>
                    <div *ngIf="stepper.step == 3" class="titleButtonb2b  small-screen" style="justify-content: center;">
                        <div class="project-flineb2b">
                            <div class="project-labelb2b">Twoja lista</div>
                        </div>
                    </div>
                    <div class="titleButtonb2b" *ngIf="stepper.step == 2">
                        <div class="project-flineb2b">
                            <div class="project-prelabelb2b">Zestaw:&nbsp;</div><div class="project-labelb2b">{{openedRoom.title}}</div>
                        </div>

                    </div>
                    <!--
    <button mat-stroked-button class="center-align-button big-screen" *ngIf="stepper.step==3"
            style="top:6px;color: white;text-align:center;padding-left:10px;float:right;display:inline-flex;justify-content: center;height: fit-content;"
            (click)="stepper.prev()">
        Wróć do edycji
    </button>
        -->
                </div>
                <div class="button-container">
                    <button *ngIf="stepper.step>0 && stepper.step<3 && wide" mat-flat-button class="center-align-button"
                            style="text-align:center;" (click)="openSummary()">
                        <img src="/images/icons/koszyk-white.png" style="height: 21px;margin-top:6px;position: inherit;margin-right: 4px;" />
                        <span matBadge="{{collectedProjectSkus}}" matBadgeSize="medium"
                              matBadgePosition="above after"
                              matBadgeOverlap="false" matBadgeColor="basic">Twoja lista</span>
                    </button>

                    <div class="big-header" *ngIf="stepper.step == 3"
                         style="justify-content: center;text-align:center;padding-left:10px;float:right;margin-right: -25px;">
                        <button mat-stroked-button color="warn" class="white-button red-label"
                                style="height: 36px;text-align:center;padding-left:10px" (click)="functionSelector.clearProject()">
                            <img src="/images/icons/usun-red.png" style="height: 21px;position: inherit;align-self: center;margin: 3px;" />
                            Wyczyść projekt
                        </button>

                        <button mat-stroked-button class="center-align-button"
                                style="height: 36px;color: white;text-align:center;padding-left:10px;float:right;display:inline-flex;justify-content: center;"
                                (click)="functionSelector.exportCart()">
                            Eksport
                        </button>
                        <button *ngIf="orderService.ecommerceEndpooint == null " 
                                [class.spinner_button]="orderService.updatingCart" [disabled]="orderService.updatingCart || orderService.order == null"
                                mat-stroked-button class="center-align-button"
                                style="height: 36px;color: white;text-align:center;padding-left:10px;float:right;display:inline-flex;justify-content: center;"
                                (click)="functionSelector.forwardToEcommerce()">
                            Kup w sklepie
                        </button>
                        <form *ngIf="orderService.ecommerceEndpooint != null"
                              
                              ngNoForm
                              action="{{orderService.ecommerceEndpooint}}" target="_blank"
                              id="{{'form_' + orderService.eCommerceName}}"
              (submit)="logAndSubmit(
                orderService.eCommerceName,
                'form_' + orderService.eCommerceName,
                orderService.orderIdWithPrefix(orderService.eCommercePrefix),
                orderService
              )"
                              method="{{orderService.ecommerceMethod}}">
                            <input type="hidden" name="request" value="{{orderService.orderWithPrefix(orderService.eCommercePrefix)}}" />
                            <input class="center-align-button"
                                   [class.spinner_button]="orderService.updatingCart" [disabled]="orderService.updatingCart || orderService.order == null"
                                   style="height: 36px;color: white;text-align:center;padding-left:10px;float:right;display:inline-flex;justify-content: center;"
                                   type="submit" value="Kup w sklepie">
                        </form>

                    </div>
                </div>
            </header>
        </div>
        <div class="b2b-content-wrapper">
            <div class="mobile-nav" *ngIf="!wide && stepper.step==2">
                <div class="mobile-nav-option" (click)="stepper.prev()">
                    <img src="/images/icons/back-livegreen.png" />
                </div>
                <div class="mobile-nav-option" (click)="openCategories()">
                    <img src="/images/icons/icons8-single-choice-filled-50-livegreen.png" />
                </div>
                <div class="mobile-nav-option" (click)="openFilters()">
                    <img src="/images/icons/icons8-filter-50-livegreen.png" />
                </div>
                <div class="mobile-nav-option" (click)="openSummary()">
                    <img src="/images/icons/koszyk-livegreen.png" />
                </div>
            </div>
            <div class="mobile-nav" *ngIf="!wide && stepper.step==3">
                <div class="mobile-nav-option" (click)="stepper.prev()">
                    <img src="/images/icons/back-livegreen.png" />
                </div>
                <div class="mobile-nav-option" (click)="functionSelector.clearProject()">
                    <img src="/images/icons/usun-red.png" />
                </div>
                <div class="mobile-nav-option" (click)="functionSelector.exportCart()">
                    <img src="/images/icons/download-livegreen.png" />
                </div>
                <div *ngIf="orderService.ecommerceEndpooint == null" class="mobile-nav-option" (click)="functionSelector.forwardToEcommerce()">
                    <img src="/images/icons/koszyk-livegreen.png" />
                </div>
                <form *ngIf="orderService.ecommerceEndpooint != null" ngNoForm
                      action="{{orderService.ecommerceEndpooint}}" target="_blank"
                      id="{{'form_' + orderService.eCommerceName}}"
              (submit)="logAndSubmit(
                orderService.eCommerceName,
                'form_' + orderService.eCommerceName,
                orderService.orderIdWithPrefix(orderService.eCommercePrefix),
                orderService
              )"
                      method="{{orderService.ecommerceMethod}}">
                    <input type="hidden" name="request" value="{{orderService.orderWithPrefix(orderService.eCommercePrefix)}}" />
                    <button class="mobile-nav-option" type="submit"
                            [class.spinner_button]="orderService.updatingCart" [disabled]="functionSelector==null || orderService.updatingCart || orderService.order == null"
                            value="Kup w sklepie"><img src="/images/icons/koszyk-livegreen.png" style="vertical-align: middle;position: inherit;" /></button>
                </form>
            </div>

            <div class="container semaincontainerb2b"
                 *ngIf="stepper.step > 1 && openedProject != null">
                <div [@fadeInOut] class="myrow">
                    <div class="col-sm-2 side-menub2b" *ngIf="stepper.step == 2 && (wide || cStepper.step == 0)">
                        <div class="search-wrapper">
                            <input type="text" [(ngModel)]="searchedCode" (change)="searchForCode($event)" placeholder="Numer referencyjny" />
                            <img src="/images/icons/search.png" alt="loop icon" (click)="searchForCodeRun(null)" />
                        </div>
                        <div class="list-group" *ngIf="stepper.step == 2">
                            <div *ngFor="let cg of categoryGroups; let i = index;" style="margin: 0px;">
                                <button type="button" class="btn btn-block flevelcategory" (click)="expandCategoryGroup(cg.id,cg.title)">{{cg.title}}</button>
                                <div class="in" id="slevel2" *ngIf="cg.id==expandedCategoryGroup && cg !=null && cg.categories != null && cg.categories.length>0">
                                    <div *ngFor="let cat of cg.categories; let i = index;">
                                        <!--
    <button type="button" class="btn btn-default btn-block slevelcategory {{ifSelectedCategoryGroup(cat.id)}}" (click)="setCategoryRun(cat.id,cat.title)">
        {{cat.title}}
    </button>
    -->
                                        <button type="button" class="btn btn-default btn-block slevelcategory {{ifSelectedCategoryGroup(cat.id)}}" (click)="setCategoryRun(cat.id,cat.title)">
                                            {{cat.title}}
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                            <div style="margin: 0px;">

                                <button type="button" (click)="openLiked()"
                                        class="btn btn-block flevelcategory {{ifSelectedLiked()}}">
                                    <img class="side-menu-icon" src="/images/icons/star-hollow-darkgray.png" />
                                    <span matBadge="{{likedNumber}}" matBadgeSize="medium"
                                          
                                          matBadgeOverlap="false" matBadgeColor="#626469">Ulubione</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2 side-menub2b side-menu-elk"
                         *ngIf="stepper.step == 3">
                        <div class="list-group" style="width:100%">
                            <div class="elektroklub-logo-login">
                                <div class="elektroklub-logo"><img src="/images/elektroklub.jpg" /></div>
                                <div class="elektroklub-login" style="">
                                    <button mat-flat-button class="next-step-button livegreen-white"
                                            style="text-align:center;align-self:center" (click)="openelk()">
                                        Zaloguj
                                    </button>
                                </div>
                            </div>

                            <div class="points-element-group">
                                <div class="points-element"
                                     *ngIf="functionSelector!=null && functionSelector.functionHelper!=null && functionSelector.functionHelper.functionsAll!=null">
                                    <div class="big-screen">Ilość punktów w&nbsp;Elektroklubie:&nbsp;</div>
                                    <div class="small-screen">Punkty:&nbsp;</div>
                                    <div class="points-in-circle">
                                        {{collectedElkPoints}}
                                    </div>
                                </div>
                                <div class="points-element"
                                     *ngIf="functionSelector!=null && functionSelector.functionHelper!=null && functionSelector.functionHelper.functionsAll!=null">
                                    <div class="big-screen">Suma na karcie VISA:&nbsp;</div>
                                    <div class="small-screen">Karta&nbsp;VISA:&nbsp;</div>
                                    <span class="points-in-circle">
                                        {{this.functionSelector.roundToTwo(collectedElkPoints*0.1)}}&nbsp;zł
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="white" style="padding:10px;width:100%">


                        <div class="tab-content active">
                            <div class="tab-pane active" id="addFunctions">
                                <div [@fadeInOut]  class="content-container">
                                    <b2b-collect-functions #functionSelector
                                                           [catId]="catId"
                                                           [catTitle]="catTitle"
                                                           [stepper]="stepper"
                                                           [cStepper]="cStepper"
                                                           [wide]="wide">
                                    </b2b-collect-functions>
                                </div>
                            </div>
                            <!--
                                    <div class="tab-pane active" id="setupTab">
                                        <div [@fadeInOut] *ngIf="isSetupActivated" class="content-container">
                                            <b2b-fill-setup></b2b-fill-setup>
                                        </div>
                                    </div>
                    -->

                        </div>
                    </div>


                </div>
            </div>
            <!--
    <b2b-start *ngIf="stepper.step == -1" [stepper]="stepper"></b2b-start>
        -->
            <b2b-project-selection *ngIf="stepper.step == 0"
                                   [stepper]="stepper"></b2b-project-selection>
            <b2b-room-selection *ngIf="stepper.step == 1" [stepper]="stepper"></b2b-room-selection>
        </div>


    </div>
</div>
