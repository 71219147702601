import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UsersManagementComponent } from './users-management.component';


const routes: Routes = [
    {
        path: 'uzytkownicy', data: { title: "Schneider-Electric Konfigurator Administracja" },
        component: UsersManagementComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes), NgxDatatableModule],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
