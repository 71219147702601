import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sku, Series } from '../../../_models';
import { AppComponent } from '../../app.component';

export interface ChangeColorDialogData {
    sku: Sku;
    alternateSkus: Sku[];
}

@Component({
    selector: 'change-color-dialog',
    templateUrl: './change-color-dialog.component.html',
    styleUrls: ['./change-color-dialog.component.css']
})
export class ChangeColorDialog {

    constructor(
        public dialogRef: MatDialogRef<ChangeColorDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ChangeColorDialogData) {

    }

    onNoClick(): void {
        AppComponent.SendEventGa('color', 'B2B_Anulowanie zmiany koloru w zestawieniu w kolumnie', 'close');
        this.dialogRef.close();

    }


}
