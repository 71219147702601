<div>
    <div class="registration-container center-block">
        <div class="boxshadow">
            <div class="panel-body-left panel-body-livegreen">

                <div class="panel-title">Witaj ponownie</div>
                <div class="panel-description">
                    Zaloguj się aby korzystać w pełni z możliwości aplikacji.
                    Dostęp na dowolnym urządzeniu i zapisywanie historii projektów.
                </div>
                <div class="form-group-row">
                    <div class="form-group">
                        <button id="login-button" mat-flat-button
                                style="text-align:center;"
                                type="submit" class="btn btn-primary white-button" [disabled]="isLoading"
                                (click)="signIn()">
                            <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                            <img src="/images/icons/zaloguj-livegreen.png" style="margin-top: 5px;position: inherit;margin-right: 11px;margin-left: 0px;" />
                            <span>
                                ZALOGUJ
                            </span>
                        </button>
                    </div>
                </div>

            </div>
            <div class="panel-body-right panel-body-white">
                <div class="panel-title">Rejestracja</div>
                <form class="register" name="registerForm"
                      (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>


                    <div class="form-group-col">
                        <div class="panel-subtitle">Kim jesteś?</div>
                        <div class="form-group form-galery">
                            <div class="role-picture {{isActive('b2c')}}" (click)="setRole('b2c')">
                                <div class="role-picture-img"><img src="/images/klient.png" /></div>
                                <div class="role-picture-label">Osoba prywatna</div>
                            </div>
                            <div class="role-picture {{isActive('b2be')}}" (click)="setRole('b2be')">
                                <div class="role-picture-img"><img src="/images/elektryk.png" /></div>
                                <div class="role-picture-label">Elektryk</div>
                            </div>
                            <div class="role-picture {{isActive('b2ba')}}" (click)="setRole('b2ba')">
                                <div class="role-picture-img"><img src="/images/architekt.png" /></div>
                                <div class="role-picture-label">Architekt</div>
                            </div>
                        </div>
                    </div>

                    <span class="errorMessage" *ngIf="f.submitted && !email.valid">
                        E-mail jest wymagany
                    </span>
                    <div class="form-group-row">
                        <div class="form-group has-feedback input-group">
                            <img src="/images/icons/login.png" />
                            <div class="field-input" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
                                <input type="email" id="login-email" name="email" placeholder="e-mail" class="form-control" [(ngModel)]="model.email" #email="ngModel" required />
                                <span *ngIf="model.email != null" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': emailValid, 'glyphicon-remove' : !emailValid}" aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-row left" *ngIf="nipPossible">
                        <mat-checkbox class="checkbox-margin" name="isElk" [(ngModel)]="isElk" (change)="changeElk()">
                        </mat-checkbox>
                        <span class="consent-text">
                            Jestem w Elektroklubie i chcę otrzymać dodatkowe punkty za projekty konfiguratorze.
                        </span>
                    </div>

            <div class="form-group-row" *ngIf="isElk">
                <div class="form-group has-feedback input-group">
                    <img src="/images/icons/NIP.png" />
                    <div class="field-input" [ngClass]="{ 'has-error': f.submitted }">
                        <input type="text" id="login-nip" name="nip" placeholder="NIP" class="form-control" [(ngModel)]="model.nip" #nip="ngModel" />
                        <span *ngIf="model.nip" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': nipValid, 'glyphicon-remove' : !nipValid}" aria-hidden="true"></span>
                    </div>
                </div>
            </div>

            <span class="infoMessage">
                Hasło musi mieć co najmniej 6 znaków.
            </span>
            <span class="errorMessage" *ngIf="f.submitted && !password.valid">
                Hasło jest wymagane
            </span>
            <div class="form-group-row">
                <div class="form-group has-feedback input-group">
                    <img src="/images/icons/haslo.png" />
                    <div class="field-input" [ngClass]="{'has-success': f.submitted && password.valid, 'has-error' : f.submitted && !password.valid}">
                        <input type="password" id="login-password" name="password" placeholder="hasło" class="form-control" [(ngModel)]="model.newPassword" #password="ngModel" required />
                        <span *ngIf="password != null" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': passwordStrong, 'glyphicon-remove' : !passwordStrong}" aria-hidden="true"></span>

                    </div>
                </div>
            </div>
            <div class="form-group-row">
                <div class="form-group has-feedback input-group">
                    <img src="/images/icons/haslo.png" />
                    <div class="field-input" [ngClass]="{'has-success': f.submitted && passwordsMatches, 'has-error' : f.submitted && !passwordsMatches}">
                        <input type="password" id="login-password-confirm" name="passwordConfirm" placeholder="potwierdź hasło" class="form-control" [(ngModel)]="passwordConfirm" required />
                        <span *ngIf="passwordConfirm != null" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': passwordsMatches, 'glyphicon-remove' : !passwordsMatches }" aria-hidden="true"></span>

                    </div>
                </div>
            </div>
            <!--
    <div class="form-group-row">
        <mat-checkbox class="checkbox-margin" name="consent" [(ngmodel)]="consent">akceptuję <a href="http://se.com" target="_blank">regulamin.</a></mat-checkbox>
    </div>
              -->

            <div class="form-group-row left">
                <mat-checkbox class="checkbox-margin" name="consent1" [(ngModel)]="consent1"></mat-checkbox>
                <span class="consent-text">
                    Wyrażam zgodę na otrzymywanie na wskazany przeze mnie adres e-mail informacji handlowych od Schneider Electric Polska Sp. z o.o. z siedzibą
                    w Warszawie („Schneider Electric”).
                </span>
            </div>
            <div class="form-group-row left">
                <mat-checkbox class="checkbox-margin" name="consent2" [(ngModel)]="consent2">

                </mat-checkbox>
                <span class="consent-text">
                    * Potwierdzam, że zapoznałem się ze <a href="https://dobierz-gniazdko.pl/documents/Common/Konfigurator_Obowi%C4%85zek%20informacyjny17.07.2019.pdf" target="_blank">szczegółowymi informacjami</a>
                    dotyczącymi zasad przetwarzania  moich danych osobowych przez Schneider Electric.
                </span>
            </div>
            <div class="form-group-row left">
                <mat-checkbox class="checkbox-margin" name="consent3" [(ngModel)]="consent3">

                </mat-checkbox>
                <span class="consent-text">
                    * Potwierdzam, że zapoznałem się z <a href="https://dobierz-gniazdko.pl/documents/Common/Regulamin_Konfigurator_Osprz%C4%99tu_Schneider_Electric_17.07.2019.pdf" target="_blank">regulaminem</a> i akceptuję jego postanowienia.
                </span>
            </div>
            <div class="form-group-row left">
                <span class="consent-text" style="color:red; margin-left:10px">
                    * Pole obowiązkowe
                </span>
            </div>

            <span class="errorMessage" *ngIf="f.submitted && (!consent2 || !consent3)">
                Potwierdź zapoznanie się z
                <span class="" *ngIf="!consent2">
                    zasadami przetwarzania danych
                </span>
                <span class="" *ngIf="!consent2 && !consent3">
                    i
                </span>
                <span class="" *ngIf="!consent3">
                    regulaminem
                </span>
                .
            </span>

            <div class="form-group-row">
                <div class="form-group">
                    <button id="register-button" mat-flat-button
                            style="text-align:center;"
                            type="submit" class="btn btn-primary next-step-button"
                            [disabled]="!inputValid">
                        <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                        <img src="/images/icons/zaloz konto.png" style="margin-top: 5px;position: inherit;margin-right: 11px;margin-left: 0px;" />
                        <span>
                            ZAŁÓŻ KONTO
                        </span>
                    </button>
                </div>
            </div>
            </form>
        </div>
            </div>
        </div>
    </div>

