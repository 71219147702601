<div mat-dialog-content class="custom-dialog-container">

    <div style="display:flex;flex-direction:row;">
        <h3>Wybrany mechanizm to monoblok, który ma już ramkę.</h3>
    </div>
    <div mat-dialog-actions style="justify-content: space-around;">
        <button mat-button type="button" class="cancel-button" (click)="onNoClick()">Anuluj</button>
        <button mat-button type="submit" (click)="onSubmitClick()" class="accept-button">Dalej</button>
    </div>
</div>
