// ====================================================
// More Templates: https://www.ebenmonney.com/templates
// Email: support@ebenmonney.com
// ====================================================

import { Injectable } from '@angular/core';

import { AppTranslationService } from "./app-translation.service";
import { LocalStoreManager } from './local-store-manager.service';
import { DBkeys } from './db-Keys';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';



type CategoryConfiguration = {
    category: number
};

@Injectable()
export class CompletionService {
    

    //***Specify default configurations here***
    public static readonly defaultCategory: number;

    //***End of defaults***  
    private _category: number;


    constructor(private localStorage: LocalStoreManager, private translationService: AppTranslationService) {
        this.loadLocalChanges();
    }



    private loadLocalChanges() {

        if (this.localStorage.exists(DBkeys.CATEGORY)) {
            this._category = this.localStorage.getDataObject<number>(DBkeys.CATEGORY);
            
        }
        else {
            this.resetCategory();
        }
 }


    private saveToLocalStore(data: any, key: string) {
        setTimeout(() => this.localStorage.savePermanentData(data, key));
    }

    


    public import(jsonValue: string) {
        this.clearLocalChanges();

        if (!jsonValue)
            return;
        let importValue: CategoryConfiguration = Utilities.JSonTryParse(jsonValue);

        if (importValue.category != null)
            this.category = importValue.category;
        
    }


    public export(changesOnly = true): string {

        let exportValue: CategoryConfiguration =
            {
                category: changesOnly ? this._category : this.category,
            };

        return JSON.stringify(exportValue);
    }


    public clearLocalChanges() {
        this._category = null;

        this.localStorage.deleteData(DBkeys.CATEGORY);

        this.resetCategory();
    }


    private resetCategory() {
            this._category = null;
    }
    set category(value: number) {
        this._category = value;
        this.saveToLocalStore(value, DBkeys.CATEGORY);
    }
    get category() {
        if (this._category != null)
            return this._category;

        return CompletionService.defaultCategory;
    }

    
}
