import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../_services/index';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { BaseRegisterComponent } from './base-register.component';
import { InformationDialogComponent, DialogData } from '../controls/information-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'register.component.html',
    styleUrls: ['./login.component.scss'],
    providers:[UserService]
})

export class RegisterComponent extends BaseRegisterComponent {
    
    isElk: any;
    constructor(
        protected router: Router,
        protected userService: UserService,
        protected alertService: AlertService, public dialog: MatDialog) {
        super(router, userService, alertService);
        this.model.roles = ['b2c'];
    }
    
    register() {
        super.register(this.SUCCESS_SUMMARY, this.SUCCESS_DETAILS);
    }
    SUCCESS_SUMMARY = 'Rejestracja udana';
    SUCCESS_DETAILS = 'Sprawdź maila';

    isRole(role: string) {
        return this.model.roles == null || this.model.roles[0] == role;
    }
    get inputValid(): boolean {
        return this.consent
            //&& this.consent2
            && this.emailValid
            && (!this.isElk || this.nipValid)
            && this.passwordConfirm != null
            && this.model.newPassword != null
            && this.passwordConfirm == this.model.newPassword;
    }
    get nipPossible(): boolean {
        return this.isRole('b2be');
    }


    get nipValid(): boolean {
        if (this.model.nip == null)
            return false;
        var nipWithoutDashes = this.model.nip.replace(/-/g, "");
        var reg = /^[0-9]{10}$/;
        if (reg.test(nipWithoutDashes) == false) {
            return false;
        }
        else {
            var digits = ("" + nipWithoutDashes).split("");
            var checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;
            return (parseInt(digits[9]) == checksum);
        }
    }

    changeElk() {
        console.log('ELK: ' + this.isElk);
        if (!this.isElk) {
            let newDialog: DialogData = {
                messageTitle: 'Elektroklub',
                messageBody: 'Czy na pewno nie chcesz zdobywać punktów w Elektroklubie?',
                url: null,
                confirmCallback: function () {this.isElk = false }
            };
            setTimeout(() => {
                this.isElk = true;
                const dialogRef = this.dialog.open(InformationDialogComponent, {
                    width: '350px',
                    data: newDialog
                });
            }, 1);
        }
    }
}
