import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, Input } from '@angular/core';

import { OrderService, SkuPosition } from '../../../services/orders.service';
import { B2BCollectFunctionsComponent } from '../collect-functions.component';

import { animate, transition, trigger, state, style } from '@angular/animations';
import { PresentationToolsComponent } from '../../../services/presentation-tools.component';
import { OrderSet } from '../../../_models/catalog';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-b2b-summary',
    templateUrl: './side-summary.component.html',
    styleUrls: ['./side-summary.component.scss'],
    animations: [
        trigger('openClosed', [
            state('opened', style({ position: 'fixed', top: ' 25vh', right: '40px' })),
            state('closed', style({
                right: '-326px',
                position: 'fixed',
                top:' 25vh'})),
            transition('* => *', animate(300))
        ])
    ]
})
/** SideSummary component*/
export class B2bSummaryComponent implements OnDestroy {
    public _orderService: OrderService;
    mobileQuery: MediaQueryList;
    @Input() functionselector: B2BCollectFunctionsComponent;
    private _mobileQueryListener: () => void;

    constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public orderService: OrderService) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this._orderService = orderService;
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    showSummary = false;
    showSummaryToggle() {
        this.showSummary = !this.showSummary;
    }
    shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
    
    get numberOfMechanisms() {
        return PresentationToolsComponent.numberOfMechanisms(this.orderService.openedRoomOrderSet());
    }

    get numberOfFrames() {
        return PresentationToolsComponent.numberOfFrames(this.orderService.openedRoomOrderSet());
    }
    clearSet() {
        AppComponent.SendEventGa('cart', 'wyczyść', "B2B_Wyczyszczenie 'Twojego zestawu'", 0);
        this.orderService.openedRoom().orderSets = new Array<OrderSet>();
    }

    public onScrollEvent(event: any): void {
        
    }
    private layers(allCodes) {
        return PresentationToolsComponent.layers(allCodes).reverse();
    }
}
