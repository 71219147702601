import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BootstrapTabDirective } from '../../directives/bootstrap-tab.directive';
import { ProjectNameDialog } from '../controls/project-name.component';
import { B2CRoutingModule } from './B2C-routing.module';
import { B2CConfigComponent } from './config.component';
import { RoomConfigDialogComponent } from './controls/room-config-dialog.component';
import { SideSummaryComponent } from './controls/side-summary.component';
import { FramesSelectionComponent } from './frames-selection.component';
import { FunctionSelectionComponent } from './function-selection.component';
import { ProjectSelectionComponent } from './project-selection.component';
import { RoomSelectionComponent } from './rooms-selection.component';
import { SeriesSelectionComponent } from './series-selection.component';
import { B2CSummaryComponent } from './summary.component';
import { SharedModule } from '../main/shared.module';
import { ColorSelectionDialogComponent } from './controls/colorselection.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PromptDialogComponent } from './controls/prompt.component';
import { MessageDialogComponent } from './controls/message.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
    imports: [
        SwiperModule,
        SharedModule,
        B2CRoutingModule,
        DragDropModule,
        DragAndDropModule
    ],
    declarations: [B2CConfigComponent,
        FunctionSelectionComponent,
        FramesSelectionComponent,
        SeriesSelectionComponent,
        B2CSummaryComponent,
        SideSummaryComponent,
        RoomSelectionComponent,
        ProjectSelectionComponent,
        RoomConfigDialogComponent,
        ColorSelectionDialogComponent,
        BootstrapTabDirective,
        MessageDialogComponent,
        PromptDialogComponent
        
    ],
    entryComponents: [RoomConfigDialogComponent, ColorSelectionDialogComponent, MessageDialogComponent, PromptDialogComponent],
    providers: [
        {
              provide: SWIPER_CONFIG,
              useValue: DEFAULT_SWIPER_CONFIG
            }
        ]
})
export class B2ConfigModule { }
