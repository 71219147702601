import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';


@Component({
    selector: 'app-tutorial-dialog',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})
/** InformationDialog component*/
export class TutorialDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TutorialDialogComponent>,
        protected router: Router) { }


    onNoClick(): void {
        this.dialogRef.close();
        
    }
    closeClick() {
        this.dialogRef.close();
    }
}
