import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import { AccountService } from "../../services/account.service";
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AuthService } from '../../services/auth.service';
import { OrderService } from '../../services/orders.service';
import { PresentationToolsComponent } from '../../services/presentation-tools.component';
import { Category, CategoryGroupFormated, Color, OrderSet, OrderSetItem, Project, Room, Series, Sku, SubSeries } from '../../_models/catalog';
import { AppComponent } from '../app.component';
import { ExportConfigComponent } from '../controls/export-config.component';
import { MyStepper } from './config.component';
import { ChangeColorDialog } from './controls/change-color-dialog.component';
import { ChangeSeriesDialog } from './controls/change-series-dialog.component';
import { ChangeSeriesGlobalDialog, ChangeSeriesOptions } from './controls/change-series-global-dialog.component';
import { SkuDetailsDialog } from './controls/sku-details-dialog.component';
import { SkuImageDialog } from './controls/sku-image-dialog.component';
import { FunctionHelperComponent } from './function-helper.component';



export class ParamValue {
    title: string;
    available: boolean;
    id: number;
    order: number;
    constructor(title: string) {
        this.title = title;
    }
}
export class Parameter {
    title: string;
    tooltip: string;
    values: ParamValue[];
    selectedValue: number;
    order: number;
    constructor(title: string, order: number) {
        this.title = title;
        this.order = order;
    }
}


@Component({
  selector: 'b2b-collect-functions',
    templateUrl: './collect-functions.component.html',
    animations: [fadeInOut],
    styleUrls: ['./collect-functions.component.scss']
})

export class B2BCollectFunctionsComponent implements OnInit {


    className = "B2BCollectFunctionsComponent as ViewChild";
    anyProductsInCategory(catId: number): any {
        if (catId == 71)
            return this.selectedSubSeriesId == 1 || this.selectedSubSeriesId == 2 || this.selectedSubSeriesId == 5 ||
                (this.selectedSubSeriesId >= 7 && this.selectedSubSeriesId<=14);
        else if (catId == 72 || catId == 73)
            return this.selectedSubSeriesId == 3 || this.selectedSubSeriesId == 4;
        else if (catId == 35)
            return this.selectedSubSeriesId == 3 || this.selectedSubSeriesId == 4;
        else return true;
        //return this.allFunctionstbfiltered.findIndex(s => s.category.id == catId) > 0;
    }
    

    openedSkus: Sku[];
    seriesAvailable = new Parameter("Seria", -3);
    subSeriesAvailable = new Parameter("Linia", -3);
    colorsAvailable = new Parameter("Kolor",-2);
    
    ipsAvailable = new Parameter("Stopień ochrony",-1);
    parameters: Parameter[];
    get paramsWithoutType(): Parameter[] {
        return this.parameters == null ? null : this.parameters.filter(p => (p.title != 'Rodzaj' && p.title != 'Typ'))
    }
    filteredSkus: Sku[];
    allFunctionstbfiltered: Sku[];
    selectedCategory: string;
    @Input() dynamicdata = "init";


    suggestedFunctions = true;
    availableFrameSkus: Sku[];
    filteredFrameSkus: Sku[];
    allFunctions: Sku[];
    baseUrl: string;
    httpclient: HttpClient;
    availableFunctions: CategoryGroupFormated[];
    allAvailableFunctions: CategoryGroupFormated[];
    //availableSeries: Series[];
    //availableColorSkus: Sku[];
    selectedSeriesId = 8;
    selectedSubSeriesId = 15;
    selectedColorIds = ['1'];
    selectedWaterProof = '*';
    value: string;
    showSummary = true;

    isDisabledCardLink(sku:Sku) {
        if (sku.code.startsWith("SDD"))
            return 'isDisabled';
        return '';
    }

    discount = 0;
    groupCatId: number;
    groupCatTitle: string;
    get subSeriesAvailableOnly(): ParamValue[] {
        return (this.subSeriesAvailable.values !== null) ?
            this.subSeriesAvailable.values.filter(ssa => ssa.available) : null;
    }
    foundCodesFiltered: Sku[];
    searchedByCode = false;
    foundCodesOfSubSeries(subSeries: ParamValue = null) {
        //console.log("searchedByCode: " + this.searchedByCode);
        if (this.searchedByCode) {
            this.foundCodesFiltered = this.foundCodes;
            return this.foundCodesFiltered;
        }
        //console.log("subSeries.id:" + subSeries.id);
        if (subSeries !== null)
            this.foundCodesFiltered = this.foundCodes.filter(s => s.subSeries.id === subSeries.id);
        else {
            this.foundCodesFiltered = this.foundCodes;
        }

        return (this.foundCodesFiltered[0] !== null && this.foundCodesFiltered[0].slots > 0) ?
            this.foundCodesFiltered.sort(this.compareSkuName) : this.foundCodesFiltered.sort(this.compareSkuId);
    }
    compareFunctionType(a: Sku, b: Sku): number {
        return a.functionType.title >= b.functionType.title ? 1 : -1;
    }
    clearFilters() {
        AppComponent.SendEventGa('filters', null, 'B2B_Wyczyszczenie filtrów', 0);
        this.selectedSeriesId = 3;
        this.selectedSubSeriesId = 1;
        this.selectedColorIds = ['' + this.colorsAvailable.values[0].id];
        this.selectedWaterProof = '*';
        for (const param of this.parameters) {
            param.selectedValue = -1;
        }
        this.reloadFunctionsAndUpdateFunctions(this.catId, this.catTitle,this.groupCatTitle,this.selectedSeriesId);
        //this.filterAllFunctionsByFilters();
        this.searchedByCode = false;
    }
    functionsLoading = false;
    ngOnInit(): void {
        console.log('init selection for category: ' + this.catId);
        this.functionsLoading = true;
        this.reloadFunctions(this.catId, this.catTitle, this.groupCatTitle, this.selectedSeriesId);
        this.httpclient.get<Sku[]>(this.baseUrl + 'api/catalogdata/functionsAll').subscribe(result => {
            this.functionHelper.functionsAll = result;
            this.functionHelper.functionsSelectable = result.filter(f => f.selectable == true);
            this.updateParametersOnCategory();
            this.functionsLoading = false;
        }, error => console.error(error));
        this.updateCart

        this.httpclient.get<SubSeries[]>(this.baseUrl + 'api/catalogdata/subseries').subscribe(result => {
            this.subSeriesAll = result.filter(ss => ss.seriesID != null).sort();
        }, error => console.error(error));
    }
    public categories: Category[];
    roundToTwo(num: number) {
        return (Math.round(num * 100) / 100).toFixed(2).replace('.',',');
    }
    reloadFunctionsAndUpdateFunctions(catId: number, catTitle: string, groupCatTitle: string, selectedSeriesId = this.selectedSeriesId, filterParam = '') {
        this.catId = catId;
        this.groupCatTitle = groupCatTitle;
        this.catTitle = catTitle;
        this.functionsLoading = true;
        this.groupCatTitle = groupCatTitle;
        this.selectedCategory = catTitle;
        this.httpclient.get<Sku[]>(this.baseUrl + 'api/catalogdata/skusbycategoryandseries/' + catId + '/' + selectedSeriesId).subscribe(result => {
            this.allFunctionstbfiltered = result;
            this.updateListsOnCategory();
            this.filterAllFunctionsByFilters(filterParam);
            this.functionsLoading = false;
        }, error => console.error(error));
    }

    reloadFunctions(catId: number, catTitle: string, groupCatTitle: string, selectedSeriesId = this.selectedSeriesId) {
        this.functionsLoading = true;
        this.groupCatTitle = groupCatTitle;
        this.selectedCategory = catTitle;
        this.httpclient.get<Sku[]>(this.baseUrl + 'api/catalogdata/skusbycategoryandseries/' + catId + '/' + selectedSeriesId).subscribe(result => {
            this.allFunctionstbfiltered = result;
            this.updateListsOnCategory();
            this.functionsLoading = false;
        }, error => console.error(error));
    }

    updateListsOnCategory() {
        console.log("B2BCollectFunctionsComponent.updateListsOfSkus")
        this.updateParametersOnCategory();
        //it is alredy done in the updateParamaters this.filterAllFunctionsByFilters();
        
        //update IP list - and set if old exists
        //update other filters: names and values - and set if old exists
    }
    get subseriesFilter(): boolean {
        return this.groupCatTitle === 'Ramki';
    }
    /*
     *  update parameters lists disabled
     */
    updateAvailabilityOnSelection() {
        if (this.colorsAvailable != null && this.colorsAvailable.values != null) {
            for (let c of this.colorsAvailable.values) {
                //console.log("color available: " + c.title + ":" + c.id + ":" + this.filteredSkus.findIndex(sku => (sku.color.id == c.id || sku.color.id == -1)))
                if (this.subseriesFilter) {
                    if (this.allFunctionstbfiltered.filter(sku => sku.subSeries != null && sku.subSeries.id == this.selectedSubSeriesId).findIndex(sku => (sku.color.id == c.id || sku.color.id == -1)) >= 0) {
                        c.available = true;
                    } else {
                        c.available = false;
                    }
                } else {
                    if (this.allFunctionstbfiltered.filter(sku => sku.series != null && sku.series.id == this.selectedSeriesId).findIndex(sku => (sku.color.id == c.id || sku.color.id == -1)) >= 0) {
                        c.available = true;
                    } else {
                        c.available = false;
                    }
                }
                
            }
        }
        if (this.seriesAvailable != null && this.seriesAvailable.values != null) {
            for (let c of this.seriesAvailable.values) {
                if (this.allFunctionstbfiltered.filter(sku => sku.series != null && sku.series.id == this.selectedSeriesId).findIndex(sku => sku.series != null && sku.series.id == c.id) >= 0) {
                    c.available = true;
                } else {
                    c.available = false;
                }
            }
        }
        if (this.subSeriesAvailable != null && this.subSeriesAvailable.values != null) {
            for (let c of this.subSeriesAvailable.values) {
                if (this.allFunctionstbfiltered.filter(sku => sku.subSeries.id == this.selectedSubSeriesId).findIndex(sku => sku.subSeries.id == c.id) >= 0) {
                    c.available = true;
                } else {
                    c.available = false;
                }
            }
        }
    }


    oldSelections = new Map<string, number>();
    /*
     *  build parameters lists
     */
    updateParametersOnCategory() {
        this.seriesAvailable.values = new Array<ParamValue>();
        this.subSeriesAvailable.values = new Array<ParamValue>();
        this.colorsAvailable.values = new Array<ParamValue>();
        this.parameters = new Array<Parameter>();
        if (this.allFunctionstbfiltered != null) {
            for (let sku of this.allFunctionstbfiltered) {

                if (this.seriesAvailable.values.findIndex(pv => sku.series != null && pv.id == sku.series.id) < 0) {
                    let newSeries = { title: sku.series.title, available: false, id: sku.series.id, order: sku.series.order };
                    this.seriesAvailable.values.push(newSeries);
                }
                if (this.subSeriesAvailable.values.findIndex(pv => pv.id == sku.subSeries.id) < 0) {
                    let newSubSeries = { title: sku.subSeries.title, available: false, id: sku.subSeries.id, order: sku.subSeries.order };
                    this.subSeriesAvailable.values.push(newSubSeries);
                }
                if (sku.subSeries != null &&
                    this.subSeriesAvailable.values.findIndex(pv => pv.id == sku.subSeries.id) < 0) {
                    let newSubSeries = { title: sku.subSeries.title, available: false, id: sku.subSeries.id, order: sku.subSeries.id };
                    this.subSeriesAvailable.values.push(newSubSeries);
                }

                if (sku.color.id != -1 && this.colorsAvailable.values.findIndex(pv => pv.id == sku.color.id) < 0) {
                    let newColor = { title: sku.color.title, available: false, id: sku.color.id,order:sku.color.order };
                    this.colorsAvailable.values.push(newColor);
                }

                for (let p of sku.params) {
                    let paramIndex = this.parameters.findIndex(pp => pp.title == p.title)
                    if (paramIndex < 0) {
                        //console.log("###################### new param ##########################\n");
                        this.parameters.push(new Parameter(p.title, p.torder));
                        paramIndex = this.parameters.length - 1;
                        this.parameters[paramIndex].values = new Array<ParamValue>();
                    }
                    if (this.parameters[paramIndex].values.findIndex(pv => pv.title == p.value) < 0) {
                        //console.log("###################### new value ##########################\n");
                        let newValue = { title: p.value, available: true, id: p.id, order:p.vorder };
                        this.parameters[paramIndex].values.push(newValue)
                    }
                }


            }

            this.colorsAvailable.values.sort(this.compareParamValues);
            //console.log("unsorted-series" + this.seriesAvailable.values[0].title + ":" + this.seriesAvailable.values[0].order);
            //[TODO] series.order is 0 so by name hardcoded
            this.seriesAvailable.values = this.seriesAvailable.values.sort((a, b) => ((a.title == 'Sedna') ? -1 : 1));
            this.subSeriesAvailable.values = this.subSeriesAvailable.values.sort((a, b) => ((a.id>b.id) ? -1 : 1));
            console.log("sorted-series");
            this.parameters.sort(this.compareParams);
            for (let p of this.parameters) {
                if (p.title == 'Podświetlenie') {
                    p.values.sort(this.compareParamValuesNoFirst);
                }
                else {
                    p.values.sort(this.compareParamValues);
                }
            }
        }
        if (this.allFunctionstbfiltered != null) {
            //console.log(this.seriesAvailable.values.find(s => s.id == this.selectedSeriesId));
            this.allFunctionstbfiltered.sort();
            this.allAvailableFunctions = this.availableFunctions;
            /*
            var tmpFiltered: Sku[];
            if (this.subseriesFilter)
                tmpFiltered = this.allFunctionstbfiltered.filter(s =>
                    (s.subSeries.id === this.selectedSubSeriesId));
            else
                tmpFiltered = this.allFunctionstbfiltered.filter(s =>
                    (s.series.id == this.selectedSeriesId));
            
            if (this.selectedColorIds.length > 0)
                tmpFiltered = tmpFiltered.filter(s =>
                    (this.selectedColorIds.includes(""+s.color.id)));
            */
            //console.log("###########################################################\n"
            //    + "selectedColorId: " + this.selectedColorId + tmpFiltered.length);
            for (let p of this.parameters) {
                if (p.values !== null && p.values.length > 0) {
                    const old = this.oldSelections.get(p.title);
                    if (old !== null) {
                        p.selectedValue = old;
                    } else {
                        p.selectedValue = -1;
                    }
                }
            }
            
        }
        this.filterAllFunctionsByFilters();

    }
    compareParamValues(c1: ParamValue, c2: ParamValue) {
        
        if (c1.order < c2.order)
            return -1;
        else if (c1.order > c2.order)
            return 1;
        else
            return 0;
    }

    compareParamValuesNoFirst(c1: ParamValue, c2: ParamValue) {
        if (c1.title.toLowerCase() == 'nie' && c2.title.toLowerCase() == 'tak')
            return -1;
        if (c1.title.toLowerCase() == 'tak' && c2.title.toLowerCase() == 'nie')
            return 1;
        else return this.compareParamValues(c1,c2);
    }
    compareParams(c1: Parameter, c2: Parameter) {
        if (c1.order < c2.order)
            return -1;
        else if (c1.order > c2.order)
            return 1;
        else
            return 0;
    }

    compareIds(sId: any, s: any): boolean {
        return sId != null && s != null && sId == s;
    }
    compareColor(cId: any, cId2: any): boolean {
        return cId != null && cId2 != null && cId == cId2;
    }
    compareParam(pId: any, pId2: any): boolean {
        return pId != null && pId2 != null && pId.id === pId2.id;
    }
    

    functionHelper: FunctionHelperComponent = null;
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute,
        private accountService: AccountService, private authService: AuthService,  public router: Router, public orderService: OrderService,
        public dialog: MatDialog,private alertService:AlertService) {
        this.httpclient = http;
        this.baseUrl = baseUrl;
        if (this.orderService.currentConfig.fH == null) {
            this.functionHelper = new FunctionHelperComponent(http, baseUrl);
            this.orderService.currentConfig.fH = this.functionHelper;
        } else {
            this.functionHelper = this.orderService.currentConfig.fH;
            //this.functionHelper = new FunctionHelperComponent(http, baseUrl, this.orderService.currentConfig.fH);
            this.functionHelper = new FunctionHelperComponent(http, baseUrl);
            if (this.orderService.currentConfig.fH != null) {
                let fh = this.orderService.currentConfig.fH;
                this.functionHelper.categoryGroups = fh.categoryGroups;
                this.functionHelper.functionsAll = fh.functionsSelectable;
                this.functionHelper.categories = fh.categories;
                this.functionHelper.categoryGroupFormated = fh.categoryGroupFormated;
                this.functionHelper.filteredFunctions = fh.filteredFunctions;
                this.functionHelper.uniqueFiltersFunctions = fh.uniqueFiltersFunctions;
                this.functionHelper.functionsCollected = fh.functionsCollected;
            }
        }

        this.seriesAvailable = new Parameter("Seria",-3);
        this.seriesAvailable.tooltip = "Seria mechanizmu";
        this.subSeriesAvailable = new Parameter("Seria", -3);
        this.subSeriesAvailable.tooltip = "Seria ramki";
        this.colorsAvailable = new Parameter("Kolor",-2);
        this.colorsAvailable.tooltip = "Kolor mechanizmu lub ramki";
        this.ipsAvailable = new Parameter("Stopień ochrony",-1);
        this.ipsAvailable.tooltip = "Stopień ochrony przed wodą, do Łazienki zalecany jest IP44";
        this.parameters = new Array<Parameter>();
        
    }

    isFunctionActivated = false;

    clearRoom() {
        AppComponent.SendEventGa('cart', null, 'B2B_Usunięcie wszystkich produktów w zestawie', 0);
        this.openedRoom.orderSets = new Array<OrderSet>();
    }
    clearScreen() {
        AppComponent.SendEventGa('cart', null, "B2B_Usunięcie wszystkich produktów na ekranie", 0);
        for (let sku of this.foundCodes) {
            let index = this.openedRoomOrderSet.orderSetItems.findIndex(osi => osi.sku.code == sku.code);
            if (index >= 0) {
                this.deletePositionNoLog(index);
            }
        }
    }
    clearProject() {
        AppComponent.SendEventGa('cart', null, "B2B_Wyczyszczenie całego projektu", 0);
        this.orderService.clearProject();
    }
    isSeriesAvailable(sTitle) {
        return (this.seriesAvailable.title == sTitle);
    }

    subSeriesChange() {
        
            if (this.selectedSubSeriesId == 3)//sedna
                this.selectedSeriesId = 1;
            else if (this.selectedSubSeriesId == 4)//asfora
                this.selectedSeriesId = 2;
            else if (this.selectedSubSeriesId >= 6 && this.selectedSubSeriesId <= 14)//Merten
                this.selectedSeriesId = 7;
            else if (this.selectedSubSeriesId >= 15 && this.selectedSubSeriesId <= 16)//SednaNew (D&E)
                this.selectedSeriesId = 8;
            else if (this.selectedSubSeriesId == 1 || this.selectedSubSeriesId == 2 || this.selectedSubSeriesId == 5)//odace styl/you/touch
                this.selectedSeriesId = 3;
        
        this.reloadFunctionsAndUpdateFunctions(this.catId, this.catTitle, this.groupCatTitle,
            this.selectedSeriesId, 'Subseries');
    }
    seriesChange() {
        
            if (this.selectedSeriesId == 1)//sedna
                this.selectedSubSeriesId = 3;
            else if (this.selectedSeriesId == 2)//asfora
                this.selectedSubSeriesId = 4;
            else if (this.selectedSeriesId == 3) {//odace
                if (this.selectedSubSeriesId != 1 && this.selectedSubSeriesId != 2 && this.selectedSubSeriesId != 5)
                    this.selectedSubSeriesId = 1;
            }
            else if (this.selectedSeriesId == 8) {//SednaNew (D&E)
                if (this.selectedSubSeriesId < 15 || this.selectedSubSeriesId > 16)
                    this.selectedSubSeriesId = 15;
            }
            else if (this.selectedSeriesId == 7) {//merten
                if (this.selectedSubSeriesId < 7 || this.selectedSubSeriesId > 14)
                    this.selectedSubSeriesId = 7;
            }
            else
                this.selectedSubSeriesId = 1;
         
        this.reloadFunctionsAndUpdateFunctions(this.catId, this.catTitle, this.groupCatTitle,
            this.selectedSeriesId, 'Series');
    }

    get isColorFilterMulti(): boolean {
        if (this.filteredSkus && this.filteredSkus.length <= 100) return true;//'multiple';
        return false;
    }
    filterAllFunctionsByFilters(param = '') {
        AppComponent.SendEventGa('filters', param, "B2B_Zmiana filtrów", 0);
        //if (this.selectedColorIds.length > 0)
          //  console.log("filterAllFunctionsByFilters:" + this.allFunctionstbfiltered.length + ":" +this.selectedColorIds.length + " " + this.selectedColorIds[0]);
        

        if (this.allFunctionstbfiltered != null) {
            if (this.subseriesFilter) {
                console.log("filterAllFunctionsBySubseries:" + this.allFunctionstbfiltered.length + ":" + this.selectedSubSeriesId);
                this.filteredSkus = this.allFunctionstbfiltered.filter(s => s.subSeries.id == this.selectedSubSeriesId &&
                    (this.selectedWaterProof == '*' || s.indexOfProtection.title == this.selectedWaterProof));
            }else
                this.filteredSkus = this.allFunctionstbfiltered.filter(s => s.series.id == this.selectedSeriesId &&
                    (this.selectedWaterProof == '*' || s.indexOfProtection.title == this.selectedWaterProof));
            console.log("number of filtered skus: " + this.filteredSkus.length + ":" + this.selectedColorIds);
            
            
            let tmpFilteredSkus = this.filteredSkus.
                filter(s => (this.selectedColorIds.length <= 0 ||
                    this.selectedColorIds.indexOf(""+s.color.id) >= 0));
            console.log("number of filtered skus: " + this.filteredSkus.length);
            //clear color if no elements by filter
            if (tmpFilteredSkus.length == 0 && this.filteredSkus[0]) {
                this.selectedColorIds = ['' + this.filteredSkus[0].color.id];
                this.filteredSkus = this.filteredSkus.
                    filter(s => s.color.id == this.filteredSkus[0].color.id);
            } else {
                this.filteredSkus = tmpFilteredSkus;
            }
            /*
            this.filteredSkus = this.filteredSkus.filter(s =>
                    (this.selectedWaterProof == '*' || s.indexOfProtection.title == this.selectedWaterProof));
              */      
            //for each parameter
            for (let p of this.parameters) {
                this.oldSelections.set(p.title, p.selectedValue);
                if (p.values.find(pv => pv.id == p.selectedValue) != null) {
                    let value_title = p.values.find(pv => pv.id == p.selectedValue).title;
                    console.log("value_title" + value_title);
                    this.filteredSkus = this.filteredSkus.filter(
                        s => (s.params == null || s.params.find(sp => sp.title == p.title) == null || s.params.find(sp => sp.title == p.title).value == value_title)
                    );
                }
            }
        } else {
            this.filteredSkus = new Array<Sku>();
        }
        this.foundCodes = this.filteredSkus.sort(this.compareSkuId);
        this.updateAvailabilityOnSelection();
    }
    compareSku(s1: Sku, s2: Sku) {
        if (s1.code > s2.code) return 1;
        if (s1.code < s2.code) return -1;
        return 0;
    }
    compareSkuName(s1: Sku, s2: Sku) {
        if (s1.functionType.title > s2.functionType.title) return 1;
        if (s1.functionType.title < s2.functionType.title) return -1;
        return 0;
    }

    compareSkuId(s1: Sku, s2: Sku) {
        if (s1.id > s2.id) return 1;
        if (s1.id < s2.id) return -1;
        return 0;
    }

    selectedCategoryGroup: CategoryGroupFormated = null;
    selectedGroupCategoryIndex: number = 0;
    selectCategoryGroup(cgi: number) {
        AppComponent.SendEventGa('selection', this.availableFunctions[cgi].cg.title, 'select-category', 0);
        this.selectedGroupCategoryIndex = cgi;
        this.selectedCategoryGroup = this.availableFunctions[cgi];;
    }
    ifSelectedCategoryGroup(cg: CategoryGroupFormated) {
        return (cg == this.selectedCategoryGroup) ? 'button-active  mat-flat-button' : 'categoryGroupButton mat-flat-button';
    }
    public setBackground():any {
        document.getElementById(this.eId).style.backgroundColor = this.oldBck;
        console.log("returned: " + this.oldBck)
    }
    oldBck: string;
    eId: string;
    newValueDetectedInRoom(event: Event, sku: Sku, roomIndex: number) {

        let element = event.currentTarget as HTMLInputElement;
        let value = element.value;
        this.eId = "row_" + sku.code;

        //let e = document.getElementById(this.eId);
        //this.oldBck = e.style.backgroundColor;
        //e.style.backgroundColor = "lightgreen";
        //setTimeout(this.setBackground,500);
        let newValue = parseFloat(value) < 0 ? 0 : parseFloat(value);

        console.log("Show alert" + sku.code);

        let oldValue = this.numberOfSkuItemsInRoom(sku, roomIndex)
        
        if (oldValue == newValue)
            return;
        if (oldValue < newValue) {
            AppComponent.SendEventGa('cart', sku.code, "B2B - dodanie produktu do 'Twoja Lista'", 0);
            this.alertService.showMessage("Produkt " + sku.code + " " + (newValue - oldValue) + "szt.", "Został dodany do zestawu.", MessageSeverity.success);
        } else if (oldValue > newValue) {
            AppComponent.SendEventGa('cart', sku.code, "B2B_Usunięcie produktu z Twojej Listy", 0);
            this.alertService.showMessage("Produkt " + sku.code + " " + (oldValue - newValue) + "szt.", "Został usuniety z zestawu.", MessageSeverity.warn);
        }
        this.functionHelper.setSkuNumberInOrderSet(this.openedProject.rooms[roomIndex].orderSets[0], newValue, sku);

        this.updateCart();
    }
    newValueDetected(event: Event, sku: Sku) {

        let element = event.currentTarget as HTMLInputElement;
        let value = element.value;
        this.eId = "row_" + sku.code;
        
        //let e = document.getElementById(this.eId);
        //this.oldBck = e.style.backgroundColor;
        //e.style.backgroundColor = "lightgreen";
        //setTimeout(this.setBackground,500);
        let newValue = parseFloat(value) < 0 ? 0 : parseFloat(value);

        console.log("Show alert" + sku.code);
        
        let oldValue = this.numberOfSkuItems(sku)
        if (oldValue == newValue)
            return;
        if (oldValue < newValue) {
            AppComponent.SendEventGa('cart', sku.code, "B2B - dodanie produktu do 'Twoja Lista'", 0);
            this.alertService.showMessage("Produkt " + sku.code + " " + (newValue - oldValue) + "szt.", "Został dodany do zestawu.", MessageSeverity.success);
        } else if (oldValue > newValue) {
            AppComponent.SendEventGa('cart', sku.code, "B2B_Usunięcie produktu z Twojej Listy", 0);
            this.alertService.showMessage("Produkt " + sku.code + " " + (oldValue - newValue) + "szt.", "Został usuniety z zestawu.", MessageSeverity.warn);
        }
        this.functionHelper.setSkuNumberInOrderSet(this.openedRoomOrderSet, newValue, sku);
        
        this.updateCart();
    }
    decSku(sku: Sku) {
        AppComponent.SendEventGa('cart', sku.code, "B2B_Usunięcie produktu z Twojej Listy", 0);
        let newValue = this.numberOfSkuItems(sku) - 1;
        newValue = newValue < 0 ? 0 : newValue;
        console.log("Show alert" + sku.code);
        this.alertService.showMessage("Produkt "+ sku.code + " 1 szt.","Został usunięty z zestawu.", MessageSeverity.warn);
        this.functionHelper.setSkuNumberInOrderSet(this.openedRoomOrderSet, newValue, sku);
        this.updateCart();
    }
    decSkuInRoom(sku: Sku, j: number) {
        AppComponent.SendEventGa('cart', sku.code, "B2B_Usunięcie produktu z Twojej Listy", 0);
        let newValue = this.numberOfSkuItemsInRoom(sku,j) - 1;
        newValue = newValue < 0 ? 0 : newValue;
        console.log("Show alert" + sku.code);
        this.alertService.showMessage("Produkt " + sku.code + " 1 szt.", "Został usunięty z zestawu.", MessageSeverity.warn);
        this.functionHelper.setSkuNumberInOrderSet(this.openedProject.rooms[j].orderSets[0], newValue, sku);
        this.updateCart();
    }
    incSku(sku: Sku) {
        AppComponent.SendEventGa('cart', sku.code, "B2B - dodanie produktu do 'Twoja Lista'", 0);
        console.log("Show alert" + sku.code);
        this.alertService.showMessage("Produkt " + sku.code + " 1 szt.", "Został dodany do zestawu.", MessageSeverity.success);
        this.functionHelper.setSkuNumberInOrderSet(this.openedRoomOrderSet, this.numberOfSkuItems(sku)+1, sku);
        this.updateCart();
    }
    incSkuInRoom(sku: Sku,j:number) {
        AppComponent.SendEventGa('cart', sku.code, "B2B - dodanie produktu do 'Twoja Lista'", 0);
        let newValue = this.numberOfSkuItemsInRoom(sku, j) + 1;
        console.log("Show alert" + sku.code);
        this.alertService.showMessage("Produkt " + sku.code + " 1 szt.", "Został dodany do zestawu.", MessageSeverity.success);
        this.functionHelper.setSkuNumberInOrderSet(this.openedProject.rooms[j].orderSets[0], newValue, sku);
        this.updateCart();
    }
    deletePositionNoLog(i: number) {
        this.alertService.showMessage("Produkt " + this.openedRoomOrderSet.orderSetItems[i].sku.code + "1 szt.", "Został usunięty z zestawu.", MessageSeverity.warn);
        this.functionHelper.remSkuInOrderSet(this.openedRoomOrderSet,
            this.openedRoomOrderSet.orderSetItems[i].sku);
        this.updateCart()
    }
    deletePosition(i: number) {
        AppComponent.SendEventGa('cart', this.openedRoomOrderSet.orderSetItems[i].sku.code, 'B2B_Usunięcie produktu z Twojej Listy  ', 0);
        this.deletePositionNoLog(i);
    }
    clearCart() {
        AppComponent.SendEventGa('cart', null, 'B2B_Usunięcie produktów z zestawu', 0);
        this.openedRoomOrderSet.orderSetItems = new Array<OrderSetItem>();
        this.updateCart();
    }
    /*saveCart() {
        this.updateCart();
    }*/
    numberOfSkuItems(sku: Sku): number {
        if (this.openedRoomOrderSet.orderSetItems == null || this.openedRoomOrderSet.orderSetItems.length == 0)
            return 0;
        let fci = this.openedRoomOrderSet.orderSetItems.findIndex(osi => (osi.sku!=null && osi.sku.id == sku.id));
        if (fci == null || fci < 0) {
            return 0;
        } else {
            return this.openedRoomOrderSet.orderSetItems[fci].qty;
        }
    }
    numberOfSkuItemsInRoom(sku: Sku, j: number): number {
        if (this.openedProject.rooms[j].orderSets[0].orderSetItems == null || this.openedProject.rooms[j].orderSets[0].orderSetItems.length == 0)
            return 0;
        let fci = this.openedProject.rooms[j].orderSets[0].orderSetItems.findIndex(osi => (osi.sku != null && osi.sku.id == sku.id));
        if (fci == null || fci < 0) {
            return 0;
        } else {
            return this.openedProject.rooms[j].orderSets[0].orderSetItems[fci].qty;
        }
    }
    get openedProject() {
        return this.orderService.cProject;
    }
    get isAnythingInSet() {
        return this.openedRoomOrderSet.orderSetItems != null && this.openedRoomOrderSet.orderSetItems.length > 0;
    }
    get openedRoomOrderSet() {
        return this.orderService.openedRoomOrderSet();
    }
    get openedRoom() {
        return this.orderService.openedRoom();
    }
    get likedNumber():number {
        return this.orderService.likedSkus.length;
    }
    numberOfFrames(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p.sku.slots > 0)
                            sum += p.qty * p.sku.slots;
                        else if (p.sku.withFrame)
                            sum += 1;
                    }
            }
        return sum;
    }
    numberOfFunctions(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        if (p.sku.slots == 0)
                            sum += p.qty;
                    }
            }
        return sum;
    }
    sumNet(r: Room) {
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        sum += p.qty * p.sku.net;
                    }
            }
        return this.roundToTwo(sum);
    }
    sumNetProject(p: Project): string {
        return this.sumNetProjectRaw(p).toFixed(2).replace('.', ',');
    }
    sumNetProjectRaw(p: Project):number{
        if (p == null)
            p = this.orderService.cProject;
        let sum = 0;
        if (p.rooms != null) for (let r of p.rooms) {
            if (r.orderSets != null)
                for (let os of r.orderSets) {
                    if (os.orderSetItems != null)
                        for (let p of os.orderSetItems) {
                            sum += p.qty * p.sku.net;
                        }
                }
        }
        return sum;
    }


    gotoSummary() {

    }
    @Input() stepper: MyStepper;
    @Input() cStepper: MyStepper;
    @Input() wide: boolean;
    @Input() catId: number;
    @Input() catTitle: string;
    selectedTab = 1;
    isStepActive(i: number) {
        return this.stepper.step == i;
    }
    
    
    updateCart() {
        this.countCollectedMechanisms();
        //this.orderService.saveCurrentContext();
        this.orderService.saveCurrentProject();

        if (this.isStepActive(3)) {
            this.orderService.changedAfterOrder = true;

            document.getElementById('spinner').hidden = false;
                    this.orderService.cartFromProjectAndCallback(function () {
                        document.getElementById('spinner').hidden = true;
                    });
        }
    }
    collectedMechanismsNumber = 0;
    collectedFrameSlotsNumber = 0;
    collectedSumNet = 0;
    collectedElkPoints = 0;

    countCollectedMechanisms() {
        this.collectedMechanismsNumber = 0;
        this.collectedFrameSlotsNumber = 0;
        this.collectedSumNet = 0;
        this.collectedElkPoints = 0;
        for (let p of this.openedRoomOrderSet.orderSetItems) {

            if (p.sku.slots == 0) {
                this.collectedMechanismsNumber += p.qty;
                if (p.sku.withFrame) {
                    this.collectedFrameSlotsNumber += p.qty;
                }
            } else {
                this.collectedFrameSlotsNumber += p.qty * p.sku.slots;
            }

            this.collectedSumNet += p.qty * p.sku.net;
            this.collectedElkPoints += p.qty * ((p.sku.elk != null) ? p.sku.elk:0);
        }
        this.collectedSumNet = this.round(this.collectedSumNet);
    }
    round(num: number) {
        return Math.round(num * 100 + Number.EPSILON) / 100;
    }

    //item list tooltips
    tooltipIndex: number = null
    skuForHover: Sku = null;
    presentTooltip(code:string, e) {
        //this.tooltipIndex = j;
        this.skuForHover = this.filteredSkus.find(s => s.code == code);
        
        console.log("hooooover (x,y)=" + e.clientX + "," + e.clientY + ") code:"+code);
        let popup = document.getElementById("skuInfoPopup");
        popup.style.top = e.clientY + 'px';
        popup.style.left = e.clientX + 20 + 'px';
        popup.hidden = false;
    }
    hideTooltip(j: number) {
        let popup = document.getElementById("skuInfoPopup");
        popup.hidden = true;
        this.tooltipIndex = null;
        this.skuForHover = null;
    }
    hoverOnItem(j: number) {
        return this.tooltipIndex == j; 
    }

    presentDialogWithSku(code: string) {
        AppComponent.SendEventGa('sku-details', code, "B2B_szczegóły produktu otwarcie", 0);
        //let skuIn = this.filteredSkus.find(s => s.code == code);
        let skuIn = this.foundCodesFiltered.find(s => s.code == code);
        const dialogRef = this.dialog.open(SkuDetailsDialog, {
            data: { sku: skuIn }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }
    presentImageWithSku(j) {
        AppComponent.SendEventGa('sku-image', this.foundCodesFiltered[j].code, "B2B_powiększenie produktu otwarcie", 0);
        const dialogRef = this.dialog.open(SkuImageDialog, {
            data: { sku: this.foundCodesFiltered[j] }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }
    presentImageOfSku(isku: Sku) {
        AppComponent.SendEventGa('sku-image', isku.code, "B2B_powiększenie produktu otwarcie", 0);
        const dialogRef = this.dialog.open(SkuImageDialog, {
            data: { sku: isku }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    alternateSubSeries(sku: Sku) {

        let sColorTitle: string = null;
        let colorEquivalents = ['biały active,biały,biały polarny,biały/kremowy', 'kremowy,beżowy,piaskowy', 'aluminium,srebrny aluminium,stal,grafit / alu',
            'brąz,satyna', 'antracyt,grafit,antracytowy', 'antracytowy czarny', 'biały polarny,biały,biały/kremowy',
            ',Brylantowy,,,szkło białe,szklana biel',
            ',Diamentowy,,,,',
            ',,,,szkło przydymione,',
            ',Onyksowy,,,szkło czarne,',
            ',Mahoniowy,,,,',
            ',Szmaragdowy,,,,',
            'stal szlachetna,,,,szczotkowane aluminium,inox',
            ',,Srebrny platynowy,,,',
            ',,Tytanowy ,,,',
            ',,,,,bronze',
            ',,Szary metaliczny,,,',
            ',,Chrom ,,,',
            'stal szlachetna,,,,szczotkowane aluminium,biały inox',
            ',,,,szczotkowane złoto,',
            ',,,,brzoza,nordic wood',
            ',,,Buk,,',
            ',,,Wiśnia,,',
            ',,,Orzech,,',
            'wenge,,,Wenge,wenge,wenge',
            'dąb,,,,,',
            'łupek,,,,,kamień ardoise',
            ',,,,,kamień galaxy',
            ',,,,beton,',
            'przezroczysta (biała),przezroczysta'
        ];
        let a = colorEquivalents.findIndex(ce => ce.includes(sku.color.title));
        if (a >= 0)
            sColorTitle = colorEquivalents[a];

        else if (sku.color.title == 'antracyt')
            sColorTitle = sku.color.title + '|grafit';
        else if (sku.color.title == 'grafit')
            sColorTitle = sku.color.title + '|antracyt';
        else if (sku.color.title == 'brąz')
            sColorTitle = sku.color.title + '|satyna';
        else if (sku.color.title == 'satyna')
            sColorTitle = sku.color.title + '|brąz'
        else if (sku.color.title == 'kremowy')
            sColorTitle = sku.color.title + '|piaskowy';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';
        else if (sku.color.title == 'biały active')
            sColorTitle = sku.color.title + '|biały active|biały';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';


        else
            sColorTitle = sku.color.title;
        let alternates = this.functionHelper.functionsSelectable;
        console.log("alternateSeries change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType.id == sku.functionType.id);
        console.log("alternateSeries:function change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.category.id == sku.category.id);
        console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);

        //alternates = alternates.filter(f => sColorTitle.includes(f.color.title));
        //console.log("alternateSeries:color change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.indexOfProtection.id == sku.indexOfProtection.id);
        console.log("alternateSeries:IP change series for: " + sku.code + " - " + alternates.length);


        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                    f.params.find(pp => pp.id == p.id) != null);
        }

        let uniqueAlternates = new Array<Sku>();
        alternates.forEach(sku => {
            if (uniqueAlternates.findIndex(s => s.subSeries.id == sku.subSeries.id) < 0)
                uniqueAlternates.push(sku);
        });
        return uniqueAlternates;

    }

    alternateSeriesWithColor(sku: Sku) {
        console.log("alternateSeriesWithColor:" + sku.code + " color");
        console.log("alternateSeriesWithColor sku.color:" + sku.color.title);
        let sColorTitle: string = null;
        let colorEquivalents = ['biały active,biały,biały polarny,biały/kremowy', 'kremowy,beżowy,piaskowy', 'aluminium,srebrny aluminium,stal,grafit / alu',
            'brąz,satyna', 'antracyt,grafit,antracytowy', 'antracytowy czarny', 'biały polarny,biały,biały/kremowy',
            ',Brylantowy,,,szkło białe,szklana biel',
            ',Diamentowy,,,,',
            ',,,,szkło przydymione,',
            ',Onyksowy,,,szkło czarne,',
            ',Mahoniowy,,,,',
            ',Szmaragdowy,,,,',
            'stal szlachetna,,,,szczotkowane aluminium,inox',
            ',,Srebrny platynowy,,,',
            ',,Tytanowy ,,,',
            ',,,,,bronze',
            ',,Szary metaliczny,,,',
            ',,Chrom ,,,',
            'stal szlachetna,,,,szczotkowane aluminium,biały inox',
            ',,,,szczotkowane złoto,',
            ',,,,brzoza,nordic wood',
            ',,,Buk,,',
            ',,,Wiśnia,,',
            ',,,Orzech,,',
            'wenge,,,Wenge,wenge,wenge',
            'dąb,,,,,',
            'łupek,,,,,kamień ardoise',
            ',,,,,kamień galaxy',
            ',,,,beton,',
            'przezroczysta (biała),przezroczysta'
        ];
        let a = colorEquivalents.findIndex(ce => ce.includes(sku.color.title));
        if (a >= 0) 
            sColorTitle = colorEquivalents[a];
            
        else if (sku.color.title == 'antracyt')
            sColorTitle = sku.color.title + '|grafit';
        else if (sku.color.title == 'grafit')
            sColorTitle = sku.color.title + '|antracyt';
        else if (sku.color.title == 'brąz')
            sColorTitle = sku.color.title + '|satyna';
        else if (sku.color.title == 'satyna')
            sColorTitle = sku.color.title + '|brąz'
        else if (sku.color.title == 'kremowy')
            sColorTitle = sku.color.title + '|piaskowy';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';
        else if (sku.color.title == 'biały active')
            sColorTitle = sku.color.title + '|biały active|biały';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';
        

        else
            sColorTitle = sku.color.title;
        let alternates = this.functionHelper.functionsSelectable;
        //console.log("alternateSeries change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType !=null && f.functionType.id == sku.functionType.id);
        //console.log("alternateSeries:function change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType != null && f.category.id == sku.category.id);
        //console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);

        //alternates = alternates.filter(f => sColorTitle.includes(f.color.title));
        //console.log("alternateSeries:color change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.indexOfProtection != null && f.indexOfProtection.id == sku.indexOfProtection.id);
        //console.log("alternateSeries:IP change series for: " + sku.code + " - " + alternates.length);

        
        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                f.params.find(pp => pp.id == p.id) != null);
        }

        let uniqueAlternates = new Array<Sku>();
        alternates.forEach(sku => {
            if (uniqueAlternates.findIndex(s => sku.series != null && s.series != null && s.series.id == sku.series.id) < 0)
                uniqueAlternates.push(sku);
        });
        return uniqueAlternates;

    }
    colorEquivalents = ['biały active,biały,biały polarny,biały/kremowy', 'kremowy,beżowy,piaskowy', 'aluminium,srebrny aluminium,stal,grafit / alu',
        'brąz,satyna', 'antracyt,grafit,antracytowy', 'antracytowy czarny', 'biały polarny,biały,biały/kremowy',
        ',Brylantowy,,,szkło białe,szklana biel',
        ',Diamentowy,,,,',
        ',,,,szkło przydymione,',
        ',Onyksowy,,,szkło czarne,',
        ',Mahoniowy,,,,',
        ',Szmaragdowy,,,,',
        'stal szlachetna,,,,szczotkowane aluminium,inox',
        ',,Srebrny platynowy,,,',
        ',,Tytanowy ,,,',
        ',,,,,bronze',
        ',,Szary metaliczny,,,',
        ',,Chrom ,,,',
        'stal szlachetna,,,,szczotkowane aluminium,biały inox',
        ',,,,szczotkowane złoto,',
        ',,,,brzoza,nordic wood',
        ',,,Buk,,',
        ',,,Wiśnia,,',
        ',,,Orzech,,',
        'wenge,,,Wenge,wenge,wenge',
        'dąb,,,,,',
        'łupek,,,,,kamień ardoise',
        ',,,,,kamień galaxy',
        ',,,,beton,',
        'przezroczysta (biała),przezroczysta'
    ];

    bestMatchingAlternateForSebSeries(sku: Sku,subseries: SubSeries): Sku {

        let sColorTitle: string = null;
        
        let a = this.colorEquivalents.findIndex(ce => ce.includes(sku.color.title));
        if (a >= 0)
            sColorTitle = this.colorEquivalents[a];
        else if (sku.color.title == 'antracyt')
            sColorTitle = sku.color.title + '|grafit';
        else if (sku.color.title == 'grafit')
            sColorTitle = sku.color.title + '|antracyt';
        else if (sku.color.title == 'brąz')
            sColorTitle = sku.color.title + '|satyna';
        else if (sku.color.title == 'satyna')
            sColorTitle = sku.color.title + '|brąz'
        else if (sku.color.title == 'kremowy')
            sColorTitle = sku.color.title + '|piaskowy';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';
        else if (sku.color.title == 'biały active')
            sColorTitle = sku.color.title + '|biały active|biały';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';


        else
            sColorTitle = sku.color.title;
        let alternates = this.functionHelper.functionsSelectable;
        console.log("alternateSeries change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType.id == sku.functionType.id);
        console.log("alternateSeries:function change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.category.id == sku.category.id);
        console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => sColorTitle.includes(f.color.title));
        console.log("alternateSeries:color change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.indexOfProtection.id == sku.indexOfProtection.id);
        console.log("alternateSeries:IP change series for: " + sku.code + " - " + alternates.length);


        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                    f.params.find(pp => pp.id == p.id) != null);
        }
        //alternates[0].subSeries
        alternates = alternates.filter(s => //s.id != sku.id &&
            (
            (s.slots > 0 && s.subSeries.id == subseries.id) ||
            s.series.id == subseries.seriesID));

        let uniqueAlternates = new Array<Sku>();
        alternates.forEach(sku => {
            if (uniqueAlternates.findIndex(s => s.subSeries.id == sku.subSeries.id) < 0)
                uniqueAlternates.push(sku);
        });
        return alternates.length > 0 ? alternates[0]: null;

    }

    alternateSubSeriesWithColor(sku: Sku) {

        let sColorTitle: string = null;
        let colorEquivalents = ['biały active,biały,biały polarny,biały/kremowy', 'kremowy,beżowy,piaskowy', 'aluminium,srebrny aluminium,stal,grafit / alu',
            'brąz,satyna', 'antracyt,grafit,antracytowy', 'antracytowy czarny', 'biały polarny,biały,biały/kremowy',
            ',Brylantowy,,,szkło białe,szklana biel',
            ',Diamentowy,,,,',
            ',,,,szkło przydymione,',
            ',Onyksowy,,,szkło czarne,',
            ',Mahoniowy,,,,',
            ',Szmaragdowy,,,,',
            'stal szlachetna,,,,szczotkowane aluminium,inox',
            ',,Srebrny platynowy,,,',
            ',,Tytanowy ,,,',
            ',,,,,bronze',
            ',,Szary metaliczny,,,',
            ',,Chrom ,,,',
            'stal szlachetna,,,,szczotkowane aluminium,biały inox',
            ',,,,szczotkowane złoto,',
            ',,,,brzoza,nordic wood',
            ',,,Buk,,',
            ',,,Wiśnia,,',
            ',,,Orzech,,',
            'wenge,,,Wenge,wenge,wenge',
            'dąb,,,,,',
            'łupek,,,,,kamień ardoise',
            ',,,,,kamień galaxy',
            ',,,,beton,',
            'przezroczysta (biała),przezroczysta'
        ];
        let a = colorEquivalents.findIndex(ce => ce.includes(sku.color.title));
        if (a >= 0)
            sColorTitle = colorEquivalents[a];

        else if (sku.color.title == 'antracyt')
            sColorTitle = sku.color.title + '|grafit';
        else if (sku.color.title == 'grafit')
            sColorTitle = sku.color.title + '|antracyt';
        else if (sku.color.title == 'brąz')
            sColorTitle = sku.color.title + '|satyna';
        else if (sku.color.title == 'satyna')
            sColorTitle = sku.color.title + '|brąz'
        else if (sku.color.title == 'kremowy')
            sColorTitle = sku.color.title + '|piaskowy';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';
        else if (sku.color.title == 'biały active')
            sColorTitle = sku.color.title + '|biały active|biały';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';


        else
            sColorTitle = sku.color.title;
        let alternates = this.functionHelper.functionsSelectable;
        console.log("alternateSeries change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType.id == sku.functionType.id);
        console.log("alternateSeries:function change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.category.id == sku.category.id);
        console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => sColorTitle.includes(f.color.title));
        console.log("alternateSeries:color change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.indexOfProtection.id == sku.indexOfProtection.id);
        console.log("alternateSeries:IP change series for: " + sku.code + " - " + alternates.length);


        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                    f.params.find(pp => pp.id == p.id) != null);
        }
        //alternates[0].subSeries

        let uniqueAlternates = new Array<Sku>();
        alternates.forEach(sku => {
            if (uniqueAlternates.findIndex(s => s.subSeries.id == sku.subSeries.id) < 0)
                uniqueAlternates.push(sku);
        });
        return uniqueAlternates;

    }

    alternateColorsOfSubSeries(sku: Sku, subseries: SubSeries) {

        let alternates = this.functionHelper.functionsSelectable;
        console.log("alternateSeries change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType.id == sku.functionType.id);
        console.log("alternateSeries:function change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.category.id == sku.category.id);
        console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);


        alternates = alternates.filter(f => f.indexOfProtection.id == sku.indexOfProtection.id);
        console.log("alternateSeries:IP change series for: " + sku.code + " - " + alternates.length);


        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                    f.params.find(pp => pp.id == p.id) != null);
        }
        //alternates[0].subSeries
        alternates = alternates.filter(s => //s.id != sku.id &&
            (
            (s.slots > 0 && s.subSeries.id == subseries.id) ||
            s.series.id == subseries.seriesID));

        return alternates;

    }


    alternateColors(sku: Sku) {
        if (this.functionHelper.functionsSelectable == null
        || sku==null)
            return;
        let alternates = this.functionHelper.functionsSelectable.filter(f => sku.series != null && f.series !=null && f.series.id == sku.series.id)
            .filter(f => f.indexOfProtection.id == sku.indexOfProtection.id)
            .filter(f => f.functionType.id == sku.functionType.id)
            .filter(f => f.category.id == sku.category.id);

        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                    f.params.find(pp => pp.id == p.id) != null);
        }

        var uniqueAlternates = new Array<Sku>();
        alternates.forEach(sku => {
            if (uniqueAlternates.findIndex(s => s.color.title == sku.color.title) <= 0)
                uniqueAlternates.push(sku);
        });

        return uniqueAlternates.sort(this.compareColouresSkus)
    }
    compareColouresSkus(s1: Sku, s2: Sku) {
        if (s1.color.order > s2.color.order) return 1;
        if (s1.color.order < s2.color.order) return -1;
        return 0;
    }

    subSeriesAll: SubSeries[] = null;
    get getSubSeriesAll(): SubSeries[] {
        if (this.subSeriesAll != null && this.subSeriesAll.length > 0)
            return this.subSeriesAll;

        return this.subSeriesAll;
    }
    changeSeriesGlobal(roomIndex: number) {
        AppComponent.SendEventGa('series', null, "B2B_zmiana grupowa serii wywołanie dialogu", 0);
        console.log("change series for All");
        const dialogRef = this.dialog.open(ChangeSeriesDialog, {
            data: { sku: null, alternateSubSeries: this.getSubSeriesAll }
        });
        dialogRef.afterClosed().subscribe(resultSubSeries => {
            console.log('The dialog was closed');
            if (resultSubSeries != null) {
                this.changeSeriesGlobalConfirm(resultSubSeries, roomIndex);
                this.updateCart();
            }
        });
    }
    changeSeriesGlobalConfirm(subseries: SubSeries, roomIndex: number) {
        AppComponent.SendEventGa('subseries', null, "B2B_zmiana grupowa subserii potwierdzenie", 0);
        let changedSkus = new Array<ChangeSeriesOptions>();
        let unmappedSkus = new Array<Sku>();
        for (let osi of this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems) {
            let alternateSkus = this.alternateColorsOfSubSeries(osi.sku, subseries);
            let fitColorSku = this.bestMatchingAlternateForSebSeries(osi.sku, subseries);

            if (alternateSkus == null || alternateSkus.length == 0) {
                unmappedSkus.push(osi.sku);

            } else {
                changedSkus.push({ mainSku: osi.sku, alternateSkus: alternateSkus, bestMatchingSku: fitColorSku, newBestMatchingSku:-1 });
            }
        }
        const dialogRef = this.dialog.open(ChangeSeriesGlobalDialog, {
            data: {
                newSeries: subseries, changedSkus: changedSkus,
                unmappedSkus: unmappedSkus
            }
        });
        dialogRef.afterClosed().subscribe(resultMap => {
            console.log('The dialog was closed');
            if (resultMap != null) {
                this.seriesChangeGlobalCommit(resultMap, roomIndex);
                this.updateCart();
            }
        });
    }
    seriesChangeGlobalCommit(changes: ChangeSeriesOptions[], roomIndex: number) {
        //AppComponent.SendEventGa('series', null, "B2B_zmiana grupowa serii potwierdzenie" , 0);
        for (let change of changes) {
            let oldSkuIndex =
                this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems.findIndex(osi => osi.sku.id == change.mainSku.id);
            this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[oldSkuIndex].sku = change.bestMatchingSku;

        }
    }

    /**
     * **************************************************************************
     * 
     */
    changeColorGlobal(roomIndex: number) {
        AppComponent.SendEventGa('color', null, "B2B_Zmiana koloru w zestawieniu w kolumnie", 0);
        console.log("change color for All");
        let alts = new Array<Sku>();
        for (let s of this.allFunctionstbfiltered) {
            if (alts.findIndex(alt => alt.color.id == s.color.id) < 0) {
                alts.push(s)
            }
        }
        console.log("AlternateSkus len: " + alts.length);
        const dialogRef = this.dialog.open(ChangeColorDialog, {
            data: { sku: null, alternateSkus: alts }
        });
        dialogRef.afterClosed().subscribe(resultSku => {
            console.log('The dialog was closed');
            if (resultSku != null) {
                this.changeColorGlobalConfirm(resultSku.color, roomIndex);
                this.updateCart();
            }
        });
    }
    changeColorGlobalConfirm(icolor: Color, roomIndex: number) {
        AppComponent.SendEventGa('color', null, "B2B_Potwierdzenie zmiany koloru w zestawieniu w kolumnie", 0);
        let changedSkus = new Array<Array<Sku>>();
        let unchangedSkus = new Array<Sku>();
        let unmappedSkus = new Array<Sku>();
        for (let osi of this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems) {
            let newSku = this.alternateColors(this.skuFull(osi.sku)).find(s => s.color.id == icolor.id);
            if (newSku == null) {
                unmappedSkus.push(osi.sku);
            } else if (osi.sku.id != newSku.id) {
                changedSkus.push([osi.sku, newSku]);
            } else {
                unchangedSkus.push(osi.sku);
            }
        }
        const dialogRef = this.dialog.open(ChangeSeriesGlobalDialog, {
            data: {
                newSeries: icolor, changedSkus: changedSkus,
                unchangedSkus: unchangedSkus, unmappedSkus: unmappedSkus
            }
        });
        dialogRef.afterClosed().subscribe(resultMap => {
            console.log('The dialog was closed');
            if (resultMap != null) {
                this.seriesChangeGlobalCommit(resultMap,roomIndex);
                this.updateCart();
            }
        });
    }/*
    colorChangeGlobalCommit(changes: Sku[][], roomIndex: number) {
        AppComponent.SendEventGa('color', null, 'change-global-commit;', 0);
        for (let change of changes) {
            let oldSkuIndex =
                this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems.findIndex(osi => osi.sku.id == change[0].id);
            this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[oldSkuIndex].sku = change[1];
        }
    }*/
    /*
    openSeriesChangeDialog(roomIndex: number, j: number): void {
        AppComponent.SendEventGa('series', null, 'change-global-dialog', 0);
        let insku = this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[j].sku;
        //let insku = this.functionHelper.functionsCollected[j].sampleSku;
        let alternates = this.alternateSeries(insku);

        console.log("change series for: " + insku.code + " - " + alternates.length);
        const dialogRef = this.dialog.open(ChangeSeriesDialog, {
            data: { sku: insku, alternateSkus: alternates }
        });

        dialogRef.afterClosed().subscribe(resultSku => {
            console.log('openSeriesChangeDialog The dialog was closed');
            if (resultSku != null) {
                this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[j].sku = resultSku;
                this.updateCart();
            }
        });
    }*/
    changeSeriesOfUnit(roomIndex: number, itemIndex: number) {
        console.log("newSkuId: " + this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].newSkuId);
        let old = this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku;
        let seriesSelectedSkuId = this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].newSkuId;
        this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku =
            this.functionHelper.functionsSelectable.find(s => s.id == seriesSelectedSkuId);
        console.log("new sku color:" + this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku.code + ":" +
            this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku.color.title);
    }
    /*openColorChangeDialog(roomIndex: number, j: number): void {
        AppComponent.SendEventGa('color', null, 'change-global-global', 0);
        let insku = this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[j].sku;
        let alternates = this.alternateColors(insku);

        const dialogRef = this.dialog.open(ChangeColorDialog, {
            data: { sku: insku, alternateSkus: alternates }
        });

        dialogRef.afterClosed().subscribe(resultSku => {
            console.log('The dialog was closed');
            if (resultSku != null) {
                this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[j].sku = resultSku;
                this.updateCart();
            }
        });
    }
    */
    newSkuId;
    changeColorOfUnit(roomIndex:number, itemIndex: number, color: Color) {
        console.log("newSkuId: " + this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].newSkuId);
        let old = this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku;
        let colorSelectedSkuId = this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].newSkuId;
        this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku =
            this.functionHelper.functionsSelectable.find(s => s.id == colorSelectedSkuId);
        console.log("new sku color:" + this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku.code + ":" +
            this.openedProject.rooms[roomIndex].orderSets[0].orderSetItems[itemIndex].sku.color.title);
    }

    availableOption(s: ParamValue) {
        return s.available ? 'disabled' : '';
    }
    exportCart() {
        AppComponent.SendEventGa('cart', null, "Otworzenia okna z eksportem do sklepów", 0);
        const dialogRef = this.dialog.open(ExportConfigComponent, {
            data: {
                os: this.orderService, p: this.openedProject
            }
        });

        dialogRef.afterClosed().subscribe();
    }

    skuFull(sku: Sku): Sku {
        console.debug("skuFull:this.functionHelper.functionsAll.find(s => s.id == " + sku.id + "); RESULT= " +
            this.functionHelper.functionsAll.find(s => s.id === sku.id));
        return this.functionHelper.functionsAll.find(s => s.id === sku.id);
    }
    foundCodes: Sku[];
    public static searchedCode: string;
    tooManyResults = false;

   

    searchForCodeGlobal(code: string) {
        //AppComponent.SendEventGa('selection', code, 'search', 0);
        B2BCollectFunctionsComponent.searchedCode = code;
        this.tooManyResults = false;
        
        let tmpFound = null;// = this.functionHelper.functionsAll.filter(s => s.code.startsWith(code));

        this.httpclient.get<Sku[]>(this.baseUrl + 'api/CatalogData/skubycode/' + code).subscribe(result => {
            tmpFound = result;

            this.searchedByCode = true;

            if (tmpFound.length < 30) {

                console.log("searchForCodeGlobal.searchedByCode: " + this.searchedByCode);
                this.foundCodes = tmpFound;
                this.foundCodes.sort(this.lexicalSimilarityToSearchedCode);
                //this.reloadFunctionsAndUpdateFunctions
            } else
                this.tooManyResults = true;
        }, error => console.error(error));
        
    }
    public lexicalSimilarityToSearchedCode(s1: Sku, s2: Sku): number {
        let s1out = s1.code.replace(B2BCollectFunctionsComponent.searchedCode, "").length;
        let s2out = s2.code.replace(B2BCollectFunctionsComponent.searchedCode, "").length;
        let res = s1out >= s2out ? 1 : -1
        console.log('comparing: ' + s1.code + " with " + s2.code + " result is " + res);
        return res;
    }
    filtersDisabled() {
        return this.searchedByCode != null && this.searchedByCode
    }
    
    likeSku(sku: Sku) {
        AppComponent.SendEventGa('selection', sku.code, 'B2B_dodanie do ulubionych', 0);
        this.orderService.likedSkus.push(sku);
        this.orderService.saveCurrentContextLocaly();
    }
    dislikeSku(sku: Sku) {
        AppComponent.SendEventGa('selection', sku.code, 'B2B_usunięcie z ulubionych', 0);
        const index = this.orderService.likedSkus.findIndex(s => s.code == sku.code);
        if (index >= 0) {
            this.orderService.likedSkus[index] = this.orderService.likedSkus[this.orderService.likedSkus.length - 1];
            this.orderService.likedSkus.pop();
        }
        this.orderService.saveCurrentContextLocaly();
    }
    isLikedSku(sku: Sku) {
        return this.orderService.likedSkus.findIndex(s => s.code == sku.code) >= 0;
    }
    openLiked(): any {
        AppComponent.SendEventGa('selection', null, 'B2B_otwarcie listy ulubionych', 0);
        this.foundCodes = this.orderService.likedSkus.sort();
    }
    skuParamValue(ip: Parameter, skui: number) {
        return (this.foundCodesFiltered[skui].params.find(p => ip.title == p.title) == null) ?
            null:this.foundCodesFiltered[skui].params.find(p => ip.title == p.title).value;
    }




    currency(a: number) {
        return a.toFixed(2).replace('.', ',');
    }
    mult(a: number, b: number): number {
        return a * b;
    }
    sumAfterDiscount() {
        return this.roundToTwo(this.sumNetProjectRaw(null) *
            ((this.discount > 0 && this.discount<100) ?
                (100 - this.discount) :
                100)
            / 100);
    }
    isDiscountValid(): boolean {
        return this.discount < 0 || this.discount > 100;
    }

    forwardToEcommerce() {
        this.orderService.openEcommerce();
    }

    icon = false;

    toggleparam() {
        this.icon = !this.icon;
    }
    private layers(allCodes) {
        return PresentationToolsComponent.layers(allCodes);
    }
    isLess(resolution: number): boolean {
        return window.screen.width < resolution;
    }
    remSpaces(code: string): string {
        return code.replace(' + ','+');
    }
}
