
    <div id="tab-content-projects">

        <div *ngFor="let project of orderService.projects; let i = index;"
             style="display:inline-flex">
            <div *ngIf="project.type !=1" class="project-button-label" >
                <div class="project-info-line" (click)="openProject(i)" >
                    <div class="project-button" title="{{project.title}}">
                        {{project.title}}
                    </div>
                    <div class="tile-summary">Suma netto*: <b>{{countAllProject(project)[0]}}zł</b></div>
                    <div class="tile-summary">Elementów: <b>{{countAllProject(project)[1]}}</b></div>
                </div>
                <div class="project-buttons-line">
                    <div id="edit-button" class="project-bottom-buttom" matTooltip="Nazwa projektu">
                        <img class="project-button-icon" (click)="changeProject(i);event.stopPropagation();" src="/images/icons/edytuj-white.png" />
                    </div>
                    <div id="duplicate-button" class="project-bottom-buttom" matTooltip="Duplikuj projekt">
                        <img class="project-button-icon" (click)="duplicateProjekt(i);event.stopPropagation();" src="/images/icons/kopiuj-white.png" />
                    </div>
                    <div id="del-button" class="project-bottom-buttom" matTooltip="Usuń projekt">
                        <img class="project-button-icon" (click)="deleteProject(i);event.stopPropagation();" src="/images/icons/usun-white.png" />
                    </div>
                </div>
            </div>
        </div>

        <div class="project-button-new">
            <div class="app-component projectContainer">
                <div (click)="newProjectConfig()" class="project-button" title="Utwórz nowy projekt">
                    Utwórz nowy projekt
                </div>
                <div (click)="newProjectConfig()" class="new-project-icon">
                    <img src="/images/icons/dodaj projekt livegreen.png" alt="Ikona dodania nowego projektu" />
                </div>
            </div>
        </div>

    </div>

