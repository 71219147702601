import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BootstrapTabDirective } from '../../directives/bootstrap-tab.directive';
import { SharedModule } from '../main/shared.module';
import { UsersManagementComponent } from './users-management.component';
import { UserInfoComponent } from './user-info.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SearchBoxComponent } from './search-box.component';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BootstrapSelectDirective } from '../../directives/bootstrap-select.directive';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [
        AdminRoutingModule,
        SharedModule,
        DragDropModule,
        DragAndDropModule,
        //NgxDatatableModule,
        ModalModule.forRoot(),
        NgxDatatableModule
    ],
    declarations: [
        UsersManagementComponent,
        UserInfoComponent,
        SearchBoxComponent,
        BootstrapSelectDirective
        
    ],
    entryComponents: [],
    providers: [
        BsModalRef
    ]
})
export class AdminConfigModule { }
