import { PresentationToolsComponent } from "../services/presentation-tools.component";
import { User } from "../models/user.model";


export interface ProjectOrder {
    projectId: string;
    requestId: string;
    userCode: string;
    cart: ProjectOrderItem[];

}
export interface ProjectOrderItem {
    code: string;
    qty: number;
}
export interface Project {
    id?: number;
    title: string;
    rooms: Room[];
    type: number;
    user?: User;
};
export interface RoomInt {
    id: number;
    title: string;

    orderSets: OrderSet[];
    wallColor: string;
    fontColor: string;
    image: string;
}
export class Room implements RoomInt {
    newId(): number {
        return Math.round(1000 * Math.random());
    }
    public empty() {
        return this.orderSets == null || this.orderSets.length == 0;
    }
    id: number;
    title: string;
    orderSets: OrderSet[];
    wallColor: string;
    fontColor: string;
    image: string;
    itemIndex: number = 0;
    constructor(title: string, wallColor?: string, image?: string, id ?: number, orderSet ?: OrderSet[]){
        this.title = title;
        this.wallColor = (wallColor != null) ? wallColor : 'white';
        this.id = (id != null) ? id : this.newId();
        this.image = image;
        this.itemIndex = 0;
    }
}
export interface Busket {
    id: number;
    title?: string;
    orderSet?: OrderSet[];
}

export interface OrderSetItem {
    id: number;
    sku: Sku;
    qty: number;
    newSkuId?: number;
    multiBlockPosition?: number;
    isVertical?: boolean;
}

export interface OrderSet {
    id: number;
    title: string;
    orderSetItems: OrderSetItem[];
}

export interface OrderItem {
    id: number;
  functionalityType: string;
  cseries: Series[];
  chosenSeries: Series;
    qty: number;
}

export interface Series {
  id: number;
    title: string;
    description: string;
    categories: Category[];
    image: string;
    order: number;
    subSeries: SubSeries[];
    employeeOnly: boolean;
}
export interface SubSeries {
    id: number;
    title: string;
    parent: Series;
    order: number;
    seriesID: number;
    inactive: boolean;
}
export interface Compatibility {
    id: number;
    compCode: string;
    series: Series;
    subSeries: SubSeries;
}

export interface Category {
    id: number;
    title: string;
    description: string;
    order: number;
    //categoryGroup: CategoryGroup;
    //avaiableSkus: Sku[];
 }
export interface CategoryGroup {
    id: number;
    title: string;
    order: number;
    categories: Category[];
}

export interface Product {
  id: number;
  title: string;
  types: Type[];
 }

export interface Type {
  id: number;
  title: string;
}

export interface FunctionType {
  id: number;
  title: string;

}

export interface Sku {
    id: number;
    title: string;
    category: Category;
    indexOfProtection: IndexOfProtection;
    color: Color;
    series: Series;
    subSeries: SubSeries;
    functionType: FunctionType;
    categoryGroupID: number;
    code: string;
    params: SkuParameter[];
    net: number;
    gross: number;
    info: string;
    withFrame: boolean;
    elk: number;
    isVertical: boolean;
    slots: number;
    shortInfo: string;
    universal: boolean;
    selectable: boolean;
}
export interface SkuParameter {
    id: number;
    title: string;
    value: string;
    vorder: number;
    torder: number;
}


export interface IndexOfProtection {
    id: number;
    title: string;
}
export interface Color {
    id: number;
    title: string;
    order: number;
}
export interface ColorSeries {
    colorId: number;
    colorName: string;
    seriesId: number;
    seriesName: string;
}



export class CategoryGroupFormated {
    public constructor(cg: CategoryGroup, functionTypes: FunctionsTypeImpl[]) {
        console.log("create categoryGroup: " + cg.title + ":" + functionTypes);
        this.cg = cg;
        if (functionTypes == null) {
            this.functionTypes = new Array<FunctionsTypeImpl>();
        } else {
            this.functionTypes = functionTypes;
        }
    }
    public cg: CategoryGroup;
    public functionTypes: FunctionsTypeImpl[];
    public categoriesFormatedStatic: FunctionsTypeImpl[][];
    public functions() {
        let f = new Array<Array<Sku>>();
        for (let ft of this.functionTypes) {
                //[TODO] it should be removed and functions should be not used in general as first selection
                f.push(ft.avaiableSkus);
        }
    }
    public categoriesFormated(nCols: number): FunctionsTypeImpl[][] {
        this.categoriesFormatedStatic = PresentationToolsComponent.formatPresentationLists(this.functionTypes, nCols); 
        return this.categoriesFormatedStatic;
    }

   
}
export class FunctionsTypeImpl {
    public id: number;
    public title: string;
    public avaiableSkus: Sku[];
    public repSku: Sku;
    constructor(id: number, title: string) {
        this.id = id;
        this.title = title;
        this.avaiableSkus = new Array<Sku>();
        this.repSku = null;

    }
    public position?: number;
}
/*
export class CategoryImpl implements Category{
    public id: number;
    public title: string;
    //public categoryGroup: CategoryGroup;
    public avaiableSkus: Sku[];
    public repSku: Sku;
    constructor(id: number, title: string) {
        this.id = id;
        this.title = title;
        //this.categoryGroup = null;
        this.avaiableSkus = new Array<Sku>();
        this.repSku = null;

    }
}*/
