import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sku, Series } from '../../../_models';
import { Color } from 'ng2-charts';
import { AppComponent } from '../../app.component';

export interface ChangeColor2GlobalData {
    newColor: Color;
    changedSkus: Sku[][];
    unchangedSkus: Sku[];
    unmappedSkus: Sku[];
}

@Component({
    selector: 'change-color-global-dialog',
    templateUrl: './change-color-global-dialog.component.html',
    styleUrls: ['./change-color-global-dialog.component.css']
})
export class ChangeColorGlobalDialog {

    constructor(
        public dialogRef: MatDialogRef<ChangeColorGlobalDialog>,
        @Inject(MAT_DIALOG_DATA) public changes: ChangeColor2GlobalData) {

    }

    onNoClick(): void {
        AppComponent.SendEventGa('color', 'cancel', 'B2B_Anulowanie zmiany koloru w zestawieniu w kolumnie', 0);
        this.dialogRef.close();

    }


}
