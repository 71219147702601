import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { GA_TOKEN, GoogleAnalyticsModule } from "angular-ga";
import { ImageUploadModule } from "angular2-image-upload";
import { ToastyModule } from 'ng2-toasty';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { AppErrorHandler } from './app-error.handler';
import { AppRoutingModule } from './app-routing.module';
import { AboutComponent } from "./components/about/about.component";
import { AppComponent } from "./components/app.component";
import { FillSetupComponent } from "./components/b2b/fill-setup.component";
import { FunctionHelperComponent } from "./components/b2b/function-helper.component";
import { EcommerceRedirectComponent } from "./components/controls/ecommerce-redirect.component";
import { ExportConfigComponent } from "./components/controls/export-config.component";
import { AccountComponent } from "./components/login/account.component";
import { AccountActivationComponent } from "./components/login/activate.component";
import { ChangePassComponent } from "./components/login/change-pass.component";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/login/register.component";
import { ResetPassComponent } from "./components/login/reset-pass.component";
import { SesaRegisterComponent } from "./components/login/sesa-register.component";
import { HomeMainComponent } from "./components/main/main.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapDatepickerDirective } from './directives/bootstrap-datepicker.directive';
import { BootstrapSelectDirective } from './directives/bootstrap-select.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { GroupByPipe } from './pipes/group-by.pipe';
import { AccountEndpoint } from './services/account-endpoint.service';
import { AccountService } from './services/account.service';
import { AlertService } from './services/alert.service';
import { AppTitleService } from './services/app-title.service';
import { TranslateLanguageLoader } from './services/app-translation.service';
import { AppTranslationService } from "./services/app-translation.service";
import { CompletionService } from './services/completion.service';
import { ConfigurationService } from './services/configuration.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { NotificationService } from './services/notification.service';
import { PresentationToolsComponent } from './services/presentation-tools.component';
import { AlertComponent } from "./_directives";
import { InformationDialogComponent } from './components/controls/information-dialog.component';
import { ProjectNameDialog } from './components/controls/project-name.component';
import { SharedModule } from './components/main/shared.module';

import { CookieLawModule } from 'angular2-cookie-law';
import { BaseRegisterComponent } from './components/login/base-register.component';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { AccountRemovecomponent } from './components/controls/account-remove.component';
import { AccountRegisterSentComponent } from './components/login/register-sent.component';
import { CodeRequestSentComponent } from './components/login/code-request-sent.component';
import { InspirationsMainComponent } from './components/inspirations/main.component';
import { ColorInfoDialog } from './components/inspirations/controls/color-info-dialog.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SetInfoDialog } from './components/inspirations/controls/set-info-dialog.component';
import { TutorialDialogComponent } from './components/main/tutorial.component';
import { VideoModule } from './components/main/video.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        
        BrowserModule.withServerTransition({ appId: 'app-root' }),
        PerfectScrollbarModule,
        NgbModule,
        BrowserModule,
        GoogleAnalyticsModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ToastyModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        NgxMaskModule.forRoot(),
        BrowserModule,
        ImageUploadModule.forRoot(),
        SharedModule.forRoot(),
        SwiperModule,
        CookieLawModule,
        MatCheckboxModule,
        MatDialogModule,
        HammerModule,
        VideoModule,
        NgxDatatableModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        AboutComponent,
        NotFoundComponent,
        EqualValidator,
        LastElementDirective,
        AutofocusDirective,
        BootstrapToggleDirective,
        BootstrapDatepickerDirective,
        GroupByPipe,
        BaseRegisterComponent,
        RegisterComponent,
        SesaRegisterComponent,
        ResetPassComponent,
        ChangePassComponent,
        AccountActivationComponent,
        AccountRegisterSentComponent,
        HomeMainComponent,
        InformationDialogComponent,
        TutorialDialogComponent,
        AccountRemovecomponent,
        FunctionHelperComponent,
        ExportConfigComponent,
        EcommerceRedirectComponent,
        NavMenuComponent, AlertComponent, LoginComponent, AccountComponent, ProjectNameDialog, CodeRequestSentComponent,
        InspirationsMainComponent, ColorInfoDialog, SetInfoDialog,
        FillSetupComponent
    ],
    exports: [LoginComponent],

    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: GA_TOKEN, useValue: 'UA-132781671-1' },
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        AlertService,
        ConfigurationService,
        AppTitleService,
        AppTranslationService,
        NotificationService,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
        LocalStoreManager,
        EndpointFactory,
        CompletionService,
        PresentationToolsComponent
    ],
    bootstrap: [AppComponent],
    entryComponents: [ExportConfigComponent, EcommerceRedirectComponent, InformationDialogComponent, ProjectNameDialog, AccountRemovecomponent,
        ColorInfoDialog, TutorialDialogComponent],
})
export class AppModule {
}




export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}
