
<div #summary class="summary-container" [class.example-is-mobile]="mobileQuery.matches"
     [@openClosed]="showSummary ? 'opened' : 'closed'">
    <button mat-flat-button id="summaryOpenButton" (click)="showSummaryToggle()">
        Twój zestaw
    </button>
    <div class="summary-list-container">
        <div class="bolded">{{_orderService.openedRoom().title}}</div>
        <div class="summary">
            <div class="summary-table">
                <div class="sum-wrapper">
                    <div class="sum">Mechanizmów:</div>
                    <div class="sum-value">{{numberOfMechanisms}}</div>
                </div>
                <div class="sum-wrapper">
                    <div class="sum">Miejsc w ramkach:&nbsp;</div>
                    <div class="sum-value">{{numberOfFrames}}</div>

                </div>
                <div class="sum-wrapper" *ngIf="numberOfFrames<numberOfMechanisms">
                    <div class="red">
                        Brakuje {{numberOfMechanisms-numberOfFrames}} <span *ngIf="(numberOfMechanisms-numberOfFrames) == 1">&nbsp;miejsca&nbsp;</span>
                        <span *ngIf="(numberOfMechanisms-numberOfFrames) > 1">&nbsp;miejsc&nbsp;</span> w ramkach
                    </div>
                </div>
                <div class="sum-wrapper" *ngIf="numberOfFrames>numberOfMechanisms">
                    <div class="red">
                        Brakuje {{numberOfFrames-numberOfMechanisms}} <span *ngIf="(numberOfFrames-numberOfMechanisms) == 1">&nbsp;mechanizmu.&nbsp;</span>
                        <span *ngIf="(numberOfFrames-numberOfMechanisms) > 1">&nbsp;mechanizmów.&nbsp;</span>
                    </div>
                </div>

            </div>
            <div >
                <button [disabled]="!_orderService.isAnythingInAnySet" mat-stroked-button color="warn" class="white-small-button red-label clear-button"
                        style="text-align:center;padding-left:10px" (click)="clearAllSets()">
                    <img src="/images/icons/usun-red.png" style="height: 21px;position: inherit;margin: 5px;" />
                    Wyczyść
                </button>
            </div>
        </div>
        <div class="summary-list-wrapper">
            <div role="list" *ngIf="_orderService.isAnythingInAnySet" style="max-height: inherit;">

                <div class="summary-position-b2c" *ngFor="let orderSet of _orderService.openedRoom().orderSets;let i = index;" role="listitem"
                     style="height:unset;">
                    <div *ngIf="orderSet.orderSetItems[0] != null" class="series-title">Seria {{orderSet.orderSetItems[0].sku.series.title}}</div>
                    <div *ngFor="let orderSetItem of orderSet.orderSetItems;let j = index;"
                         class="summary-set-position">
                        <div class="position-wrapper" *ngIf="orderSetItem!=null && orderSetItem.sku != null && orderSetItem.qty>0">
                            <div class="position-label-split">
                                <div class="position-label">{{orderSetItem.sku.functionType.title}}</div>
                                <div class="position-label">{{currency(orderSetItem.sku.net)}}&nbsp;zł</div>
                            </div>
                            
                            <div class="position-label-left">
                                {{orderSetItem.sku.code}}
                                <div class="summary-action" (click)="deleteOrderSetPosition(j,i)">Usuń</div>
                            </div>

                        </div>

                        <div class="frame-image" *ngIf="orderSetItem!=null && orderSetItem.sku != null  && orderSetItem.qty>0">
                            <img *ngFor="let layer of layers(orderSetItem.sku.code)" src="/images/seriesorg/{{orderSetItem.sku.series.title}}/{{layer}}.png"
                                 onerror='this.src="/images/icons/noimage.png"'  alt="{{orderSetItem.sku.functionType.title}}" style="max-height:50px;max-width: 80%;" />
                        </div>


                        <div hidden class="frame-number-config" *ngIf="orderSetItem!=null && orderSetItem.sku!=null && orderSetItem.sku.slots>-1">
                            <div class="frame-number-manipulation-cell" (click)="_orderService.decPosition(j,i)">
                                <img src="/images/icons/minus.png" />
                            </div>
                            <div class="frame-number-manipulation-cell">{{orderSetItem.qty}}</div>
                            <div class="frame-number-manipulation-cell" (click)="_orderService.incPosition(j,i)">
                                <img src="/images/icons/plus.png" />
                            </div>
                        </div>
                    </div>

                    <mat-divider *ngIf="orderSetItem!=null && orderSetItem.sku != null"></mat-divider>
                </div>
            </div>
            <div role="list" *ngIf="!_orderService.isAnythingInAnySet" style="max-height: inherit;">
                <div class="summary-position-b2c" role="listitem" style="height:unset;">
                    <div class="summary-set-position">
                        <div class="position-wrapper">
                            <div class="position-label">Brak pozycji na liście</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

