<div class="button-wrapper">
    <div class="button-row">
        <div title="Pobierz PDF" class="image-button" (click)="downloadPdf()">
            <img src="/images/icons/pdf-black.png" />
            <div class="label">Pobierz PDF</div>
        </div>
        <div title="Pobierz Excel" class="image-button" (click)="downloadExcel()">
            <img src="/images/icons/excel-black.png" />
            <div class="label">Pobierz Excel</div>
        </div>
        <div title="Pobierz CSV" class="image-button" (click)="downloadCsv()">
            <img src="/images/icons/csv-black.png" />
            <div class="label">Pobierz CSV</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="button-row">
        <div title="Pobierz karty katalogowe" class="image-button" (click)="downloadCardsForProject()">
            <img src="/images/icons/details-black.png" />
            <div class="label">Pobierz karty katalogowe</div>
        </div>
        <div title="Pobierz zdjęcia" class="image-button" (click)="downloadPicturesForProject()">
            <img src="/images/icons/images-black.png" />
            <div class="label">Pobierz zdjęcia</div>
        </div>
    </div>
</div>
<div id="b2bSummaryCanvas" hidden>
    <div class="e-pdf-header">
        <div class="e-pdf-logo">
            <img src="/images/logo-white2.png" />
        </div>
        <div class="e-pdf-text">
            <span>
                ul. Konstruktorska 12
            </span>
            <span>
                02-673 Warszawa
            </span>
            <span>
                www.se.com/pl
            </span>
            <span>
                www.dobierz-gniazdko.pl
            </span>
        </div>
        <div class="e-pdf-text">
            <span>
                Cetrum Obsługi Klienta
            </span>
            <div style="display:flex;flex-direction:row">
                <div>
                    Infolinia:
                </div>
                <div style="display:flex;flex-direction:column">
                    <div>
                        &nbsp; +48 22 511 84 64
                    </div>
                    <div>
                        &nbsp; +48 801 171 500
                    </div>
                </div>
            </div>
            <span>
                e-mail: poland.helpdesk@se.com
            </span>
        </div>
    </div>
    <div class="version-note">Numer zamówienia: {{data.os.cartId}} {{data.os.timestamp}}</div>
    <mat-expansion-panel *ngFor="let room of set.rooms;let j = index;" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Zestaw:&nbsp; <b>{{room.title}}</b>
            </mat-panel-title>
            <mat-panel-description>
                Ilość miejsc w ramkach:&nbsp;<b>{{numberOfFrames(room)}}</b>, ilość mechanizmów:&nbsp;<b>{{numberOfFunctions(room)}}</b>.
                Suma netto*:&nbsp;<b>{{sumNet(room)}}&nbsp;zł</b>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="function-row-wrapper-header">
            <div class="function-row-wrapper">

                <div class="function_header" style="height:inherit;font-size:small">Produkt</div>
                <div class="function_header" style="height:inherit;font-size:small" title="Numer referencyjny produktu">Nr ref.</div>

                <div class="function_header" style="height:inherit;font-size:small">
                    Seria
                </div>
                <div class="function_header" style="height:inherit;font-size:small">
                    Kolor
                </div>
                <div class="function_header" style="height:inherit;font-size:small">Podgląd</div>
                <div title="cena katalogowa netto wg cennika" class="function_header" style="height:inherit;font-size:small">Cena netto*</div>

                <div class="function_header" style="height:inherit;font-size:small">Ilość</div>
                <div class="function_header" style="height:inherit;font-size:small">Wartość netto</div>
            </div>
            <div class="function-row-wrapper{{i%2}}"
                 *ngFor="let osi of room.orderSets[0].orderSetItems ;let i = index;">


                <div class="e-function-label" style="text-align: left;">
                    {{osi.sku.info}}
                    <span style="color:red;" *ngIf="osi.sku.withFrame">z&nbsp;ramką</span>
                </div>
                <div class="e-function-label" title="Numer referencyjny produktu">
                    {{osi.sku.code}}
                </div>

                <div class="seriesInSummary e-function-label" title="Zmień serię">
                    {{osi.sku.series.title}}
                </div>
                <div class="colorInSummary e-function-label" title="Zmień kolor">
                    {{osi.sku.color.title}}
                </div>


                <div class="e-function-label  e-function-label-icon e-function-label-clickable e-function-label-right" (click)="presentImageOfSku(osi.sku)"
                     style="padding:0px;" title="Podgląd produktu">
                    <img src="/images/series/colors/{{osi.sku.color.title}}.png" />
                </div>


                <div title="cena katalogowa netto wg cennika" style="width: 60px;" class="e-function-label">
                    {{currency(osi.sku.net)}}&nbsp;zł
                </div>
                <div class="function-input-wrapper">
                    {{osi.qty}}
                </div>
                <div title="suma wg ceny katalogowej netto" style="width: 60px;" class="e-function-label">
                    {{currency(osi.sku.net*osi.qty)}}&nbsp;zł
                </div>
            </div>
        </div>


    </mat-expansion-panel>


    <div class="html2pdf__page-break" *ngIf="data.os.containsComplexSkus"></div>
    <mat-expansion-panel *ngIf="data.os.containsComplexSkus" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Pojedyncze pozycje
            </mat-panel-title>
            <mat-panel-description>
                wszystkie zestawy, pojedyncze produkty
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="version-note" *ngIf="data.os.containsComplexSkus && data.os.unitProjectSkus != null">
            <span class="bolded-green">Pojedyncze produkty - zestawienie</span>
        </div>
        <div class="function-row-wrapper-header" *ngIf="data.os.containsComplexSkus && data.os.unitProjectSkus != null;">
            <div class="function-row-wrapper">

                <div class="function_header" style="height:inherit;font-size:small">Produkt</div>
                <div class="function_header" style="height:inherit;font-size:small" title="Numer referencyjny produktu">Nr ref.</div>

                <div class="function_header" style="height:inherit;font-size:small">
                    Seria
                </div>
                <div class="function_header" style="height:inherit;font-size:small">
                    Kolor
                </div>
                <div class="function_header" style="height:inherit;font-size:small">Podgląd</div>
                <div title="cena katalogowa netto wg cennika" class="function_header" style="height:inherit;font-size:small">Cena netto*</div>

                <div class="function_header" style="height:inherit;font-size:small">Ilość</div>
                <div class="function_header" style="height:inherit;font-size:small">Wartość netto</div>
            </div>
            <div class="function-row-wrapper{{i%2}}"
                 *ngFor="let osi of data.os.unitProjectSkus;let i = index;">


                <div class="e-function-label" style="text-align: left;">
                    {{osi.sku.info}}
                    <span style="color:red;" *ngIf="osi.sku.withFrame">z&nbsp;ramką</span>
                </div>
                <div class="e-function-label" title="Numer referencyjny produktu">
                    {{osi.sku.code}}
                </div>

                <div class="seriesInSummary e-function-label" title="Zmień serię">
                    {{osi.sku.series.title}}
                </div>
                <div class="colorInSummary e-function-label" title="Zmień kolor">
                    {{osi.sku.color.title}}
                </div>


                <div class="e-function-label  e-function-label-icon e-function-label-clickable e-function-label-right" (click)="presentImageOfSku(osi.sku)"
                     style="padding:0px;" title="Podgląd produktu">
                    <img src="/images/series/colors/{{osi.sku.color.title}}.png" />
                </div>


                <div title="cena katalogowa netto wg cennika" style="width: 60px;" class="e-function-label">
                    {{currency(osi.sku.net)}}&nbsp;zł
                </div>
                <div class="function-input-wrapper">
                    {{osi.qty}}
                </div>
                <div title="suma wg ceny katalogowej netto" style="width: 60px;" class="e-function-label">
                    {{currency(osi.sku.net*osi.qty)}}&nbsp;zł
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</div>
<div id="spinner" hidden>
    <mat-spinner  class="summary-set-spinner"></mat-spinner>
</div>
