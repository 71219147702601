<!-- Series not selected-->
<div id="series-selection-header" class="pseudoshadow" >
    <div class="tab-content-description">
        <h2>Kliknij, aby wybrać serię.</h2>
    </div>
</div>
<div class="tab-content-series" [ngStyle]="{'background-color': _orderService.wallColorSafe}" style="color: black" ng-controller="SeriesSelectionComponent" cdk-scrollable>
    <!-- Series selection -->
    <div class="series-collection">
        <div *ngFor="let tuple of seriesSubseries" class="series-container" style="min-width:102px;max-width:240px;width:20% !important;">
              
                    <div class="series-button" style="height: 100%; display: flex; justify-content:space-between; flex-direction:column;" (click)="selectSubSeries(tuple[1],tuple[0]);">
                        <div class="app-component blacktoplabel series-label">
                            {{tuple[1].title}}
                        </div>
                        <div class="app-component series-image">
                            <img src="/images/series/{{tuple[1].title}}/reps/Default.png" alt="{{tuple[1].title}}" style="width:100%;" />
                        </div>

                    </div>
        </div>
    </div>
</div>

<app-side-summary></app-side-summary>
