<div class="vertical-center-flex">
    <div class="login-container center-block">
        <div class="boxshadow">
            <div class="panel-body-left panel-body-white">
                <div class="panel-title">Aktywacja</div>
                <div class="panel-description">
                    {{message}}
                </div>
                <div class="form-group-row"></div>
            </div>
            <div class="panel-body-right panel-body-livegreen">

                <div class="panel-title">Witaj!</div>
                <div class="panel-description">
                    Zaloguj się aby móc w pełni skorzystać z możliwości aplikacji.
                    Dostęp na dowolnym urządzeniu i zapisywanie historii projektów.
                </div>
                <div class="form-group-row">
                    <div class="form-group">
                        <button id="login-button" mat-flat-button
                                type="submit" class="btn btn-primary white-button" [disabled]="isLoading"
                                (click)="signIn()">
                            <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>
                            <img src="/images/icons/plus-livegreen.png"/>
                            <span>
                                ZALOGUJ
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
