import { Component, OnInit, OnDestroy, Input } from "@angular/core";

import { UserService, AlertService } from '../../_services/index';
import { MessageSeverity, DialogType } from '../../services/alert.service';
import { AuthService } from "../../services/auth.service";
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { UserLogin } from '../../models/user-login.model';
import { Router } from "@angular/router";
import { InformationDialogComponent, DialogData } from "../controls/information-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AppComponent } from "../app.component";
import { SesaRegisterComponent } from "./sesa-register.component";

@Component({
    selector: "app-change-pass",
    templateUrl: './change-pass.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService, AlertService]
})

export class ChangePassComponent implements OnInit {

    isLoading = false;
    ngOnInit(): void {
        AppComponent.SendEventGa('selection', null, 'Ustawienie hasła_Otwarcie formatki z e-mail', 0);
        const parameters = new URLSearchParams(window.location.search);
        let token = parameters.get('token');
        if (!this.authService.isLoggedIn && token == null)
            this.router.navigate(['/logowanie']);
        else
            this.model.resetToken = token;
    }
    model: any = {};
    get loggedIn(): boolean {
        return this.authService.isLoggedIn;
    }
    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        private authService: AuthService) {
    }
    
    changePass() {
        AppComponent.SendEventGa('selection', null, 'Ustawienie hasła_wysłanie nowego hasła', 0);
        this.isLoading = true;
        

        this.userService.changePass(this.model)
            .subscribe(
                data => {
                    this.alertService.success('Zmiana zarejestrowana', true);
                    this.router.navigate(['/logowanie']);
                },
                error => {
                    this.alertService.error("Zmiana nie powiodła się");
                    
                    this.isLoading = false;
                });
    }

    setRole(role: string) {
    }
    isActive(role: string) {
        return 'active-role';
    }

    signUp() {
        AppComponent.SendEventGa('selection', null, 'Ustawienie hasła_Przejście do rejestracji', 0);
        let cameFromSesaRegistration = new Boolean(localStorage.getItem(SesaRegisterComponent.SESA_RECENT_VIEWER));
        console.log('cameFromSesaRegistration: ' + cameFromSesaRegistration);
        if (cameFromSesaRegistration != null && cameFromSesaRegistration == true)
            this.router.navigateByUrl('/se');
        else
            this.router.navigateByUrl('/rejestracja');
    }
    signIn() {
        AppComponent.SendEventGa('selection', null, 'Ustawienie hasła_Przejście do logowania', 0);
        this.router.navigateByUrl('/logowanie');
    }
    showErrorAlert(message: string) {
        this.alertService.error(message);
    }


}
