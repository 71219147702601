<div><h1>Wybierz sklep</h1></div>
<div class="button-wrapper">
    <div class="button-row">
        <form ngNoForm *ngFor="let shop of shops; let i = index"
              action="{{shopParam(i,'url')}}" target="_blank"
              id="{{'form_'+shopParam(i,'name')}}"
              (submit)="logAndSubmit(
                shopParam(i,'name'),
                'form_'+shopParam(i,'name'),
                orderService.orderIdWithPrefix(shopParam(i,'prefix')),
                orderService
              )"
              class="image-button"
              method="{{shopParam(i,'method')}}">
            <input type="hidden" name="request" value="{{orderService.orderWithPrefix(shopParam(i,'prefix'))}}" />
            <button type="submit" class="image-button" value="Kup w sklepie"><img src="/images/ecommerce/{{shopParam(i,'name')}} logo.jpg" /></button>
        </form>
    </div>
</div>

