import { Component, OnInit, OnDestroy, Input } from "@angular/core";


import { UserService } from '../../_services/index';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AuthService } from "../../services/auth.service";
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { UserLogin } from '../../models/user-login.model';
import { Router } from "@angular/router";
import { AppComponent } from "../app.component";

@Component({
    selector: "app-activate",
    templateUrl: './activate.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService, AlertService]
})

export class AccountActivationComponent implements OnInit {
    activated: boolean = false;
    
    ngOnInit(): void {
        AppComponent.SendEventGa('selection', null, 'Rejestracja_aktywacja linkiem', 0);
        const parameters = new URLSearchParams(window.location.search);
        let token = parameters.get('token');
        if (token == null) {
            this.alertService.showMessage(`Aktywacja nie prawidłowa`, null, MessageSeverity.error);
            this.router.navigate(['/logowanie']);
        }else {
            this.model.resetToken = token;
            this.activate();
        }
    }
    model: any = {};

    isLoading = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        private authService: AuthService) {
    }
    message = "Zaczekaj na aktywację konta";
    activate() {
        this.isLoading = true;
        

        this.userService.activate(this.model)
            .subscribe(
                data => {
                    this.alertService.showMessage(`Aktywacja zakończona`, null, MessageSeverity.success);
                    this.message = "Konto zostało pomyślnie aktywowane. Teraz możesz dobierać łączniki i gniazda do swojego domu oraz zapisywać projekty i edytować później. Zaloguj się:"
                    this.activated = true;
                    this.isLoading = false;
                    //this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.showMessage(`Aktywacja nie powiodła się`, null, MessageSeverity.error);
                    this.message = "Aktywacja nipoprawna. Sprawdź link lub skopiuj go z e-maila."
                    
                    this.isLoading = false;
                });
    }
    
    reset() {
        this.formResetToggle = false;

        setTimeout(() => {
            this.formResetToggle = true;
        });
    }
    modalClosedCallback: () => void;
    closeModal() {
        if (this.modalClosedCallback) {
            this.modalClosedCallback();
        }
    }
    userLogin = new UserLogin();
    formResetToggle = true;
    isModal: boolean = false;
    login() {
        AppComponent.SendEventGa('selection', null, 'Logowanie_Wysłanie formularza', 0);
        this.isLoading = true;
        this.alertService.showMessage(`Logowanie...`, null, MessageSeverity.info);
        this.authService.login(this.userLogin.email, this.userLogin.password, this.userLogin.rememberMe)
            .subscribe(
                user => {
                    setTimeout(() => {
                        this.alertService.stopLoadingMessage();
                        this.isLoading = false;
                        this.reset();

                        if (!this.isModal) {
                            this.alertService.showMessage(`Witaj ${user.userName}!`, null, MessageSeverity.success);
                            this.router.navigate(['/']);
                        }
                        else {
                            this.alertService.showMessage(`Witaj ponownie ${user.userName}!`, null, MessageSeverity.success);
                            setTimeout(() => {
                                this.alertService.showStickyMessage("Sesja odzyskana", "Spróbuje ponownie", MessageSeverity.default);
                            }, 500);

                            this.closeModal();
                        }
                    }, 500);
                },
                error => {

                    this.alertService.stopLoadingMessage();

                    if (Utilities.checkNoNetwork(error)) {
                        this.alertService.showMessage(`Problem techniczny, spróbuj ponownie za chwilę`, null, MessageSeverity.error);
                        
                    }
                    else {
                        let errorMessage = Utilities.findHttpResponseMessage("error_description", error);

                        if (errorMessage)
                            this.alertService.showStickyMessage("Logowanie nieudane", errorMessage, MessageSeverity.error, error);
                        else
                            this.alertService.showStickyMessage("Logowanie nieudane", "Wystąpił błąd logowania spróbuj ponownie za chwilę", MessageSeverity.error, error);
                    }

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                });
    }
    signIn() {
        this.router.navigateByUrl('/logowanie');
    }

    signUp() {

        this.router.navigateByUrl('/rejestracja');
    }
    showErrorAlert(message: string) {
        this.alertService.showMessage(message, null, MessageSeverity.error);
    }
}
