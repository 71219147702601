<h3 mat-dialog-title>Zmień serię</h3>
<div mat-dialog-content>

    <div class="button-row" *ngFor="let s of data.alternateSubSeries">
        <button mat-raised-button
                mat-button [mat-dialog-close]="s" cdkFocusInitial>
            {{s.title}}
        </button>
    </div>

</div>
