
<h1 mat-dialog-title>{{data.messageTitle}}</h1>
<div mat-dialog-content>
    <h2 mat-dialog-title>{{data.messageBody}}</h2>
</div>
<div mat-dialog-actions>

    <button *ngIf="data.confirmCallback != null" mat-button (click)="onNoClick()" class="cancel-button">Anuluj</button>

    <button *ngIf="data.confirmCallback != null" mat-button (click)="onAck()" class="accept-button">Dalej</button>
    <button *ngIf="data.confirmCallback == null" mat-button (click)="onAck()" class="b2c-next-step-button">Dalej</button>
</div>
