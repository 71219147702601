import { Component, ViewEncapsulation, HostListener } from "@angular/core";
import { LocalStoreManager } from "../../services/local-store-manager.service";
import { Router } from "@angular/router";
import { SesaRegisterComponent } from "../login/sesa-register.component";
import { InformationDialogComponent } from "../controls/information-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TutorialDialogComponent } from "./tutorial.component";

@Component({
  selector: "app-home-main",
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeMainComponent {

    
    constructor(private localStorageM: LocalStoreManager, private router: Router, public dialog: MatDialog) {
        //const parameters = new URLSearchParams(window.location.search);
        this.localStorageM.savePermanentData(this.urlParam('code'), "eCommerceId");
        localStorage.removeItem(SesaRegisterComponent.SESA_RECENT_VIEWER);
    }

    urlParam(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        else {
            return decodeURI(results[1]) || 0;
        }
    }
    tileRows = [[null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, 'b3', null, null, 'b4', null]];

    subTitles = [' ',
        'Dobierz produkty odpowiednie do Twoich pomieszczeń w domu oraz design, który najbardziej pasuje do aranżacji Twojego wnętrza!',
        'Skonfiguruj listę produktów na podstawie funkcjonalności niezbędnych w projekcie domu. Dla profesjonalistów.'];

    titles = ['Dobierz gniazdka i włączniki światła ze Schneider Electric',
    'Wybierz design','Szybka konfiguracja'];
    get subtitle(): string {
        return this.subTitles[this.selection];
    }
    get title(): string {
        return this.titles[this.selection];
    }
    selection: number = 0;

    tailclass(i: number, j: number):string {
        if (i == 2 && this.position1 <= j && j <= this.position2)
            return 'rotating';
        else return 'tailimg'
    }
    objectClass(i: number, j: number):string{
        if (i == 2 && this.position1 <= j && j <= this.position2)
            return 'menu-button-' + i + '-' + j;
        else return null;
    }
    position1: number = 1;
    position2: number = 2;
    //position3: number = 3;
    buttonLabel(i, j,k) {
        if (j == this.position1)
            return k==0? 'Wybierz':'design';
        if (j == this.position2)
            return k == 0 ? 'Szybka' : 'konfiguracja';
        //if (j == this.position3)
        //    return k == 0 ? 'Odace' : '';
    }
    switch(j: number) {
        if (j == this.position1)
            this.selection = 1;
        if (j == this.position2)
            this.selection = 2;
        //if (j == this.position3)
        //    this.selection = 3;
    }
    reset() {
        this.selection = 0;
    }
    open(j: number) {
        console.log("opening submodule number: " + j);
        if (j == this.position1)
            this.router.navigateByUrl("/wybierz-design");
        if (j == this.position2)
            this.router.navigateByUrl("/szybka-konfiguracja");
        //if (j == this.position3)
        //    window.open("http://dobierz-gniazdko.pl/odace", '_self');
    }

    rowForVideolink(i: number) {
        return i == 3;
    }
    showVideo() {
        const dialogRef = this.dialog.open(TutorialDialogComponent, {
            width: 'auto'
        });
    }
}
