import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sku, SubSeries } from '../../../_models';
import { AppComponent } from '../../app.component';

export interface ChangeSeriesDialogData {
    sku: Sku;
    alternateSubSeries: SubSeries[];
}

@Component({
    selector: 'change-series-dialog',
    templateUrl: './change-series-dialog.component.html',
    styleUrls: ['./change-series-dialog.component.css']
})
export class ChangeSeriesDialog {

    constructor(
        public dialogRef: MatDialogRef<ChangeSeriesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ChangeSeriesDialogData) {
        data.alternateSubSeries.sort((a, b) => ((a.title < b.title) ? -1 : 1));

    }

    onNoClick(): void {
        AppComponent.SendEventGa('series', 'cancel', 'B2B_zmiana grupowa serii zamknięcie dialogu', 0);
        this.dialogRef.close();

    }


}
