import { Component, Injectable } from '@angular/core';
import { Sku, Series, CategoryGroup, CategoryGroupFormated, FunctionsTypeImpl, OrderSet, Room, OrderSetItem } from '../_models/catalog';



@Injectable()
export class PresentationToolsComponent {
    
    static numberOfFrames(os: OrderSet): number {
        let collectedFrameSlotsNumber = 0
        for (let p of os.orderSetItems) {
            if (p != null && p.sku != null) {
                if (p.sku.slots == 0) {
                    if (p.sku.withFrame) {
                        collectedFrameSlotsNumber += p.qty;
                    }
                } else {
                    collectedFrameSlotsNumber += p.qty * p.sku.slots;
                }
            }
        }
        return collectedFrameSlotsNumber;
    }
    static numberOfFramesInRoom(r: Room): number {
        let sum = 0;
        if (r == null)
            return 0;
        for (let os of r.orderSets) {
            sum += PresentationToolsComponent.numberOfFrames(os);
        }
        return sum;
    }
    static numberOfMechanisms(os: OrderSet): number {
        let collectedMechanismsNumber = 0
        for (let p of os.orderSetItems) {
            if (p != null && p.sku != null && p.sku.slots == 0) {
                collectedMechanismsNumber += p.qty;
            }
        }
        return collectedMechanismsNumber;
    }
    static numberOfMechanismsInRoom(r:Room):number {
        let sum = 0;
        if (r != null)
        for (let os of r.orderSets) {
            
            sum += PresentationToolsComponent.numberOfMechanisms(os);
        }
        return sum;
    }
    static uniqueFunctions(skus: Sku[]): Sku[] {
        let uniFuns = new Array<Sku>();
        for (let s of skus) {
            if (uniFuns.findIndex(sku => sku.functionType.title == s.functionType.title) < 0) {
                uniFuns.push(s);
            }
        }
        return uniFuns;
    }

    static UniqueColors(skus: Sku[]): Sku[] {
        var uniqueColorSkus = new Array<Sku>();
        skus.forEach(function (sku) {
            console.log(sku.color.title);
            if (sku.color.id != -1 && uniqueColorSkus.findIndex(s => s.color.title == sku.color.title) < 0) uniqueColorSkus.push(sku)
        });
        return uniqueColorSkus;
    }
    static categorisedCollections(functions: Sku[], categoryGroups: CategoryGroup[], nRows, grouping:boolean = true): CategoryGroupFormated[] {
        console.log('PresentationToolsComponent.categorisedFunctions: functions.length ' + functions.length);
        var returnMap = new Array<CategoryGroupFormated>();
        var cgfMap = new Array<CategoryGroupFormated>();
        if (categoryGroups == null) {
            let cgf: CategoryGroupFormated = 
                new CategoryGroupFormated(
                    {id:-1,title:'Polecane',order:-1,categories:null},
                    new Array<FunctionsTypeImpl>());
            
            for (let f of functions) {
                console.log('PresentationToolsComponent.categorisedFunctions: add function ' + f.functionType.title);
                let funT = new FunctionsTypeImpl(f.functionType.id, f.functionType.title);
                funT.repSku = f;
                cgf.functionTypes.push(funT);
            }
            cgfMap.push(cgf);
            return cgfMap;
        }
        for (let f of functions) {
            let cgf: CategoryGroupFormated;
            if (cgfMap.length>0)
                cgf = cgfMap.find(cgf => (cgf.cg!=null && cgf.cg.id == f.categoryGroupID) );
            let cg = categoryGroups.find(cg => cg.id == f.categoryGroupID);
            if (cg != null) {
                if (cgf == null //there is no CGF
                    //&& categoryGroups.findIndex(cg => cg.id == f.categoryGroupID) > 0
                ) {//there is CategoryGroup in database - just for inconsistency
                    //console.log("categoryGroups:" + categoryGroups.length + " f.categoryGroupID:" + f.categoryGroupID + "functiontype:+" + f.functionType.title);
                    cgf =
                        new CategoryGroupFormated(
                            cg,
                            new Array<FunctionsTypeImpl>());
                    cgfMap.push(cgf);
                }
                //check if categogory for function exists
                let funT = cgf.functionTypes.find(ft => ft.title == f.functionType.title);
                if (funT == null) {
                    //console.log("categoryGroup:" + f.category.title + f.functionType.title);
                    funT = new FunctionsTypeImpl(f.functionType.id, f.functionType.title);
                    cgf.functionTypes.push(funT);
                }
                funT.avaiableSkus.push(f);
                if (funT.repSku == null) {
                    funT.repSku = f;
                }
            }

        }
        for (let cgid of [1, 2, 3, 4, 5, 6, 1002]) {
            var cg = categoryGroups.find(cg => cg.id == cgid);
            //this is just for ordering hardcoded in this place
            if (cg != null) {
                let curcfg = cgfMap.find(cgf => cgf.cg.id == cg.id);
                if (curcfg != null) {
                    curcfg.categoriesFormated(nRows);
                    returnMap.push(cgfMap.find(cgf => cgf.cg.id == cg.id));
                }
            }
        }
        return returnMap;
    }
    /*
    static categorisedFunctions(functions: Sku[], categoryGroups: CategoryGroup[], nRows): CategoryGroupFormated[] {
        //console.log('PresentationToolsComponent.categorisedFunctions: functions.length ' + functions.length);
        var returnMap = new Array<CategoryGroupFormated>();
        for (let cgid of [1, 2, 3, 4, 5, 6, 1002]) {
            var cg = categoryGroups.find(cg => cg.id == cgid);
            //console.log('PresentationToolsComponent.presentationCategoryGroups:GroupId:' + cgid);
            if (cg != null) {
                //console.log('PresentationToolsComponent.categorisedFunctions: functionsFiltered ' + functions.filter(f => f.categoryGroupID == cgid).length);

                returnMap.push(
                    new CategoryGroupFormated(cg, this.formatPresentationLists(functions.filter(f => f.categoryGroupID == cgid),nRows))
                )
            }
        }

        
        return returnMap;
    }*/
    static filterFunctions(functions: Sku[], waterProof: Boolean, selectedSeriesItem: Series,
        colorId: number, framesubseries: string): Sku[] {
        var filteredFunctions;
        console.log("Startfiltering:" + functions.length + ":" + waterProof + ":" + selectedSeriesItem + ":" +
            colorId);
        if (waterProof != null && waterProof)
            filteredFunctions = functions.filter(f => f.indexOfProtection.title == 'IP44');
        else if (waterProof != null)
            filteredFunctions = functions.filter(f => f.indexOfProtection.title == 'IP20');
        else
            filteredFunctions = functions;
        console.log("WaterProofFiltered:" + filteredFunctions.length);
        if (selectedSeriesItem != null) {
            filteredFunctions = filteredFunctions.filter(f => f.series.id == selectedSeriesItem.id);
        }
        console.log("SeriesFiltered:" + filteredFunctions.length + "color:" + colorId);
        if (colorId != null ) {
            console.log("SeriesFiltered:" + filteredFunctions.length + "color:" + colorId);
            filteredFunctions = filteredFunctions.filter(f => (f.color != null && f.color.id
                == colorId));
        }
        if (framesubseries != null) {
            filteredFunctions = filteredFunctions.filter(f =>
                !((framesubseries == 'Sedna Design' && f.functionType.title.indexOf('Elements') >= 0) ||
                    (framesubseries == 'Sedna Elements' && f.functionType.title.indexOf('Design') >= 0))
            );
        }
        console.log("ColorFiltered:" + filteredFunctions.length);
        return filteredFunctions;
    }
    /** PresentationTools ctor */
    constructor() {

    }

    public static formatPresentationLists(list: any[], columnNumber = 6): any[][]{
        var combinedList = [];
        var rowList = [];
        var counter = 0;
        var columns = columnNumber;
        for (var sku in list) {
            //console.log("sku: " + list[sku]);
            rowList.push(list[sku]);
            counter++;
            if (!(counter % columns)) {
                combinedList.push(rowList);
                rowList = [];
            }
        }
        //fill empty cells in last rowList with nulls to have equal number of cells in row
        
        if (rowList != null && rowList.length > 0) {
            var lack = columns - rowList.length;
            /*for (var i = 0; i < lack; i++) {
                rowList.push(null);
            }*/
            combinedList.push(rowList);
        }
        return combinedList;
    }

    public static formatFramePresentationLists(list: Sku[], columnNumber = 6): Sku[][] {
        console.log(list.length);
        var hSkus = list.filter(s => !s.functionType.title.includes('pionowa'));
        var vSkus = list.filter(s => s.functionType.title.includes('pionowa'));
        if (hSkus.length == 0) {
            list = vSkus;
        } else if (vSkus.length == 0) {
            list = hSkus;
        }else {
            var run = true;
            var i = 0;
            var newList = new Array<Sku>();
            //add half from h and half from v skus
            while (i < hSkus.length || i < vSkus.length) {
                var j = 0;
                while (j < columnNumber / 2) {
                    if (i + j < hSkus.length)
                        newList.push(hSkus[i + j]);
                    else
                        newList.push(null);
                    j = j + 1;
                }
                j = 0;
                while (j < columnNumber / 2) {
                    if (i + j < vSkus.length)
                        newList.push(vSkus[i + j]);
                    else
                        newList.push(null);
                    j = j + 1;
                }
                i = i + columnNumber / 2;
            }
            list = newList;
        }
        var combinedList = [];
        var rowList = [];
        var counter = 0;
        var columns = columnNumber;
        for (var sku in list) {
            rowList.push(list[sku]);
            counter++;
            if (!(counter % columns)) {
                combinedList.push(rowList);
                rowList = [];
            }
        }

        //fill empty cells in last rowList with nulls to have equal number of cells in row
        if (rowList != null && rowList.length > 0) {
            var lack = columns - rowList.length;
            for (var i = 0; i < lack; i++) {
                rowList.push(null);
            }
            combinedList.push(rowList);
        }
        return combinedList;
    }

    public static rotationStyle(frameOrderItem: OrderSetItem, osi: OrderSetItem, mech: Sku): string {
        console.log('rotationStyle mech.sku: '+ mech.code)
        if (((frameOrderItem.sku != null && frameOrderItem.sku.isVertical) || osi.isVertical) && ['EPH9810221', 'EPH9810223', 'EPH9810261', 'EPH9810262', 'EPH9810269', 'EPH9810271',
            'SDN2800921', 'SDN2800923', 'SDN2800960', 'SDN2800968', 'SDN2800970', 'SDD111275',
            'SDD112275', 'SDD113275', 'SDD114275', 'SDD111271', 'SDD113271', 'SDD114271'].findIndex(s => s == mech.code) >= 0)
            return 'rotate90';
        return 'rotate0';
    }

    public static rotationFrameStyle(frame: Sku, vertical: boolean): string {
        console.log('rotationFrameStyle:' + frame.code + ' vertical: ' + vertical)
        if (frame!=null && frame.slots>1 && !frame.isVertical && vertical)
            return 'rotate90';
        return 'rotate0';
    }


    public static layers(allCodes: string) {
        if (allCodes == null)
            return [];

        let layers = allCodes.split("+");

        for (var i = 0; i < layers.length; i++) {
            layers[i] = layers[i].trim();
        }
        return layers;
    }
}
