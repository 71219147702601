import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sku, SubSeries } from '../../../_models';
import { AppComponent } from '../../app.component';

export interface ChangeSeriesGlobalData {
    newSeries: SubSeries;
    changedSkus: ChangeSeriesOptions[];
    unmappedSkus: Sku[];
    functionsAll: Sku[];
}

export interface ChangeColorGlobalData {
    newSeries: SubSeries;
    changedSkus: Sku[][];
    unchangedSkus: Sku[];
    unmappedSkus: Sku[];
    functionsAll: Sku[];
}


export interface ChangeSeriesOptions {
    mainSku: Sku;
    alternateSkus: Sku[];
    bestMatchingSku: Sku;
    newBestMatchingSku: number;

}

@Component({
    selector: 'change-series-global-dialog',
    templateUrl: './change-series-global-dialog.component.html',
    styleUrls: ['./change-series-global-dialog.component.css']
})
export class ChangeSeriesGlobalDialog {
    public changes: ChangeSeriesGlobalData;
    constructor(
        public dialogRef: MatDialogRef<ChangeSeriesGlobalDialog>,
        @Inject(MAT_DIALOG_DATA) public ichanges: ChangeSeriesGlobalData) {
        this.changes = ichanges;
    }
    get getSeriesTitle(): string {
        return this.changes.newSeries.title;
    }
    onNoClick(): void {
        AppComponent.SendEventGa('series', 'cancel', 'B2B_zmiana grupowa serii zamknięcie dialogu', 0);
        this.dialogRef.close();

    }
    saveBestMatchingValue(i: number) {
        if (this.changes.changedSkus[i].bestMatchingSku != null)
            return this.changes.changedSkus[i].bestMatchingSku.id;
        return null;
    }
    saveBestMatchingColor(i: number) {
        if (this.changes.changedSkus[i].bestMatchingSku != null)
            return this.changes.changedSkus[i].bestMatchingSku.color.title;
        return null;
    }

    
    get allChangesFinal(): boolean {
        var allSet: boolean = true;
        this.changes.changedSkus.forEach(s => {
            if (s.bestMatchingSku == null)
                allSet = false;
        });
        return allSet;
    }
    changeColorOfUnit(i: number) {
        console.info("this.changes.changedSkus[" + i + "].newBestMatchingSku:" + this.changes.changedSkus[i].newBestMatchingSku);
        this.changes.changedSkus[i].bestMatchingSku = this.changes.changedSkus[i].alternateSkus[this.changes.changedSkus[i].newBestMatchingSku];
    }
    alternateSeries(sku: Sku) {

        let sColorTitle: string = null;
        let colorEquivalents = ['biały active,biały,biały polarny,biały/kremowy', 'kremowy,beżowy,piaskowy', 'aluminium,srebrny aluminium,stal,grafit / alu',
            'brąz,satyna', 'antracyt,grafit,antracytowy', 'biały polarny,biały,biały/kremowy',
            ',Brylantowy,,,szkło białe,szklana biel',
            ',Diamentowy,,,,',
            ',,,,szkło przydymione,',
            ',Onyksowy,,,szkło czarne,',
            ',Mahoniowy,,,,',
            ',Szmaragdowy,,,,',
            'stal szlachetna,,,,szczotkowane aluminium,inox',
            ',,Srebrny platynowy,,,',
            ',,Tytanowy ,,,',
            ',,,,,bronze',
            ',,Szary metaliczny,,,',
            ',,Chrom ,,,',
            'stal szlachetna,,,,szczotkowane aluminium,biały inox',
            ',,,,szczotkowane złoto,',
            ',,,,brzoza,nordic wood',
            ',,,Buk,,',
            ',,,Wiśnia,,',
            ',,,Orzech,,',
            'wenge,,,Wenge,wenge,wenge',
            'dąb,,,,,',
            'łupek,,,,,kamień ardoise',
            ',,,,,kamień galaxy',
            ',,,,beton,',
            'przezroczysta (biała),przezroczysta'
        ];
        let a = colorEquivalents.findIndex(ce => ce.includes(sku.color.title));
        if (a >= 0)
            sColorTitle = colorEquivalents[a];

        else if (sku.color.title == 'antracyt')
            sColorTitle = sku.color.title + '|grafit';
        else if (sku.color.title == 'grafit')
            sColorTitle = sku.color.title + '|antracyt';
        else if (sku.color.title == 'brąz')
            sColorTitle = sku.color.title + '|satyna';
        else if (sku.color.title == 'satyna')
            sColorTitle = sku.color.title + '|brąz'
        else if (sku.color.title == 'kremowy')
            sColorTitle = sku.color.title + '|piaskowy';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';
        else if (sku.color.title == 'biały active')
            sColorTitle = sku.color.title + '|biały active|biały';
        else if (sku.color.title == 'piaskowy')
            sColorTitle = sku.color.title + '|kremowy';


        else
            sColorTitle = sku.color.title;
        let alternates = this.changes.functionsAll;
        console.log("alternateSeries change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.functionType.id == sku.functionType.id);
        console.log("alternateSeries:function change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.category.id == sku.category.id);
        console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.series.id == sku.category.id);
        console.log("alternateSeries:category change series for: " + sku.code + " - " + alternates.length);

        //alternates = alternates.filter(f => sColorTitle.includes(f.color.title));
        //console.log("alternateSeries:color change series for: " + sku.code + " - " + alternates.length);

        alternates = alternates.filter(f => f.indexOfProtection.id == sku.indexOfProtection.id);
        console.log("alternateSeries:IP change series for: " + sku.code + " - " + alternates.length);


        for (let p of sku.params) {
            alternates = alternates.filter(
                f => f.params != null &&
                    f.params.find(pp => pp.id == p.id) != null);
        }
        //alternates[0].subSeries

        let uniqueAlternates = new Array<Sku>();
        alternates.forEach(sku => {
            if (uniqueAlternates.findIndex(s => s.subSeries.id == sku.subSeries.id) < 0)
                uniqueAlternates.push(sku);
        });
        return uniqueAlternates;

    }

}
