import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, Directive, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from "../../services/account.service";
import { AlertService } from '../../services/alert.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { CompletionService } from '../../services/completion.service';
import { OrderService } from '../../services/orders.service';
import { Category, Room } from '../../_models/catalog';
import { RoomConfigDialogComponent } from './controls/room-config-dialog.component';
import { AppComponent } from '../app.component';






@Directive({ selector: 'roomButton' })
export class RoomButton {
    id: string;
}

@Component({
    selector: 'rooms-selection',
    templateUrl: './rooms-selection.component.html',
    styleUrls: ['./rooms-selection.component.css'],
})
export class RoomSelectionComponent implements OnInit, OnDestroy,AfterContentInit {
    
    ngAfterContentInit(): void {
        $('#roomsButton').addClass('active');
    }
    selectedRoom: string;
    backgroundColor: string = 'white';
    
    public categories: Category[];
    _orderService: OrderService;
    constructor(private alertService: AlertService, private translationService: AppTranslationService,
        http: HttpClient, @Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private accountService: AccountService,
        public configurations: CompletionService, public orderService: OrderService, public dialog: MatDialog,
        private router: Router) {
        this._orderService = orderService;
        this.roomSelectionDialog = dialog;

        

    }
 

    ngOnInit() {
        if (this.orderService.cProject == null)
            this.router.navigateByUrl('/wybierz-design');
        else if (this.orderService.cProject.rooms == null || this.orderService.cProject.rooms.length == 0) {
            this.openRoomConfigDialog(null);
        }
    }
    

    ngOnDestroy() {
    }

    @Output() roomSelectionEvent = new EventEmitter<string>();
    changeRoom(index: number) {
        AppComponent.SendEventGa('operation', null, 'B2C_rozpoczęcie edycji pomieszczenia');
        let j = this.orderService.cProject.rooms.length - index - 1;
        j = index;
        this.openRoomConfigDialog(index);
    }
    
    selectRoom(index: number) {
        AppComponent.SendEventGa('operation', null, 'B2C_otwarcie pomieszczenia');
        let j = this.orderService.cProject.rooms.length - index - 1;
        j = index;
        this.orderService.openRoom(j);

        this.router.navigateByUrl('/wybierz-design#serie');
    }

    roomSelectionDialog: any;

    newRoomConfig(): void {
        AppComponent.SendEventGa('operation', null, 'B2C_utworzenie nowego pomieszczenia');
        this.openRoomConfigDialog(null);
    }

    openRoomConfigDialog(index: number): void {
        let selectedRoom = this.orderService.cProject.rooms[index];
        
        var inputRoom = (selectedRoom != null) ? selectedRoom : new Room(null, 'white', null);
        const dialogRef = this.roomSelectionDialog.open(RoomConfigDialogComponent, {
            width: '900px', maxWidth:'97%', overflowY:'scroll',
            data: { roomId: inputRoom.id, room: inputRoom }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (selectedRoom == null && result != null) {
                this.orderService.addRoom(result);
            } else if (result != null) {
                this.orderService.updateRoom(result,index);
            }
            if (selectedRoom == null && result != null)
                this.router.navigateByUrl('/wybierz-design#serie');
        });
    }
    deleteRoom(i: number) {
        this.orderService.delRoom(i);
    }
    
    selectColour(color: string) {
        localStorage.setItem('backgound-color', color);
        this.backgroundColor = color;
        this.myObj = { "background-color": color };
        console.log('this.backgroundColor' + this.backgroundColor);
        console.log('this.myObj' + this.myObj);
        
    }
    
    myObj = { "background-color": "coral" };
    duplicateRoom(i: number) {
        this.orderService.duplicateRoom(i);
    }
}
