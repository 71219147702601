import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { B2CConfigComponent } from './config.component';


const routes: Routes = [
    {
        path: '', data: { title: "Schneider Electric Wybierz Design" },
        component: B2CConfigComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class B2CRoutingModule { }
