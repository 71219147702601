<h3 mat-dialog-title>Nowy projekt</h3>
<form class="new-project-form">
    <div mat-dialog-content class="zero-margin">


        <div class="row" style="display:block;margin: 0 0 20px 0;">
            <input autocomplete="off" autocorrect="off" name="project-name" placeholder="Nazwa projektu" [(ngModel)]="project.title" cdkFocusInitial />

        </div>

        <div class="row" style="display:block;margin: 0 0 20px 0;">
            <input autocomplete="off" autocorrect="off" name="room-name" placeholder="Nazwa zestawu" [(ngModel)]="project.rooms[0].title" />

        </div>


    </div>
    <div *ngIf="!importFile" style="display: flex;margin-bottom: 15px;">
        <button type="button" mat-stroked-button style="margin-bottom: 10px;background-color:#3DCD58;color:white;border-color:white;min-width: 83pxpx;"
                class="warn" (click)="clickFileUpload()">
            Importuj xls
        </button>

        <div style="margin: 10px 10px 10px 10px;"><a href="/documents/import template.xlsx" style="line-height:17px; font-size:14px;color:#009530">Pobierz formatkę</a></div>

    </div>
    <div *ngIf="importedFileName != null" style="line-height:17px; font-size:14px;color:#009530; text-align:center">
        Zaimportowano: {{importedFileName}}
    </div>
    <div *ngIf="projectNameInValid" style="line-height:17px; font-size:14px;color:#009530;text-align:center;color:red">
        Wprowadź nazwę projektu.
    </div>
    <div *ngIf="!projectNameInValid && namesInValid" style="line-height:17px; font-size:14px;color:#009530;text-align:center;color:red">
        Wprowadź nazwę zestawu.
    </div>
    <input id="inputfile" type="file" autocomplete="off" (change)="handleFile($event)"
           hidden />
    <div mat-dialog-actions>
        <button mat-stroked-button type="button" style="min-width: 83px;"
                class="cancel-button" (click)="onNoClick()">
            Anuluj
        </button>
        <button style="min-width: 83px;" class="accept-button"
                [disabled]="namesInValid" mat-stroked-button (click)="onSubmitClick()"
                type="submit">
            Utwórz
        </button>
    </div>
</form>
