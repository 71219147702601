<div class="insp-panel-wrapper">
    <div class="insp-main-panel">
        <div class="insp-side-menu">
            <div class="insp-smenu-option {{optionStyle(i)}}" *ngFor="let o of sideMenuOptions; let i = index"
                 (click)="selectOption(i)">
                <div class="insp-smenu-option-img">
                    <img src="images/icons/insp-{{o.Image}}-{{optionIconColor(i)}}.png" />
                </div>
                <div class="insp-smenu-option-label">
                    {{o.Label}}
                </div>
            </div>
        </div>
        <div class="insp-main-column">
            <div class="insp-main-bigpicture">
                <img src="/images/inspirations/{{sideMenuOptions[sOpt].Image}}/big-{{sideMenuOptions[sOpt].Variants[sVariant].Image}}.jpg" />
                <div *ngFor="let w of sideMenuOptions[sOpt].Variants[sVariant].Walls" class="tag" [style]="secureStyle(w.Position)" (click)="selectedColor = w;colorDialogHidden = false"></div>

                <div class="fulloverlay" (click)="colorDialogHidden = true" *ngIf="!colorDialogHidden">
                    
                </div>
                <div class="color-dialog" [style]="secureStyle(selectedColor.Position)" *ngIf="!colorDialogHidden">
                    <a href="{{selectedColor.Url}}" target="_blank">
                        <div class="color-image">
                            <img src="/images/icons/{{selectedColor.Color}}.png" />
                        </div>
                        <div class="color-label">{{selectedColor.Label}}</div>
                    </a>
                </div>

            </div>


            <div class="insp-main-sublist">
                <div class="insp-main-list-option " *ngFor="let v of sideMenuOptions[sOpt].Variants;let i = index"
                     (click)="selectVariant(i)">
                    <img class="{{variantStyle(i)}}" src="images/inspirations/{{sideMenuOptions[sOpt].Image}}/small-{{v.Image}}.jpg" />
                    <span> {{sideMenuOptions[sOpt].Variants[sVariant].ProductSetHeader}}</span>
                </div>
            </div>
        </div>
        <div class="insp-products-column">
            <div class="insp-product-main">
                <div class="insp-products-variant-header">
                    <span style="font-weight:700;margin-bottom: 10px;">{{sideMenuOptions[sOpt].Variants[sVariant].ProductSetHeader}}</span>
                    <div class="insp-product-sect-header"  style="text-align: left;font-size: 14px;" >Polecane produkty</div>
                </div>
                <div class="swiper-container" [swiper]="config" [(index)]="index">
                    <swiper class="swiper-container" fxFlex="auto" [config]="config"
                            (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
                            (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
                        <div class="swiper-slide" *ngFor="let p of sideMenuOptions[sOpt].Variants[sVariant].Products">

                            <div class="insp-product-main-img">
                                <img src="/images/inspirations/{{sideMenuOptions[sOpt].Image}}/prod-{{p.Image}}.png" />
                            </div>
                            <div class="insp-product-main-title">{{p.Title}}</div>
                            <div *ngIf="false" class="insp-product-main-subtitle">{{p.Subtitle}}</div>
                            <div class="insp-product-main-desc">{{p.Desc}}</div>
                            <div class="insp-product-main-row" (click)="addToCart(p)"><div class="insp-product-main-button">Dodaj do listy</div></div>
                        </div>
                    </swiper>

                    <!--
    <div class="swiper-button-prev" [hidden]="swiper.isAtFirst" style="top: 25%"></div>
    <div class="swiper-button-next" [hidden]="swiper.isAtLast" (click)="swiper.index = swiper.index + 1"></div>
        -->
                </div>
            </div>
            <div class="insp-product-cat">
                <div class="insp-product-cat-header" *ngIf="false">
                    {{sideMenuOptions[sOpt].Variants[sVariant].ProductSetHeader}}
                </div>
                <div class="insp-product-sect-header" style="line-height: 50px;">
                    <span>Kolory ścian </span>
                    <a href="http://tikkurila.pl" target="_blank"><img src="/images/icons/tikkurila.png" /></a>
                </div>
                <div class="insp-product-cat-list">
                    <div class="insp-product-cat-img" *ngFor="let w of sideMenuOptions[sOpt].Variants[sVariant].Walls;let i = index">
                        <div class="image">
                            <a href="{{w.Url}}" target="_blank"><img src="/images/icons/{{w.Color}}.png" /></a>
                        </div>
                        <div class="label">
                            <a href="{{w.Url}}" target="_blank">{{w.Label}}</a>
                        </div>
                    </div>
                </div>
                <div class="insp-product-main-row" *ngIf="false">
                    <a href="/wybierz-design">
                        <div class="insp-product-cat-button">
                            WSZYSTKIE
                        </div>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>

