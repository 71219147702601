import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChildren, AfterViewInit, QueryList, ElementRef, HostListener } from "@angular/core";
import { Router, NavigationStart } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from '../services/alert.service';
import { NotificationService } from "../services/notification.service";
import { AppTranslationService } from '../services/app-translation.service';
import { AccountService } from '../services/account.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AppTitleService } from '../services/app-title.service';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { Permission } from '../models/permission.model';
import { LoginComponent } from "../components/login/login.component";
import { OrderService } from "../services/orders.service";
import { MatDialog } from "@angular/material/dialog";
import { GoogleAnalyticsService } from "angular-ga";
import { AccountRemovecomponent } from "./controls/account-remove.component";
import { DialogData } from "./controls/information-dialog.component";
import { CommonModule } from "@angular/common";

var alertify: any = require('../assets/scripts/alertify.js');


@Component({
  selector: "app-root",
    templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [OrderService]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy{

    
    @HostListener('window:beforeunload', ['$event'])
    @HostListener('window:pagehide', ['$event'])
    doSomething($event) {
        if (!this.authService.isLoggedIn && this.orderService.changedAfterOrder)
            $event.returnValue = 'Your data will be lost!';
    }
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
    appTitle = "Dobierz gniazdka i łączniki elektryczne do swojego domu!";

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;

    @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;

  loginModal: ModalDirective;
  loginControl: LoginComponent;


  get notificationsTitle() {

    let gT = (key: string) => this.translationService.getTranslation(key);

    if (this.newNotificationCount)
      return `${gT("app.Notifications")} (${this.newNotificationCount} ${gT("app.New")})`;
    else
      return gT("app.Notifications");
  }


  constructor(storageManager: LocalStoreManager, private toastyService: ToastyService, private toastyConfig: ToastyConfig,
    private accountService: AccountService, private alertService: AlertService, private notificationService: NotificationService, private appTitleService: AppTitleService,
      private authService: AuthService, private translationService: AppTranslationService, public configurations: ConfigurationService, public router: Router
      , public dialog: MatDialog, private gaService: GoogleAnalyticsService, private orderService: OrderService) {

    storageManager.initialiseStorageSyncListener();

    translationService.addLanguages(["en"]);
    translationService.setDefaultLanguage('en');


    this.toastyConfig.theme = 'bootstrap';
    this.toastyConfig.position = 'top-right';
    this.toastyConfig.limit = 1;
      this.toastyConfig.showClose = true;

    this.appTitleService.appName = this.appTitle;
  }


  ngAfterViewInit() {

    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () => this.loginModal.hide();
          }
          else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }


  onLoginModalShown() {
    this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again", MessageSeverity.info);
  }


  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired)
      this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again to renew your session", MessageSeverity.warn);
  }


  onLoginModalHide() {
    this.alertService.resetStickyMessage();
    }
    

  ngOnInit() {
      this.isUserLoggedIn = this.authService.isLoggedIn;
      /*
      if (!this.isUserLoggedIn) {
          window.onbeforeunload = 
          function () {
              return "You have attempted to leave this page.  If you have made any changes to the fields without clicking the Save button, your changes will be lost.  Are you sure you want to exit this page?";
          }
      }
      */

    // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
    setTimeout(() => this.isAppLoaded = true, 50);
    setTimeout(() => this.removePrebootScreen = true, 100);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();

        //if (!this.authService.isSessionExpired)
        this.alertService.showMessage("Login", `Welcome back ${this.userName}!`, MessageSeverity.default);
        //else
        //    this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again", MessageSeverity.warn);
      }
    }, 200);

      console.log("subscribe to alerts");
    //this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));

    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;


      if (this.isUserLoggedIn) {
        //this.initNotificationsLoading();
      }
      else {
        this.unsubscribeNotifications();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage("Session Ended!", "", MessageSeverity.default);
        }
      }, 500);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url = (<NavigationStart>event).url;

        if (url !== url.toLowerCase()) {
          this.router.navigateByUrl((<NavigationStart>event).url.toLowerCase());
        }
      }
    });
  }


  ngOnDestroy() {
    this.unsubscribeNotifications();
  }


  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription)
      this.notificationsLoadingSubscription.unsubscribe();
  }



  initNotificationsLoading() {
      
    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe(notifications => {
        this.dataLoadingConsecutiveFailurs = 0;
        this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      },
      error => {
        this.alertService.logError(error);

        if (this.dataLoadingConsecutiveFailurs++ < 20)
          setTimeout(() => this.initNotificationsLoading(), 5000);
        else
          this.alertService.showStickyMessage("Load Error", "Loading new notifications from the server failed!", MessageSeverity.error);
      });
      
  }


  markNotificationsAsRead() {

    let recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true)
        .subscribe(response => {
          for (let n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter(n => !n.isRead).length;
        },
        error => {
          this.alertService.logError(error);
          this.alertService.showMessage("Notification Error", "Marking read notifications failed", MessageSeverity.error);

        });
    }
  }


    /*
  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || "Cancel"
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          }, dialog.defaultValue);

        break;
    }
  }
  */




  showToast(message: AlertMessage, isSticky: boolean) {

    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.toastyService.clear(id);
      }

      return;
    }

    let toastOptions: ToastOptions = {
      title: message.summary,
        msg: message.detail,
        showClose: true,
      timeout: isSticky ? 0 : 4000
    };


    if (isSticky) {
      toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);

      toastOptions.onRemove = (toast: ToastData) => {
        let index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    }


    switch (message.severity) {
      case MessageSeverity.default: this.toastyService.default(toastOptions); break
      case MessageSeverity.info: this.toastyService.info(toastOptions); break;
      case MessageSeverity.success: this.toastyService.success(toastOptions); break;
      case MessageSeverity.error: this.toastyService.error(toastOptions); break
      case MessageSeverity.warn: this.toastyService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastyService.wait(toastOptions); break;
    }
  }





  logout() {
      this.authService.logout();
      this.orderService.clearContext();
        this.authService.redirectLogoutUser();
    }


  getYear() {
    return new Date().getUTCFullYear();
  }


  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }


  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }



  get canViewCustomers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewCustomersPermission
  }

  get canViewProducts() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewProductsPermission
  }

  get canViewOrders() {
    return true; //eg. viewOrdersPermission
    }

    public static SendEventGa(inEventCategory: string, inEventLabel: string | number, inEventAction: string, inEventValue: number = 0) {
        (<any>window).ga('send', 'event', {
            eventCategory: inEventCategory,
            eventLabel: inEventLabel,
            eventAction: inEventAction,
            eventValue: inEventValue
        });
    }
    
    getBackground() {
        if (window.location.pathname == '/')
            return 'backed_main_page';
        else
            return 'backed_main_container';
    }
    get isMainPage(): boolean {
        return window.location.pathname == '/';
    }

    get isSePage(): boolean {
        return window.location.pathname == '/se';
    }

    get isRegisterPage(): boolean {
        return window.location.pathname == '/rejestracja';
    }


    removeAccountDialog() {
        let newDialog: DialogData = {
            messageTitle: "Ostrzeżenie",
            messageBody: "Czy na pewno chcesz usunąć swoje konto z systemu?",
            url: null,
            confirmCallback: null
        };
        setTimeout(() => {
            const dialogRef = this.dialog.open(AccountRemovecomponent, {
                width: '350px'
            });
        }, 1);

    }
}
