import { Component } from '@angular/core';

import { Busket, Category, Sku, Series, OrderSet, OrderSetItem, Color, CategoryGroup, Project, Room, SubSeries } from '../_models/catalog';
import { SkuPosition } from '../services/orders.service';
import { FunctionHelperComponent } from '../components/b2b/function-helper.component';



export class ConfigurationSet {
    
    fH: FunctionHelperComponent;
    project: Project;
    projectTitle: string;
    rooms: Room[];
    currentRoom: Room;
    selectedSeries: Series;
    selectedSubSeries: SubSeries;
    selectedColorSku: Sku;
    selectedShapeSku: Sku;
    selectedWallColor: string;
    selectedFunctions: SkuPosition[];

    /** projectConfig ctor */
    constructor(projektName:string = 'NowyProjekt') {
        this.project = null;
        this.projectTitle = projektName;
        this.rooms = null;
        this.currentRoom = null;
        this.selectedSeries = null;
        this.selectedColorSku = null;
        this.selectedShapeSku = null;
        this.selectedWallColor = 'white';
        this.selectedFunctions = null;
        this.fH = null;

    }
    public addRoom(room: Room): boolean {
        if (this.rooms.find(r => r.id == room.id) != null) {
            return false;
        }
        this.currentRoom = room;
        this.rooms.push(room);
        return true;
    }

    public updateRoom(room: Room): any {
        this.currentRoom = room;
        var urId = this.rooms.findIndex(r => r.id == room.id);
        if (urId < 0) {
            return false;
        } else {
            //this.rooms[urId].title = room.title;
            //this.rooms[urId].wallColor = room.wallColor;
            this.rooms[urId] = room;
        }
    }
}
