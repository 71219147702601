import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models/index';

@Injectable()
export class UserService {
    activate(user: User) {
        return this.http.post('/api/Registration/activation', user);
    }
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>('/api/users');
    }

    getById(id: number) {
        return this.http.get('/api/users/' + id);
    }

    create(user: User) {
        return this.http.post('/api/Account/users', user);
    }
    register(user: any) {
        user.newPassword = encodeURIComponent(user.newPassword);
        console.log('password: ' + user.newPassword);
        return this.http.post('/api/Registration/users', user);
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user);
    }

    delete(id: string) {
        //return this.http.delete('/api/account/users/' + id);
        return this.http.delete('/api/Registration/users/delete/' + id); 
        
    }

    initReset(user: User) {
        return this.http.post('/api/Registration/users/initPasswordReset', user);
    }
    changePass(user: any) {

        user.newPassword = encodeURIComponent(user.newPassword);
        return this.http.post('/api/Registration/users/updatePass', user);
    }

    requestCode(user: any) {
        return this.http.post('/api/Registration/users/requestCode', user);
    }
}
