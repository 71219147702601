import { HttpClient } from "@angular/common/http";
import { AfterContentInit, AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ToastyConfig, ToastyService } from 'ng2-toasty';
import { Subscription } from "rxjs";
import { BootstrapTabDirective } from "../../directives/bootstrap-tab.directive";
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTitleService } from '../../services/app-title.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { NotificationService } from "../../services/notification.service";
import { OrderService } from '../../services/orders.service';
import { Series, Sku } from "../../_models/catalog";
import { ExportConfigComponent } from "../controls/export-config.component";
import { FramesSelectionComponent } from "./frames-selection.component";
import { FunctionSelectionComponent } from "./function-selection.component";
import { ProjectSelectionComponent } from "./project-selection.component";
import { RoomSelectionComponent } from "./rooms-selection.component";
import { SeriesSelectionComponent } from "./series-selection.component";
import { B2CSummaryComponent } from "./summary.component";
import { EcommerceRedirectComponent } from "../controls/ecommerce-redirect.component";
import { ColorSelectionDialogComponent } from "./controls/colorselection.component";
import { AppComponent } from "../app.component";






var alertify: any = require('../../assets/scripts/alertify.js');


@Component({
  selector: "app-b2c-main",
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss'],
    animations: [fadeInOut],
    encapsulation: ViewEncapsulation.None
})
export class B2CConfigComponent implements AfterContentInit, OnInit, OnDestroy, AfterViewInit{

    @ViewChild("tab", { static: true })
    tab: BootstrapTabDirective;

    @ViewChild("rooms", { static: false })
    private rooms: RoomSelectionComponent;

    @ViewChild("projects", { static: false })
    private projects: ProjectSelectionComponent;

    @ViewChild(SeriesSelectionComponent, { static: false })
    private sSC: SeriesSelectionComponent;

    @ViewChild(FramesSelectionComponent, { static: false })
    private fSC: FramesSelectionComponent;

    @ViewChild("functions", { static: false })
    public functions: FunctionSelectionComponent;

    @ViewChild("summary", { static: false })
    public summary: B2CSummaryComponent;
    selectedFrameSku() { return null };
    selectedFrameSkuColorTitle() { return "" };
    

    ngAfterViewInit(): void {
        
        setTimeout(() => this.selectedFrameSku = () => this.orderService.selectedFrameColor, 0);
        setTimeout(() => this.selectedFrameSkuColorTitle = () => {
            
            if (this.orderService.selectedFrameColorSku != null) {
                console.log('B2CConfigComponent:' + this.orderService.selectedFrameColorSku.color.title);
                return this.orderService.selectedFrameColorSku.color.title;
            }else
                return null;
        }, 0);

        
        setTimeout(() => {
            this.initProject();
        }, 2);
        
    }
    ngAfterContentInit(): void {
        console.log('B2CConfig - ngAfterViewInit');

        if (this.router.url.split('#').length == 1) this.showContent('projekty');
        else this.showContent(this.router.url.split('#')[1]);
    }
    initProject() {
        if (this.orderService.projects == null || this.orderService.projects.length == 0) {
            if (this.projects != null) {
                this.projects.newProjectConfig();
            }
        }
    }
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;

    projectName = 'Nowy Projekt';
    isProjectActivated = true;
    isRoomsActivated = false;
    isSeriesActivated = false;
    isFramesActivated = false;
    isFunctionsActivated = false;
    isSummaryActivated = false;
    readonly projectsTab = "projekty";
    readonly roomsTab = "pokoje";
    readonly seriesTab = "serie";
    readonly framesTab = "ramki";
    readonly functionsTab = "funkcje";
    readonly summaryTab = "zestawienie";
    fragmentSubscription: any;

    selectedSeries: Series;
    selectedFrameShapeSku: Sku;


    
    onShowTab(event) {
        
        let activeTab;
        if (event.target.hash != null) {
            activeTab = event.target.hash.split("#", 2).pop();
        }
        console.log("onShowTab: " + activeTab);

        this.isFunctionsActivated = activeTab == this.functionsTab;
        this.isProjectActivated = activeTab == this.projectsTab;
        this.isRoomsActivated = activeTab == this.roomsTab;
        this.isSeriesActivated = activeTab == this.seriesTab;
        this.isFramesActivated = activeTab == this.framesTab;
        this.isSummaryActivated = activeTab == this.summaryTab;
        if (!this.isProjectActivated) {
            $('#projectsTab').removeClass('active');
        }

        if (!this.isRoomsActivated) {
            $('#roomsTab').removeClass('active');
        }
        if (!this.isSeriesActivated) {
            $('#seriesTab').removeClass('active');
        }
        if (!this.isFramesActivated) {
            $('#framesTab').removeClass('active');
        }
        if (!this.isFunctionsActivated) {
            $('#functionsTab').removeClass('active');
        }
        if (!this.isSummaryActivated) {
            $('#summaryTab').removeClass('active');
        }
    }
    prevTab: string = null;
    openSummaryUrl() {
        if (this.isProjectActivated) {
            this.prevTab = this.projectsTab;
        } else if (this.isRoomsActivated) {
            this.prevTab = this.roomsTab;
        } else if (this.isSeriesActivated) {
            this.prevTab = this.seriesTab;
        }
        else if (this.isFramesActivated) {
            this.prevTab = this.framesTab;
        }
        else if (this.isFunctionsActivated) {
            this.prevTab = this.functionsTab;
        }
        else if (this.isSummaryActivated) {
            this.prevTab = this.summaryTab;
        }
        this.router.navigateByUrl("/wybierz-design#zestawienie")
    }
    openPrevUrl(url: string = null) {
        if (url != null)
            this.router.navigateByUrl("/wybierz-design#" + url)
        else
            this.router.navigateByUrl("/wybierz-design#" + this.prevTab)
    }
    openTab(tab: string) {
        document.getElementsByClassName("navbar-collapse")[0].classList.remove('show')
        console.log("openTab: " + tab);
        if (tab == null)
            tab = this.projectsTab;
               
        this.isProjectActivated = tab == this.projectsTab;
        this.isRoomsActivated = tab == this.roomsTab;
        this.isSeriesActivated = tab == this.seriesTab;
        this.isFramesActivated = tab == this.framesTab;
        this.isFunctionsActivated = tab == this.functionsTab;
        this.isSummaryActivated = tab == this.summaryTab;
        if (!this.isProjectActivated) {
            $('#projectsTab').removeClass('active');
            $("#projectsButton").removeClass('active');
        }
        if (!this.isRoomsActivated) {
            $('#roomsTab').removeClass('active');
            $("#roomsButton").removeClass('active');
        }
        if (!this.isSeriesActivated) {
            $('#seriesTab').removeClass('active');
            $("#seriesButton").removeClass('active');
        }
        if (!this.isFramesActivated) {
            $('#framesTab').removeClass('active');
            $("#framesButton").removeClass('active');
        }
        if (!this.isFunctionsActivated) {
            $('#functionsTab').removeClass('active');
            $("#functionsButton").removeClass('active');
        }
        if (!this.isSummaryActivated) {
            $('#summaryTab').removeClass('active');
            $("#summaryButton").removeClass('active');
        }
    }

    showContent(anchor: string) {
        var otab = 'projects';
        switch (anchor) {
            case this.projectsTab:
                otab = 'projects';
                break;
            case this.roomsTab:
                otab = 'rooms';
                break;
            case this.seriesTab:
                otab = 'series';
                break;
            case this.framesTab:
                otab = 'frames';
            case this.functionsTab:
                otab = 'functions';
                break;
            case this.summaryTab:
                otab = 'summary';
                break;
        }
        this.tab.show('#'+otab+'Tab');
        this.tab.show('#'+otab+'Button');
        if (this.functions!=null ) this.functions.fullscreen = false;
        this.openTab(anchor);
    }
    
    isFragmentEquals(fragment1: string, fragment2: string) {

        if (fragment1 == null)
            fragment1 = "";

        if (fragment2 == null)
            fragment2 = "";

        return fragment1.toLowerCase() == fragment2.toLowerCase();
    }

    orderServicesubscription: Subscription;
    
    _opacity = 'opacity1';
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, storageManager: LocalStoreManager, private toastyService: ToastyService, private toastyConfig: ToastyConfig,
    private accountService: AccountService, private alertService: AlertService, private notificationService: NotificationService, private appTitleService: AppTitleService,
        private authService: AuthService, private translationService: AppTranslationService, public configurations: ConfigurationService, public router: Router,
        public orderService: OrderService, public dialog: MatDialog) {

    storageManager.initialiseStorageSyncListener();

        orderService.currentSeries$.subscribe(
            selectedSeries => {
                console.log("ConfigComponent: event currentSeries: ${selectedSeries}");
                /*this.selectedSeries = cSeries;*/
            });
        this.orderServicesubscription = orderService.selectedSeries$.subscribe(
            series => {
                console.log("ConfigComponent: event currentSeries: ${series}");
                this.selectedSeries = series;
            }
        )
        this.orderServicesubscription = orderService.selectedFrameShape$.subscribe(
            sku => {
                console.log("ConfigComponent: eventReceived for selectedFrameColor: " + sku.id + sku.code);
                console.log("ConfigComponent: eventReceived for selectedFrameColor: " + orderService.selectedFrameShapeSku.functionType.title);
                this.selectedFrameShapeSku = sku;
            }
        )
        console.log("ConfigComponent.orderService:" + orderService.instance);
  }
    
    receiveRoomSelectionMessage($event) {
        console.log('receiveRoomSelectionMessage:' + $event);
        this._opacity = $event
    }

    receiveProjectSelectionMessage($event) {
        console.log('receiveProjectSelectionMessage:' + $event);
    }
    

    receiveSeriesSelectionMessage($event) {
        console.log('receiveSeriesSelectionMessage:' + $event);
        this.selectedSeries = $event;
    }

    ngOnInit() {
        console.debug("b2c config onInit");
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
    setTimeout(() => this.isAppLoaded = true, 100);
        setTimeout(() => this.removePrebootScreen = true, 100);

  }


    ngOnDestroy() {
        this.orderServicesubscription.unsubscribe();
    }

    /*
    selectColour(color: string) {
        this.rooms.selectColour(color);

    }*/
    //backgroundStyles = ['white', 'Mercurius', 'Venus', 'Portia', 'Jupiter', 'Hyperion', 'Triton', 'Hydra', 'Calypso', 'Ariel', 'Janus', 'Galatea', 'Mars', 'Deimus', 'Uranus', 'Luna', 'Rosalind', 'Elara', 'Cressida', 'Desdemona', 'Copper', 'Juliet', 'Sole', 'Terra',
    //    'Umbriel', 'Silver', 'Gold'];
    //backgroundStyles = ['white', 'Mercurius', 'Venus', 'Portia', 'Jupiter', 'Hyperion', 'Hydra', 'Calypso', 'Ariel', 'Janus', 'Galatea', 'Mars', 'Uranus', 'Luna'];//, 'Rosalind', 'Elara', 'Cressida', 'Copper', 'Juliet', 'Sole','Umbriel'];
    backgroundStyles = ['white', '#88AFDA', '#B9ADB9', '#5D7696', '#3D629F', '#D9EDE3', '#459370', '#E7B6C9', '#73733F', '#57605E', '#F2E6C1', '#EAC14E', '#BB9446'];
    fontStyles = ['green', 'green', 'green', 'white', 'white', 'green', 'white', 'green', 'white', 'white', 'green', 'green', 'white'];

    backgroundStyle: string = 'white';
    selectColor(i: number) {
        this.orderService.setWallColor(this.backgroundStyles[i], this.fontStyles[i]);
        
        //this.backgroundColor = color;
        var bck = null;
        //
        if (this.isFunctionsActivated)
            bck = document.getElementById('frameBackbround')
        if (this.isFramesActivated)
            bck = document.getElementById('frameCollectionContainer')
        if (bck != null)
            bck.style.backgroundImage = 'unset';
    }
    otherColor() {
        const dialogRef = this.dialog.open(ColorSelectionDialogComponent, {maxWidth:'100%',
            data: { os: this.orderService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null)
                this.orderService.setWallColor(result, this.fontStyles[0]);
        });
    }
    get roomColor(): string {
        return this.orderService.roomWallColor;
    }

    changeRoom() {
        this.router.navigateByUrl('/wybierz-design#pokoje');
        
    }
    openFunctions() {
        this.router.navigateByUrl('/wybierz-design#funkcje');

    }
    openSummary() {
        console.log('goto summary');
        this.router.navigateByUrl('/wybierz-design#zestawienie');
        //this.router.navigateByUrl('/wybierz-design#!pokoje');

    }
    anyProjectOpened() {
        return this.orderService.projects != null && this.orderService.openedProjectIndex != null &&
            this.orderService.openedProjectIndex >= 0 &&
            this.orderService.projects[this.orderService.openedProjectIndex] != null;
    }


    openNav() {
    document.getElementById("mySidenav").style.width = "250px";
}

/* Set the width of the side navigation to 0 */
closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}

    changeProject(openedProjectIndex: number) {
        if (this.projects != null) {
            this.projects.changeProject(openedProjectIndex);
        } else {
            this.router.navigateByUrl('/wybierz-design#projekty');
        }
    }

    public files: any[];

    contructor() { this.files = []; }

    onFileChanged(event: any) {
        this.files = event.target.files;
        var getImagePath = URL.createObjectURL(event.target.files[0]);
        //document.getElementById('app_container').style.backgroundImage = 'url(' + getImagePath + ')';
        var bck = null;
        if (this.isFunctionsActivated) {
            console.log("set bck for: frameBackbround"+ getImagePath)
            bck = document.getElementById('frameBackbround')
        }if (this.isFramesActivated)
            bck = document.getElementById('frameCollectionContainer')
        if (bck != null)
            bck.style.backgroundImage = 'url(' + getImagePath + ')';
    }

    get collectedProjectSkus() {
        return this.orderService != null ? this.orderService.collectedProjectSkus : 0;
    }

    exportCart() {
        AppComponent.SendEventGa('cart', null, "Otworzenia okna z eksportem do sklepów", 0);
        const dialogRef = this.dialog.open(ExportConfigComponent, {
            data: { os: this.orderService, p: this.orderService.cProject }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }
    cancelSeries() {
        this.fSC.cancelSeries();
    }

    forwardToEcommerce() {
        this.orderService.openEcommerce();
    }
    logAndSubmit(shop: string, formId: string, orderId: string, orderService: OrderService) {
        event.preventDefault();
        orderService.updateOrder(orderId, "redirected");
        AppComponent.SendEventGa('cart', shop, "Otwarcie przekierowania do sklepu", 0);

        var form = <HTMLFormElement>document.getElementById(formId);
        form.submit();
    }
    get contentLoading(): boolean {
        return this.orderService.contentLoading;
    }
}
