import { Component, Input } from '@angular/core';
import { OrderService } from '../../services/orders.service';
import { ProjectNameDialog } from '../controls/project-name.component';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Project, Room, OrderSet } from '../../_models';
import { Router } from '@angular/router';
import { MyStepper } from './config.component';
import { AppComponent } from '../app.component';

@Component({
    selector: 'b2b-room-selection',
    templateUrl: './room-selection.component.html',
    styleUrls: ['./room-selection.component.scss']
})
/** ProjectSelection component*/
export class B2BRoomSelectionComponent {
    roomSelected: number = null;
    @Input() stepper: MyStepper;
    /** ProjectSelection ctor */
    constructor(public orderService: OrderService,
        public dialog: MatDialog, public router: Router) {

    }
    roundToTwo(num: number) {
        return Math.round(num * 100) / 100;
    }
    sumNet(r: Room) {
        let count = 0;
        let sum = 0;
        if (r.orderSets != null)
            for (let os of r.orderSets) {
                if (os.orderSetItems != null)
                    for (let p of os.orderSetItems) {
                        sum += p.qty * p.sku.net;
                        count += p.qty;
                    }
            }
        return [(Math.round(sum * 100) / 100).toFixed(2).replace('.', ','),count];
    }
    openRoom(i: number) {
        AppComponent.SendEventGa('sets', null, 'B2B_otwarcie zestawu', 0);
        if (this.orderService.cProject.rooms != null && this.orderService.cProject.rooms.length > 1) {
            this.orderService.openRoom(i);
            //this.selectRooms = true;
        } else {
            this.orderService.openRoom(i);
        }
        this.stepper.next();
    }
    changeRoom(i: number): void {
        AppComponent.SendEventGa('sets', null, 'B2B_otwarcie modyfikacji pomieszczenia', i);
        const dialogRef = this.dialog.open(ProjectNameDialog, {
            width: '250px',
            data: { projectName: this.orderService.cProject.rooms[i].title }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed: room name');
            if (result != null) {
                this.orderService.cProject.rooms[i].title = result;
                this.orderService.saveChangesProjectById(i);
                //if (i == this.projectSelected)
                //    this.orderService.setProjectName(result);

            }
        });

        this.orderService.saveCurrentContextOld();
    }
    duplicateRoom(i: number) {
        AppComponent.SendEventGa('sets', null, 'B2B_skopiowanie zestawu', 0);
        this.orderService.duplicateRoom(i);
    }
    deleteRoom(i: number) {
        AppComponent.SendEventGa('sets', null, 'B2B_usunięcie zestawu', 0);
        this.orderService.delRoom(this.orderService.cProject.rooms.length-i-1);
    }
    newRoomConfig() {
        AppComponent.SendEventGa('sets', null, 'B2B_otwarcie konfiguracji nowego pomieszczenia', 0);
        let newRoom:Room = new Room("Zestaw x");
        const dialogRef = this.dialog.open(ProjectNameDialog, {
            width: '250px',
            data: { projectName: newRoom.title }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                newRoom.title = result;
                this.addAndOpenRoom(newRoom);
            }
        });
    }
    private addAndOpenRoom(r: Room) {
        this.orderService.addRoom(r);
    }

}
